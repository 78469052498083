import { FunctionComponent } from "react";
import styled, { useTheme } from "styled-components";
import {
  selectIsCoHost,
  selectOtherHosts,
} from "../../store/features/eventSlice";
import { useAppSelector } from "../../store/hooks";
import { JoinPageViews } from "../../types/views";

interface JoinPageHostListProps {
  view: JoinPageViews;
}

const HostsList = styled.div<{ color?: string }>`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 160%;
  color: ${(props) => props.color || props.theme.color.black};
`;

const ByText = styled.span`
  color: ${(props) => props.theme.color.lightGray};
`;

const HostNames = styled.span``;

const JoinPageHostList: FunctionComponent<JoinPageHostListProps> = ({
  view,
}) => {
  const theme = useTheme();
  const otherHosts = useAppSelector(selectOtherHosts);
  const isCoHost = useAppSelector(selectIsCoHost);

  return (
    <HostsList
      color={
        view === JoinPageViews.ARCHIVE ||
        view === JoinPageViews.ARCHIVE_RESTRICTED
          ? theme.color.lightGray
          : undefined
      }
    >
      <ByText>By</ByText>
      <HostNames>
        {" "}
        {isCoHost && "You"}
        {isCoHost && otherHosts.length > 1 && ", "}
        {isCoHost && otherHosts.length === 1 && " and"}
      </HostNames>
      {otherHosts?.map((val, ind) => (
        <HostNames key={val.id}>
          {otherHosts.length > 1 && otherHosts.length - 1 === ind && " and "}{" "}
          {val.name}
          {otherHosts.length > 1 && otherHosts.length - 2 > ind && ", "}
        </HostNames>
      ))}
    </HostsList>
  );
};

export default JoinPageHostList;
