import React from "react";
import styled, { useTheme } from "styled-components";
import { InputContainer, InputName } from "../JoinSocial/JoinDetails";
import ChevronIcon from "../../assets/ChevronIcon";
import OutsideClickHandler from "react-outside-click-handler";
import {
  createSlackChannel,
  joinExistingSlackChannel,
  getSlackChannelsList,
  ChannelsListItem,
} from "../../API/slack";
import { useAppSelector } from "../../store/hooks";
import { selectActiveOrganization } from "../../store/features/userSlice";
import { selectEventDetails } from "../../store/features/eventSlice";
import SlackSuccssOrErrorPrompt from "./SlackSuccssOrErrorPrompt";
import { GreenTick } from "../../assets/GreenTick";
import AlertTriangleIcon from "../../assets/AlertTriangleIcon";
import { TruncatedName } from "../common/Styled";
import MotionButton from "../common/MotionButton";

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: ${(props) => props.theme.color.white};
  box-shadow: 0px 4px 16px -2px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  padding: 24px 12px 12px;
  border-radius: 28px;
`;

export const Bold = styled.span`
  font-weight: 600;
`;

export const Heading = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 28px;
  line-height: 140%;
  align-items: center;
  text-align: center;
  color: ${(props) => props.theme.color.black};
  margin-top: 24px;
  margin-bottom: 16px;
  max-width: 455px;
`;

const CreatSlackHeading = styled(Heading)`
  text-align: start;
  margin-top: 0;
`;

const SubHeading = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 160%;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${(props) => props.theme.color.darkGray};
`;

const CreateChannelFormWrapper = styled.div`
  margin-bottom: 16px;
`;

const Error = styled.div`
  margin-top: 8px;
  display: flex;
  width: 479px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 160%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;
  color: ${(props) => props.theme.color.red500};
`;

const Label = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 160%;
  letter-spacing: -0.01em;
  color: ${(props) => props.theme.color.darkGray};
  margin-bottom: 8px;
`;

const ChannelNameInput = styled(InputName)`
  width: 479px;
  border-radius: 16px;
  padding-left: 40px;
  font-family: "Inter";

  &::placeholder {
    content: attr(placeholder);
    font-style: normal;
    opacity: 1;
  }
`;

const ChannelNameInputContainer = styled(InputContainer)`
  position: relative;
`;

const ChannelSelectContainer = styled(InputContainer)`
  position: relative;
`;

const FormContianer = styled.div<{ isError: boolean }>`
  opacity: ${({ isError }) => (isError ? 0.5 : 1)};
  pointer-events: ${({ isError }) => (isError ? "none" : "all")};
`;

const Seperator = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 160%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 16px;
  letter-spacing: -0.01em;
  color: ${(props) => props.theme.color.lightGray};
`;

const HashIcon = styled.div`
  font-weight: 700;
  position: absolute;
  color: ${(props) => props.theme.color.lightGray};
  top: 35%;
  left: 23px;
  z-index: 1;
`;

const DropDownHashIcon = styled(HashIcon)`
  top: 28%;
`;

const DropDownOptionHashIcon = styled(HashIcon)`
  top: 29%;
`;

const ChannelSelectInput = styled.div<{ isDropDownOpen: boolean }>`
  width: 479px;
  height: 64px;
  border-radius: 16px;
  background: ${({ isDropDownOpen, theme }) =>
    isDropDownOpen ? theme.color.white : theme.lounge.background};
  color: ${(props) => props.theme.color.darkGray};
  font-size: 16px;
  box-sizing: border-box;
  padding: 10px;
  position: relative;

  border: 1px solid ${(props) => props.theme.color.backgroundGray};
  border-bottom: ${({ isDropDownOpen }) => isDropDownOpen && "none"};
  border-bottom-left-radius: ${({ isDropDownOpen }) => isDropDownOpen && "0px"};
  border-bottom-right-radius: ${({ isDropDownOpen }) =>
    isDropDownOpen && "0px"};

  visibility: ${({ isDropDownOpen }) => isDropDownOpen && "hidden"};
`;

const ChannelOption = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: ${(props) => props.theme.color.darkGray};
`;

const ErrorSubHeading = styled(SubHeading)`
  display: inline-block;
`;

const ChannelOptionsContainer = styled.div`
  position: absolute;
  background: ${(props) => props.theme.color.white};
  width: 477px;
  height: 198px;
  overflow-y: scroll;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  border: 1px solid ${(props) => props.theme.color.backgroundGray};

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const ChannelSelectInputOption = styled.div`
  width: 479px;
  border-radius: 16px;
  color: ${(props) => props.theme.color.darkGray};
  font-size: 16px;
  box-sizing: border-box;
  position: relative;
  background: ${(props) => props.theme.color.white};
  height: 43px;
  padding: 5px 10px;
  cursor: pointer;
`;

const ChannelOptionText = styled.div`
  z-index: 1;
  margin-left: 2rem;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ShadowElement = styled.div`
  border-radius: 8px;
  height: 38px;
  display: flex;
  align-items: center;

  &:hover {
    background: ${(props) => props.theme.color.backgroundGray};
  }
`;

const CaretIcon = styled.div`
  position: absolute;
  right: 22px;
  z-index: 1;
`;

const GrayText = styled.span`
  color: ${(props) => props.theme.color.lightGray};
`;

const SlackAddOrSelectForm: React.FC = () => {
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const eventDetails = useAppSelector(selectEventDetails);

  const [channelName, setChannelName] = React.useState("");
  const [selectedChannelId, setSelectedChannelId] = React.useState(0);
  const [selectedChannel, setSelectedChannel] =
    React.useState<ChannelsListItem>();
  const [showDropDown, setShowDropDown] = React.useState(false);
  const [slackChannelsList, setSlackChannelsList] = React.useState<
    ChannelsListItem[]
  >([]);
  const [showCreateError, setShowCreateError] = React.useState(false);
  const [showSelectError, setShowSelectError] = React.useState(false);
  const [channelNameValidationError, setChannelNameValidationError] =
    React.useState(false);
  const [showAddorSelectForm, setShowAddorSelectForm] = React.useState(true);

  const theme = useTheme();

  React.useEffect(() => {
    if (activeOrganization && activeOrganization.organization) {
      (async () => {
        const response = await getSlackChannelsList({
          organizationId: activeOrganization.organization.id,
        });

        if (response && response.length > 0) {
          setSlackChannelsList(response);
        }
      })();
    }
  }, [activeOrganization]);

  React.useEffect(() => {
    setSelectedChannel(slackChannelsList[selectedChannelId]);
  }, [slackChannelsList, selectedChannelId]);

  const createSlackChannelHandler = () => {
    if (channelName === "" || channelNameValidationError) return;

    (async () => {
      const response = await createSlackChannel({
        organizationId: activeOrganization.organization.id,
        eventId: eventDetails?.eventId,
        organizationName: channelName,
      });

      if (!response) {
        setShowCreateError(true);
        setShowAddorSelectForm(true);
        return;
      }

      setShowAddorSelectForm(false);
    })();
  };

  const joinChannelHandler = () => {
    if (!selectedChannel) return;

    (async () => {
      const response = await joinExistingSlackChannel({
        organizationId: activeOrganization.organization.id,
        eventId: eventDetails?.eventId,
        externalId: selectedChannel?.id,
      });

      if (!response) {
        setShowSelectError(true);
        setShowAddorSelectForm(true);
        return;
      }

      setShowAddorSelectForm(false);
    })();
  };

  const onChangeChannelNameHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const targetValue = e.target.value;
    setChannelNameValidationError(false);
    setChannelName(targetValue.toLocaleLowerCase());

    if (targetValue.trim().length > 80) {
      return false;
    }

    if (!targetValue.match(/^[a-zA-Z0-9-_]{0,80}$/)) {
      setChannelNameValidationError(true);
    }
  };

  React.useEffect(() => {
    if (showCreateError && showSelectError) {
      setShowAddorSelectForm(false);
    }
  }, [showCreateError, showSelectError]);

  return (
    <>
      {showAddorSelectForm ? (
        <Box>
          <CreatSlackHeading> Create a Slack channel </CreatSlackHeading>
          {/* Create New Channel with name */}
          <FormContianer isError={showCreateError}>
            <CreateChannelFormWrapper>
              <Label>New Channel</Label>
              <ChannelNameInputContainer margin={" 0 0 8px"}>
                <ChannelNameInput
                  type="text"
                  placeholder="thursday"
                  value={channelName}
                  onChange={onChangeChannelNameHandler}
                  showError={false}
                  disabled={showCreateError}
                />
                <HashIcon>#</HashIcon>
              </ChannelNameInputContainer>
              {channelNameValidationError ? (
                <Error>
                  Channel names can't contain spaces, periods, or most
                  punctuation.
                </Error>
              ) : (
                <SubHeading>
                  We recommend naming your channel&nbsp;<GrayText>#</GrayText>
                  <Bold>thursday</Bold>&nbsp;or&nbsp;<GrayText>#</GrayText>
                  <Bold>thursday-social</Bold>
                </SubHeading>
              )}
            </CreateChannelFormWrapper>
            <MotionButton
              clickHandler={createSlackChannelHandler}
              backgroundColor={showCreateError ? "lightGray" : "purple500"}
              backgroundColorHover="purple600"
              textColor="white"
              textColorHover="white"
              borderRadius={16}
              height={56}
            >
              Create Channel
            </MotionButton>
          </FormContianer>
          {showCreateError && (
            <Error>
              You do not have the appropriate permissions to create a new
              channel in your Slack workspace. Ask your admin to create one.
            </Error>
          )}
          <Seperator>OR</Seperator>

          {/* Select channel from existing list */}
          <FormContianer isError={showSelectError}>
            <CreateChannelFormWrapper>
              <Label>Existing Channel</Label>
              <OutsideClickHandler
                onOutsideClick={() => setShowDropDown(false)}
              >
                <ChannelSelectContainer margin={"0 0 8px"}>
                  <ChannelSelectInput
                    onClick={() => setShowDropDown(!showDropDown)}
                    isDropDownOpen={showDropDown}
                  >
                    <ShadowElement>
                      <ChannelOption>
                        <DropDownHashIcon>#</DropDownHashIcon>
                        <ChannelOptionText>
                          {selectedChannel?.channelName}
                        </ChannelOptionText>
                        <CaretIcon>
                          <ChevronIcon
                            color={theme.color.lightGray}
                            rotateRight={showDropDown}
                            data-notclickable
                          />
                        </CaretIcon>
                      </ChannelOption>
                    </ShadowElement>
                  </ChannelSelectInput>
                  {showDropDown && (
                    <ChannelOptionsContainer>
                      {slackChannelsList.map(({ channelName, id }, index) => (
                        <ChannelSelectInputOption
                          key={id}
                          onClick={() => {
                            setSelectedChannelId(index);
                            setShowDropDown(false);
                          }}
                        >
                          <ShadowElement>
                            <ChannelOption>
                              <DropDownOptionHashIcon>#</DropDownOptionHashIcon>
                              <ChannelOptionText>
                                {channelName}
                              </ChannelOptionText>
                              <CaretIcon>
                                <ChevronIcon
                                  color={theme.color.lightGray}
                                  rotateRight={true}
                                  data-notclickable
                                />
                              </CaretIcon>
                            </ChannelOption>
                          </ShadowElement>
                        </ChannelSelectInputOption>
                      ))}
                    </ChannelOptionsContainer>
                  )}
                </ChannelSelectContainer>
              </OutsideClickHandler>
            </CreateChannelFormWrapper>
            <MotionButton
              clickHandler={joinChannelHandler}
              backgroundColor={
                showSelectError
                  ? "lightGray"
                  : showCreateError
                  ? "purple500"
                  : "purple100"
              }
              textColor={
                showSelectError || showCreateError ? "white" : "purple500"
              }
              backgroundColorHover={showCreateError ? "purple600" : "purple200"}
              textColorHover={showCreateError ? "white" : "purple500"}
              borderRadius={16}
              height={56}
            >
              Use existing channel
            </MotionButton>
          </FormContianer>
          {showSelectError && (
            <Error>
              You do not have the appropriate permissions to create a new
              channel in your Slack workspace. Ask your admin to create one.
            </Error>
          )}
        </Box>
      ) : (
        <SlackSuccssOrErrorPrompt
          icon={
            showSelectError && showCreateError ? (
              <AlertTriangleIcon size={80} />
            ) : (
              <GreenTick width={80} height={80} />
            )
          }
          headingText={
            showSelectError && showCreateError
              ? "Unable to install app in your Slack workspace"
              : "Slack app installed"
          }
          subHeading={
            showSelectError && showCreateError ? (
              <ErrorSubHeading>
                You do not have the appropriate permissions to install an app{" "}
                <br /> in your Slack workspace.{" "}
                <Bold>Ask your admin to install the app.</Bold>
              </ErrorSubHeading>
            ) : (
              <SubHeading>
                Check for&nbsp;
                <TruncatedName maxWidth={130}>
                  <Bold>#{channelName || selectedChannel?.channelName}</Bold>
                </TruncatedName>
                &nbsp;channel in your workspace
              </SubHeading>
            )
          }
        />
      )}
    </>
  );
};

export default SlackAddOrSelectForm;
