import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getSegmentThemeAPI,
  getThemesDetails,
  getThemesForActivity,
  selectThemeForSegment,
} from "../../API/common";
import { RootState } from "../store";
import { Theme } from "./mixerSlice";

export interface MixerThemesState {
  videoThemes: Theme[] | undefined;
  themeDetails?: Theme | null;
  currentSegmentThemeDetails?: Theme;

  themesMap: { [key: string]: Theme[] };
}

const initialState: MixerThemesState = {
  videoThemes: [],

  themesMap: {},
};

const FetchActivityThemes = async ({
  activityId,
  activityType,
}: {
  activityId: string;
  activityType: string;
}) => {
  const data = await getThemesForActivity(activityId);
  return data;
};

export const updateMixerTheme = createAsyncThunk(
  "updateMixerTheme",
  async ({
    segmentId,
    themeId,
  }: {
    segmentId: string;
    themeId: string | null;
  }) => {
    const response = await selectThemeForSegment({
      segmentId: segmentId,
      themeId,
    });
    return response;
  },
  {
    condition: (args, { getState }) => {
      const { segmentId, themeId } = args;
      const { lounge }: any = getState();
      const segments = lounge.segments;

      const segment = segments.filter(
        (seg: any) => seg.currentSegmentId === segmentId
      );

      if (segment[0].themeId === themeId) {
        return false;
      }
    },
  }
);

// Use this to fetch themes
export const getMixerThemes = createAsyncThunk(
  "getMixerThemes",
  FetchActivityThemes,
  {
    condition: (args, { getState }) => {
      const { mixerThemes }: any = getState();
      const { activityType } = args;
      const isActivityDataExists = !!mixerThemes.themesMap[activityType];
      if (isActivityDataExists) {
        return false;
      }
    },
  }
);

export const getLoungeVideoThemes = createAsyncThunk(
  "getLoungeVideoThemes",
  async (activityId: string) => {
    const response = await getThemesForActivity(activityId);
    return response;
  }
);

export const getThemeDetails = createAsyncThunk(
  "getThemeDetails",
  async (id: string) => {
    const response = await getThemesDetails(id);
    return response;
  }
);

export const getCurrentSegmentTheme = createAsyncThunk(
  "getSegmentTheme",
  async (id: string) => {
    const data = await getSegmentThemeAPI(id);
    return data;
  }
);

export const mixerThemesSlice = createSlice({
  name: "mixerThemes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMixerThemes.fulfilled, (state, action) => {
        const { activityType } = action.meta.arg;
        if (action.payload) {
          state.themesMap = {
            ...state.themesMap,
            [activityType]: action.payload,
          };
        }
      })
      .addCase(getLoungeVideoThemes.fulfilled, (state, action) => {
        state.videoThemes = action.payload;
      })
      .addCase(getThemeDetails.fulfilled, (state, action) => {
        state.themeDetails = action.payload;
      })
      .addCase(getCurrentSegmentTheme.fulfilled, (state, action) => {
        state.currentSegmentThemeDetails = action.payload;
      });
  },
});

export const selectThemesMap = (state: RootState) =>
  state.mixerThemes.themesMap;

export const selectVideoThemes = (state: RootState) =>
  state.mixerThemes.videoThemes;

export const selectThemeDetails = (state: RootState) =>
  state.mixerThemes.themeDetails;

export const selectCurrentSegmentThemeDetails = (state: RootState) =>
  state.mixerThemes.currentSegmentThemeDetails;

export default mixerThemesSlice.reducer;
