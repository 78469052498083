export const DoodleBrush = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.05957 11.9002L17.1296 3.84016C17.3926 3.56861 17.7071 3.35211 18.0546 3.20327C18.4022 3.05443 18.7759 2.97624 19.1539 2.97323C19.532 2.97023 19.9069 3.04248 20.2568 3.18578C20.6066 3.32907 20.9245 3.54055 21.1918 3.80789C21.4592 4.07523 21.6707 4.39309 21.814 4.74296C21.9572 5.09283 22.0295 5.46772 22.0265 5.84578C22.0235 6.22385 21.9453 6.59754 21.7965 6.94509C21.6476 7.29264 21.4311 7.6071 21.1596 7.87016L13.0996 15.9502"
        fill="white"
      />
      <path
        d="M9.05957 11.9002L17.1296 3.84016C17.3926 3.56861 17.7071 3.35211 18.0546 3.20327C18.4022 3.05443 18.7759 2.97624 19.1539 2.97323C19.532 2.97023 19.9069 3.04248 20.2568 3.18578C20.6066 3.32907 20.9245 3.54055 21.1918 3.80789C21.4592 4.07523 21.6707 4.39309 21.814 4.74296C21.9572 5.09283 22.0295 5.46772 22.0265 5.84578C22.0235 6.22385 21.9453 6.59754 21.7965 6.94509C21.6476 7.29264 21.4311 7.6071 21.1596 7.87016L13.0996 15.9502L12.9557 15.5644C12.3484 13.9356 11.0645 12.6503 9.43633 12.0411L9.05957 11.9002Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.07002 14.9399C5.41002 14.9399 4.07002 16.2899 4.07002 17.9599C4.07002 19.2899 1.57002 19.4799 2.07002 19.9799C3.15002 21.0799 4.56002 21.9999 6.07002 21.9999C8.27002 21.9999 10.07 20.1999 10.07 17.9599C10.0713 17.5647 9.99478 17.173 9.84473 16.8073C9.69468 16.4416 9.47407 16.1091 9.19549 15.8286C8.91691 15.5482 8.58583 15.3254 8.22114 15.1729C7.85645 15.0204 7.4653 14.9413 7.07002 14.9399V14.9399Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DoodleYellowBrush = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1211 23.8042L34.2611 7.68423C34.7872 7.14112 35.4161 6.70812 36.1112 6.41045C36.8063 6.11278 37.5537 5.95638 38.3098 5.95038C39.066 5.94437 39.8157 6.08887 40.5155 6.37546C41.2152 6.66205 41.8509 7.085 42.3856 7.61969C42.9203 8.15437 43.3433 8.79009 43.6299 9.48983C43.9164 10.1896 44.061 10.9393 44.0549 11.6955C44.0489 12.4516 43.8925 13.199 43.5949 13.8941C43.2972 14.5892 42.8642 15.2181 42.3211 15.7442L26.2011 31.9042"
        fill="#FFC200"
        stroke="#FFC200"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1405 29.8799C10.8205 29.8799 8.14053 32.5799 8.14053 35.9199C8.14053 38.5799 3.14053 38.9599 4.14053 39.9599C6.30053 42.1599 9.12053 43.9999 12.1405 43.9999C16.5405 43.9999 20.1405 40.3999 20.1405 35.9199C20.1432 35.1293 19.9901 34.346 19.69 33.6146C19.3898 32.8832 18.9486 32.2181 18.3915 31.6572C17.8343 31.0964 17.1721 30.6507 16.4428 30.3458C15.7134 30.0408 14.9311 29.8825 14.1405 29.8799V29.8799Z"
        stroke="#FFC200"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
