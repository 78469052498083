import React from "react";

const CopyLinkIcon: React.FC<{ color?: string; size?: string }> = ({
  color = "#6558F5",
  size = "20",
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.29688 10.289C8.58318 10.6718 8.94845 10.9885 9.36791 11.2177C9.78737 11.4468 10.2512 11.5831 10.728 11.6173C11.2047 11.6514 11.6833 11.5826 12.1311 11.4156C12.5789 11.2485 12.9856 10.9871 13.3235 10.649L15.3235 8.64904C15.9307 8.02037 16.2667 7.17836 16.2591 6.30437C16.2515 5.43038 15.901 4.59434 15.2829 3.97631C14.6649 3.35828 13.8289 3.00772 12.9549 3.00013C12.0809 2.99253 11.2389 3.32851 10.6102 3.93571L9.46354 5.07571"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9624 8.95588C10.6761 8.57313 10.3108 8.25642 9.89134 8.02725C9.47188 7.79808 9.00803 7.6618 8.53127 7.62766C8.05451 7.59351 7.57598 7.6623 7.12814 7.82936C6.6803 7.99642 6.27363 8.25783 5.93571 8.59588L3.93571 10.5959C3.32851 11.2246 2.99253 12.0666 3.00013 12.9406C3.00772 13.8145 3.35828 14.6506 3.97631 15.2686C4.59434 15.8866 5.43038 16.2372 6.30437 16.2448C7.17836 16.2524 8.02037 15.9164 8.64904 15.3092L9.78904 14.1692"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CopyLinkIcon;
