import {
  createAction,
  createAsyncThunk,
  createSlice,
  Draft,
  PayloadAction,
} from "@reduxjs/toolkit";
import axios from "axios";
import { fetchCurrentSegmentGroupPrompt } from "../../API/common";
import { TriviaTimerMessage } from "../../types/socketMessage";
import { RootState } from "../store";
import { Asset, PromptData } from "./questionSlice";
import {
  MIXER_BROKEN,
  MIXER_CLOSED,
  MIXER_DISCONNECT,
  MIXER_MESSAGE,
  MIXER_OPEN,
} from "./socketActionTypes";
import { SOCKET_CONTROL_MESSAGES } from "./ws";

export enum MixerStateOptions {
  INITIAL = "INITIAL",
  TRIAL = "TRIAL",
  RESET = "RESET",
  PLAY = "PLAY",
  SKIP = "SKIP",
  REVEALED = "REVEALED",
  OPEN_REVEALED = "OPEN_REVEALED",
  END = "END",
  START = "START",
  SINGLE_USER_ROUND_END = "SINGLE_USER_ROUND_END",
}

export interface EndScreenData {
  participantId: string;
  lieStatement?: string;
  detects?: number;
  serialLiar?: boolean;
  serialDetector?: boolean;
}

export interface Scores {
  name: string;
  score: number;
  id: string;
  participantId: string;
}

export interface GetScoreInput {
  segmentGroupId: string;
}

export interface GetCurrentRoundAnswersInput {
  segmentGroupId: string;
  promptId: string;
}

interface SegmentGroupScore {
  name: string;
  total: number;
  id: string;
}

interface GetMixerParticipantsInput {
  id: string;
  limit: number;
  isActive: boolean;
  sortBy: string;
}

export interface GetMetadataResponse {
  totalGroups: number;
  country: string;
}

export interface GetSegmentDataResponse {
  totalRounds: number;
  currentRound: number;
}

export interface TopScorers {
  id: string;
  total: number;
  name: string;
}

export interface MixerParticipants {
  id: string;
  participantId: string;
  name?: string;
  promptId: string;
  type?: string;
}
export interface MixerStateInterface {
  state: MixerStateOptions;
}
export interface Theme {
  id: string;
  name: string;
  activityId: string;
  asset: Asset;
  themePrompts: PromptData[];
}
export interface MixerState {
  connected: boolean;
  segmentScores: Scores[];
  correctAnswer: number;
  didYouKnow: string;
  imageLink: string;
  segmentGroupScores: SegmentGroupScore[];
  imageCredits: string;
  sgpScoresCurrentQuestion: Scores[];
  scoresLoading: boolean;
  disbaleDoodleCanvas: boolean;
  doodleCanvasInput: string;
  promptId: string;
  mixerTimer: number;
  midRoundTimer: number;
  totalGroups: number;
  country?: string;
  globalTopScorers: TopScorers[];
  segmentDataLoaded: boolean;
  currentRound?: number;
  totalRounds?: number;
  mixerParticipants: MixerParticipants[];
  mixerState?: MixerStateOptions;
  endScreen?: { [participantId: string]: EndScreenData };
  refresh: number;
  stateRefresh: number;
  charadesActorId?: string;
  answerSubmit: number;
  answerSubmitTime: number;
  hideTimer: boolean;
}

const initialState: MixerState = {
  connected: false,
  segmentScores: [],
  imageCredits: "",
  correctAnswer: -1,
  didYouKnow: "",
  imageLink: "",
  segmentGroupScores: [],
  sgpScoresCurrentQuestion: [],
  scoresLoading: false,
  disbaleDoodleCanvas: false,
  doodleCanvasInput: "",
  promptId: "",
  mixerTimer: -1,
  midRoundTimer: 0,
  totalGroups: 0,
  globalTopScorers: [],
  segmentDataLoaded: false,
  currentRound: undefined,
  totalRounds: 1,
  mixerParticipants: [],
  mixerState: MixerStateOptions.INITIAL,
  refresh: 0,
  answerSubmit: 0,
  stateRefresh: 0,
  answerSubmitTime: 0,
  hideTimer: false,
};

export const getSegmentGroupTopScores = createAsyncThunk(
  "getSegmentGroupTopScores",
  async (mixerInfo: GetScoreInput) => {
    const response = await axios.get<SegmentGroupScore[]>(
      `${process.env.REACT_APP_API_IDENTIFIER}/segment-group/${mixerInfo.segmentGroupId}/top-scorers`
    );
    return { segmentGroupScores: response.data };
  }
);

export const getCurrentRoundAnswers = createAsyncThunk(
  "getCurrentRoundAnswers",
  async (mixerInfo: GetCurrentRoundAnswersInput) => {
    const response = await axios.get<Scores[]>(
      `${process.env.REACT_APP_API_IDENTIFIER}/answer/group?segmentGroupId=${mixerInfo.segmentGroupId}&promptId=${mixerInfo.promptId}`
    );
    return { segmentGroupScores: response.data };
  }
);

export const nextPrompt = createAsyncThunk(
  "nextPrompt",
  async ({ segmentGroupId }: { segmentGroupId: string }) => {
    const response = await axios.put(
      `${process.env.REACT_APP_API_IDENTIFIER}/segment-group/${segmentGroupId}/next-prompt`
    );
    return response.data;
  }
);

export const endMixer = createAsyncThunk(
  "endMixer",
  async ({ eventInstanceId }: { eventInstanceId: string }) => {
    const response = await axios.put(
      `${process.env.REACT_APP_API_IDENTIFIER}/event-instance/${eventInstanceId}/end-segment`
    );
    return response.data;
  }
);

export const getMetadata = createAsyncThunk(
  "getMetadata",
  async (eventInstanceId: string) => {
    const response = await axios.get<GetMetadataResponse>(
      `${process.env.REACT_APP_API_IDENTIFIER}/event-instance/${eventInstanceId}/metadata`
    );
    return response.data;
  }
);

export const getSegmentData = createAsyncThunk(
  "getMixerData",
  async (segmentId: string) => {
    const response = await axios.get<GetSegmentDataResponse>(
      `${process.env.REACT_APP_API_IDENTIFIER}/segment/${segmentId}`
    );
    return response.data;
  }
);

export const getGlobalTopScorers = createAsyncThunk(
  "getGlobalTopScorers",
  async ({ id, limit }: { id: string; limit?: number }) => {
    let api = `${process.env.REACT_APP_API_IDENTIFIER}/segment/${id}/top-scorers`;
    if (limit) {
      api = `${api}?limit=${limit}`;
    }
    const response = await axios.get<TopScorers[]>(api);
    return response.data;
  }
);

export const getMixerState = createAsyncThunk(
  "getMixerState",
  async ({ id }: { id: string }) => {
    const api = `${process.env.REACT_APP_API_IDENTIFIER}/segment-group/${id}/state`;
    const response = await axios.get<MixerStateInterface>(api);
    return response.data;
  }
);

export const updateMixerState = createAsyncThunk(
  "updateMixerState",
  async ({ id, payload }: { id: string; payload: MixerStateInterface }) => {
    const api = `${process.env.REACT_APP_API_IDENTIFIER}/segment-group/${id}/state`;
    const response = await axios.put(api, payload);
    return response.data;
  }
);

export const getMixerParticipants = createAsyncThunk(
  "getMixerParticipants",
  async (payload: GetMixerParticipantsInput) => {
    const api = `${process.env.REACT_APP_API_IDENTIFIER}/segment-group/${payload.id}/participants?limit=${payload.limit}&isActive=${payload.isActive}&sortBy=${payload.sortBy}`;
    const response = await axios.get<MixerParticipants[]>(api);
    return response.data;
  }
);

export const getCurrentParticipant = createAsyncThunk(
  "getCurrentParticipant",
  async (id: string) => {
    const api = `${process.env.REACT_APP_API_IDENTIFIER}/ttal/all-players?segmentGroupId=${id}`;
    const response = await axios.get<MixerParticipants[]>(api);
    return response.data;
  }
);
export const updateCurentParticipant = createAsyncThunk(
  "updateCurentParticipant",
  async ({ id, current }: { id: string; current: string }) => {
    const api = `${process.env.REACT_APP_API_IDENTIFIER}/segment-group/${id}/current-participant`;
    const payload = {
      id: current,
    };
    const response = await axios.post(api, payload);
    return response.data;
  }
);

export const getEndScreenData = createAsyncThunk(
  "getEndScreenData",
  async (id: string) => {
    const api = `${process.env.REACT_APP_API_IDENTIFIER}/ttal/end-screen?segmentGroupId=${id}`;
    const response = await axios.get<{
      [participantId: string]: EndScreenData;
    }>(api);
    return response.data;
  }
);

export const getCurrentSegmentPrompt = createAsyncThunk(
  "getCurrentSegmentPrompt",
  async (segmentGroupId: string) => {
    const response = await fetchCurrentSegmentGroupPrompt(segmentGroupId);
    return response;
  }
);

export const mixerSlice = createSlice({
  name: "mixer",
  initialState,
  reducers: {
    setMixerTimer: (state, action: PayloadAction<number>) => {
      state.mixerTimer = action.payload;
    },
    setCorrectAnswer: (state, action: PayloadAction<number>) => {
      state.correctAnswer = action.payload;
    },
    setDisableDoodleCanvas: (state, action: PayloadAction<boolean>) => {
      state.disbaleDoodleCanvas = action.payload;
    },
    setDoodleCanvasInput: (state, action: PayloadAction<string>) => {
      state.doodleCanvasInput = action.payload;
    },
    setCharadesActorId: (state, action: PayloadAction<string>) => {
      state.charadesActorId = action.payload;
    },
    setAnswerSubmitted: (state) => {
      state.answerSubmit = 0;
    },
    setHideTimer: (state, action: PayloadAction<boolean>) => {
      state.hideTimer = action.payload;
    },
    resetGlobalTopScorers: (state) => {
      state.globalTopScorers = [];
    },
    resetMixer: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSegmentGroupTopScores.pending, (state) => {
        state.scoresLoading = true;
        state.sgpScoresCurrentQuestion = [];
      })
      .addCase(getSegmentGroupTopScores.fulfilled, (state, action) => {
        state.scoresLoading = false;
        state.segmentGroupScores = action.payload?.segmentGroupScores;
      })
      .addCase(getCurrentRoundAnswers.fulfilled, (state, action) => {
        state.sgpScoresCurrentQuestion = action.payload?.segmentGroupScores;
      })
      .addCase(getMetadata.pending, (state, action) => {
        state.totalGroups = -1;
      })
      .addCase(getMetadata.fulfilled, (state, action) => {
        state.totalGroups = action.payload?.totalGroups;
        state.country = action.payload?.country;
      })
      .addCase(getSegmentData.pending, (state, action) => {
        // state.totalRounds = 1;
        // state.currentRound = 1;
        state.segmentDataLoaded = false;
      })
      .addCase(getSegmentData.fulfilled, (state, action) => {
        state.totalRounds = action.payload.totalRounds;
        state.currentRound = action.payload.currentRound;
        state.segmentDataLoaded = true;
      })
      .addCase(getGlobalTopScorers.fulfilled, (state, action) => {
        state.globalTopScorers = action.payload;
      })
      .addCase(getMixerParticipants.fulfilled, (state, action) => {
        state.mixerParticipants = action.payload;
      })
      .addCase(getCurrentParticipant.fulfilled, (state, action) => {
        state.mixerParticipants = action.payload;
      })
      .addCase(getEndScreenData.fulfilled, (state, action) => {
        state.endScreen = action.payload;
      })
      .addCase(getMixerState.fulfilled, (state, action) => {
        state.mixerState = action.payload.state;
      })
      .addCase(getCurrentSegmentPrompt.fulfilled, (state, action) => {
        if (action.payload) state.promptId = action.payload.id;
      })
      .addCase(
        createAction<{ message: string }>(MIXER_MESSAGE),
        (state: Draft<MixerState>, action) => {
          let response: TriviaTimerMessage;
          try {
            const message = action.payload.message.replaceAll('"', "");
            response = JSON.parse(atob(message));

            if (response.info === SOCKET_CONTROL_MESSAGES.TRIVIA_TIMER) {
              return {
                ...state,
                mixerTimer: response?.timer || 0,
                promptId: response?.promptId || "",
              };
            } else if (
              response.info === SOCKET_CONTROL_MESSAGES.WYR_TIMER ||
              response.info === SOCKET_CONTROL_MESSAGES.DOODLE_TIMER
            ) {
              return {
                ...state,
                mixerTimer: response?.timer || 0,
                promptId: response?.promptId || "",
              };
            } else if (
              response.info === SOCKET_CONTROL_MESSAGES.CHARADES_TIMER
            ) {
              return {
                ...state,
                mixerTimer: response?.timer || 0,
              };
            } else if (
              response.info === SOCKET_CONTROL_MESSAGES.CHARADES_MID_ROUND_TIMER
            ) {
              return {
                ...state,
                midRoundTimer: response?.timer || 0,
                mixerTimer: response?.timer !== 1 ? state.mixerTimer : 0,
              };
            } else if (response.info === SOCKET_CONTROL_MESSAGES.TTAL_TIMER) {
              return {
                ...state,
                mixerTimer: response?.timer || 0,
              };
            } else if (
              response.info === SOCKET_CONTROL_MESSAGES.TTAL_MIXER_MESSAGE
            ) {
              return {
                ...state,
                mixerState: response?.mixerState || MixerStateOptions.RESET,
              };
            } else if (
              response.info === SOCKET_CONTROL_MESSAGES.CHARADES_REFRESH
            ) {
              // console.log({ rI: response.info, response });

              return {
                ...state,
                refresh: state.refresh + 1,
                // midRoundTimer: 0,
                // mixerTimer: 0,
              };
            } else if (
              response.info === SOCKET_CONTROL_MESSAGES.CHARADES_STATE_REFRESH
            ) {
              return {
                ...state,
                stateRefresh: state.stateRefresh + 1,
              };
            } else if (
              response.info === SOCKET_CONTROL_MESSAGES.CHARADES_ANSWER_MESSAGE
            ) {
              return {
                ...state,
                answerSubmit: state.answerSubmit + 1,
                answerSubmitTime: response?.timer || 0,
              };
            }

            return state;
          } catch (e) {
            console.error(e);
          }
        }
      )
      .addCase(
        createAction<{ message: string }>(MIXER_CLOSED),
        (state: Draft<MixerState>, action) => {
          return { ...state, connected: false };
        }
      )
      .addCase(
        createAction<{ message: string }>(MIXER_OPEN),
        (state: Draft<MixerState>, action) => {
          return { ...state, connected: true };
        }
      )
      .addCase(
        createAction<{ message: string }>(MIXER_DISCONNECT),
        (state: Draft<MixerState>, action) => {
          return { ...state, connected: false };
        }
      )
      .addCase(
        createAction<{ message: string }>(MIXER_BROKEN),
        (state: Draft<MixerState>, action) => {
          return { ...state, connected: false };
        }
      );
  },
});

export const {
  setMixerTimer,
  setCorrectAnswer,
  resetMixer,
  setDisableDoodleCanvas,
  setDoodleCanvasInput,
  setCharadesActorId,
  resetGlobalTopScorers,
  setAnswerSubmitted,
  setHideTimer,
} = mixerSlice.actions;

export const selectIsMixerConnected = (state: RootState) =>
  state.mixer.connected;

export const selectSegmentGroupScores = (state: RootState) =>
  state.mixer.segmentGroupScores;

export const selectSegmentScores = (state: RootState) =>
  state.mixer.segmentScores;

export const selectCorrectAnswer = (state: RootState) =>
  state.mixer.correctAnswer;

export const selectDidYouKnow = (state: RootState) => state.mixer.didYouKnow;
export const selectImageCredits = (state: RootState) =>
  state.mixer.imageCredits;
export const selectImageLink = (state: RootState) => state.mixer.imageLink;

export const selectSgpScoresCurrentQuestion = (state: RootState) =>
  state.mixer.sgpScoresCurrentQuestion;

export const selectMixerTimer = (state: RootState) => state.mixer.mixerTimer;
export const selectMidRoundTimer = (state: RootState) =>
  state.mixer.midRoundTimer;

export const selectRefresh = (state: RootState) => state.mixer.refresh;
export const selectStateRefresh = (state: RootState) =>
  state.mixer.stateRefresh;
export const selectAnswerSubmit = (state: RootState) =>
  state.mixer.answerSubmit;
export const selectAnswerSubmitTime = (state: RootState) =>
  state.mixer.answerSubmitTime;

export const selectPromptId = (state: RootState) => state.mixer.promptId;

export const selectDisablDoodleCanvas = (state: RootState) =>
  state.mixer.disbaleDoodleCanvas;

export const selectTotalRooms = (state: RootState) => state.mixer.totalGroups;

export const selectDoodleCanvasInput = (state: RootState) =>
  state.mixer.doodleCanvasInput;

export const selectGlobalTopScorers = (state: RootState) =>
  state.mixer.globalTopScorers;

export const selectMixerParticipants = (state: RootState) =>
  state.mixer.mixerParticipants;

export const selectMixerState = (state: RootState) => state.mixer.mixerState;

export const selectCurrentRound = (state: RootState) =>
  state.mixer.currentRound;

export const selectTotalRounds = (state: RootState) => state.mixer.totalRounds;

export const selectSegmentDataLoaded = (state: RootState) =>
  state.mixer.segmentDataLoaded;

export const selectCountry = (state: RootState) => state.mixer.country;

export const selectCharadesActorId = (state: RootState) =>
  state.mixer.charadesActorId;

export const selectEndScreenData = (state: RootState) => state.mixer.endScreen;

export const selectHideTimer = (state: RootState) => state.mixer.hideTimer;

export default mixerSlice.reducer;
