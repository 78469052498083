import React from "react";
import styled, { useTheme } from "styled-components";
import { motion } from "framer-motion";
import amplitude from "amplitude-js";

import QuestionMarkIcon from "../../assets/QuestionMarkIcon";
import NextArrow from "../../assets/NextArrow";
import { DrunkStartups_Idea_Length } from "../../types/DrunkStartups";
import {
  selectloungeParticipants,
  sendCursorData,
} from "../../store/features/Lounge/loungeSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectIdentifier } from "../../store/features/authSlice";
import DrunkStartupsSplashLogo from "../../assets/DrunkStartupsSplashLogo";
import { PromptData } from "../../store/features/questionSlice";
import {
  selectActivities,
  selectNextSegmentId,
} from "../../store/features/meetingSlice";
import { ActivityEnum } from "../../utils/activity.enum";
import { IcebreakerEnum } from "../../types/activity";
import { selectEventDetails } from "../../store/features/eventSlice";
import { createPrompt, fetchPrompts, selectPrompt } from "../../API/common";
import Spinner from "../common/Spinner";
import { LocalStorageKeys } from "../../utils/localstorage";
import { AmplitudeEvents } from "../../types/amplitudeEvents";
import AddYourIdeaSVG from "../../assets/DrunkStartup/AddYourIdeaSVG";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ZIndexLevel } from "../../utils/zIndex.enum";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 100px;
  bottom: 104px;
  box-sizing: border-box;
  padding: 16px 12px 12px;
  width: 560px;
  min-height: 300px;
  background: ${(props) => props.theme.color.white};
  box-shadow: 0px 4px 16px -2px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  z-index: 4;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HowItWorksRow = styled(Row)`
  cursor: pointer;
  color: ${(props) => props.theme.color.lightGray};

  &:hover {
    color: ${(props) => props.theme.color.darkGray};
  }
  transform: translateY(-5px);
`;

const TabRow = styled(Row)`
  width: 100%;
`;

const HowItWorks = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 160%;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  margin-left: 9px;
  user-select: none;
`;

const Tab = styled.div<{ isActive?: boolean; tabNo?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 260px;
  height: 48px;
  left: 0px;
  top: 0px;
  box-sizing: border-box;
  background: ${(props) =>
    props.isActive ? props.theme.color.yellow100 : props.theme.color.white};
  border-radius: ${(props) => (props.isActive ? "16px 16px 0px 0px" : "16px")};
  cursor: pointer;
  font-weight: ${(props) => (props.isActive ? 700 : 500)};
  font-size: 16px;
  line-height: 160%;
  text-align: center;
  color: ${(props) => props.theme.color.darkGray};
  user-select: none;

  &:after {
    content: "";
    position: relative;
    visibility: ${(props) =>
      props.isActive && props.tabNo === 0 ? "visible" : "hidden"};
    background-color: ${(props) => props.theme.color.white};
    left: 70px;
    top: 14px;
    height: 20px;
    width: 10px;
    border-bottom-left-radius: 12px;
    box-shadow: 0 10px 0 0 ${(props) => props.theme.color.yellow100};
    pointer-events: none;
  }

  &:before {
    content: "";
    position: relative;
    visibility: ${(props) =>
      props.isActive && props.tabNo === 1 ? "visible" : "hidden"};
    background-color: ${(props) => props.theme.color.white};
    right: 57px;
    top: 14px;
    height: 20px;
    width: 10px;
    border-bottom-right-radius: 12px;
    box-shadow: 0 10px 0 0 ${(props) => props.theme.color.yellow100};
    pointer-events: none;
  }
`;

const ContentContainer = styled.div<{ activeTab: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 16px;
  width: 536px;
  min-height: 106px;
  background: ${(props) => props.theme.color.yellow100};
  border-radius: ${(props) => (props.activeTab === 0 ? 0 : 16)}px
    ${(props) => (props.activeTab === 0 ? 16 : 0)}px 16px 16px;
  user-select: none;
`;

const Button = styled(motion.button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  padding: 15px 24px;
  margin-top: 16px;
  height: 56px;
  background: ${(props) => props.theme.color.purple500};
  border-radius: 16px;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  color: ${(props) => props.theme.color.white};
  border: none;
  user-select: none;

  &:hover {
    background: ${(props) => props.theme.color.purple600};
  }
  &:disabled {
    background: ${(props) => props.theme.color.purple200};
  }
`;

export const ArrowContainer = styled.div<{ isLeft?: boolean; hide: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 8px 4px;
  width: 32px;
  height: 64px;
  background: ${(props) => props.theme.color.white};
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  cursor: pointer;
  visibility: ${(props) => (props.hide ? "hidden" : "visible")};
`;

const IdeaTextContainer = styled(motion.div)`
  height: 82px;
  display: flex !important;
  align-content: center;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 160%;
  box-sizing: border-box;
  width: 370px;
  color: ${(props) => props.theme.color.darkGray};
  user-select: none;
`;

const IdeaText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px 0px 20px;
`;

const SliderContianer = styled.div`
  width: 450px;
  margin: auto;

  & .slick-prev {
    z-index: ${ZIndexLevel.PROMT_ARROW_INDEX};
  }

  & .slick-prev:before {
    content: "";
  }

  & .slick-next:before {
    content: "";
  }

  & .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    background: ${(props) => props.theme.color.white};
  }
`;

const TextArea = styled.textarea`
  width: 504px;
  height: 82px;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 20px;
  line-height: 160%;
  color: ${(props) => props.theme.color.darkGray};
  background: transparent;
  border: none;
  resize: none;
  font-family: "Inter";

  &::placeholder {
    font-family: "Inter";
    font-size: 20px;
    line-height: 160%;
    color: ${(props) => props.theme.color.lightGray};
  }
  &:focus {
    outline: none;
  }
`;

const LengthRemaining = styled.span`
  position: absolute;
  bottom: 90px;
  right: 25px;
  font-family: "Space Mono";
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  color: ${(props) => props.theme.color.lightGray};
`;

const TABS = ["Choose an idea", "Add your own idea"];

interface Props {
  setShowHowItWorks: React.Dispatch<React.SetStateAction<boolean>>;
  setShowHowItWorksActivityName: React.Dispatch<
    React.SetStateAction<ActivityEnum | IcebreakerEnum>
  >;
  setShowChoseOrAddDsIdea: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAloneModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChooseOrAddIdea: React.FC<Props> = ({
  setShowHowItWorks,
  setShowHowItWorksActivityName,
  setShowChoseOrAddDsIdea,
  setShowAloneModal,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const identifier = useAppSelector(selectIdentifier);
  const dsActivity = useAppSelector(selectActivities).find(
    (activity) => activity.name === ActivityEnum.DRUNK_STARTUPS
  );
  const nextSegmentId = useAppSelector(selectNextSegmentId);
  const eventDetails = useAppSelector(selectEventDetails);
  const loungeParticipants = useAppSelector(selectloungeParticipants);

  const [activeTab, setActiveTab] = React.useState(0);
  const [isButtonPressed, setIsButtonPressed] = React.useState(false);
  const [isButtonTapped, setIsButtonTapped] = React.useState(false);
  const [selectedPrompt, setSelectedPrompt] = React.useState(0);
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);
  const [dsIdeas, setDsIdeas] = React.useState<PromptData[]>([]);
  const [howItWorksHovered, setHowItWorksHovered] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [ideaLength, setIdeaLength] = React.useState(
    localStorage.getItem(LocalStorageKeys.DrunkStartupsUserIdea)?.length || 0
  );

  const sliderRef = React.useRef<any>(null);

  React.useEffect(() => {
    (async () => {
      if (dsActivity?.id) {
        const ideas = await fetchPrompts(dsActivity.id, true, 5);
        if (ideas) {
          setDsIdeas(ideas);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    centerMode: true,
    centerPadding: "20px",
    slidesToScroll: 1,
    nextArrow: (
      <RightArrow setSelectedPrompt={setSelectedPrompt} dsIdeas={dsIdeas} />
    ),
    prevArrow: <LeftArrow setSelectedPrompt={setSelectedPrompt} />,
  };

  const content =
    activeTab === 0 ? (
      <ContentContainer activeTab={activeTab}>
        <SliderContianer>
          <Slider {...settings} ref={sliderRef}>
            {dsIdeas.map((idea) => {
              return (
                <IdeaTextContainer key={idea.id}>
                  <IdeaText>{idea?.text}</IdeaText>
                </IdeaTextContainer>
              );
            })}
            <IdeaTextContainer>
              <IdeaText>
                <AddYourIdeaSVG />
              </IdeaText>
            </IdeaTextContainer>
          </Slider>
        </SliderContianer>
      </ContentContainer>
    ) : (
      <ContentContainer activeTab={activeTab}>
        <TextArea
          ref={textareaRef}
          placeholder="Keep it open ended and short..."
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          maxLength={DrunkStartups_Idea_Length}
          onInput={() => {
            setIdeaLength(textareaRef.current?.value.length || 0);
            localStorage.setItem(
              LocalStorageKeys.DrunkStartupsUserIdea,
              textareaRef.current?.value || ""
            );
          }}
          value={
            localStorage.getItem(LocalStorageKeys.DrunkStartupsUserIdea) || ""
          }
        ></TextArea>
        <LengthRemaining>
          {DrunkStartups_Idea_Length - ideaLength}
        </LengthRemaining>
      </ContentContainer>
    );

  const showInstructions = () => {
    dispatch(
      sendCursorData({
        eid: identifier,
        showDsInstructions: true,
      })
    );
    setShowChoseOrAddDsIdea(false);
  };

  const handleNext = () => {
    if (loungeParticipants.length === 1) {
      setShowAloneModal(true);
      setShowChoseOrAddDsIdea(false);
      return;
    }
    showInstructions();
  };

  const handleClick = async () => {
    if (isLoading) return;
    setIsLoading(true);
    switch (activeTab) {
      case 0:
        if (selectedPrompt === dsIdeas.length) {
          setActiveTab(1);
        } else {
          if (eventDetails?.eventInstanceId && dsIdeas?.[selectedPrompt]?.id) {
            const promptSelected = await selectPrompt(
              eventDetails.eventInstanceId,
              identifier,
              nextSegmentId,
              dsIdeas[selectedPrompt].id
            );
            if (!!promptSelected) {
              handleNext();
            }
          }
        }
        break;
      case 1:
        amplitude.getInstance().logEvent(AmplitudeEvents.DS_ADDED_OWN_IDEA);
        if (dsActivity?.id && textareaRef.current?.value) {
          const promptCreated = await createPrompt(
            nextSegmentId,
            dsActivity.id,
            textareaRef.current.value
          );
          if (promptCreated) {
            handleNext();
          }
        }
        break;
    }
    setIsLoading(false);
  };

  const getButtonState = () => {
    if (isLoading) {
      return <Spinner size={24} opacity="00" border={4} borderOpacity={255} />;
    }

    return selectedPrompt === dsIdeas.length
      ? `Add your own idea`
      : `Choose this idea`;
  };

  return (
    <Container>
      {dsIdeas.length > 0 ? (
        <>
          <Header>
            <div
              style={{
                transform: "scale(0.7) translatex(-50px)",
              }}
            >
              <DrunkStartupsSplashLogo />
            </div>
            <HowItWorksRow
              onClick={() => {
                setShowHowItWorks(true);
                setShowHowItWorksActivityName(ActivityEnum.DRUNK_STARTUPS);
              }}
              onMouseEnter={() => setHowItWorksHovered(true)}
              onMouseLeave={() => setHowItWorksHovered(false)}
            >
              <QuestionMarkIcon
                size={18}
                color={
                  howItWorksHovered
                    ? theme.color.yellow600
                    : theme.color.yellow500
                }
              />
              <HowItWorks>How it works</HowItWorks>
            </HowItWorksRow>
          </Header>
          <TabRow>
            {TABS.map((tab, index) => (
              <Tab
                isActive={index === activeTab}
                tabNo={index}
                onClick={() => {
                  setActiveTab(index);
                  setTimeout(() => {
                    sliderRef?.current?.slickGoTo(selectedPrompt, true);
                  }, 0);
                }}
                key={index}
              >
                {tab}
              </Tab>
            ))}
          </TabRow>
          {content}
          <Button
            animate={{
              scale: isButtonPressed || isButtonTapped ? 0.94 : 1,
            }}
            onTap={() => {
              setIsButtonTapped(true);
              setTimeout(() => {
                setIsButtonTapped(false);
              }, 100);
            }}
            onMouseDown={() => setIsButtonPressed(true)}
            onMouseUp={() => setIsButtonPressed(false)}
            onMouseLeave={() => setIsButtonPressed(false)}
            disabled={activeTab === 1 && !textareaRef.current?.value.length}
            onClick={handleClick}
          >
            {getButtonState()}
          </Button>
        </>
      ) : (
        <Spinner size={64} />
      )}
    </Container>
  );
};

interface ArrowPropsInterface {
  setSelectedPrompt: React.Dispatch<React.SetStateAction<number>>;
  dsIdeas?: PromptData[];
  className?: string;
  onClick?: () => void;
  currentSlide?: number;
}

const LeftArrow = (props: ArrowPropsInterface) => {
  const { className, onClick, currentSlide = 0, setSelectedPrompt } = props;

  React.useEffect(() => {
    setSelectedPrompt(currentSlide);
  }, [currentSlide]);

  return (
    <ArrowContainer
      isLeft
      hide={currentSlide === 0}
      className={className}
      onClick={() => {
        onClick && onClick();
      }}
    >
      <NextArrow isLeft />
    </ArrowContainer>
  );
};

const RightArrow = (props: ArrowPropsInterface) => {
  const {
    className,
    onClick,
    currentSlide = 0,
    dsIdeas = [],
    setSelectedPrompt,
  } = props;

  React.useEffect(() => {
    setSelectedPrompt(currentSlide);
  }, [currentSlide]);

  return (
    <ArrowContainer
      className={className}
      onClick={() => {
        onClick && onClick();
      }}
      hide={currentSlide === dsIdeas.length}
    >
      <NextArrow />
    </ArrowContainer>
  );
};

export default ChooseOrAddIdea;
