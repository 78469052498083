const LeaderBoardTrophySvg: React.FC = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2.75A1.25 1.25 0 0 0 2.75 4v.25H1.492A1.25 1.25 0 0 0 .242 5.5v1a2.75 2.75 0 0 0 2.75 2.75h.073c.648 1.8 2.237 3.163 4.185 3.446v1.054H6a.75.75 0 0 0 0 1.5h4a.75.75 0 0 0 0-1.5H8.75v-1.054a5.252 5.252 0 0 0 4.2-3.446h.045a2.75 2.75 0 0 0 2.75-2.75v-1a1.25 1.25 0 0 0-1.25-1.25H13.25V4A1.25 1.25 0 0 0 12 2.75H4ZM2.757 7.728a5.375 5.375 0 0 1-.007-.284V5.75H1.742v.75a1.25 1.25 0 0 0 1.015 1.228Zm11.122-.344a1.25 1.25 0 0 1-.634.34 5.26 5.26 0 0 0 .005-.224V5.75h.995v.75c0 .332-.131.65-.366.884Z"
        fill="url(#a)"
      />
      <defs>
        <linearGradient
          id="a"
          x1="2.954"
          y1="6.228"
          x2="6.597"
          y2="16.335"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC200" />
          <stop offset=".275" stopColor="#FFEEB8" />
          <stop offset="1" stopColor="#FFC200" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LeaderBoardTrophySvg;
