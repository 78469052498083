const DoodleIcon: React.FC<{ size?: string }> = ({ size = "24" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.41623 18.724C9.95412 19.8627 17.4076 19.823 20.5927 13.2767"
        stroke="#475766"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 7C15.75 9.07107 14.0711 10.75 12 10.75C9.92893 10.75 8.25 9.07107 8.25 7C8.25 4.92893 9.92893 3.25 12 3.25C14.0711 3.25 15.75 4.92893 15.75 7Z"
        fill="#FFC200"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4788 10.2343C16.2676 9.38625 16.75 8.24946 16.75 7C16.75 5.89881 16.3753 4.88515 15.7464 4.07956C16.326 3.7125 17.0132 3.5 17.75 3.5C19.8211 3.5 21.5 5.17893 21.5 7.25C21.5 9.32107 19.8211 11 17.75 11C16.8963 11 16.1092 10.7147 15.4788 10.2343ZM14.7704 9.52732C15.3789 8.8607 15.75 7.97368 15.75 7C15.75 6.14349 15.4628 5.35404 14.9796 4.72268C14.3711 5.3893 14 6.27632 14 7.25C14 8.10651 14.2872 8.89596 14.7704 9.52732Z"
        fill="#FFC200"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.2536 4.07956C7.67399 3.7125 6.98681 3.5 6.25 3.5C4.17893 3.5 2.5 5.17893 2.5 7.25C2.5 9.32107 4.17893 11 6.25 11C7.10374 11 7.89084 10.7147 8.52117 10.2343C7.73242 9.38625 7.25 8.24946 7.25 7C7.25 5.89881 7.62472 4.88515 8.2536 4.07956ZM9.02044 4.72268C8.53715 5.35404 8.25 6.14349 8.25 7C8.25 7.97368 8.62109 8.8607 9.22956 9.52732C9.71285 8.89596 10 8.10651 10 7.25C10 6.27632 9.62891 5.3893 9.02044 4.72268Z"
        fill="#FFC200"
      />
    </svg>
  );
};

export default DoodleIcon;
