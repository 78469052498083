import React from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";
import signUpPage from "../assets/signUpPageBackground.png";
import { ActionButton } from "../components/common";
import ThursdayLoginLogo from "../assets/ThursdayLoginLogo";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  resetUser,
  selectOrgDetails,
  selectUserDetails,
} from "../store/features/userSlice";
import { useQuery } from "../hooks";
import { clearLocalStorage } from "../utils/clearLocalStorage";
import { googleRedirectApi } from "../API/invite";
import { logoutApi } from "../API/auth";

const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: url(${signUpPage});
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const JoinButton = styled(ActionButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.color.purple500};
`;
const ButtonContainer = styled(motion.div)<{ pressed: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  height: 26px;
  background: ${(props) => props.theme.color.purple500};
  box-shadow: ${({ pressed }) =>
    pressed
      ? "0px 2px 2px -2px rgba(0, 0, 0, 0.12)"
      : "0px 2px 4px -1px rgba(0, 0, 0, 0.12)"};
  z-index: 4;
  border-radius: 16px;
  margin: 32px 20px 0px 20px;
  &:hover {
    background: ${(props) => props.theme.color.purple600};
    ${JoinButton} {
      background: ${(props) => props.theme.color.purple600};
    }
  }
`;
const TnC = styled.div`
  line-height: 18px;
  color: ${(props) => props.theme.color.darkGray};
  font-size: 11px;
  font-weight: 600;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 8px;
  display: flex;
`;
const UnderlinedText = styled.div`
  text-decoration: underline;
  margin: 0px 3px;
`;
const LogoContainer = styled.div`
  position: fixed;
  top: 24px;
  left: 24px;
`;
const Modal = styled.div`
  background: ${(props) => props.theme.color.white};
  height: 167px;
  width: 352px;
  padding: 24px;
  margin-top: 35vh;
  border-radius: 32px;
`;
const Subtext = styled.div`
  margin-top: 28px;
  font-size: 13px;
  line-height: 21px;
  color: ${(props) => props.theme.color.darkGray};
`;
const Email = styled.span`
  font-weight: 600;
`;
const SubHeader = styled.div`
  margin-top: 16px;
  font-size: 13px;
  line-height: 21px;
  font-weight: 600;
  color: ${(props) => props.theme.color.darkGray};
`;
const OrgNames = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: ${(props) => props.theme.color.black};
`;
const Logout = styled.span`
  margin-left: 12px;
  color: ${(props) => props.theme.color.purple500};
`;
const InviteText = styled.div`
  text-align: center;
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  padding: 0 10px;
  color: ${(props) => props.theme.color.black};
`;
const JoinText = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: ${(props) => props.theme.color.white};
`;
const OrganizationJoin: React.FunctionComponent = () => {
  const query = useQuery();
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  let history = useHistory();
  const [isButtonPressed, setIsButtonPressed] = React.useState(false);
  const [isButtonTapped, setIsButtonTapped] = React.useState(false);
  const [organizationCode, setOrganizationCode] = React.useState("");
  const [organizationName, setOrganizationName] = React.useState("");
  const eventInstanceId = String(query.get("eventInstanceId"));
  const userDetails = useAppSelector(selectUserDetails);
  const orgDetails = useAppSelector(selectOrgDetails);
  //   React.useEffect(() => {
  //   })
  React.useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_IDENTIFIER}/auth/organization/join`, {
        organizationId: id,
      })
      .then((res) => {
        console.log(res.data.organizationCode);
        setOrganizationCode(res.data.organizationCode);
        setOrganizationName(res.data.organizationName);
      });
  }, []);

  const handleButtonMouseTap = () => {
    setIsButtonTapped(true);
    setTimeout(() => {
      setIsButtonTapped(false);
    }, 100);
  };

  const handleLogout = async () => {
    await logoutApi();
    dispatch(resetUser());
    clearLocalStorage();
    history.push("sign-in");
  };

  return (
    <Container>
      <LogoContainer>
        <ThursdayLoginLogo />
      </LogoContainer>
      <Modal>
        <InviteText>You’re invited to join {organizationName}</InviteText>
        <ButtonContainer
          pressed={isButtonPressed || isButtonTapped}
          animate={{
            scale: isButtonPressed || isButtonTapped ? 0.9 : 1,
          }}
        >
          <JoinButton
            width={282}
            onClick={() => {
              googleRedirectApi({
                organizationCode,
                redirect: `join/${eventInstanceId}`,
              });
            }}
            onTap={handleButtonMouseTap}
            onMouseDown={() => setIsButtonPressed(true)}
            onMouseUp={() => setIsButtonPressed(false)}
            onMouseLeave={() => setIsButtonPressed(false)}
          >
            {/* <GoogleIcon /> */}
            <JoinText>Join Team</JoinText>
          </JoinButton>
        </ButtonContainer>
      </Modal>
      <Subtext>
        You are signed in as <Email>{userDetails.user.email}</Email>{" "}
        <Logout onClick={handleLogout}>Log out</Logout>
      </Subtext>
      <SubHeader>You are already a part of these teams:</SubHeader>
      <OrgNames>
        {orgDetails.map((o, idx) => (
          <>
            <span>{o.organization.name}</span>
            {idx !== orgDetails?.length - 1 && <span>, </span>}
          </>
        ))}
      </OrgNames>
      <TnC>
        By continuing you agree to our{" "}
        <UnderlinedText>Terms {"&"} Conditions</UnderlinedText> and our{" "}
        <UnderlinedText>Privacy Policy</UnderlinedText>
      </TnC>
    </Container>
  );
};
export default OrganizationJoin;
