import { createClient } from "@liveblocks/client";
import { liveblocksAuthEndpoint } from "../API/drunkStartups";

export const client = createClient({
  authEndpoint: liveblocksAuthEndpoint,
});

export const getDrunkStartupsShareUrl = (segmentId: string, index: number) => {
  return `${process.env.REACT_APP_BASE_URL}/share/drunk-startups/${segmentId}?i=${index}`;
};
