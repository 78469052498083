import { Dispatch, MiddlewareAPI, Middleware } from "@reduxjs/toolkit";
import { LOUNGE_ERROR, LOUNGE_SEND } from "../features/socketActionTypes";
import { LoungeState } from "../features/Lounge/loungeSlice";

export const loggerMiddleware: Middleware =
  ({ getState }: MiddlewareAPI) =>
  (next: Dispatch) =>
  (action) => {
    console.log("will dispatch", action);

    // Call the next dispatch method in the middleware chain.
    const returnValue = next(action);

    console.log("state after dispatch", getState());

    // This will likely be the action itself, unless
    // a middleware further in chain changed it.
    return returnValue;
  };

export const webSocketCustomMiddleware: Middleware =
  ({ getState }) =>
  (next) =>
  (action) => {
    if (action.type === LOUNGE_SEND) {
      const { lounge } = getState() as { lounge: LoungeState };
      if (lounge.connected) {
        next(action);
      }
    } else {
      next(action);
    }
  };

export const webSocketErrorLoggerMiddleware: Middleware =
  (store) => (next) => (action) => {
    const loungeWSClosedActions = [LOUNGE_ERROR];
    if (loungeWSClosedActions.includes(action.type)) {
      console.log("ws error", action);
    }

    next(action);
  };
