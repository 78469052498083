import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  createNewTeam,
  DomainInterface,
  getDomainDetails,
} from "../API/teamAPIs";
import signUpPage from "../assets/signUpPageBackground.png";
import TeamIcon from "../assets/TeamIcon";
import ThursdayLogo from "../assets/ThursdayLoginLogo";
import WarningIcon from "../assets/WarningIcon";
import MotionButton from "../components/common/MotionButton";
import SpinningLoader from "../components/common/SpinningLoader";
import TextBtn from "../components/common/TextBtn";
import NameInput from "../components/Team/NameInput";
import ModalProvider from "../Providers/ModalProvider";
import { RoutesEnum } from "../routes/routes.enum";
import {
  getUserDetails,
  selectOrgDetails,
  selectUserDetails,
} from "../store/features/userSlice";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { clearLocalStorage } from "../utils/clearLocalStorage";
import { validateStr } from "../utils";

const Main = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  background-image: url(${signUpPage});
  background-repeat: no-repeat;
  background-size: cover;
`;

const MainLogoContainer = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
`;

const TeamDetailsSection = styled.div<{ padding: string }>`
  position: absolute;
  width: 440px;
  padding: ${({ padding }) => padding};
  top: 50%;
  left: 50%;
  background: ${({ theme }) => theme.color.white};

  transform: translate(-50%, -50%);
  border-radius: 28px;
  box-sizing: border-box;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.color.black};
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  margin: 24px 0;
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const ErrMsgContainer = styled.div`
  height: 64px;
`;

const SubText = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: ${({ theme }) => theme.color.darkGray};
  line-height: 160%;
`;

const TitleMsgForExisting = styled(Title)`
  text-align: left;
  margin: 8px 0;
  line-height: 160%;
`;

const BtnContainer = styled.div`
  margin: 20px 0;
`;

const TextBtnMain = styled.div`
  margin: 23px 0 21px;
  font-weight: 500;
  font-size: 16px;
  color: ${({ theme }) => theme.color.darkGray};
  text-align: center;
`;

const AlreadyExistsMain = styled.div`
  margin: 0 8px;
`;

const ConfirmTeamCreationCard = styled.div`
  padding: 16px;
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0px 4px 16px -2px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  width: 440px;
  box-sizing: border-box;
`;

const TitleMsgConfirmModal = styled(Title)`
  text-align: left;
  margin: 0;
  line-height: 160%;
`;

const ConfirmMsgSubTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  margin: 8px 0 70px;
  line-height: 160%;
`;

const TeamIconWrapper = styled.div`
  text-align: center;
`;

const ErrMsg = styled.div`
  font-weight: 600;
  font-size: 11px;
  color: ${({ theme }) => theme.color.red500};
  margin: 8px 12px;
`;

const CreateTeam: React.FunctionComponent = () => {
  const orgDetails = useAppSelector(selectOrgDetails);
  const history = useHistory();
  const [domainDetails, setDomainDetails] = useState<DomainInterface | null>(
    null
  );
  const userDetails = useAppSelector(selectUserDetails);

  const [isDomainDetailsLoaded, setIsDomainDetailsLoaded] = useState(false);
  const [showDomainExists, setShowDomainExists] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [newOrgName, setNewOrgName] = useState("");
  const [inputErrorMsg, setInputErrorMsg] = useState("");
  const [showInputError, setShowInputError] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      const resp = await getDomainDetails();

      if (resp.status === 200 && resp.data) {
        setDomainDetails(resp.data);

        setShowDomainExists(
          !resp.data.isPublicDomain && resp.data.orgCount > 0
        );

        setNewOrgName(resp.data.prefilledOrgName || "");
      } else {
        // Pending error handling
      }

      setIsDomainDetailsLoaded(true);
    })();
  }, []);

  useEffect(() => {
    // If the user has already created team take to user to the Dashboard
    if (orgDetails.length !== 0) {
      history.push(RoutesEnum.DASHBOARD);
    }
  }, [orgDetails]);

  const handleShowConfirmModal = () => {
    setShowConfirmModal(true);
  };

  const handleCreateNewTeamBtnClick = () => {
    setShowDomainExists(false);
    setShowConfirmModal(false);
  };

  const logOutAndRedirectToMarketing = () => {
    clearLocalStorage();
    history.push(RoutesEnum.NO_ROUTE);
  };

  const handleInputErrors = ({
    text,
    isError,
  }: {
    text: string;
    isError: boolean;
  }) => {
    setShowInputError(isError);
    setInputErrorMsg(text);
  };

  const submitTeamName = async () => {
    if (validateStr(newOrgName)) {
      setShowInputError(true);
      setInputErrorMsg("Team name cannot be empty");
    } else {
      const resp = await createNewTeam({
        orgName: newOrgName,
      });

      if (resp.status === 200) {
        dispatch(getUserDetails());
      } else if (resp.status === 409) {
        setShowInputError(true);
        //Todo: Move this string to constant
        setInputErrorMsg(
          "This team name is already taken, please try a different one."
        );
      } else {
        setShowInputError(true);
        //Todo: Move this string to constant
        setInputErrorMsg("Something went wrong! Please try again.");
      }
    }
  };

  if (!isDomainDetailsLoaded) {
    return <SpinningLoader />;
  }

  return (
    <Main>
      <MainLogoContainer>
        <ThursdayLogo />
      </MainLogoContainer>

      {showConfirmModal && (
        <ModalProvider setShowModal={setShowConfirmModal}>
          <ConfirmTeamCreationCard>
            <TitleMsgConfirmModal>
              Are you sure you want to create a new team?
            </TitleMsgConfirmModal>

            <MotionButton clickHandler={handleCreateNewTeamBtnClick}>
              Create new team
            </MotionButton>
          </ConfirmTeamCreationCard>
        </ModalProvider>
      )}

      <TeamDetailsSection padding={showDomainExists ? "19px 12px" : "12px"}>
        {showDomainExists ? (
          <AlreadyExistsMain>
            <WarningIcon />

            <TitleMsgForExisting>
              {(domainDetails?.orgCount || 1) > 1 ? "Multiple teams" : "A team"}{" "}
              with{" "}
              {userDetails.user.email.split("@")?.[1]
                ? `@${userDetails.user.email.split("@")?.[1]}`
                : "this email"}{" "}
              {(domainDetails?.orgCount || 1) > 1 ? "exist" : "exists"} on
              Thursday.
            </TitleMsgForExisting>

            <SubText>
              Maybe your teammate has already created a team here. It would be
              best to ask them to invite you to Thursday.
            </SubText>

            <BtnContainer>
              <MotionButton
                clickHandler={logOutAndRedirectToMarketing}
                backgroundColor="purple100"
                backgroundColorHover="purple200"
                textColor="purple500"
                textColorHover="purple500"
              >
                Ok, thanks.
              </MotionButton>
            </BtnContainer>

            <TextBtnMain>
              <TextBtn onClickHandler={handleShowConfirmModal}>
                Or, create a new team
              </TextBtn>
            </TextBtnMain>
          </AlreadyExistsMain>
        ) : (
          <>
            <TeamIconWrapper>
              <TeamIcon />
            </TeamIconWrapper>

            <Title>What should we call your team?</Title>

            <InputContainer>
              <NameInput
                handleTextChange={setNewOrgName}
                value={newOrgName}
                width="400px"
                errorHandler={handleInputErrors}
                isError={showInputError}
              />
            </InputContainer>

            <ErrMsgContainer>
              {showInputError && <ErrMsg>{inputErrorMsg}</ErrMsg>}
            </ErrMsgContainer>

            <MotionButton clickHandler={submitTeamName}>Next</MotionButton>
          </>
        )}
      </TeamDetailsSection>
    </Main>
  );
};

export default CreateTeam;
