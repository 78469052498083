import React from "react";

import { useTheme } from "styled-components";

const ChatIconActive: React.FC = () => {
  const theme = useTheme();

  return (
    <svg
      width="28"
      height="24"
      viewBox="0 0 28 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 14.1667C13.9977 15.0466 14.2033 15.9146 14.6 16.7C15.0704 17.6412 15.7935 18.4328 16.6884 18.9862C17.5832 19.5396 18.6145 19.8329 19.6667 19.8333C20.5466 19.8356 21.4146 19.63 22.2 19.2333L26 20.5L24.7333 16.7C25.13 15.9146 25.3356 15.0466 25.3333 14.1667C25.3329 13.1145 25.0396 12.0832 24.4862 11.1884C23.9328 10.2935 23.1412 9.57037 22.2 9.09999C21.4146 8.70328 20.5466 8.49769 19.6667 8.49999H19.3333C17.9438 8.57665 16.6313 9.16316 15.6472 10.1472C14.6632 11.1313 14.0767 12.4438 14 13.8333V14.1667Z"
        fill="#78D2B4"
        stroke="#78D2B4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 11.5C21.0034 12.8199 20.6951 14.1219 20.1 15.3C19.3944 16.7117 18.3098 17.8992 16.9674 18.7293C15.6251 19.5594 14.0782 19.9994 12.5 20C11.1801 20.0034 9.87812 19.6951 8.7 19.1L3 21L4.9 15.3C4.30493 14.1219 3.99656 12.8199 4 11.5C4.00061 9.92176 4.44061 8.37485 5.27072 7.03255C6.10083 5.69025 7.28825 4.60557 8.7 3.9C9.87812 3.30493 11.1801 2.99656 12.5 3H13C15.0843 3.11499 17.053 3.99476 18.5291 5.47086C20.0052 6.94695 20.885 8.91565 21 11V11.5Z"
        fill={theme.color.green500}
        stroke={theme.color.green500}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChatIconActive;
