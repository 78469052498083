interface RRStarProps {
  color: string;
}

const RRStar: React.FC<RRStarProps> = ({ color }) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.2418 5.1527C25.3247 3.29477 22.6753 3.29477 21.7582 5.1527L16.8236 15.1497L5.78684 16.7629C3.73706 17.0625 2.92009 19.5821 4.40408 21.0275L12.3882 28.8041L10.5042 39.7891C10.1539 41.8315 12.2977 43.3889 14.1318 42.4244L24 37.2348L33.8682 42.4244C35.7023 43.3889 37.8461 41.8315 37.4958 39.7891L35.6118 28.8041L43.5959 21.0275C45.0799 19.5821 44.263 17.0625 42.2132 16.7629L31.1764 15.1497L26.2418 5.1527Z"
        fill={color}
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RRStar;
