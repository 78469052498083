import React from "react";
import styled from "styled-components";

const Container = styled.div<{ isRotated: boolean; isRightRotate: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${({ isRotated, isRightRotate }) =>
    (isRotated && "rotate(180deg)") || (isRightRotate && "rotate(270deg)")};
  transition: transform 0.1s linear;
`;

const Path = styled.path<{ color: string }>`
  stroke: ${({ color }) => color};
`;

interface Props {
  color: string;
  rotate?: boolean;
  rotateRight?: boolean;
}

//TODO: Refactor this component to remove color prop.
const ChevronIcon: React.FC<Props> = ({
  rotate = false,
  color,
  rotateRight = false,
}) => {
  return (
    <Container isRotated={rotate} isRightRotate={rotateRight} data-notclickable>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-notclickable
      >
        <Path
          color={color}
          d="M4 6L8 10L12 6"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          data-notclickable
        />
      </svg>
    </Container>
  );
};

export default ChevronIcon;
