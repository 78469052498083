import React from "react";

const ThursdayLoginLogo: React.FC<{ width?: number; height?: number }> = ({
  width = 155,
  height = 50,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 155 50"
      fill="none"
    >
      <g filter="url(#filter0_thursdayIcon)">
        <mask
          id="path-1-outside-1_331_1888"
          maskUnits="userSpaceOnUse"
          x="3.49365"
          y="1.70996"
          width="149"
          height="43"
          fill="black"
        >
          <rect fill="white" x="3.49365" y="1.70996" width="149" height="43" />
          <path d="M16.9537 17.978H20.8057V13.658H16.9537V9.33796H11.5177V13.658H8.49365V17.978H11.5177V21.722V25.214C11.5177 29.858 14.3257 32.09 17.9977 32.09C18.8977 32.09 19.7617 31.982 20.6617 31.802L20.6257 27.194C20.2657 27.266 19.8337 27.302 19.2577 27.302C17.8537 27.302 16.9537 26.438 16.9537 24.818V21.722V17.978Z" />
          <path d="M32.3816 13.298C29.9696 13.298 28.0976 14.594 27.1976 16.718V6.70996H21.6896V31.91H27.1976V21.83C27.1976 19.238 28.4216 17.726 30.4736 17.726C32.5256 17.726 33.7136 19.13 33.7136 21.542V31.91H39.2216V20.714C39.2216 16.25 36.4856 13.298 32.3816 13.298Z" />
          <path d="M52.0455 13.658V23.738C52.0455 26.366 50.8575 27.842 48.8775 27.842C47.0055 27.842 45.8895 26.438 45.8895 24.062V13.658H40.3815V24.854C40.3815 29.318 43.0815 32.306 47.0415 32.306C49.3455 32.306 51.0735 31.406 52.1175 29.786L52.0455 31.91H57.5535V13.658H52.0455Z" />
          <path d="M65.3098 32.522L70.2058 30.434L67.8298 24.854C65.9938 20.75 67.1098 18.338 70.2418 18.338C70.9618 18.338 71.3578 18.374 72.0418 18.446V13.478C71.5018 13.37 70.8898 13.298 70.2418 13.298C67.1098 13.298 65.2018 15.566 65.3458 19.094H65.2738L62.8618 13.514L57.9658 15.566L65.3098 32.522Z" />
          <path d="M80.4821 32.594C85.1261 32.594 88.4381 30.074 88.4381 26.438C88.4381 23.342 86.1341 21.506 81.8861 20.606L80.1221 20.21C79.0061 19.958 78.4661 19.418 78.4661 18.626C78.4661 17.582 79.4741 16.826 80.8421 16.826C82.4981 16.826 83.9021 17.906 83.9741 19.49L88.9421 19.382C88.8341 15.602 85.4501 12.974 80.8421 12.974C76.3781 12.974 73.1741 15.566 73.1741 19.274C73.1741 22.406 75.0101 24.17 78.9341 24.962L80.6981 25.358C82.2461 25.718 83.0741 26.078 83.0741 27.086C83.0741 28.094 82.0301 28.742 80.4821 28.742C78.5381 28.742 77.2781 27.698 77.2421 26.078L72.2381 26.222C72.2741 29.966 75.6581 32.594 80.4821 32.594Z" />
          <path d="M103.157 6.70996V15.71C101.861 14.162 100.061 13.298 97.8291 13.298C92.6451 13.298 89.0451 17.186 89.0451 22.802C89.0451 28.418 92.6451 32.306 97.8291 32.306C100.061 32.306 101.861 31.478 103.157 29.93V31.91H108.665V27.734V6.70996H103.157ZM99.0531 27.77C96.4251 27.77 94.6251 25.754 94.6251 22.802C94.6251 19.85 96.4611 17.798 99.0531 17.798C101.645 17.798 103.445 19.85 103.445 22.802C103.445 25.754 101.645 27.77 99.0531 27.77Z" />
          <path d="M115.578 32.126C118.422 32.126 120.33 30.758 121.05 28.598H121.158C121.158 30.182 121.266 31.298 121.446 31.91H126.774C126.522 30.506 126.414 28.274 126.414 26.33V20.714C126.414 16.25 122.958 13.118 117.99 13.118C113.274 13.118 109.926 16.034 109.746 20.246L114.966 20.354C115.038 18.698 116.262 17.582 117.99 17.582C119.754 17.582 120.942 18.734 120.942 20.426V21.11L117.882 21.398C112.914 21.83 109.926 23.99 109.926 27.266C109.926 30.29 112.086 32.126 115.578 32.126ZM117.702 28.67C116.298 28.67 115.398 27.95 115.398 26.798C115.398 25.358 116.586 24.458 118.638 24.278L121.014 24.026V24.746C121.014 26.942 119.862 28.67 117.702 28.67Z" />
          <path d="M130.446 39.29C134.478 39.29 137.574 36.806 139.482 32.018L146.97 13.658H141.282L136.962 25.394L132.246 13.658H126.414L133.974 31.586L133.686 32.234C132.75 34.178 131.31 34.502 129.51 34.502C128.862 34.502 128.25 34.43 127.926 34.322V39.002C128.646 39.218 129.51 39.29 130.446 39.29Z" />
        </mask>
        <path
          d="M16.9537 17.978H20.8057V13.658H16.9537V9.33796H11.5177V13.658H8.49365V17.978H11.5177V21.722V25.214C11.5177 29.858 14.3257 32.09 17.9977 32.09C18.8977 32.09 19.7617 31.982 20.6617 31.802L20.6257 27.194C20.2657 27.266 19.8337 27.302 19.2577 27.302C17.8537 27.302 16.9537 26.438 16.9537 24.818V21.722V17.978Z"
          fill="#6633FF"
        />
        <path
          d="M32.3816 13.298C29.9696 13.298 28.0976 14.594 27.1976 16.718V6.70996H21.6896V31.91H27.1976V21.83C27.1976 19.238 28.4216 17.726 30.4736 17.726C32.5256 17.726 33.7136 19.13 33.7136 21.542V31.91H39.2216V20.714C39.2216 16.25 36.4856 13.298 32.3816 13.298Z"
          fill="#6633FF"
        />
        <path
          d="M52.0455 13.658V23.738C52.0455 26.366 50.8575 27.842 48.8775 27.842C47.0055 27.842 45.8895 26.438 45.8895 24.062V13.658H40.3815V24.854C40.3815 29.318 43.0815 32.306 47.0415 32.306C49.3455 32.306 51.0735 31.406 52.1175 29.786L52.0455 31.91H57.5535V13.658H52.0455Z"
          fill="#6633FF"
        />
        <path
          d="M65.3098 32.522L70.2058 30.434L67.8298 24.854C65.9938 20.75 67.1098 18.338 70.2418 18.338C70.9618 18.338 71.3578 18.374 72.0418 18.446V13.478C71.5018 13.37 70.8898 13.298 70.2418 13.298C67.1098 13.298 65.2018 15.566 65.3458 19.094H65.2738L62.8618 13.514L57.9658 15.566L65.3098 32.522Z"
          fill="#6633FF"
        />
        <path
          d="M80.4821 32.594C85.1261 32.594 88.4381 30.074 88.4381 26.438C88.4381 23.342 86.1341 21.506 81.8861 20.606L80.1221 20.21C79.0061 19.958 78.4661 19.418 78.4661 18.626C78.4661 17.582 79.4741 16.826 80.8421 16.826C82.4981 16.826 83.9021 17.906 83.9741 19.49L88.9421 19.382C88.8341 15.602 85.4501 12.974 80.8421 12.974C76.3781 12.974 73.1741 15.566 73.1741 19.274C73.1741 22.406 75.0101 24.17 78.9341 24.962L80.6981 25.358C82.2461 25.718 83.0741 26.078 83.0741 27.086C83.0741 28.094 82.0301 28.742 80.4821 28.742C78.5381 28.742 77.2781 27.698 77.2421 26.078L72.2381 26.222C72.2741 29.966 75.6581 32.594 80.4821 32.594Z"
          fill="#6633FF"
        />
        <path
          d="M103.157 6.70996V15.71C101.861 14.162 100.061 13.298 97.8291 13.298C92.6451 13.298 89.0451 17.186 89.0451 22.802C89.0451 28.418 92.6451 32.306 97.8291 32.306C100.061 32.306 101.861 31.478 103.157 29.93V31.91H108.665V27.734V6.70996H103.157ZM99.0531 27.77C96.4251 27.77 94.6251 25.754 94.6251 22.802C94.6251 19.85 96.4611 17.798 99.0531 17.798C101.645 17.798 103.445 19.85 103.445 22.802C103.445 25.754 101.645 27.77 99.0531 27.77Z"
          fill="#6633FF"
        />
        <path
          d="M115.578 32.126C118.422 32.126 120.33 30.758 121.05 28.598H121.158C121.158 30.182 121.266 31.298 121.446 31.91H126.774C126.522 30.506 126.414 28.274 126.414 26.33V20.714C126.414 16.25 122.958 13.118 117.99 13.118C113.274 13.118 109.926 16.034 109.746 20.246L114.966 20.354C115.038 18.698 116.262 17.582 117.99 17.582C119.754 17.582 120.942 18.734 120.942 20.426V21.11L117.882 21.398C112.914 21.83 109.926 23.99 109.926 27.266C109.926 30.29 112.086 32.126 115.578 32.126ZM117.702 28.67C116.298 28.67 115.398 27.95 115.398 26.798C115.398 25.358 116.586 24.458 118.638 24.278L121.014 24.026V24.746C121.014 26.942 119.862 28.67 117.702 28.67Z"
          fill="#6633FF"
        />
        <path
          d="M130.446 39.29C134.478 39.29 137.574 36.806 139.482 32.018L146.97 13.658H141.282L136.962 25.394L132.246 13.658H126.414L133.974 31.586L133.686 32.234C132.75 34.178 131.31 34.502 129.51 34.502C128.862 34.502 128.25 34.43 127.926 34.322V39.002C128.646 39.218 129.51 39.29 130.446 39.29Z"
          fill="#6633FF"
        />
        <path
          d="M16.9537 17.978H20.8057V13.658H16.9537V9.33796H11.5177V13.658H8.49365V17.978H11.5177V21.722V25.214C11.5177 29.858 14.3257 32.09 17.9977 32.09C18.8977 32.09 19.7617 31.982 20.6617 31.802L20.6257 27.194C20.2657 27.266 19.8337 27.302 19.2577 27.302C17.8537 27.302 16.9537 26.438 16.9537 24.818V21.722V17.978Z"
          stroke="white"
          strokeWidth="9.6"
          strokeLinejoin="round"
          mask="url(#path-1-outside-1_331_1888)"
        />
        <path
          d="M32.3816 13.298C29.9696 13.298 28.0976 14.594 27.1976 16.718V6.70996H21.6896V31.91H27.1976V21.83C27.1976 19.238 28.4216 17.726 30.4736 17.726C32.5256 17.726 33.7136 19.13 33.7136 21.542V31.91H39.2216V20.714C39.2216 16.25 36.4856 13.298 32.3816 13.298Z"
          stroke="white"
          strokeWidth="9.6"
          strokeLinejoin="round"
          mask="url(#path-1-outside-1_331_1888)"
        />
        <path
          d="M52.0455 13.658V23.738C52.0455 26.366 50.8575 27.842 48.8775 27.842C47.0055 27.842 45.8895 26.438 45.8895 24.062V13.658H40.3815V24.854C40.3815 29.318 43.0815 32.306 47.0415 32.306C49.3455 32.306 51.0735 31.406 52.1175 29.786L52.0455 31.91H57.5535V13.658H52.0455Z"
          stroke="white"
          strokeWidth="9.6"
          strokeLinejoin="round"
          mask="url(#path-1-outside-1_331_1888)"
        />
        <path
          d="M65.3098 32.522L70.2058 30.434L67.8298 24.854C65.9938 20.75 67.1098 18.338 70.2418 18.338C70.9618 18.338 71.3578 18.374 72.0418 18.446V13.478C71.5018 13.37 70.8898 13.298 70.2418 13.298C67.1098 13.298 65.2018 15.566 65.3458 19.094H65.2738L62.8618 13.514L57.9658 15.566L65.3098 32.522Z"
          stroke="white"
          strokeWidth="9.6"
          strokeLinejoin="round"
          mask="url(#path-1-outside-1_331_1888)"
        />
        <path
          d="M80.4821 32.594C85.1261 32.594 88.4381 30.074 88.4381 26.438C88.4381 23.342 86.1341 21.506 81.8861 20.606L80.1221 20.21C79.0061 19.958 78.4661 19.418 78.4661 18.626C78.4661 17.582 79.4741 16.826 80.8421 16.826C82.4981 16.826 83.9021 17.906 83.9741 19.49L88.9421 19.382C88.8341 15.602 85.4501 12.974 80.8421 12.974C76.3781 12.974 73.1741 15.566 73.1741 19.274C73.1741 22.406 75.0101 24.17 78.9341 24.962L80.6981 25.358C82.2461 25.718 83.0741 26.078 83.0741 27.086C83.0741 28.094 82.0301 28.742 80.4821 28.742C78.5381 28.742 77.2781 27.698 77.2421 26.078L72.2381 26.222C72.2741 29.966 75.6581 32.594 80.4821 32.594Z"
          stroke="white"
          strokeWidth="9.6"
          strokeLinejoin="round"
          mask="url(#path-1-outside-1_331_1888)"
        />
        <path
          d="M103.157 6.70996V15.71C101.861 14.162 100.061 13.298 97.8291 13.298C92.6451 13.298 89.0451 17.186 89.0451 22.802C89.0451 28.418 92.6451 32.306 97.8291 32.306C100.061 32.306 101.861 31.478 103.157 29.93V31.91H108.665V27.734V6.70996H103.157ZM99.0531 27.77C96.4251 27.77 94.6251 25.754 94.6251 22.802C94.6251 19.85 96.4611 17.798 99.0531 17.798C101.645 17.798 103.445 19.85 103.445 22.802C103.445 25.754 101.645 27.77 99.0531 27.77Z"
          stroke="white"
          strokeWidth="9.6"
          strokeLinejoin="round"
          mask="url(#path-1-outside-1_331_1888)"
        />
        <path
          d="M115.578 32.126C118.422 32.126 120.33 30.758 121.05 28.598H121.158C121.158 30.182 121.266 31.298 121.446 31.91H126.774C126.522 30.506 126.414 28.274 126.414 26.33V20.714C126.414 16.25 122.958 13.118 117.99 13.118C113.274 13.118 109.926 16.034 109.746 20.246L114.966 20.354C115.038 18.698 116.262 17.582 117.99 17.582C119.754 17.582 120.942 18.734 120.942 20.426V21.11L117.882 21.398C112.914 21.83 109.926 23.99 109.926 27.266C109.926 30.29 112.086 32.126 115.578 32.126ZM117.702 28.67C116.298 28.67 115.398 27.95 115.398 26.798C115.398 25.358 116.586 24.458 118.638 24.278L121.014 24.026V24.746C121.014 26.942 119.862 28.67 117.702 28.67Z"
          stroke="white"
          strokeWidth="9.6"
          strokeLinejoin="round"
          mask="url(#path-1-outside-1_331_1888)"
        />
        <path
          d="M130.446 39.29C134.478 39.29 137.574 36.806 139.482 32.018L146.97 13.658H141.282L136.962 25.394L132.246 13.658H126.414L133.974 31.586L133.686 32.234C132.75 34.178 131.31 34.502 129.51 34.502C128.862 34.502 128.25 34.43 127.926 34.322V39.002C128.646 39.218 129.51 39.29 130.446 39.29Z"
          stroke="white"
          strokeWidth="9.6"
          strokeLinejoin="round"
          mask="url(#path-1-outside-1_331_1888)"
        />
      </g>
      <defs>
        <filter
          id="filter0_thursdayIcon"
          x="0.693848"
          y="0.910156"
          width="154.076"
          height="48.1797"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_331_1888"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_331_1888"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_331_1888"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default ThursdayLoginLogo;
