import { DefaultTheme } from "styled-components";
import { darkTheme } from "./darkTheme";
import { ActivityEnum } from "../utils/activity.enum";

const MainColors = {
  backgroundGray: "#F0F4F7",
  backgroundGrayHover: "#DFE6EB",
  lightGray: "#b2bcc6",
  darkGray: "#475766",
  black: "#2f373d",
  purple100: "#F0EBFF",
  purple200: "#E0D6FF",
  purple400: "#A385FF",
  purple500: "#6633FF",
  purple600: "#5322E5",
  green100: "#E9F7F3",
  green200: "#D2F0E6",
  green400: "#78D2B4",
  green500: "#1EB482",
  green600: "#12996C",
  red100: "#FDEDED",
  red200: "#FBDADA",
  red400: "#F49191",
  red500: "#EC4747",
  red600: "#CC3333",
  yellow100: "#FFF9E6",
  yellow200: "#FFF3CC",
  yellow500: "#FFC200",
  yellow600: "#EBB200",
  orange100: "#FDF2EA",
  orange200: "#FDE5D4",
  orange400: "#F4B07F",
  orange500: "#FD7B2A",
  orange600: "#D96716",
  blue100: "#E6F7FF",
  blue200: "#CDEEFF",
  blue500: "#05AAFF",
  blue600: "#0094ED",
  royalBlue: "#4285F4",
  trueBlue: "#0078D4",
  white: "#ffffff",
  slack: "#4a154b",
  azureishWhite: "#D9E7FD",
};

const theme: DefaultTheme = {
  ...darkTheme,
  home: {
    background: "#F0EBFF",
  },
  lounge: {
    background: "#F0F4F7",
    backgroundHover: "#DFE6EB",
  },
  color: MainColors,
  trivia: {
    selectedOption: "#E2D8FF",
    optionBubble: "#d7caff",
  },
  volumeIndicator: {
    orange: "#ED7B2A",
  },
  userColors: [
    "#6633FF",
    "#CB5E10",
    "#007EBD",
    "#DF4A4A",
    "#16835F",
    "#D416C1",
    "#588023",
    "#1A53DB",
    "#AB46DD",
    "#19848A",
  ],
  doodle: {
    background: "#E5E5E5",
  },
  splashBackground: new Map([
    [ActivityEnum.TRIVIA, "#ED7B2A"],
    [ActivityEnum.WOULD_YOU_RATHER, "#6633FF"],
    [ActivityEnum.DOODLE_RACE, "#FFC200"],
    [ActivityEnum.RAPID_RENDEZVOUS, "#05AAFF"],
    [ActivityEnum.KNOW_YOUR_TEAM, "#FD7B2A"],
    [ActivityEnum.MINDFUL_TALK, "#1EB482"],
    [ActivityEnum.TWO_TRUTHS_AND_A_LIE, "#1EB482"],
    [ActivityEnum.CHARADES, "#05AAFF"],
    [ActivityEnum.DRUNK_STARTUPS, "#FFC200"],
  ]),
};

export default theme;
