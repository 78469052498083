import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { captureMessage, Severity } from "@sentry/react";
import axios from "axios";
import { fetchParticipantsFlag } from "../../API/common";
import { SentryErrorLogMessages } from "../../types/SentryErrorMessages";
import {
  getActiveOrganizationIdFromLocalStorage,
  setActiveOrganizationIdToLocalStorage,
} from "../../utils/activeOrganizationStore";
import {
  getGuestParticipantIdFromLocalStorage,
  setGuestParticipantIdToLocalStorage,
} from "../../utils/guestParticipantStore";
import { NewIconFlags } from "../../utils/newIconFlagTypes.enum";
import { RootState } from "../store";

export interface Organization {
  id: string;
  name: string;
}

export enum loginStatus {
  TO_CHECK = "toCheck",
  CHECKING = "checking",
  LOGGED_IN = "loggedIn",
  LOGGED_OUT = "loggedOut",
}

export type userLoginStatus = "toCheck" | "checking" | "loggedIn" | "loggedOut";
export interface Role {
  id: string;
  name: string;
}
export interface OrganizationUser {
  id: string;
  organization: Organization;
  role: Role;
}

export interface User {
  id: string;
  email: string;
  user_detail?: {
    id: string;
    name: string;
  }[];
}
export interface UserDetails {
  id: string;
  name: string;
  user: User;
}

// export interface UserState {
//   userDetails: UserDetails;
//   userDetailsLoading: boolean;
// }

// const initialState: UserState = {
//   userDetails: {
//       organisations: [],
//       currentOrganisationId: "",
//       userEmail: "",
//       userName: ""
//   },
//   userDetailsLoading: false
// };

interface ParticipantsFlagDataInterface {
  [key: string]: boolean;
}

export interface UserState {
  organizationUsers: OrganizationUser[];
  activeOrganizationId: string;
  loadingActiveOrganization: boolean;
  userDetails: UserDetails;
  joinedSocial: boolean | undefined;
  userDetailsLoading: boolean;
  userLoginStatus: userLoginStatus;
  activeNotificationSlackChannel: {
    organizationID: string;
    slackTeamID: string;
    name: string;
    status: "joined" | "removed";
  } | null;
  activeSlackApp: {
    organizationID: string;
    slackTeamID: string;
    status: "active" | "revoked";
    teamName: string;
  } | null;
  triggerSlackButtonClick: boolean;
  participantId?: string;
  guestParticipantId?: string;
  isValidated?: boolean;
  participantsFlagData: ParticipantsFlagDataInterface;
}

const initialState: UserState = {
  organizationUsers: [],
  activeOrganizationId: getActiveOrganizationIdFromLocalStorage() || "",
  loadingActiveOrganization: false,
  joinedSocial: undefined,
  userDetailsLoading: false,
  userLoginStatus: "toCheck",
  userDetails: {
    id: "",
    name: "",
    user: {
      id: "",
      email: "",
      user_detail: undefined,
    },
  },
  activeNotificationSlackChannel: null,
  activeSlackApp: null,
  triggerSlackButtonClick: false,
  guestParticipantId: getGuestParticipantIdFromLocalStorage() || "",
  isValidated: false,
  participantsFlagData: {},
};

// TODO: Needs refactoring
export const getUserDetails = createAsyncThunk("userDetails", async () => {
  try {
    const response = await axios.get<UserState>(
      `${process.env.REACT_APP_API_IDENTIFIER}/auth/token/validate`
    );
    return response;
  } catch (err) {
    // TODO: Scheduling v0: check if this is required
    // setLoginStatusFailed();
    captureMessage(SentryErrorLogMessages.UserFetchError, Severity.Error);
    throw err;
  }
});

export const getParticipantsFlag = createAsyncThunk(
  "getParticipantsFlag",
  async (flagTypes: Array<string>) => {
    const response: ParticipantsFlagDataInterface = await fetchParticipantsFlag(
      flagTypes
    );

    return response;
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUser: (state) => initialState,
    setLoadingActiveOrganization: (state, action: PayloadAction<boolean>) => {
      state.loadingActiveOrganization = action.payload;
    },
    setUser: (state, action: PayloadAction<UserState>) => {
      // state.email = action.payload.email;
      // state.organizationId = action.payload.organizationId;
      // state.name = action.payload.name;
      // state.organizationName = action.payload.organizationName;
    },
    changeActiveOrg: (state, action: PayloadAction<string>) => {
      state.activeOrganizationId = action.payload;
      setActiveOrganizationIdToLocalStorage(action.payload);
      state.loadingActiveOrganization = false;
    },
    changeOrgName: (state, action: PayloadAction<string>) => {
      let tempOrgUsers = state.organizationUsers.map((ou) => {
        if (ou.organization.id === state.activeOrganizationId) {
          ou.organization.name = action.payload;
        }
        return ou;
      });
      state.organizationUsers = tempOrgUsers;
    },
    setTriggerSlackButtonClick: (state, action: PayloadAction<boolean>) => {
      state.triggerSlackButtonClick = action.payload;
    },
    setGuestParticipantId: (state, action: PayloadAction<string>) => {
      setGuestParticipantIdToLocalStorage(action.payload);
      state.guestParticipantId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserDetails.pending, (state) => {
        state.userDetailsLoading = true;
        state.userLoginStatus = loginStatus.CHECKING;
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        state.userDetailsLoading = false;
        state.userLoginStatus = loginStatus.LOGGED_OUT;
        // Todo: temporary fix to prevent token issues for 401 response on dashboard page
        if (window.location.pathname === "/dashboard") {
          window.location.href = "/sign-in";
        }
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.userDetailsLoading = false;
        state.isValidated = true;

        if (action.payload.status === 200) {
          state.userLoginStatus = !!action.payload?.data?.userDetails
            ? loginStatus.LOGGED_IN
            : loginStatus.LOGGED_OUT;
          state.organizationUsers = action.payload?.data?.organizationUsers;
          state.userDetails = action.payload?.data?.userDetails;
          state.activeOrganizationId =
            action.payload?.data.activeOrganizationId;
          setActiveOrganizationIdToLocalStorage(
            action.payload?.data.activeOrganizationId
          );
          state.joinedSocial = action.payload?.data?.joinedSocial;
          state.activeNotificationSlackChannel =
            action.payload.data.activeNotificationSlackChannel;
          state.activeSlackApp = action.payload.data.activeSlackApp;
          state.participantId = action.payload.data.participantId;
        } else {
          state.userLoginStatus = loginStatus.LOGGED_OUT;
        }
      })
      .addCase(getParticipantsFlag.fulfilled, (state, action) => {
        state.participantsFlagData = Object.assign(
          state.participantsFlagData,
          action.payload
        );
      })
      .addCase(getParticipantsFlag.rejected, (state, action) => {
        state.participantsFlagData = {};
      });
  },
});

export const {
  resetUser,
  setLoadingActiveOrganization,
  setUser,
  changeOrgName,
  changeActiveOrg,
  setTriggerSlackButtonClick,
  setGuestParticipantId,
} = userSlice.actions;

//export const selectUserDetails = (state: RootState) => state.user.userDetails;

export const selectUserDetails = (state: RootState) => state.user.userDetails;

export const selectSlackAppStatus = (state: RootState) =>
  state.user.activeSlackApp?.status;

export const selectLoadingActiveOrganization = (state: RootState) =>
  state.user.loadingActiveOrganization;

export const selectSlackWorkspaceName = (state: RootState) =>
  state.user.activeSlackApp?.teamName;

export const selectTriggerSlackButtonClick = (state: RootState) =>
  state.user.triggerSlackButtonClick;

export const selectSlackChannelName = (state: RootState) =>
  state.user.activeNotificationSlackChannel?.name;

export const selectJoinedSocial = (state: RootState) => state.user.joinedSocial;

export const selectOrgDetails = (state: RootState) =>
  state.user.organizationUsers;

export const selectUserLoginStatus = (state: RootState) =>
  state.user.userLoginStatus;

export const selectParticipantId = (state: RootState) =>
  state.user.participantId;

export const selectGuestParticipantId = (state: RootState) =>
  state.user.guestParticipantId;

export const selectActiveOrganization = (state: RootState) => {
  return state?.user?.organizationUsers?.filter(
    (u) => u?.organization?.id === state?.user?.activeOrganizationId
  )?.[0];
};

export const selectUserDetailsLoading = (state: RootState) => {
  return state?.user?.userDetailsLoading;
};

export const selectIsValidated = (state: RootState) => {
  return state.user.isValidated;
};

export const selectIsAdmin = (state: RootState) => {
  return (
    state?.user?.organizationUsers?.filter(
      (u) => u?.organization?.id === state?.user?.activeOrganizationId
    )?.[0]?.role?.name === "Admin"
  );
};

export const selectParticipantsFlagData = (state: RootState) => {
  return state?.user.participantsFlagData;
};

export default userSlice.reducer;
