import React from "react";
import { useTheme } from "styled-components";

interface Props {
  color?: string;
  width?: string;
  height?: string;
}

const EditIcon: React.FC<Props> = ({ color, width, height }) => {
  const theme = useTheme();

  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2883 6.13251L13.4986 4.92212C13.6576 4.76317 13.7837 4.57447 13.8697 4.3668C13.9557 4.15912 14 3.93653 14 3.71174C14 3.48695 13.9557 3.26436 13.8697 3.05669C13.7837 2.84901 13.6576 2.66031 13.4986 2.50136C13.3397 2.34241 13.151 2.21632 12.9433 2.1303C12.7356 2.04428 12.513 2 12.2883 2C12.0635 2 11.8409 2.04428 11.6332 2.1303C11.4255 2.21632 11.2368 2.34241 11.0779 2.50136L9.7079 3.87133M12.2883 6.13251L5.32855 13.0922L2 14L2.90779 10.6714L9.7079 3.87133M12.2883 6.13251L9.7079 3.87133"
        stroke={color || theme.color.lightGray}
        strokeLinecap="round"
        strokeLinejoin="round"
        className="editable-stroke"
      />
      <path
        d="M2.64516 10.7742L2 14L4.90323 13.6774L12 6.25806L9.74194 4L2.64516 10.7742Z"
        fill={color || theme.color.lightGray}
        className="editable-fill"
      />
    </svg>
  );
};

export default EditIcon;
