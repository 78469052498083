import React, { useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { RoutesEnum } from "../routes/routes.enum";
import {
  loginStatus,
  selectUserDetailsLoading,
  selectUserLoginStatus,
  selectActiveOrganization,
} from "../store/features/userSlice";
import { useAppSelector } from "../store/hooks";
import LoginComponent from "../components/common/LoginComponent";
import JoinedFromMobile from "../components/Dashboard/JoinFromMobile";
import { useWindowSize, useQuery } from "../hooks";

export const BottomDiv = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;
  line-height: 21px;
  font-size: 13px;
`;

export const BottomDivGrayText = styled.div`
  font-weight: 400;
  color: ${(props) => props.theme.color.darkGray};
`;

export const BottomDivLoginText = styled.div`
  font-weight: 600;
  text-decoration: underline;
  margin-left: 4px;
  color: ${(props) => props.theme.color.purple500};
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.color.purple600};
  }
`;

const Login: React.FunctionComponent = () => {
  let history = useHistory();
  const userDetailsLoading = useAppSelector(selectUserDetailsLoading);
  const userLoginStatus = useAppSelector(selectUserLoginStatus);
  const activeOrganization = useAppSelector(selectActiveOrganization);

  const { width } = useWindowSize();
  const query = useQuery();
  const templateCode = String(query.get("templateCode"));

  useEffect(() => {
    if (!userDetailsLoading && userLoginStatus === loginStatus.LOGGED_IN) {
      if (templateCode === "null") {
        history.push(RoutesEnum.DASHBOARD);
      } else if (activeOrganization && activeOrganization?.id) {
        const createSocialLink = `${RoutesEnum.CREATE_SOCIAL}?auto=true&templateCode=${templateCode}`;
        history.push(createSocialLink);
      }
    }
  }, [userDetailsLoading, userLoginStatus, templateCode]);

  return (
    <>
      {width > 768 ? (
        <LoginComponent
          redirect={
            templateCode === "null"
              ? "dashboard"
              : `create-social?auto=true&templateCode=${templateCode}`
          }
          title={"Sign up on Thursday"}
          subtitle={""}
        />
      ) : (
        <JoinedFromMobile />
      )}
    </>
  );
};

export default Login;
