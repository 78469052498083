import styled from "styled-components";

export const ColouredBox = styled.div<{
  width?: string;
  bR?: string;
  bgC?: string;
  padding?: string;
}>`
  width: ${({ width }) => width || "100%"};
  border-radius: ${({ bR }) => bR || "24px"};
  background-color: ${({ bgC, theme }) => bgC || theme.color.yellow100};
  padding: ${({ padding }) => padding || "19px 10px"};
  text-align: center;
  box-sizing: border-box;
`;

export const MeetingStatusBox = styled(ColouredBox)<{ bgC?: string }>`
  background-color: ${({ bgC }) => bgC};
  margin-top: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MeetingStatusIcon = styled.div`
  margin: 0 10px;
  display: flex;
`;
