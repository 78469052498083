import * as React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { PrimaryButton } from "../components/common/Styled";
import { RoutesEnum } from "../routes/routes.enum";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { resetEvent, setShowSocialCreated } from "../store/features/eventSlice";
import { useQuery } from "../hooks";
import SpinningLoader from "../components/common/SpinningLoader";
import amplitude from "amplitude-js";
import { AmplitudeEvents } from "../types/amplitudeEvents";
import { resetMixer } from "../store/features/mixerSlice";
import { resetLounge } from "../store/features/Lounge/loungeSlice";
import { resetMeeting } from "../store/features/meetingSlice";
import { disconnect } from "../store/features/ws";
import { LOUNGE_PREFIX } from "../store/features/socketActionTypes";
import { setTemplateName } from "../store/features/authSlice";
import { TemplateCode } from "../types/template";
import {
  loginStatus,
  selectActiveOrganization,
  selectUserDetailsLoading,
  selectUserLoginStatus,
  setGuestParticipantId,
} from "../store/features/userSlice";
import { createEvent } from "../API/event";
import { getSocialLink } from "../utils";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const JoinHeading = styled.div`
  font-size: 24px;
  text-align: center;
  width: 200px;
  margin-bottom: 25px;
  position: relative;
  top: 40px;
  background: #fff;
  color: #888;
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 220px;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const CreateSocial: React.FunctionComponent = () => {
  const query = useQuery();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const activeOrg = useAppSelector(selectActiveOrganization);
  const auto = Boolean(query.get("auto"));
  const templateName = String(query.get("templateName"));
  const templateCode = String(query.get("templateCode"));
  const userDetailsLoading = useAppSelector(selectUserDetailsLoading);
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const userLoginStatus = useAppSelector(selectUserLoginStatus);

  let tCode = templateCode;

  if (templateCode === "null") {
    tCode = TemplateCode.CREATE_YOUR_OWN;
  }

  React.useEffect(() => {
    if (
      userDetailsLoading === false &&
      userLoginStatus !== loginStatus.CHECKING &&
      userLoginStatus !== loginStatus.TO_CHECK
    ) {
      if (templateName !== "null") {
        dispatch(setTemplateName(templateName));
      } else {
        dispatch(setTemplateName(""));
      }
      if (auto) {
        if (
          userLoginStatus !== loginStatus.LOGGED_IN ||
          (activeOrganization && activeOrganization?.id)
        ) {
          createSocialHandler();
        } else {
          history.push(`${RoutesEnum.DASHBOARD}`);
        }
      }
      dispatch(resetMixer());
      dispatch(resetLounge());
      dispatch(resetMeeting());
      dispatch(resetEvent());
      dispatch(disconnect(LOUNGE_PREFIX));
    }
  }, [userDetailsLoading, userDetailsLoading]);

  const createSocialHandler = async () => {
    const data = await createEvent({
      templateCode: tCode,
      frequency: "none",
      organizationId: activeOrg?.organization.id || "",
    });
    if (data) {
      dispatch(setShowSocialCreated(true));
      dispatch(setGuestParticipantId(data.participantId));
      amplitude.getInstance().logEvent(AmplitudeEvents.SOCIAL_CREATED);
      history.push(getSocialLink(data.eventCode));
    } else {
      history.push(`${RoutesEnum.DASHBOARD}`);
    }
  };

  return (
    <Container>
      {auto ? (
        <SpinningLoader />
      ) : (
        <>
          <JoinHeading>Create Social</JoinHeading>
          <FormRow>
            <PrimaryButton type="button" onClick={createSocialHandler}>
              Create
            </PrimaryButton>
          </FormRow>
        </>
      )}
    </Container>
  );
};

export default CreateSocial;
