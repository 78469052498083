import * as React from "react";
import styled, { useTheme } from "styled-components";
import MixerBuddiesList from "./MixerBuddiesList";

const Container = styled.div`
  position: relative;
`;

export const FundingIdea = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;

  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.01em;
  border-radius: 24px;
  width: 24px;
  height: 24px;
  /* Electric Purple/500 */

  color: ${(props) => props.theme.color.purple500};
  background: ${(props) => props.theme.color.purple100};
`;

const MixerBuddiesListTooltip = styled.div`
  position: absolute;
  top: -3rem;
  width: fit-content;
  background: ${(props) => props.theme.color.black};
  border-radius: 24px;
  /* left: -4rem; */
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 35px;
`;

const MixerBuddiesDivDrop = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  left: calc(50% - 32px / 2);
  top: -2.4rem;
  background: ${(props) => props.theme.color.black};
  transform: rotate(45deg);
  border-radius: 5px;
`;

interface FundingCounterPropsInterface {
  mixerBuddies: Array<string>;
}

const FundingCounter: React.FC<FundingCounterPropsInterface> = ({
  children,
  mixerBuddies = [],
}) => {
  const theme = useTheme();
  const [showHint, setShowHint] = React.useState(false);
  return (
    <Container>
      <FundingIdea
        onMouseLeave={() => {
          setShowHint(false);
        }}
        onMouseEnter={() => setShowHint(true)}
      >
        {children}
        {showHint && (
          <>
            <MixerBuddiesListTooltip>
              <MixerBuddiesList
                textColor={theme.color.white}
                seperatorColor={theme.color.lightGray}
                mixerBuddies={mixerBuddies}
              />
            </MixerBuddiesListTooltip>
            <MixerBuddiesDivDrop></MixerBuddiesDivDrop>
          </>
        )}
      </FundingIdea>
    </Container>
  );
};

export default FundingCounter;
