import React from "react";
import styled from "styled-components";

const TagsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 600px;
  margin-bottom: 26px;
`;

const Tag = styled.div<{ isSelected: boolean }>`
  display: flex;
  margin: 6px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px 8px 12px;
  background: ${({ theme, isSelected }) =>
    isSelected ? theme.color.purple500 : theme.color.white};
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.color.white : theme.color.black};
  border-radius: 24px;
  cursor: pointer;
  &:hover {
    background: ${({ theme, isSelected }) =>
      !isSelected && theme.lounge.background};
  }
`;

const TagIcon = styled.img`
  height: 16px;
`;

const TagText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 10px;
  letter-spacing: -0.01em;
`;

export interface TagsType {
  id: string;
  imgLink: string;
  text: string;
}

interface Props {
  tags: TagsType[];
  selectedTheme?: string;
  setSelectedTheme?: React.Dispatch<React.SetStateAction<string>>;
}

const Tags: React.FC<Props> = ({ tags, selectedTheme, setSelectedTheme }) => {
  return (
    <TagsContainer>
      {tags.map((tag) => {
        return (
          <Tag
            key={tag.id}
            isSelected={selectedTheme === tag.id}
            onClick={(ev) => {
              setSelectedTheme && setSelectedTheme(tag.id);
              ev.stopPropagation();
            }}
          >
            <TagIcon
              src={`${process.env.REACT_APP_CDN_BASE_URL}/theme-assets/${tag.imgLink}`}
            />
            <TagText>{tag.text}</TagText>
          </Tag>
        );
      })}
    </TagsContainer>
  );
};

export default Tags;
