import axios from "axios";
import { Theme } from "../store/features/mixerSlice";
import { PromptData } from "../store/features/questionSlice";

export interface utmData {
  ref?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
  time?: number;
}

export interface DoodlePredictResponse {
  Points: number;
  TotalScore: number;
  Prediction: any;
}

interface VideoMetaData {
  state: string;
  lastSeekTime: number;
}

export const fetchPrompts = async (
  activityId: string,
  isRandom?: boolean,
  limit?: number
) => {
  try {
    let url = `${
      process.env.REACT_APP_API_IDENTIFIER
    }/prompt?activityId=${activityId}&limit=${limit || 5}`;
    if (isRandom) {
      url += "&isRandom=true";
    }
    const response = await axios.get<PromptData[]>(url);
    return response.data;
  } catch (err) {
    console.error("err", err);
  }
};

export const selectPrompt = async (
  eventInstanceId: string,
  identifier: string,
  segmentId: string,
  promptId: string
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_IDENTIFIER}/segment-prompt/create?eventInstanceId=${eventInstanceId}&participantId=${identifier}`,
      {
        segmentId,
        promptId,
      }
    );
    if (response.status === 201) {
      return true;
    } else {
      console.error("err", response);
      return false;
    }
  } catch (err) {
    console.error("err", err);
    return false;
  }
};

export const createPrompt = async (
  segmentId: string,
  activityId: string,
  text: string
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_IDENTIFIER}/segment-prompt/prompt?segmentId=${segmentId}`,
      {
        activityId,
        text,
      }
    );
    if (response.status === 201) {
      return true;
    } else {
      console.error("err", response);
      return false;
    }
  } catch (err) {
    console.error("err", err);
    return false;
  }
};

export const utmCookieRequest = async (utmParams: utmData) => {
  try {
    let url = `${process.env.REACT_APP_API_IDENTIFIER}/utm/store`;

    if (Object.keys(utmParams).length === 0) return;
    axios.get(url, {
      withCredentials: true,
      params: utmParams,
    });
  } catch (err) {
    console.log("err", err);
  }
};

export const UpdateUserName = async (name: string, participantId: string) => {
  try {
    await axios.put(
      `${process.env.REACT_APP_API_IDENTIFIER}/participant/${participantId}`,
      {
        name: name,
      }
    );
  } catch (er) {
    console.log("Error in updating name");
    return false;
  }

  return true;
};

export const getThemesForActivity = async (activityId: string) => {
  try {
    const response = await axios.get<Theme[]>(
      `${process.env.REACT_APP_API_IDENTIFIER}/theme/activity-themes?activityId=${activityId}`
    );
    return response.data;
  } catch (err) {
    console.error("err", err);
  }
};

export const getThemesDetails = async (themeId: string) => {
  try {
    const response = await axios.get<Theme>(
      `${process.env.REACT_APP_API_IDENTIFIER}/theme/${themeId}`
    );
    return response.data;
  } catch (err) {
    console.error("err", err);
  }
};

export const selectThemeForSegment = async ({
  segmentId,
  themeId,
}: {
  segmentId: string;
  themeId: string | null;
}) => {
  try {
    await axios.put(
      `${process.env.REACT_APP_API_IDENTIFIER}/segment/${segmentId}/theme`,
      {
        themeId,
      }
    );
  } catch (err) {
    console.error("err", err);
  }
};

export const getSegmentThemeAPI = async (segmentId: string) => {
  try {
    const response = await axios.get<Theme>(
      `${process.env.REACT_APP_API_IDENTIFIER}/theme/segment-theme?segmentId=${segmentId}`
    );
    return response.data;
  } catch (err) {
    console.error("err", err);
  }
};

export enum ParticipantFlags {
  HIGHLIGHT_TEAM_MEMBER_COUNT = "HIGHLIGHT_TEAM_MEMBER_COUNT",
}

interface ParticipantFlagResponse {
  [ParticipantFlags.HIGHLIGHT_TEAM_MEMBER_COUNT]?: boolean;
}

interface DashboardStatsResponse {
  total_active_events: number;
}

export const getParticipantFlag = async ({
  orgId,
  types,
}: {
  orgId: string;
  types: ParticipantFlags[];
}) => {
  try {
    const response = await axios.get<ParticipantFlagResponse>(
      `${
        process.env.REACT_APP_API_IDENTIFIER
      }/participant/flag?organizationId=${orgId}&types=${JSON.stringify(types)}`
    );

    return response;
  } catch (err) {
    console.error("err", err);
  }
};

export const getDashboardStats = async ({ orgId }: { orgId: string }) => {
  try {
    const response = await axios.get<DashboardStatsResponse>(
      `${process.env.REACT_APP_API_IDENTIFIER}/organization/${orgId}/dashboard-stat`
    );

    return response;
  } catch (err) {
    console.error("err", err);
  }
};

export const unsetParticipantFlag = async ({
  orgId,
  type,
}: {
  orgId: string;
  type: ParticipantFlags;
}) => {
  try {
    const response = await axios.put<DashboardStatsResponse>(
      `${process.env.REACT_APP_API_IDENTIFIER}/participant/unset-flag`,
      {
        organizationId: orgId,
        type: type,
      }
    );

    return response;
  } catch (err) {
    console.error("err", err);
  }
};

export const fetchParticipantsFlag = async (flagTypes: Array<string>) => {
  try {
    const fetchFlagsURIParams = encodeURIComponent(JSON.stringify(flagTypes));

    const response = await axios.get(
      `${process.env.REACT_APP_API_IDENTIFIER}/participant/flag?types=${fetchFlagsURIParams}`
    );

    return response.data;
  } catch (err) {
    console.error("err", err);
  }
};

export const unsetNewIconFlag = async (flagType: {}) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_IDENTIFIER}/participant/unset-flag`,
      {
        type: flagType,
      }
    );
    if (response.status === 202) {
      return true;
    } else {
      console.error("err", response);
      return false;
    }
  } catch (err) {
    console.error("err", err);
  }
};

export const updateVideoMetaData = async (
  promptId: string,
  videoMetaData: VideoMetaData
) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_IDENTIFIER}/video-metadata/segment-prompt/${promptId}`,
      videoMetaData
    );
    if (response.status === 202) {
      return true;
    } else {
      console.error("err", response);
      return false;
    }
  } catch (err) {
    console.error("err", err);
  }
};

export const CreatePromptForVideoLink = async (
  link: string,
  activityId: string,
  participantId: string
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_IDENTIFIER}/prompt?link=${link}`,
      {
        activityId,
        participantId,
      }
    );
    return response.data;
  } catch (err) {
    console.error("err", err);
  }
};

export const createSuggestedVideoSegmentPrompt = async (
  eventInstanceId: string,
  segmentId: string,
  segmentGroupId: string,
  promptId: string,
  startedBy: string,
  updatedBy: string
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_IDENTIFIER}/segment-prompt/create?eventInstanceId=${eventInstanceId}`,
      {
        segmentId,
        promptId,
        segmentGroupId,
        startedBy,
        updatedBy,
      }
    );
    return response.data;
  } catch (err) {
    console.error("err", err);
  }
};

export const clearSegmentPrompt = async (
  eventInstanceId: string,
  segmentId: string,
  identifier: string
) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_IDENTIFIER}/segment-prompt/clear?eventInstanceId=${eventInstanceId}&segmentId=${segmentId}&participantId=${identifier}`
    );
    if (response.status === 200) {
      return true;
    } else {
      console.error("err", response);
      return false;
    }
  } catch (err) {
    return false;
    console.error("err", err);
  }
};

export const fetchPromptsById = async (promptId: string) => {
  try {
    const response = await axios.get<PromptData>(
      `${process.env.REACT_APP_API_IDENTIFIER}/prompt/${promptId}`
    );

    return response.data;
  } catch (err) {
    console.error("err", err);
  }
};

export const extendMixerTime = async (segmentId: string, time: number) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_IDENTIFIER}/segment/${segmentId}/mixerTime?time=${time}`
    );

    return response.data;
  } catch (err) {
    console.error("err", err);
  }
};

export const doodlePredict = async (
  canvasInput: string,
  mixerTimer: number,
  identifier: string,
  segmentGroupId: string,
  segmentId: string,
  promptId?: string
) => {
  try {
    const url = `${process.env.REACT_APP_API_IDENTIFIER}/doodle/predict?participantId=${identifier}&segmentGroupId=${segmentGroupId}&promptId=${promptId}&segmentId=${segmentId}`;
    const response = await axios.post<DoodlePredictResponse>(
      url,
      { canvasInput: canvasInput, timeTaken: mixerTimer },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;
  } catch (err) {
    console.error("err", err);
  }
};

export const fetchCurrentSegmentGroupPrompt = async (
  segmentGroupId: string
) => {
  try {
    const api = `${process.env.REACT_APP_API_IDENTIFIER}/segment-group/current-prompt/${segmentGroupId}`;
    const response = await axios.get<PromptData>(api);
    return response.data;
  } catch (err) {
    console.error("err", err);
  }
};
