import axios, { AxiosError } from "axios";
import { MemberOld } from "../components/Dashboard/TeamView";
import { MemberRoles, Members } from "../store/features/dashboardSlice";
import { getMugshot } from "../utils";

export const getOrganizationMembers = async (organizationId: string) => {
  try {
    const members = await axios.get<MemberOld[]>(
      `${process.env.REACT_APP_API_IDENTIFIER}/auth/organization/members?organizationId=${organizationId}`
    );
    const normalizedMembers: Members[] = members.data.map((member) => {
      const { email, id } = member.user;
      const { name } = member.user?.user_detail[0];
      const { code } = member.role as { code: MemberRoles };
      const { id: participantId } = member.Participant;
      return {
        name,
        email,
        role: code,
        icon: getMugshot(id, "0", "small"),
        id,
        participantId,
      };
    });
    return normalizedMembers;
  } catch (err) {
    console.error("err", err);
  }
};

export const validateTeamName = async (
  name: string,
  organizationId: string
) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_IDENTIFIER}/auth/organization/change-name`,
      {
        name: name,
        organizationId: organizationId,
      }
    );
    return res.status;
  } catch (error) {
    const err = error as AxiosError;
    console.error("err", err);
    return err?.response?.status;
  }
};
