import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { getMeetingDetailsApi } from "../../API/event";
import { Activity, ActivityType } from "../../types/activity";
import { MeetingDetails } from "../../types/meeting";
import { ActivityEnum } from "../../utils/activity.enum";
import { RootState } from "../store";
import { AuthState } from "./authSlice";

export interface Participants {
  participantIdentifier: string;
  name: string;
}
export interface MeetingState {
  meetingLoading: boolean;
  enterSocialLoading: boolean;
  roundMeetingLoaded: boolean;
  meetingFailed: boolean;
  meetingDetails: MeetingDetails | undefined;
  currentActivityName: string;
  nextSegmentDetailsLoading: boolean;
  nextSegmentId: string;
  currentSegmentId: string;
  currentSegmentDuration: number;
  currentSegmentGroupId: string;
  segmentGroupParticipants: Participants[];
  feedbackSubmitted: boolean;
  microphoneOn: boolean;
  videoOn: boolean;
  voiceFocusOn: boolean;
  audioInputDeviceId: string;
  audioOutputDeviceId: string;
  videoInputDeviceId: string;
  activities: Activity[];
  teamId: string;
}

const initialState: MeetingState = {
  meetingLoading: false,
  enterSocialLoading: false,
  roundMeetingLoaded: false,
  meetingFailed: false,
  meetingDetails: undefined,
  currentActivityName: "",
  currentSegmentDuration: 0,
  nextSegmentId: "",
  currentSegmentId: "",
  nextSegmentDetailsLoading: false,
  segmentGroupParticipants: [],
  currentSegmentGroupId: "",
  feedbackSubmitted: false,
  microphoneOn: JSON.parse(localStorage.getItem("microphoneOn") || "true"),
  videoOn: JSON.parse(localStorage.getItem("videoOn") || "true"),
  voiceFocusOn: localStorage.getItem("voiceFocusOn") !== "false",
  audioInputDeviceId: "",
  audioOutputDeviceId: "",
  videoInputDeviceId: "",
  teamId: "",
  activities: [],
};

export interface CreateOrJoinMeetingData {
  eventInstanceId: string;
}

export interface GetMeetingData {
  segmentGroupId: string;
  eventInstanceId: string;
}

export const createOrJoinMeeting = createAsyncThunk(
  "createOrJoinMeeting",
  async (data: CreateOrJoinMeetingData, { getState }) => {
    const { auth } = getState() as { auth: AuthState };
    const response = await axios.post<MeetingState>(
      `${process.env.REACT_APP_API_IDENTIFIER}/meeting/enter-social/${data.eventInstanceId}`,
      {
        name: auth.guestName,
      }
    );
    return response.data;
  }
);

export const fetchActivities = createAsyncThunk(
  "fetchActivities",
  async (type?: ActivityType) => {
    const response = await axios.get<Activity[]>(
      `${process.env.REACT_APP_API_IDENTIFIER}/activity?type=${type || ""}`
    );
    return response.data;
  }
);
export const getMeetingDetails = createAsyncThunk(
  "getMeetingDetails",
  async (payload: GetMeetingData) => {
    const data = await getMeetingDetailsApi({
      eventInstanceId: payload.eventInstanceId,
      segmentGroupId: payload.segmentGroupId,
    });
    return data;
  }
);

export const meetingSlice = createSlice({
  name: "meeting",
  initialState,
  reducers: {
    setFeedbackSubmitted: (state, action: PayloadAction<boolean>) => {
      state.feedbackSubmitted = action.payload;
    },
    setMicrophoneOn: (state, action: PayloadAction<boolean>) => {
      localStorage.setItem("microphoneOn", `${action.payload}`);
      state.microphoneOn = action.payload;
    },
    setVideoOn: (state, action: PayloadAction<boolean>) => {
      localStorage.setItem("videoOn", `${action.payload}`);
      state.videoOn = action.payload;
    },
    setVoiceFocusOn: (state, action: PayloadAction<boolean>) => {
      localStorage.setItem("voiceFocusOn", `${action.payload}`);
      state.voiceFocusOn = action.payload;
    },
    setAudioInputDeviceId: (state, action: PayloadAction<string>) => {
      state.audioInputDeviceId = action.payload;
    },
    setAudioOutputDeviceId: (state, action: PayloadAction<string>) => {
      state.audioOutputDeviceId = action.payload;
    },
    setVideoInputDeviceId: (state, action: PayloadAction<string>) => {
      state.videoInputDeviceId = action.payload;
    },
    setNextSegmentId: (state, action: PayloadAction<string>) => {
      state.nextSegmentId = action.payload;
    },
    setMeetingFailed: (state, action: PayloadAction<boolean>) => {
      state.meetingFailed = action.payload;
    },
    setEnterSocialLoading: (state, action: PayloadAction<boolean>) => {
      state.enterSocialLoading = action.payload;
    },
    clearMixerBuddies: (state) => {
      state.segmentGroupParticipants = [];
    },
    resetMeeting: (state) => {
      return {
        ...initialState,
        microphoneOn: state.microphoneOn,
        videoOn: state.videoOn,
        voiceFocusOn: true,
        audioInputDeviceId: state.audioInputDeviceId,
        audioOutputDeviceId: state.audioOutputDeviceId,
        videoInputDeviceId: state.videoInputDeviceId,
        currentActivityName: state.currentActivityName,
        enterSocialLoading: state.enterSocialLoading,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrJoinMeeting.pending, (state) => {
        state.meetingLoading = true;
        state.enterSocialLoading = true;
        state.roundMeetingLoaded = false;
      })
      .addCase(createOrJoinMeeting.rejected, (state) => {
        state.meetingFailed = true;
      })
      .addCase(createOrJoinMeeting.fulfilled, (state, action) => {
        state.meetingLoading = false;
        state.enterSocialLoading = false;
        state.meetingFailed = false;
        state.currentActivityName = action.payload?.currentActivityName;
        state.nextSegmentId = action.payload?.nextSegmentId;
        state.currentSegmentId = action.payload?.currentSegmentId;
        state.currentSegmentDuration = action.payload?.currentSegmentDuration;
        state.currentSegmentGroupId = action.payload?.currentSegmentGroupId;
        state.segmentGroupParticipants =
          action.payload?.segmentGroupParticipants;
        state.roundMeetingLoaded = true;
        state.teamId = action.payload?.teamId;
      })
      .addCase(getMeetingDetails.pending, (state) => {
        state.meetingLoading = true;
      })
      .addCase(getMeetingDetails.fulfilled, (state, action) => {
        state.meetingLoading = false;
        state.meetingDetails = action.payload?.meetingDetails;
      })
      .addCase(fetchActivities.fulfilled, (state, action) => {
        const activities: Activity[] = [];
        activities.push(
          action.payload.find(
            (activity) => activity.name === ActivityEnum.KNOW_YOUR_TEAM
          )!
        );
        activities.push(
          action.payload.find(
            (activity) => activity.name === ActivityEnum.RAPID_RENDEZVOUS
          )!
        );
        activities.push(
          ...action.payload.filter(
            (activity) =>
              activity.name !== ActivityEnum.KNOW_YOUR_TEAM &&
              activity.name !== ActivityEnum.RAPID_RENDEZVOUS
          )
        );
        state.activities = activities;
      });
  },
});

export const {
  setFeedbackSubmitted,
  setMicrophoneOn,
  setVideoOn,
  setVoiceFocusOn,
  setAudioInputDeviceId,
  setAudioOutputDeviceId,
  setVideoInputDeviceId,
  clearMixerBuddies,
  resetMeeting,
  setNextSegmentId,
  setMeetingFailed,
  setEnterSocialLoading,
} = meetingSlice.actions;

export const selectMeetingLoading = (state: RootState) =>
  state.meeting.meetingLoading;
export const selectEnterSocialLoading = (state: RootState) =>
  state.meeting.enterSocialLoading;
export const selectRoundMeetingLoaded = (state: RootState) =>
  state.meeting.roundMeetingLoaded;
export const selectMeetingFailed = (state: RootState) =>
  state.meeting.meetingFailed;
export const selectMeetingDetails = (state: RootState) =>
  state.meeting.meetingDetails;
export const selectCurrentActivityName = (state: RootState) =>
  state.meeting.currentActivityName;
export const selectNextSegmentId = (state: RootState) =>
  state.meeting.nextSegmentId;
export const selectCurrentSegmentId = (state: RootState) =>
  state.meeting.currentSegmentId;
export const selectCurrentSegmentDuration = (state: RootState) =>
  state.meeting.currentSegmentDuration;
export const selectFeedbackSubmitted = (state: RootState) =>
  state.meeting.feedbackSubmitted;
export const selectMicrophoneOn = (state: RootState) =>
  state.meeting.microphoneOn;
export const selectVideoOn = (state: RootState) => state.meeting.videoOn;
export const selectAudioInputDeviceId = (state: RootState) =>
  state.meeting.audioInputDeviceId;
export const selectAudioOutputDeviceId = (state: RootState) =>
  state.meeting.audioOutputDeviceId;
export const selectVideoInputDeviceId = (state: RootState) =>
  state.meeting.videoInputDeviceId;
export const selectVoiceFocusOn = (state: RootState) =>
  state.meeting.voiceFocusOn;
export const selectCurrentSegmentGroupId = (state: RootState) =>
  state.meeting.currentSegmentGroupId;

export const selectCurrentTeamId = (state: RootState) => state.meeting.teamId;
export const selectActivities = (state: RootState) => state.meeting.activities;
export const selectCurrentSegmentGroupParticipants = (state: RootState) =>
  state.meeting.segmentGroupParticipants;

export default meetingSlice.reducer;
