import axios from "axios";

export const getOrganizationCode = async (organizationId: string) => {
  try {
    const response = await axios.get<{ organizationCode: string }>(
      `${process.env.REACT_APP_API_IDENTIFIER}/organization-invite?organizationId=${organizationId}`
    );
    return response.data;
  } catch (err) {
    console.error("err", err);
  }
};

export interface OrganizationDetails {
  organizationId: string;
  organizationName: string;
  createdBy: string;
}

export const getOrganizationFromCode = async (organizationCode: string) => {
  try {
    const response = await axios.get<OrganizationDetails>(
      `${process.env.REACT_APP_API_IDENTIFIER}/organization-invite/${organizationCode}`
    );
    return response.data;
  } catch (err) {
    console.error("err", err);
  }
};

export const googleRedirectApi = async ({
  organizationCode,
  redirect = "",
  emailIdentifier = "",
}: {
  organizationCode: string;
  redirect?: string;
  emailIdentifier?: string;
}) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_IDENTIFIER}/auth/google/redirect-url`,
      {
        params: {
          state: {
            organizationCode,
            RedirectRoute: redirect,
            emailIdentifier,
          },
        },
      }
    );
    window.location.href = decodeURIComponent(escape(response.data.url));
    return true;
  } catch (err) {
    console.error("err", err);
    return false;
  }
};

export const changeActiveOrgApi = async (orgId: string) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_API_IDENTIFIER}/auth/user/change-active-organization`,
      {
        activeOrganizationId: orgId,
      }
    );
  } catch (err) {
    console.error("err", err);
  }
};
