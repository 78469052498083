import * as React from "react";
import styled from "styled-components";
import BuildingIcon from "../../assets/BuildingIcon";

export const WorkspaceDetailsContainer = styled.div`
  display: flex;
  margin-top: 8px;
`;

export const WorkspaceName = styled.div`
  font-weight: 500;
  font-size: 13px;
  display: flex;
  line-height: 160%;
  align-items: center;
  color: ${(props) => props.theme.color.darkGray};
  margin-left: 8px;
`;

const WorkspaceDetails: React.FC<{ workspaceName?: string }> = ({
  workspaceName = "",
}) => {
  return (
    <WorkspaceDetailsContainer>
      <BuildingIcon />
      <WorkspaceName>{workspaceName}</WorkspaceName>
    </WorkspaceDetailsContainer>
  );
};

export default WorkspaceDetails;
