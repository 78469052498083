import React, { CSSProperties } from "react";

const maskStyle: CSSProperties = {
  maskType: "alpha",
};

const DrunkStartupsSplashLogo: React.FC = () => {
  return (
    <svg
      width="246"
      height="48"
      viewBox="0 0 246 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1395_1232)">
        <g filter="url(#filter0_d_1395_1232)">
          <mask
            id="mask0_1395_1232"
            style={maskStyle}
            maskUnits="userSpaceOnUse"
            x="3"
            y="1"
            width="241"
            height="43"
          >
            <path
              d="M243.328 1.58496H3.32764V43.585H243.328V1.58496Z"
              fill="white"
            />
            <path
              d="M9.6465 37.9963L19.2094 37.4952C27.1185 37.0807 32.3836 31.6136 31.9955 24.2078C31.6074 16.802 25.7997 11.9152 17.8905 12.3297L8.32764 12.8309L9.6465 37.9963ZM15.1574 33.0932L14.3209 17.1311L18.0598 25.9352C22.5536 25.6997 25.8887 19.8054 26.1355 24.5149C26.3824 29.2244 23.3901 32.6617 18.8963 32.8973L15.1574 33.0932Z"
              fill="black"
            />
            <path
              d="M39.8663 37.0253L44.6463 34.6839L41.9816 29.2359C39.9333 25.2337 40.9215 22.7666 44.0492 22.6026C44.7682 22.565 45.1656 22.5802 45.8524 22.6163L45.5924 17.6551C45.0475 17.5755 44.4326 17.5356 43.7855 17.5695C40.6577 17.7335 38.8711 20.0982 39.1995 23.6138L39.1276 23.6176L36.4269 18.1715L31.645 20.4769L39.8663 37.0253Z"
              fill="black"
            />
            <path
              d="M58.129 17.1783L58.6566 27.2445C58.7941 29.8689 57.685 31.4051 55.7077 31.5087C53.8383 31.6067 52.6503 30.263 52.526 27.8903L51.9815 17.5005L46.481 17.7888L47.067 28.9694C47.3006 33.4273 50.1533 36.2699 54.1079 36.0627C56.4087 35.9421 58.0872 34.9529 59.045 33.2805L59.0843 35.4053L64.5847 35.1171L63.6295 16.8901L58.129 17.1783Z"
              fill="black"
            />
            <path
              d="M75.8143 15.8912C73.2978 16.0231 71.4659 17.525 70.7236 19.8711L70.6563 16.522L65.1558 16.8103L66.1111 35.0373L71.6115 34.749L71.084 24.6828C70.9483 22.0944 72.0915 20.5204 74.1407 20.413C76.1899 20.3056 77.4497 21.6455 77.576 24.0542L78.1186 34.408L83.619 34.1197L83.0331 22.9391C82.7994 18.4812 79.9127 15.6764 75.8143 15.8912Z"
              fill="black"
            />
            <path
              d="M105.285 14.7071L98.5258 15.0613L91.1619 22.4408L92.7127 8.6968L87.4963 8.21313L84.5483 33.8186L89.7988 34.2644L90.3126 29.6231L94.2188 25.7414L98.9058 33.3185L105.017 32.9982L101.023 21.9731L105.285 14.7071Z"
              fill="black"
            />
            <path
              d="M118.258 32.9892C122.896 32.7461 126.072 30.0562 125.881 26.4252C125.719 23.3335 123.322 21.6206 119.033 20.9441L117.251 20.641C116.123 20.4477 115.556 19.9367 115.514 19.1458C115.459 18.1033 116.427 17.2955 117.793 17.2239C119.446 17.1373 120.905 18.1423 121.06 19.7204L126.015 19.3525C125.71 15.5834 122.193 13.1361 117.591 13.3772C113.133 13.6109 110.069 16.367 110.263 20.0699C110.427 23.1976 112.353 24.8631 116.313 25.4487L118.095 25.7518C119.66 26.0303 120.506 26.3465 120.559 27.3531C120.611 28.3597 119.603 29.0614 118.057 29.1425C116.115 29.2442 114.803 28.2676 114.682 26.6517L109.692 27.0574C109.924 30.7944 113.441 33.2416 118.258 32.9892Z"
              fill="black"
            />
            <path
              d="M134.134 17.5212L137.98 17.3196L137.754 13.0056L133.907 13.2072L133.681 8.89307L128.253 9.17756L128.479 13.4917L125.459 13.6499L125.685 17.964L128.705 17.8057L129.084 25.0318C129.327 29.6695 132.248 31.7514 135.915 31.5593C136.813 31.5122 137.671 31.3591 138.56 31.1322L138.283 26.5324C137.927 26.6232 137.498 26.6817 136.922 26.7119C135.52 26.7854 134.576 25.9696 134.491 24.3519L134.134 17.5212Z"
              fill="black"
            />
            <path
              d="M144.996 31.1192C147.836 30.9703 149.67 29.5043 150.276 27.3096L150.384 27.304C150.467 28.8858 150.633 29.9946 150.845 30.5964L156.165 30.3175C155.84 28.9286 155.616 26.7053 155.514 24.764L155.22 19.1557C154.986 14.6978 151.371 11.751 146.41 12.011C141.7 12.2578 138.51 15.345 138.55 19.5607L143.769 19.3953C143.754 17.7378 144.918 16.5593 146.644 16.4689C148.405 16.3765 149.652 17.4648 149.74 19.1545L149.776 19.8375L146.735 20.2853C141.797 20.9767 138.926 23.2901 139.097 26.5616C139.256 29.5815 141.509 31.3019 144.996 31.1192ZM146.936 27.5567C145.534 27.6302 144.598 26.9583 144.537 25.8079C144.462 24.3699 145.601 23.4089 147.641 23.1218L150.001 22.7458L150.038 23.4648C150.153 25.6578 149.093 27.4437 146.936 27.5567Z"
              fill="black"
            />
            <path
              d="M163.531 30.5443L168.311 28.203L165.646 22.755C163.598 18.7527 164.586 16.2856 167.714 16.1217C168.433 16.084 168.83 16.0992 169.517 16.1353L169.257 11.1741C168.712 11.0945 168.097 11.0546 167.45 11.0886C164.323 11.2525 162.536 13.6172 162.864 17.1329L162.792 17.1366L160.092 11.6905L155.31 13.9959L163.531 30.5443Z"
              fill="black"
            />
            <path
              d="M178.173 15.2131L182.02 15.0115L181.794 10.6974L177.947 10.899L177.721 6.58496L172.292 6.86946L172.518 11.1835L169.499 11.3418L169.725 15.6559L172.745 15.4976L173.123 22.7237C173.366 27.3613 176.287 29.4433 179.954 29.2511C180.853 29.204 181.71 29.051 182.599 28.8241L182.322 24.2243C181.967 24.3151 181.537 24.3736 180.962 24.4038C179.56 24.4772 178.616 23.6615 178.531 22.0437L178.173 15.2131Z"
              fill="black"
            />
            <path
              d="M194.145 10.0503L194.672 20.1164C194.81 22.7408 193.701 24.277 191.723 24.3806C189.854 24.4786 188.666 23.1349 188.542 20.7622L187.997 10.3724L182.497 10.6607L183.083 21.8414C183.316 26.2992 186.169 29.1418 190.124 28.9346C192.424 28.814 194.103 27.8248 195.061 26.1524L195.1 28.2772L200.6 27.989L199.645 9.76196L194.145 10.0503Z"
              fill="black"
            />
            <path
              d="M211.938 8.75717C209.745 8.8721 207.991 9.79317 206.775 11.3709L206.672 9.39363L201.171 9.6819L202.485 34.7395L207.985 34.4512L207.525 25.6793C208.899 27.1214 210.74 27.854 212.933 27.7391C218.109 27.4678 221.501 23.3967 221.207 17.7884C220.913 12.1801 217.115 8.48586 211.938 8.75717ZM211.509 23.2715C208.884 23.409 206.981 21.49 206.827 18.5421C206.672 15.5941 208.362 13.4507 210.987 13.3132C213.539 13.1794 215.48 15.1325 215.635 18.0805C215.789 21.0284 214.097 23.1359 211.509 23.2715Z"
              fill="black"
            />
            <path
              d="M230.169 27.1242C234.806 26.8811 237.982 24.1913 237.792 20.5602C237.63 17.4685 235.233 15.7556 230.944 15.0791L229.161 14.776C228.034 14.5828 227.466 14.0718 227.425 13.2808C227.37 12.2383 228.337 11.4306 229.703 11.359C231.357 11.2723 232.815 12.2773 232.97 13.8554L237.926 13.4875C237.62 9.71833 234.103 7.27104 229.502 7.5122C225.044 7.74583 221.98 10.502 222.174 14.2049C222.338 17.3326 224.263 18.9981 228.224 19.5837L230.006 19.8868C231.571 20.1653 232.416 20.4815 232.469 21.4881C232.522 22.4947 231.513 23.1965 229.967 23.2775C228.026 23.3792 226.713 22.4026 226.592 20.7867L221.603 21.1924C221.835 24.9294 225.351 27.3767 230.169 27.1242Z"
              fill="black"
            />
          </mask>
          <g mask="url(#mask0_1395_1232)">
            <path
              d="M9.6465 37.9963L19.2094 37.4952C27.1185 37.0807 32.3836 31.6136 31.9955 24.2078C31.6074 16.802 25.7997 11.9152 17.8905 12.3297L8.32764 12.8309L9.6465 37.9963ZM15.1574 33.0932L14.3209 17.1311L18.0598 25.9352C22.5536 25.6997 25.8887 19.8054 26.1355 24.5149C26.3824 29.2244 23.3901 32.6617 18.8963 32.8973L15.1574 33.0932Z"
              fill="#FFC200"
            />
            <path
              d="M39.8663 37.0253L44.6463 34.6839L41.9816 29.2359C39.9333 25.2337 40.9215 22.7666 44.0492 22.6026C44.7682 22.565 45.1656 22.5802 45.8524 22.6163L45.5924 17.6551C45.0475 17.5755 44.4326 17.5356 43.7855 17.5695C40.6577 17.7335 38.8711 20.0982 39.1995 23.6138L39.1276 23.6176L36.4269 18.1715L31.645 20.4769L39.8663 37.0253Z"
              fill="#FFC200"
            />
            <path
              d="M58.129 17.1783L58.6566 27.2445C58.7941 29.8689 57.685 31.4051 55.7077 31.5087C53.8383 31.6067 52.6503 30.263 52.526 27.8903L51.9815 17.5005L46.481 17.7888L47.067 28.9694C47.3006 33.4273 50.1533 36.2699 54.1079 36.0627C56.4087 35.9421 58.0872 34.9529 59.045 33.2805L59.0843 35.4053L64.5847 35.1171L63.6295 16.8901L58.129 17.1783Z"
              fill="#FFC200"
            />
            <path
              d="M75.8143 15.8912C73.2978 16.0231 71.4659 17.525 70.7236 19.8711L70.6563 16.522L65.1558 16.8103L66.1111 35.0373L71.6115 34.749L71.084 24.6828C70.9483 22.0944 72.0915 20.5204 74.1407 20.413C76.1899 20.3056 77.4497 21.6455 77.576 24.0542L78.1186 34.408L83.619 34.1197L83.0331 22.9391C82.7994 18.4812 79.9127 15.6764 75.8143 15.8912Z"
              fill="#FFC200"
            />
            <path
              d="M105.285 14.7071L98.5258 15.0613L91.1619 22.4408L92.7127 8.6968L87.4963 8.21313L84.5483 33.8186L89.7988 34.2644L90.3126 29.6231L94.2188 25.7414L98.9058 33.3185L105.017 32.9982L101.023 21.9731L105.285 14.7071Z"
              fill="#FFC200"
            />
            <path
              d="M118.258 32.9892C122.896 32.7461 126.072 30.0562 125.881 26.4252C125.719 23.3335 123.322 21.6206 119.033 20.9441L117.251 20.641C116.123 20.4477 115.556 19.9367 115.514 19.1458C115.459 18.1033 116.427 17.2955 117.793 17.2239C119.446 17.1373 120.905 18.1423 121.06 19.7204L126.015 19.3525C125.71 15.5834 122.193 13.1361 117.591 13.3772C113.133 13.6109 110.069 16.367 110.263 20.0699C110.427 23.1976 112.353 24.8631 116.313 25.4487L118.095 25.7518C119.66 26.0303 120.506 26.3465 120.559 27.3531C120.611 28.3597 119.603 29.0614 118.057 29.1425C116.115 29.2442 114.803 28.2676 114.682 26.6517L109.692 27.0574C109.924 30.7944 113.441 33.2416 118.258 32.9892Z"
              fill="#FFC200"
            />
            <path
              d="M134.134 17.5212L137.98 17.3196L137.754 13.0056L133.907 13.2072L133.681 8.89307L128.253 9.17756L128.479 13.4917L125.459 13.6499L125.685 17.964L128.705 17.8057L129.084 25.0318C129.327 29.6695 132.248 31.7514 135.915 31.5593C136.813 31.5122 137.671 31.3591 138.56 31.1322L138.283 26.5324C137.927 26.6232 137.498 26.6817 136.922 26.7119C135.52 26.7854 134.576 25.9696 134.491 24.3519L134.134 17.5212Z"
              fill="#FFC200"
            />
            <path
              d="M144.996 31.1192C147.836 30.9703 149.67 29.5043 150.276 27.3096L150.384 27.304C150.467 28.8858 150.633 29.9946 150.845 30.5964L156.165 30.3175C155.84 28.9286 155.616 26.7053 155.514 24.764L155.22 19.1557C154.986 14.6978 151.371 11.751 146.41 12.011C141.7 12.2578 138.51 15.345 138.55 19.5607L143.769 19.3953C143.754 17.7378 144.918 16.5593 146.644 16.4689C148.405 16.3765 149.652 17.4648 149.74 19.1545L149.776 19.8375L146.735 20.2853C141.797 20.9767 138.926 23.2901 139.097 26.5616C139.256 29.5815 141.509 31.3019 144.996 31.1192ZM146.936 27.5567C145.534 27.6302 144.598 26.9583 144.537 25.8079C144.462 24.3699 145.601 23.4089 147.641 23.1218L150.001 22.7458L150.038 23.4648C150.153 25.6578 149.093 27.4437 146.936 27.5567Z"
              fill="#FFC200"
            />
            <path
              d="M163.531 30.5443L168.311 28.203L165.646 22.755C163.598 18.7527 164.586 16.2856 167.714 16.1217C168.433 16.084 168.83 16.0992 169.517 16.1353L169.257 11.1741C168.712 11.0945 168.097 11.0546 167.45 11.0886C164.323 11.2525 162.536 13.6172 162.864 17.1329L162.792 17.1366L160.092 11.6905L155.31 13.9959L163.531 30.5443Z"
              fill="#FFC200"
            />
            <path
              d="M178.173 15.2131L182.02 15.0115L181.794 10.6974L177.947 10.899L177.721 6.58496L172.292 6.86946L172.518 11.1835L169.499 11.3418L169.725 15.6559L172.745 15.4976L173.123 22.7237C173.366 27.3613 176.287 29.4433 179.954 29.2511C180.853 29.204 181.71 29.051 182.599 28.8241L182.322 24.2243C181.967 24.3151 181.537 24.3736 180.962 24.4038C179.56 24.4772 178.616 23.6615 178.531 22.0437L178.173 15.2131Z"
              fill="#FFC200"
            />
            <path
              d="M194.145 10.0503L194.672 20.1164C194.81 22.7408 193.701 24.277 191.723 24.3806C189.854 24.4786 188.666 23.1349 188.542 20.7622L187.997 10.3724L182.497 10.6607L183.083 21.8414C183.316 26.2992 186.169 29.1418 190.124 28.9346C192.424 28.814 194.103 27.8248 195.061 26.1524L195.1 28.2772L200.6 27.989L199.645 9.76196L194.145 10.0503Z"
              fill="#FFC200"
            />
            <path
              d="M211.938 8.75717C209.745 8.8721 207.991 9.79317 206.775 11.3709L206.672 9.39363L201.171 9.6819L202.485 34.7395L207.985 34.4512L207.525 25.6793C208.899 27.1214 210.74 27.854 212.933 27.7391C218.109 27.4678 221.501 23.3967 221.207 17.7884C220.913 12.1801 217.115 8.48586 211.938 8.75717ZM211.509 23.2715C208.884 23.409 206.981 21.49 206.827 18.5421C206.672 15.5941 208.362 13.4507 210.987 13.3132C213.539 13.1794 215.48 15.1325 215.635 18.0805C215.789 21.0284 214.097 23.1359 211.509 23.2715Z"
              fill="#FFC200"
            />
            <path
              d="M230.169 27.1242C234.806 26.8811 237.982 24.1913 237.792 20.5602C237.63 17.4685 235.233 15.7556 230.944 15.0791L229.161 14.776C228.034 14.5828 227.466 14.0718 227.425 13.2808C227.37 12.2383 228.337 11.4306 229.703 11.359C231.357 11.2723 232.815 12.2773 232.97 13.8554L237.926 13.4875C237.62 9.71833 234.103 7.27104 229.502 7.5122C225.044 7.74583 221.98 10.502 222.174 14.2049C222.338 17.3326 224.263 18.9981 228.224 19.5837L230.006 19.8868C231.571 20.1653 232.416 20.4815 232.469 21.4881C232.522 22.4947 231.513 23.1965 229.967 23.2775C228.026 23.3792 226.713 22.4026 226.592 20.7867L221.603 21.1924C221.835 24.9294 225.351 27.3767 230.169 27.1242Z"
              fill="#FFC200"
            />
            <path
              d="M9.6465 37.9963L19.2094 37.4952C27.1185 37.0807 32.3836 31.6136 31.9955 24.2078C31.6074 16.802 25.7997 11.9152 17.8905 12.3297L8.32764 12.8309L9.6465 37.9963ZM15.1574 33.0932L14.3209 17.1311L18.0598 25.9352C22.5536 25.6997 25.8887 19.8054 26.1355 24.5149C26.3824 29.2244 23.3901 32.6617 18.8963 32.8973L15.1574 33.0932Z"
              stroke="#FFC200"
              strokeWidth="10"
              strokeLinejoin="round"
            />
            <path
              d="M39.8663 37.0253L44.6463 34.6839L41.9816 29.2359C39.9333 25.2337 40.9215 22.7666 44.0492 22.6026C44.7682 22.565 45.1656 22.5802 45.8524 22.6163L45.5924 17.6551C45.0475 17.5755 44.4326 17.5356 43.7855 17.5695C40.6577 17.7335 38.8711 20.0982 39.1995 23.6138L39.1276 23.6176L36.4269 18.1715L31.645 20.4769L39.8663 37.0253Z"
              stroke="#FFC200"
              strokeWidth="10"
              strokeLinejoin="round"
            />
            <path
              d="M58.129 17.1783L58.6566 27.2445C58.7941 29.8689 57.685 31.4051 55.7077 31.5087C53.8383 31.6067 52.6503 30.263 52.526 27.8903L51.9815 17.5005L46.481 17.7888L47.067 28.9694C47.3006 33.4273 50.1533 36.2699 54.1079 36.0627C56.4087 35.9421 58.0872 34.9529 59.045 33.2805L59.0843 35.4053L64.5847 35.1171L63.6295 16.8901L58.129 17.1783Z"
              stroke="#FFC200"
              strokeWidth="10"
              strokeLinejoin="round"
            />
            <path
              d="M75.8143 15.8912C73.2978 16.0231 71.4659 17.525 70.7236 19.8711L70.6563 16.522L65.1558 16.8103L66.1111 35.0373L71.6115 34.749L71.084 24.6828C70.9483 22.0944 72.0915 20.5204 74.1407 20.413C76.1899 20.3056 77.4497 21.6455 77.576 24.0542L78.1186 34.408L83.619 34.1197L83.0331 22.9391C82.7994 18.4812 79.9127 15.6764 75.8143 15.8912Z"
              stroke="#FFC200"
              strokeWidth="10"
              strokeLinejoin="round"
            />
            <path
              d="M105.285 14.7071L98.5258 15.0613L91.1619 22.4408L92.7127 8.6968L87.4963 8.21313L84.5483 33.8186L89.7988 34.2644L90.3126 29.6231L94.2188 25.7414L98.9058 33.3185L105.017 32.9982L101.023 21.9731L105.285 14.7071Z"
              stroke="#FFC200"
              strokeWidth="10"
              strokeLinejoin="round"
            />
            <path
              d="M118.258 32.9892C122.896 32.7461 126.072 30.0562 125.881 26.4252C125.719 23.3335 123.322 21.6206 119.033 20.9441L117.251 20.641C116.123 20.4477 115.556 19.9367 115.514 19.1458C115.459 18.1033 116.427 17.2955 117.793 17.2239C119.446 17.1373 120.905 18.1423 121.06 19.7204L126.015 19.3525C125.71 15.5834 122.193 13.1361 117.591 13.3772C113.133 13.6109 110.069 16.367 110.263 20.0699C110.427 23.1976 112.353 24.8631 116.313 25.4487L118.095 25.7518C119.66 26.0303 120.506 26.3465 120.559 27.3531C120.611 28.3597 119.603 29.0614 118.057 29.1425C116.115 29.2442 114.803 28.2676 114.682 26.6517L109.692 27.0574C109.924 30.7944 113.441 33.2416 118.258 32.9892Z"
              stroke="#FFC200"
              strokeWidth="10"
              strokeLinejoin="round"
            />
            <path
              d="M134.134 17.5212L137.98 17.3196L137.754 13.0056L133.907 13.2072L133.681 8.89307L128.253 9.17756L128.479 13.4917L125.459 13.6499L125.685 17.964L128.705 17.8057L129.084 25.0318C129.327 29.6695 132.248 31.7514 135.915 31.5593C136.813 31.5122 137.671 31.3591 138.56 31.1322L138.283 26.5324C137.927 26.6232 137.498 26.6817 136.922 26.7119C135.52 26.7854 134.576 25.9696 134.491 24.3519L134.134 17.5212Z"
              stroke="#FFC200"
              strokeWidth="10"
              strokeLinejoin="round"
            />
            <path
              d="M144.996 31.1192C147.836 30.9703 149.67 29.5043 150.276 27.3096L150.384 27.304C150.467 28.8858 150.633 29.9946 150.845 30.5964L156.165 30.3175C155.84 28.9286 155.616 26.7053 155.514 24.764L155.22 19.1557C154.986 14.6978 151.371 11.751 146.41 12.011C141.7 12.2578 138.51 15.345 138.55 19.5607L143.769 19.3953C143.754 17.7378 144.918 16.5593 146.644 16.4689C148.405 16.3765 149.652 17.4648 149.74 19.1545L149.776 19.8375L146.735 20.2853C141.797 20.9767 138.926 23.2901 139.097 26.5616C139.256 29.5815 141.509 31.3019 144.996 31.1192ZM146.936 27.5567C145.534 27.6302 144.598 26.9583 144.537 25.8079C144.462 24.3699 145.601 23.4089 147.641 23.1218L150.001 22.7458L150.038 23.4648C150.153 25.6578 149.093 27.4437 146.936 27.5567Z"
              stroke="#FFC200"
              strokeWidth="10"
              strokeLinejoin="round"
            />
            <path
              d="M163.531 30.5443L168.311 28.203L165.646 22.755C163.598 18.7527 164.586 16.2856 167.714 16.1217C168.433 16.084 168.83 16.0992 169.517 16.1353L169.257 11.1741C168.712 11.0945 168.097 11.0546 167.45 11.0886C164.323 11.2525 162.536 13.6172 162.864 17.1329L162.792 17.1366L160.092 11.6905L155.31 13.9959L163.531 30.5443Z"
              stroke="#FFC200"
              strokeWidth="10"
              strokeLinejoin="round"
            />
            <path
              d="M178.173 15.2131L182.02 15.0115L181.794 10.6974L177.947 10.899L177.721 6.58496L172.292 6.86946L172.518 11.1835L169.499 11.3418L169.725 15.6559L172.745 15.4976L173.123 22.7237C173.366 27.3613 176.287 29.4433 179.954 29.2511C180.853 29.204 181.71 29.051 182.599 28.8241L182.322 24.2243C181.967 24.3151 181.537 24.3736 180.962 24.4038C179.56 24.4772 178.616 23.6615 178.531 22.0437L178.173 15.2131Z"
              stroke="#FFC200"
              strokeWidth="10"
              strokeLinejoin="round"
            />
            <path
              d="M194.145 10.0503L194.672 20.1164C194.81 22.7408 193.701 24.277 191.723 24.3806C189.854 24.4786 188.666 23.1349 188.542 20.7622L187.997 10.3724L182.497 10.6607L183.083 21.8414C183.316 26.2992 186.169 29.1418 190.124 28.9346C192.424 28.814 194.103 27.8248 195.061 26.1524L195.1 28.2772L200.6 27.989L199.645 9.76196L194.145 10.0503Z"
              stroke="#FFC200"
              strokeWidth="10"
              strokeLinejoin="round"
            />
            <path
              d="M211.938 8.75717C209.745 8.8721 207.991 9.79317 206.775 11.3709L206.672 9.39363L201.171 9.6819L202.485 34.7395L207.985 34.4512L207.525 25.6793C208.899 27.1214 210.74 27.854 212.933 27.7391C218.109 27.4678 221.501 23.3967 221.207 17.7884C220.913 12.1801 217.115 8.48586 211.938 8.75717ZM211.509 23.2715C208.884 23.409 206.981 21.49 206.827 18.5421C206.672 15.5941 208.362 13.4507 210.987 13.3132C213.539 13.1794 215.48 15.1325 215.635 18.0805C215.789 21.0284 214.097 23.1359 211.509 23.2715Z"
              stroke="#FFC200"
              strokeWidth="10"
              strokeLinejoin="round"
            />
            <path
              d="M230.169 27.1242C234.806 26.8811 237.982 24.1913 237.792 20.5602C237.63 17.4685 235.233 15.7556 230.944 15.0791L229.161 14.776C228.034 14.5828 227.466 14.0718 227.425 13.2808C227.37 12.2383 228.337 11.4306 229.703 11.359C231.357 11.2723 232.815 12.2773 232.97 13.8554L237.926 13.4875C237.62 9.71833 234.103 7.27104 229.502 7.5122C225.044 7.74583 221.98 10.502 222.174 14.2049C222.338 17.3326 224.263 18.9981 228.224 19.5837L230.006 19.8868C231.571 20.1653 232.416 20.4815 232.469 21.4881C232.522 22.4947 231.513 23.1965 229.967 23.2775C228.026 23.3792 226.713 22.4026 226.592 20.7867L221.603 21.1924C221.835 24.9294 225.351 27.3767 230.169 27.1242Z"
              stroke="#FFC200"
              strokeWidth="10"
              strokeLinejoin="round"
            />
          </g>
        </g>
        <path
          d="M9.6465 37.9962L19.2094 37.4951C27.1185 37.0806 32.3836 31.6135 31.9955 24.2077C31.6074 16.8019 25.7997 11.9152 17.8905 12.3297L8.32764 12.8308L9.6465 37.9962ZM15.1574 33.0931L14.3209 17.131L18.0598 16.9351C22.5536 16.6996 25.8887 19.8053 26.1355 24.5148C26.3824 29.2243 23.3901 32.6616 18.8963 32.8972L15.1574 33.0931Z"
          fill="white"
        />
        <path
          d="M39.8663 37.0253L44.6463 34.6839L41.9816 29.2359C39.9333 25.2337 40.9215 22.7666 44.0492 22.6026C44.7682 22.565 45.1656 22.5802 45.8524 22.6163L45.5924 17.6551C45.0475 17.5755 44.4326 17.5356 43.7855 17.5695C40.6577 17.7335 38.8711 20.0982 39.1995 23.6138L39.1276 23.6176L36.4269 18.1715L31.645 20.4769L39.8663 37.0253Z"
          fill="white"
        />
        <path
          d="M58.129 17.1783L58.6566 27.2445C58.7941 29.8689 57.685 31.4051 55.7077 31.5087C53.8383 31.6067 52.6503 30.263 52.526 27.8903L51.9815 17.5005L46.481 17.7888L47.067 28.9694C47.3006 33.4273 50.1533 36.2699 54.1079 36.0627C56.4087 35.9421 58.0872 34.9529 59.045 33.2805L59.0843 35.4053L64.5847 35.1171L63.6295 16.8901L58.129 17.1783Z"
          fill="white"
        />
        <path
          d="M75.8143 15.8912C73.2978 16.0231 71.4659 17.525 70.7236 19.8711L70.6563 16.522L65.1558 16.8103L66.1111 35.0373L71.6115 34.749L71.084 24.6828C70.9483 22.0944 72.0915 20.5204 74.1407 20.413C76.1899 20.3056 77.4497 21.6455 77.576 24.0542L78.1186 34.408L83.619 34.1197L83.0331 22.9391C82.7994 18.4812 79.9127 15.6764 75.8143 15.8912Z"
          fill="white"
        />
        <path
          d="M105.285 14.7071L98.5258 15.0613L91.1619 22.4408L92.7127 8.69679L87.4963 8.21313L84.5483 33.8186L89.7988 34.2644L90.3126 29.6231L94.2188 25.7414L98.9058 33.3185L105.017 32.9982L98.0228 21.9731L105.285 14.7071Z"
          fill="white"
        />
        <path
          d="M118.258 32.9892C122.896 32.7461 126.072 30.0562 125.881 26.4252C125.719 23.3335 123.322 21.6206 119.033 20.9441L117.251 20.641C116.123 20.4477 115.556 19.9367 115.514 19.1458C115.459 18.1033 116.427 17.2955 117.793 17.2239C119.446 17.1373 120.905 18.1423 121.06 19.7204L126.015 19.3525C125.71 15.5833 122.193 13.136 117.591 13.3772C113.133 13.6108 110.069 16.367 110.263 20.0699C110.427 23.1976 112.353 24.8631 116.313 25.4487L118.095 25.7518C119.66 26.0303 120.506 26.3465 120.559 27.3531C120.611 28.3597 119.603 29.0614 118.057 29.1425C116.115 29.2442 114.803 28.2676 114.682 26.6517L109.692 27.0574C109.924 30.7944 113.441 33.2416 118.258 32.9892Z"
          fill="white"
        />
        <path
          d="M134.134 17.5212L137.98 17.3196L137.754 13.0055L133.907 13.2071L133.681 8.89307L128.253 9.17756L128.479 13.4916L125.459 13.6499L125.685 17.964L128.705 17.8057L129.084 25.0318C129.327 29.6695 132.248 31.7514 135.915 31.5593C136.813 31.5122 137.671 31.3591 138.56 31.1322L138.283 26.5324C137.927 26.6232 137.498 26.6817 136.922 26.7119C135.52 26.7854 134.576 25.9696 134.491 24.3519L134.134 17.5212Z"
          fill="white"
        />
        <path
          d="M144.996 31.1193C147.836 30.9704 149.67 29.5044 150.276 27.3097L150.384 27.3041C150.467 28.8859 150.633 29.9947 150.845 30.5965L156.165 30.3176C155.84 28.9287 155.616 26.7054 155.514 24.7641L155.22 19.1558C154.986 14.6979 151.371 11.751 146.41 12.011C141.7 12.2579 138.51 15.3451 138.55 19.5608L143.769 19.3954C143.754 17.7379 144.918 16.5594 146.644 16.469C148.405 16.3766 149.652 17.4649 149.74 19.1546L149.776 19.8376L146.735 20.2854C141.797 20.9768 138.926 23.2902 139.097 26.5617C139.256 29.5816 141.509 31.302 144.996 31.1193ZM146.936 27.5568C145.534 27.6303 144.598 26.9584 144.537 25.808C144.462 24.37 145.601 23.409 147.641 23.1219L150.001 22.7459L150.038 23.4649C150.153 25.6579 149.093 27.4438 146.936 27.5568Z"
          fill="white"
        />
        <path
          d="M163.531 30.5443L168.311 28.203L165.646 22.755C163.598 18.7527 164.586 16.2856 167.714 16.1217C168.433 16.084 168.83 16.0992 169.517 16.1353L169.257 11.1741C168.712 11.0945 168.097 11.0546 167.45 11.0886C164.323 11.2525 162.536 13.6172 162.864 17.1329L162.792 17.1366L160.092 11.6905L155.31 13.9959L163.531 30.5443Z"
          fill="white"
        />
        <path
          d="M178.173 15.2131L182.02 15.0115L181.794 10.6974L177.947 10.899L177.721 6.58496L172.292 6.86946L172.518 11.1835L169.499 11.3418L169.725 15.6559L172.745 15.4976L173.123 22.7237C173.366 27.3613 176.287 29.4433 179.954 29.2511C180.853 29.204 181.71 29.051 182.599 28.8241L182.322 24.2243C181.967 24.3151 181.537 24.3736 180.962 24.4038C179.56 24.4772 178.616 23.6615 178.531 22.0437L178.173 15.2131Z"
          fill="white"
        />
        <path
          d="M194.145 10.0502L194.672 20.1164C194.81 22.7408 193.701 24.277 191.723 24.3806C189.854 24.4786 188.666 23.1349 188.542 20.7622L187.997 10.3724L182.497 10.6607L183.083 21.8414C183.316 26.2992 186.169 29.1418 190.124 28.9346C192.424 28.814 194.103 27.8248 195.061 26.1524L195.1 28.2772L200.6 27.989L199.645 9.76196L194.145 10.0502Z"
          fill="white"
        />
        <path
          d="M211.938 8.75717C209.745 8.8721 207.991 9.79317 206.775 11.3709L206.672 9.39363L201.171 9.6819L202.485 34.7395L207.985 34.4512L207.525 25.6793C208.899 27.1214 210.74 27.854 212.933 27.7391C218.109 27.4678 221.501 23.3967 221.207 17.7884C220.913 12.1801 217.115 8.48586 211.938 8.75717ZM211.509 23.2715C208.884 23.409 206.981 21.49 206.827 18.5421C206.672 15.5941 208.362 13.4507 210.987 13.3132C213.539 13.1794 215.48 15.1325 215.635 18.0805C215.789 21.0284 214.097 23.1359 211.509 23.2715Z"
          fill="white"
        />
        <path
          d="M230.169 27.1242C234.806 26.8811 237.982 24.1913 237.792 20.5602C237.63 17.4685 235.233 15.7555 230.944 15.0791L229.161 14.776C228.034 14.5827 227.466 14.0717 227.425 13.2808C227.37 12.2382 228.337 11.4305 229.703 11.3589C231.357 11.2723 232.815 12.2773 232.97 13.8554L237.926 13.4875C237.62 9.71833 234.103 7.27104 229.502 7.5122C225.044 7.74583 221.98 10.502 222.174 14.2049C222.338 17.3326 224.263 18.9981 228.224 19.5837L230.006 19.8868C231.571 20.1653 232.416 20.4815 232.469 21.4881C232.522 22.4947 231.513 23.1965 229.967 23.2775C228.026 23.3792 226.713 22.4026 226.592 20.7867L221.603 21.1924C221.835 24.9294 225.351 27.3767 230.169 27.1242Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1395_1232"
          x="-0.672363"
          y="-0.415039"
          width="247.599"
          height="49.4115"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1395_1232"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1395_1232"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_1395_1232">
          <rect width="246" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DrunkStartupsSplashLogo;
