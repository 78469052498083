export const RR_Description =
  "Weather or traffic! Match up for quick chats over multiple speed rounds.";
export const RR_Extra_Info =
  "After each round participants are shuffled and paired with a new player.";

export const KYT_Description =
  "Get to know your teammates better over a longer 1-on-1 conversation.";

export const RR_And_KYT_Splash_Timer = 7;

export const RR_Timer_Per_Round = 4 * 60;
export const KYT_Timer_Per_Round = 10 * 60;

export const RR_background_color = "blue";
export const KYT_background_color = "orange";
