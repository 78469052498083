import * as React from "react";
import styled from "styled-components";
import { ZIndexLevel } from "../../utils/zIndex.enum";
import { Leaderboard, LeaderBoardTitle } from "./LeaderBoardListMenu";
import OutsideClickHandler from "react-outside-click-handler";
import MobileLeaderboardItem from "./MobileLeaderBoardItem";
import { SegmentGroupIdsMapInterface } from "./DsBackToLoungeCanvas";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  selectFundUpdated,
  setFundUpdated,
} from "../../store/features/parentSlice";
import { isEmpty, mergeWith, sortBy } from "lodash";
import { fetchFundingLeaderBoard } from "../../API/drunkStartups";
import { MOBILE_BREAKPOINT } from "../../types/breakpoints";
import Spinner from "../common/Spinner";
import { useParams } from "react-router-dom";
import {
  SegmentsWithFundAmountMapInterface,
  SegmentsWithFundAmountItem,
} from "./DsViewOnlyCanvas";

const MobileCanvasBottomToolbar = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: absolute;
  bottom: 0.6rem;
  margin: 0 1rem;
  z-index: ${ZIndexLevel.CANVAS_TOOLBAR_OPTIONS_INDEX};

  /* hiding mixer buddies list on mobile devices */
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

const MobileCanvasBottomDivDrop = styled.div<{ top: number }>`
  position: absolute;
  border-radius: 24px;
  top: ${({ top }) => `${top}rem`};
  background: ${(props) => props.theme.color.lightGray};
  height: 6px;
  width: 40px;
  left: calc(50% - 35px);
  border: 8px solid ${(props) => props.theme.color.white};
`;

const MobileLeaderboard = styled(Leaderboard)`
  left: 0rem;
  right: 0rem;
  width: 100%;
`;

const MobileLeaderBoardTitle = styled(LeaderBoardTitle)`
  width: 100px;
  justify-content: space-between;
`;

const SpinnerLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

interface MobileLeaderboardPanelPropsInterface {
  segmentGroupIdsMap?: SegmentGroupIdsMapInterface;
  activeSegmentGroup: string;
}

const MobileLeaderboardPanel: React.FC<MobileLeaderboardPanelPropsInterface> =
  ({ segmentGroupIdsMap = {}, activeSegmentGroup = "" }) => {
    const dispatch = useAppDispatch();
    const [showLeaderBoard, setShowLeaderBoardPanel] = React.useState(false);
    const [leaderBoardData, setLeaderBoardData] =
      React.useState<SegmentsWithFundAmountMapInterface>({});
    // const segmentId = useAppSelector(previousDSMixerSegment);
    const isFundUpdated = useAppSelector(selectFundUpdated);
    const { id: segmentId } = useParams<{ id: string }>();

    const closeLeaderBoardModal = () => {
      setShowLeaderBoardPanel(false);
    };

    React.useEffect(() => {
      (async () => {
        if (segmentId) {
          const resData = await fetchFundingLeaderBoard(segmentId);
          if (resData) {
            let customizer = (
              objValue: number,
              srcValue: SegmentsWithFundAmountItem
            ) => {
              return { amount: objValue || 0, ...srcValue };
            };
            let mergedSegmentsWithFundAmount = mergeWith(
              resData,
              segmentGroupIdsMap,
              customizer
            );
            setLeaderBoardData(mergedSegmentsWithFundAmount);
            dispatch(setFundUpdated(false));
          }
        }
      })();
    }, [isFundUpdated, segmentGroupIdsMap]);

    const getCurrentSegmentData = React.useCallback(() => {
      return leaderBoardData[activeSegmentGroup] || {};
    }, [activeSegmentGroup, leaderBoardData]);

    const CurrentSegmentMobileLeaderBoardItem = ({
      currentSegmentData,
    }: {
      currentSegmentData: SegmentsWithFundAmountItem;
    }) => {
      return (
        <MobileLeaderboardItem
          height={"auto"}
          mixerBuddies={currentSegmentData.participants}
          fundAmount={currentSegmentData.amount}
          index={currentSegmentData.index}
          disabledFundAmount={
            isEmpty(currentSegmentData) || !(currentSegmentData?.amount || 0)
          }
        />
      );
    };

    return (
      <>
        <MobileCanvasBottomToolbar>
          <MobileCanvasBottomDivDrop
            top={-0.8}
            onClick={() => setShowLeaderBoardPanel(!showLeaderBoard)}
          ></MobileCanvasBottomDivDrop>

          {isEmpty(getCurrentSegmentData()) ? (
            <SpinnerLoaderContainer>
              <Spinner size={40} />
            </SpinnerLoaderContainer>
          ) : (
            <CurrentSegmentMobileLeaderBoardItem
              currentSegmentData={getCurrentSegmentData()}
            />
          )}
        </MobileCanvasBottomToolbar>

        <OutsideClickHandler onOutsideClick={closeLeaderBoardModal}>
          {showLeaderBoard && (
            <MobileLeaderboard>
              <MobileCanvasBottomDivDrop
                top={0}
                onClick={() => setShowLeaderBoardPanel(!showLeaderBoard)}
              ></MobileCanvasBottomDivDrop>
              <MobileLeaderBoardTitle onClick={closeLeaderBoardModal}>
                {/* TODO : Troubleshoot why LeaderBoardTrophySvg component not rendering */}
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4 2.75C3.66848 2.75 3.35054 2.8817 3.11612 3.11612C2.8817 3.35054 2.75 3.66848 2.75 4V4.25H1.49219C1.16067 4.25 0.842724 4.3817 0.608304 4.61612C0.373884 4.85054 0.242188 5.16848 0.242188 5.5V6.5C0.242188 7.22935 0.531919 7.92882 1.04764 8.44454C1.56337 8.96027 2.26284 9.25 2.99219 9.25H3.06489C3.71345 11.0493 5.3021 12.4128 7.25 12.6959V13.75H6C5.58579 13.75 5.25 14.0858 5.25 14.5C5.25 14.9142 5.58579 15.25 6 15.25H8H10C10.4142 15.25 10.75 14.9142 10.75 14.5C10.75 14.0858 10.4142 13.75 10 13.75H8.75V12.6963C9.17244 12.6353 9.58684 12.5229 9.98383 12.3609C10.625 12.0992 11.2081 11.7132 11.6994 11.2252C12.1908 10.7373 12.5808 10.1569 12.8469 9.51753C12.8837 9.4292 12.918 9.33999 12.9498 9.25H12.9953C13.7247 9.25 14.4241 8.96027 14.9399 8.44454C15.4556 7.92882 15.7453 7.22935 15.7453 6.5V5.5C15.7453 5.16848 15.6136 4.85054 15.3792 4.61612C15.1448 4.3817 14.8268 4.25 14.4953 4.25H13.25V4C13.25 3.66848 13.1183 3.35054 12.8839 3.11612C12.6495 2.8817 12.3315 2.75 12 2.75H4ZM2.7575 7.72778C2.75252 7.63367 2.75 7.53897 2.75 7.44375V5.75H1.74219V6.5C1.74219 6.83152 1.87388 7.14946 2.1083 7.38388C2.28663 7.56221 2.5133 7.68109 2.7575 7.72778ZM13.8792 7.38388C13.7046 7.55851 13.4836 7.67613 13.2452 7.72477C13.2484 7.64995 13.25 7.57501 13.25 7.49998V5.75H14.2453V6.5C14.2453 6.83152 14.1136 7.14946 13.8792 7.38388Z"
                    fill="url(#paint0_linear_846_2932)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_846_2932"
                      x1="2.95391"
                      y1="6.22774"
                      x2="6.59663"
                      y2="16.3346"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#FFC200" />
                      <stop offset="0.275459" stopColor="#FFEEB8" />
                      <stop offset="1" stopColor="#FFC200" />
                    </linearGradient>
                  </defs>
                </svg>
                Leaderboard
              </MobileLeaderBoardTitle>
              {sortBy(leaderBoardData, ["amount"])
                .reverse()
                .map(({ index, participants = [], amount = "00" }: any) => (
                  <MobileLeaderboardItem
                    key={index}
                    height={"80px"}
                    mixerBuddies={participants}
                    fundAmount={amount}
                    index={index}
                    disabledFundAmount={amount <= 0}
                  />
                ))}
            </MobileLeaderboard>
          )}
        </OutsideClickHandler>
      </>
    );
  };

export default MobileLeaderboardPanel;
