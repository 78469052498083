export const DoodleBotEmoji = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1364_5898)">
        <path
          d="M19 11H5C3.89543 11 3 11.8954 3 13V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V13C21 11.8954 20.1046 11 19 11Z"
          fill="white"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 18C9.10457 18 10 17.1046 10 16C10 14.8954 9.10457 14 8 14C6.89543 14 6 14.8954 6 16C6 17.1046 6.89543 18 8 18Z"
          fill="#FFC200"
        />
        <path
          d="M16 18C17.1046 18 18 17.1046 18 16C18 14.8954 17.1046 14 16 14C14.8954 14 14 14.8954 14 16C14 17.1046 14.8954 18 16 18Z"
          fill="#FFC200"
        />
        <path
          d="M12 7V11"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1364_5898">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export const DoodleYellowBotEmoji: React.FC<{ size?: number }> = ({
  size = 48,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.0034 13.9995C26.2126 13.9995 28.0034 12.2086 28.0034 9.99951C28.0034 7.79037 26.2126 5.99951 24.0034 5.99951C21.7943 5.99951 20.0034 7.79037 20.0034 9.99951C20.0034 12.2086 21.7943 13.9995 24.0034 13.9995Z"
        stroke="#FFC200"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.0029 21.5996H10.0029C7.79379 21.5996 6.00293 23.3905 6.00293 25.5996V37.5996C6.00293 39.8087 7.79379 41.5996 10.0029 41.5996H38.0029C40.2121 41.5996 42.0029 39.8087 42.0029 37.5996V25.5996C42.0029 23.3905 40.2121 21.5996 38.0029 21.5996ZM15.6013 36.0006C18.2522 36.0006 20.4013 33.8516 20.4013 31.2006C20.4013 28.5497 18.2522 26.4006 15.6013 26.4006C12.9503 26.4006 10.8013 28.5497 10.8013 31.2006C10.8013 33.8516 12.9503 36.0006 15.6013 36.0006ZM37.2026 31.2006C37.2026 33.8516 35.0536 36.0006 32.4026 36.0006C29.7516 36.0006 27.6026 33.8516 27.6026 31.2006C27.6026 28.5497 29.7516 26.4006 32.4026 26.4006C35.0536 26.4006 37.2026 28.5497 37.2026 31.2006Z"
        fill="#FFC200"
      />
      <path
        d="M10.0029 23.0996H38.0029V20.0996H10.0029V23.0996ZM7.50293 25.5996C7.50293 24.2189 8.62222 23.0996 10.0029 23.0996V20.0996C6.96536 20.0996 4.50293 22.562 4.50293 25.5996H7.50293ZM7.50293 37.5996V25.5996H4.50293V37.5996H7.50293ZM10.0029 40.0996C8.62222 40.0996 7.50293 38.9803 7.50293 37.5996H4.50293C4.50293 40.6372 6.96536 43.0996 10.0029 43.0996V40.0996ZM38.0029 40.0996H10.0029V43.0996H38.0029V40.0996ZM40.5029 37.5996C40.5029 38.9803 39.3836 40.0996 38.0029 40.0996V43.0996C41.0405 43.0996 43.5029 40.6372 43.5029 37.5996H40.5029ZM40.5029 25.5996V37.5996H43.5029V25.5996H40.5029ZM38.0029 23.0996C39.3836 23.0996 40.5029 24.2189 40.5029 25.5996H43.5029C43.5029 22.562 41.0405 20.0996 38.0029 20.0996V23.0996ZM18.9013 31.2006C18.9013 33.0232 17.4238 34.5006 15.6013 34.5006V37.5006C19.0807 37.5006 21.9013 34.68 21.9013 31.2006H18.9013ZM15.6013 27.9006C17.4238 27.9006 18.9013 29.3781 18.9013 31.2006H21.9013C21.9013 27.7212 19.0807 24.9006 15.6013 24.9006V27.9006ZM12.3013 31.2006C12.3013 29.3781 13.7787 27.9006 15.6013 27.9006V24.9006C12.1219 24.9006 9.30128 27.7212 9.30128 31.2006H12.3013ZM15.6013 34.5006C13.7787 34.5006 12.3013 33.0232 12.3013 31.2006H9.30128C9.30128 34.68 12.1219 37.5006 15.6013 37.5006V34.5006ZM32.4026 37.5006C35.882 37.5006 38.7026 34.68 38.7026 31.2006H35.7026C35.7026 33.0232 34.2251 34.5006 32.4026 34.5006V37.5006ZM26.1026 31.2006C26.1026 34.68 28.9232 37.5006 32.4026 37.5006V34.5006C30.58 34.5006 29.1026 33.0232 29.1026 31.2006H26.1026ZM32.4026 24.9006C28.9232 24.9006 26.1026 27.7212 26.1026 31.2006H29.1026C29.1026 29.3781 30.58 27.9006 32.4026 27.9006V24.9006ZM38.7026 31.2006C38.7026 27.7212 35.882 24.9006 32.4026 24.9006V27.9006C34.2251 27.9006 35.7026 29.3781 35.7026 31.2006H38.7026Z"
        fill="#FFC200"
      />
      <path
        d="M24.0015 13.9995V21.9995"
        stroke="#FFC200"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
