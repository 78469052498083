const UserListIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9922 16.5L22.9922 16.5"
        stroke="#F49191"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4922 12L22.9922 12"
        stroke="#F49191"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.9922 7.5L22.9922 7.5"
        stroke="#F49191"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.15885 17.8333V13.5742L0.853617 6.56826C0.620935 6.30972 0.492188 5.97421 0.492188 5.62638C0.492188 5.0043 0.996485 4.5 1.61857 4.5H14.3658C14.9879 4.5 15.4922 5.0043 15.4922 5.62638C15.4922 5.97421 15.3634 6.30972 15.1308 6.56826L8.82552 13.5742V17.8333H12.1589C12.6191 17.8333 12.9922 18.2064 12.9922 18.6667C12.9922 19.1269 12.6191 19.5 12.1589 19.5H3.82552C3.36528 19.5 2.99219 19.1269 2.99219 18.6667C2.99219 18.2064 3.36528 17.8333 3.82552 17.8333H7.15885ZM4.23385 7.83333H11.7505L12.875 6.58391C13.0198 6.42303 12.9056 6.16667 12.6892 6.16667H3.29519C3.07875 6.16667 2.96458 6.42303 3.10937 6.58391L4.23385 7.83333Z"
        fill="#EC4747"
      />
    </svg>
  );
};

export default UserListIcon;
