import React from "react";
import { useLocation } from "react-router-dom";
import useResizeObserver from "@react-hook/resize-observer";

import TriviaIcon from "../assets/TriviaIcon";
import TriviaiconDisabled from "../assets/TriviaiconDisabled";
import WYRIcon from "../assets/WyrIcon";
import WYRIconDisabled from "../assets/WYRIconDisabled";
import {
  selectActivities,
  selectCurrentActivityName,
} from "../store/features/meetingSlice";
import { useAppSelector } from "../store/hooks";
import { ActivityEnum } from "../utils/activity.enum";
import WYR from "../assets/WYR.png";
import Ttal from "../assets/Ttal.png";
import MT from "../assets/MT.png";
import trivia from "../assets/trivia.png";
import doodle from "../assets/doodle.png";
import charades from "../assets/charades.png";
import speedoneonone from "../assets/speedoneonone.png";
import kyt from "../assets/KYT.png";
import drunk_startups from "../assets/drunk_startups.png";
import DoodleIconDisabled from "../assets/DoodleIconDisabled";
import DoodleIcon from "../assets/DoodleIcon";
import { MixerColors, IcebreakerEnum } from "../types/activity";

import ReadyOverlay from "../assets/ReadyOverlay";
import { SetOverlay1, SetOverlay2 } from "../assets/SetOverlay";
import DoodleOverlay from "../assets/DoodleOverlay";
import Choose from "../assets/Choose";
import { WYR_Bg_Color, WYR_Description } from "../types/WouldYouRather";
import { Trivia_Bg_Color, Trivia_Description } from "../types/trivia";
import { Doodle_Bg_Color, Doodle_Description } from "../types/doodle";
import {
  KYT_Timer_Per_Round,
  RR_background_color,
  RR_Description,
  RR_And_KYT_Splash_Timer,
  RR_Timer_Per_Round,
  KYT_Description,
  KYT_background_color,
  RR_Extra_Info,
} from "../types/RapidRendezvous";
import {
  selectCurrentRound,
  selectTotalRounds,
} from "../store/features/mixerSlice";
import {
  selectElapsedTimer,
  selectRemainingTimer,
} from "../store/features/parentSlice";
import { TTAL_Bg_Color, TTAL_Description } from "../types/TwoTruthsAndALie";
import TtalIcon from "../assets/TtalIcon";
import TtalIconDisabled from "../assets/TtalIconDisabled";
import Goooo from "../assets/Goooo";
import { ClockSVG } from "../assets/ClockSVG";
import { DoodleYellowBotEmoji } from "../assets/DoodleBotEmoji";
import { DoodleYellowBrush } from "../assets/DoodleBrush";
import RRBuddy from "../assets/RRBuddy";
import RRCup from "../assets/RRCup";
import RRStar from "../assets/RRStar";
import GreenDiceIcon from "../assets/TwoTruthsAndALie/GreenDiceIcon";
import GreenFingurePointerIcon from "../assets/TwoTruthsAndALie/GreenFingurePointerIcon";
import GreenSearchIcon from "../assets/TwoTruthsAndALie/GreenSearchIcon";
import {
  RR1Digit,
  RR2Digit,
  RR3Digit,
  RRExclamation,
  RRRoundText,
  RRSlash,
} from "../assets/RROverlayCollection";
import { Icon1, Icon2, Icon3 } from "../assets/CharadesInstructionIcon";
import { Charades_Bg_Color, Charades_Description } from "../types/Charades";
import CharadesIcon from "../assets/Charades/CharadesIcon";
import CharadesIconDisabled from "../assets/Charades/CharadesIconDisabled";
import { Overlay } from "../assets/CharadesInstructionIcon";
import RRIconDisabled from "../assets/RRIconDisabled";
import RRIcon from "../assets/RRIcon";
import KYTIconDisabled from "../assets/KYTIconDisabled";
import KYTIcon from "../assets/KYTIcon";
import { useTheme } from "styled-components";
import MTIcon from "../assets/MTIcon";
import MTDisabledIcon from "../assets/MTDisabledIcon";
import { Mindful_Talk_Description, MT_Bg_color } from "../types/MindfulTalk";
import {
  DrunkStartups_background_color,
  DrunkStartups_Description,
} from "../types/DrunkStartups";
import GridLayoutIcon from "../assets/GridLayoutIcon";
import ChatIconActive from "../assets/ChatIconActive";
import BrainstormIcon from "../assets/BrainstormIcon";
import PitchIcon from "../assets/PitchIcon";
import { MOBILE_BREAKPOINT } from "../types/breakpoints";
import StarIcon from "../assets/StarIcon";
import DrunkStartupsIcon from "../assets/DrunkStartupsIcon";
import DrunkStartupsIconDisabled from "../assets/DrunkStartupsIconDisabled";

import KYTSplashLogo from "../assets/KYTSplashLogo";
import RRSplashLogo from "../assets/RRSplashLogo";

import TTALSplashLogo from "../assets/TTALSplashLogo";
import WYRSplashLogo from "../assets/WYRSplashLogo";
import DoodleSplashLogo from "../assets/DoodleSplashLogo";
import CharadesSplashLogo from "../assets/Charades/CharadesSplashLogo";
import MindfulTalkSSLogo from "../assets/MindfulTalkSSLogo";
import DrunkStartupsSplashLogo from "../assets/DrunkStartupsSplashLogo";
import TriviaSplashLogo from "../assets/TriviaSplashLogo";

import AddYourIdeaSVG from "../assets/DrunkStartup/AddYourIdeaSVG";

import { mixerVideos } from "../assets/CdnUrls";
import {
  loginStatus,
  selectGuestParticipantId,
  selectSlackAppStatus,
  selectSlackChannelName,
  selectUserLoginStatus,
} from "../store/features/userSlice";
import { selectHostId } from "../store/features/eventSlice";
import {
  KYTModalIcon,
  SpeedOne_OneModalIcon,
  TwoTnLModalIcon,
  WYRModalIcon,
  DRModalIcon,
  CharadesModalIcon,
  MTModalIcon,
  DSModalIcon,
  TriviaModalIcon,
} from "../assets/MixerNames";
import {
  FastForwardCircleIcon,
  MagnifyingGlassIcon,
  ClownIcon,
  ArrowsOutHorizontalIcon,
  TrophyIcon,
  FlagIcon,
  PlusCircleIcon,
  MegaphoneIcon,
  FlashIcon,
} from "../assets/InstructionIcons";
import { selectIsLoungeTourOpen } from "../store/features/Lounge/loungeSlice";

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const useWindowQuery = () => {
  const search = window.location.search;
  return new URLSearchParams(search);
};

export function usePrevious(value: any, skipFalsyValue?: boolean) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = React.useRef<any>();
  // Store current value in ref
  React.useEffect(() => {
    if (!skipFalsyValue || (skipFalsyValue && value)) {
      ref.current = value;
    }
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export const useGetSegmentDetails = (
  activityId: string,
  iconType?: "TOOLTIP" | "DISABLED"
) => {
  const activities = useAppSelector(selectActivities);
  let segmentName;
  let segmentDescription;
  let segmentIcon;
  let segmentImage = "";
  let segmentImagerotationAngle = "";
  let segmentBackgroundColor: MixerColors = "yellow";
  let additionalInfo = "";
  let segmentIdentifier: ActivityEnum = ActivityEnum.WOULD_YOU_RATHER;

  switch (activities.find((activity) => activity.id === activityId)?.name) {
    case ActivityEnum.WOULD_YOU_RATHER:
      segmentName = "Would you rather";
      segmentDescription = WYR_Description;
      segmentIcon = iconType ? (
        <WYRIconDisabled isTooltip={iconType === "TOOLTIP"} />
      ) : (
        <WYRIcon />
      );
      segmentImage = WYR;
      segmentImagerotationAngle = "-3deg";
      segmentBackgroundColor = WYR_Bg_Color;
      segmentIdentifier = ActivityEnum.WOULD_YOU_RATHER;
      break;
    case ActivityEnum.TRIVIA:
      segmentName = "Trivia";
      segmentDescription = Trivia_Description;
      segmentIcon = iconType ? (
        <TriviaiconDisabled isTooltip={iconType === "TOOLTIP"} />
      ) : (
        <TriviaIcon />
      );
      segmentImage = trivia;
      segmentImagerotationAngle = "-7deg";
      segmentBackgroundColor = Trivia_Bg_Color;
      segmentIdentifier = ActivityEnum.TRIVIA;
      break;
    case ActivityEnum.DOODLE_RACE:
      segmentName = "Doodle race";
      segmentDescription = Doodle_Description;
      segmentIcon = iconType ? (
        <DoodleIconDisabled isTooltip={iconType === "TOOLTIP"} />
      ) : (
        <DoodleIcon />
      );
      segmentImage = doodle;
      segmentImagerotationAngle = "-5deg";
      segmentBackgroundColor = Doodle_Bg_Color;
      segmentIdentifier = ActivityEnum.DOODLE_RACE;
      break;
    case ActivityEnum.RAPID_RENDEZVOUS:
      segmentName = "Speed 1-on-1s";
      segmentDescription = RR_Description;
      segmentIcon = iconType ? (
        <RRIconDisabled isTooltip={iconType === "TOOLTIP"} />
      ) : (
        <RRIcon />
      );
      segmentImage = speedoneonone;
      segmentImagerotationAngle = "-3deg";
      segmentBackgroundColor = RR_background_color;
      segmentIdentifier = ActivityEnum.RAPID_RENDEZVOUS;
      additionalInfo = RR_Extra_Info;
      break;
    case ActivityEnum.KNOW_YOUR_TEAM:
      segmentName = "Know your team";
      segmentDescription = KYT_Description;
      segmentIcon = iconType ? (
        <KYTIconDisabled isTooltip={iconType === "TOOLTIP"} />
      ) : (
        <KYTIcon />
      );
      segmentImage = kyt;
      segmentImagerotationAngle = "-7deg";
      segmentBackgroundColor = KYT_background_color;
      segmentIdentifier = ActivityEnum.KNOW_YOUR_TEAM;
      break;
    case ActivityEnum.CHARADES:
      segmentName = "Charades";
      segmentDescription = Charades_Description;
      segmentIcon = iconType ? (
        <CharadesIconDisabled isTooltip={iconType === "TOOLTIP"} />
      ) : (
        <CharadesIcon />
      );
      segmentImage = charades;
      segmentImagerotationAngle = "-5deg";
      segmentBackgroundColor = Charades_Bg_Color;
      segmentIdentifier = ActivityEnum.CHARADES;
      break;

    case ActivityEnum.TWO_TRUTHS_AND_A_LIE:
      segmentName = "2 truths & a lie";
      segmentDescription = TTAL_Description;
      segmentIcon = iconType ? (
        <TtalIconDisabled isTooltip={iconType === "TOOLTIP"} />
      ) : (
        <TtalIcon />
      );
      segmentImage = Ttal;
      segmentImagerotationAngle = "-5deg";
      segmentBackgroundColor = TTAL_Bg_Color;
      segmentIdentifier = ActivityEnum.TWO_TRUTHS_AND_A_LIE;
      break;
    case ActivityEnum.MINDFUL_TALK:
      segmentName = "Mindful Talks";
      segmentDescription = Mindful_Talk_Description;
      segmentIcon = iconType ? (
        <MTDisabledIcon isTooltip={iconType === "TOOLTIP"} />
      ) : (
        <MTIcon />
      );
      segmentImage = MT;
      segmentImagerotationAngle = "-5deg";
      segmentBackgroundColor = MT_Bg_color;
      segmentIdentifier = ActivityEnum.MINDFUL_TALK;
      break;
    case ActivityEnum.DRUNK_STARTUPS:
      segmentName = "Drunk startups";
      segmentDescription = DrunkStartups_Description;
      segmentIcon = iconType ? (
        <DrunkStartupsIconDisabled isTooltip={iconType === "TOOLTIP"} />
      ) : (
        <DrunkStartupsIcon />
      );
      segmentImage = drunk_startups;
      segmentImagerotationAngle = "-5deg";
      segmentBackgroundColor = DrunkStartups_background_color;
      segmentIdentifier = ActivityEnum.DRUNK_STARTUPS;
      break;
    default:
  }

  return {
    segmentName,
    segmentIcon,
    segmentDescription,
    segmentImage,
    segmentImagerotationAngle,
    segmentBackgroundColor,
    segmentIdentifier,
    additionalInfo,
  };
};

export const useGetCurrentSegmentDetails = () => {
  let segmentDescription;
  let segmentBackgroundColor: MixerColors = "yellow";
  const activityName = useAppSelector(selectCurrentActivityName);

  switch (activityName) {
    case ActivityEnum.WOULD_YOU_RATHER:
      segmentDescription = WYR_Description;
      segmentBackgroundColor = WYR_Bg_Color;
      break;
    case ActivityEnum.TRIVIA:
      segmentDescription = Trivia_Description;
      segmentBackgroundColor = Trivia_Bg_Color;
      break;
    case ActivityEnum.DOODLE_RACE:
      segmentDescription = Doodle_Description;
      segmentBackgroundColor = Doodle_Bg_Color;
      break;
    case ActivityEnum.TWO_TRUTHS_AND_A_LIE:
      segmentDescription = TTAL_Description;
      segmentBackgroundColor = TTAL_Bg_Color;
      break;
    case ActivityEnum.CHARADES:
      segmentDescription = Charades_Description;
      segmentBackgroundColor = Charades_Bg_Color;
      break;
    case ActivityEnum.RAPID_RENDEZVOUS:
      segmentDescription = RR_Description;
      segmentBackgroundColor = RR_background_color;
      break;
    case ActivityEnum.KNOW_YOUR_TEAM:
      segmentDescription = KYT_Description;
      segmentBackgroundColor = KYT_background_color;
      break;
    case ActivityEnum.DRUNK_STARTUPS:
      segmentDescription = DrunkStartups_Description;
      segmentBackgroundColor = DrunkStartups_background_color;
      break;
    default:
  }

  return {
    segmentDescription,
    segmentBackgroundColor,
  };
};

export const useMixerInstructions = () => {
  const theme = useTheme();
  const activityName = useAppSelector(selectCurrentActivityName);
  switch (activityName) {
    case ActivityEnum.DOODLE_RACE:
      return [
        { instruction: "Everyone draws", icon: <DoodleYellowBrush /> },
        { instruction: "AI guesses", icon: <DoodleYellowBotEmoji /> },
        { instruction: "20s to draw", icon: <ClockSVG /> },
      ];
    case ActivityEnum.TWO_TRUTHS_AND_A_LIE:
      return [
        {
          instruction: "A player submits 3 statements",
          icon: <GreenDiceIcon />,
        },
        {
          instruction: "Figure out their lie",
          icon: <GreenFingurePointerIcon />,
        },
        {
          instruction: "Find out detectives in the team",
          icon: <GreenSearchIcon />,
        },
      ];
    case ActivityEnum.RAPID_RENDEZVOUS:
      return [
        {
          instruction: "Get paired with a teammate",
          icon: <RRBuddy color={theme.color.blue500} />,
        },
        {
          instruction: "3 min/round for a quick chat",
          icon: <RRCup color={theme.color.blue500} />,
        },
        {
          instruction: "Take insights to lounge",
          icon: <RRStar color={theme.color.blue500} />,
        },
      ];
    case ActivityEnum.MINDFUL_TALK:
      return [
        {
          instruction: "Discuss around a topic ",
          icon: <RRBuddy color={theme.color.green500} />,
        },
        {
          instruction: "Make quick notes",
          icon: <RRCup color={theme.color.green500} />,
        },
        {
          instruction: "No scores, ofcourse!",
          icon: <RRStar color={theme.color.green500} />,
        },
      ];
    case ActivityEnum.KNOW_YOUR_TEAM:
      return [
        {
          instruction: "Get paired with a teammate",
          icon: <RRBuddy color={theme.color.orange500} />,
        },
        {
          instruction: "10 mins for an engaging chat",
          icon: <RRCup color={theme.color.orange500} />,
        },
        {
          instruction: "No scores, ofcourse!",
          icon: <RRStar color={theme.color.orange500} />,
        },
      ];
    case ActivityEnum.CHARADES:
      return [
        {
          instruction: "Act out the word sent by opposite team",
          icon: <Icon1 />,
        },
        {
          instruction: "Your team guesses out aloud",
          icon: <Icon2 />,
        },
        {
          instruction: "60s to get it right",
          icon: <Icon3 />,
        },
      ];
    case ActivityEnum.DRUNK_STARTUPS:
      return [
        {
          instruction: "Ideate on drunk ideas with the team",
          icon: <BrainstormIcon size={48} />,
        },
        {
          instruction: "Finalise on the best one or two",
          icon: <StarIcon />,
        },
        {
          instruction: "Pitch it in lounge & get funded",
          icon: <PitchIcon size={48} color="yellow" />,
        },
      ];
  }
  return [];
};

export const useHowItWorks = (
  activity: ActivityEnum | IcebreakerEnum,
  iconColor?: MixerColors
) => {
  let description: string = "";
  let logo: JSX.Element | undefined;
  let instructions: { instruction: string; icon?: JSX.Element }[] = [];
  const media = mixerVideos[activity];

  switch (activity) {
    case ActivityEnum.KNOW_YOUR_TEAM:
      description = KYT_Description;
      instructions = [
        {
          icon: <GridLayoutIcon size={24} color={iconColor} />,
          instruction: "Breakout into smaller rooms of 2 people",
        },
        {
          icon: <ChatIconActive />,
          instruction:
            "Talk about absolutely anything, or use our conversation starter",
        },
      ];
      logo = <KYTModalIcon />;
      break;
    case ActivityEnum.RAPID_RENDEZVOUS:
      description = RR_Description;
      instructions = [
        {
          icon: <GridLayoutIcon size={24} color={iconColor} />,
          instruction: "Breakout into smaller rooms of 2 people",
        },
        {
          icon: <ChatIconActive />,
          instruction:
            "Talk about absolutely anything, or use our conversation starter",
        },
        {
          icon: <FastForwardCircleIcon />,
          instruction: "Meet 3 people, with 3 speed rounds",
        },
      ];
      logo = <SpeedOne_OneModalIcon />;
      break;
    case ActivityEnum.TWO_TRUTHS_AND_A_LIE:
      description = TTAL_Description;
      instructions = [
        {
          icon: <GridLayoutIcon size={24} color={iconColor} />,
          instruction: "Breakout into rooms of 8 to 10 people",
        },
        {
          icon: <MagnifyingGlassIcon />,
          instruction:
            "Player makes 3 statements. Figure out what their lie is.",
        },
        {
          icon: <ClownIcon />,
          instruction:
            "Go back to lounge to see who are the serial liars and detectives.",
        },
      ];
      logo = <TwoTnLModalIcon />;
      break;
    case ActivityEnum.WOULD_YOU_RATHER:
      description = WYR_Description;
      instructions = [
        {
          icon: <GridLayoutIcon size={24} color={iconColor} />,
          instruction: "Breakout into smaller rooms of 6 to 8 people",
        },
        {
          icon: <ArrowsOutHorizontalIcon />,
          instruction: "Make some tough choices and see who agrees with you",
        },
      ];
      logo = <WYRModalIcon />;
      break;
    case ActivityEnum.DOODLE_RACE:
      description = Doodle_Description;
      instructions = [
        {
          icon: <GridLayoutIcon size={24} color={iconColor} />,
          instruction: "Breakout into smaller rooms of 4 to 6 people",
        },
        {
          icon: <DoodleYellowBotEmoji size={24} />,
          instruction: "You draw the given prompt, A.I. guesses what you draw",
        },
        {
          icon: <TrophyIcon />,
          instruction:
            "Come back to lounge to see who the real artists of the team are",
        },
      ];
      logo = <DRModalIcon />;
      break;
    case ActivityEnum.CHARADES:
      description = Charades_Description;
      instructions = [
        {
          icon: <GridLayoutIcon size={24} color={iconColor} />,
          instruction: "Breakout into rooms of 12 people",
        },
        {
          icon: <FlagIcon />,
          instruction:
            "Team vs Team. Act out the word given by the opponent team",
        },
        {
          icon: <PlusCircleIcon />,
          instruction: "Earn points if your team guesses it correctly. ",
        },
      ];
      logo = <CharadesModalIcon />;
      break;
    case ActivityEnum.MINDFUL_TALK:
      description = Mindful_Talk_Description;
      instructions = [
        {
          icon: <GridLayoutIcon size={24} color={iconColor} />,
          instruction: "Breakout into smaller rooms of 3 to 4 people",
        },
        {
          icon: <ChatIconActive />,
          instruction: "Discuss and take notes on a given topic",
        },
        {
          icon: <MegaphoneIcon />,
          instruction:
            "Go back to lounge and share your insights weith everyone",
        },
      ];
      logo = <MTModalIcon />;
      break;
    case ActivityEnum.DRUNK_STARTUPS:
      description = DrunkStartups_Description;
      instructions = [
        {
          icon: <GridLayoutIcon size={24} color={iconColor} />,
          instruction: "Breakout into smaller rooms of 4 to 6 people",
        },
        {
          icon: <BrainstormIcon />,
          instruction: "Brainstorm and come up with 1 or 2 best ideas",
        },
        {
          icon: <PitchIcon color={iconColor} />,
          instruction: "Pitch in lounge & get funded",
        },
      ];
      logo = <DSModalIcon />;
      break;
    case ActivityEnum.TRIVIA:
      description = Trivia_Description;
      instructions = [
        {
          icon: <GridLayoutIcon size={24} color={iconColor} />,
          instruction: "Breakout into smaller rooms of 4 to 6 people",
        },
        {
          icon: <FlashIcon />,
          instruction:
            "Fastest fingers first, get extra points for quick answers",
        },
        {
          icon: <TrophyIcon />,
          instruction: "Come back to lounge to see who are the top 3 scorers",
        },
      ];
      logo = <TriviaModalIcon />;
      break;
  }

  return { description, logo, instructions, media };
};

export const useMixerOverlay = (color: string) => {
  const activityName = useAppSelector(selectCurrentActivityName);
  const totalRound = useAppSelector(selectTotalRounds) || 1;
  const currentRound = useAppSelector(selectCurrentRound) || 1;

  const SVGs: Array<React.ReactElement> = [];
  switch (activityName) {
    case ActivityEnum.WOULD_YOU_RATHER:
      break;
    case ActivityEnum.TRIVIA:
      SVGs.push(
        <ReadyOverlay color={color} />,
        <SetOverlay1 color={color} />,
        <>
          <SetOverlay2 color={color} />
          <Choose />
        </>
      );
      break;
    case ActivityEnum.DOODLE_RACE:
      SVGs.push(
        <ReadyOverlay color={color} />,
        <SetOverlay1 color={color} />,
        <>
          <SetOverlay2 color={color} />
          <DoodleOverlay />
        </>
      );
      break;
    case ActivityEnum.TWO_TRUTHS_AND_A_LIE:
      SVGs.push(
        <ReadyOverlay color={color} />,
        <SetOverlay1 color={color} />,
        <>
          <SetOverlay2 color={color} />
          <Goooo />
        </>
      );
      break;
    case ActivityEnum.RAPID_RENDEZVOUS:
      const Digits = [<RR1Digit />, <RR2Digit />, <RR3Digit />];
      SVGs.push(
        <>
          <RRRoundText />
          {Digits[currentRound - 1]}
          <RRSlash />
          {Digits[totalRound - 1]}
          <RRExclamation />
        </>
      );
      break;
    case ActivityEnum.CHARADES:
      SVGs.push(<Overlay />);
      break;
    case ActivityEnum.DRUNK_STARTUPS:
      break;
    default:
      break;
  }
  return SVGs;
};

export const useRoundTime = () => {
  const activityName = useAppSelector(selectCurrentActivityName);
  const remainingTime = useAppSelector(selectRemainingTimer);
  const elapsedTime = useAppSelector(selectElapsedTimer);
  const totalRounds = useAppSelector(selectTotalRounds) || 1;
  const currentRound = useAppSelector(selectCurrentRound) || 1;

  const [perRoundTimer, setPerRoundTimer] = React.useState(RR_Timer_Per_Round);

  React.useEffect(() => {
    switch (activityName) {
      case ActivityEnum.RAPID_RENDEZVOUS:
        setPerRoundTimer(RR_Timer_Per_Round);
        break;
      case ActivityEnum.KNOW_YOUR_TEAM:
        setPerRoundTimer(KYT_Timer_Per_Round);
        break;
      default:
        setPerRoundTimer(RR_Timer_Per_Round);
    }
  }, [activityName]);

  let remainingRoundTime = remainingTime;
  let elapsedRoundTime = elapsedTime;
  switch (activityName) {
    case ActivityEnum.RAPID_RENDEZVOUS:
      if (totalRounds === 1 || currentRound === totalRounds) {
        remainingRoundTime = remainingTime;
      }
      const remainingRounds = Math.floor(
        remainingTime / (perRoundTimer + RR_And_KYT_Splash_Timer)
      );
      remainingRoundTime =
        remainingTime -
        remainingRounds * (perRoundTimer + RR_And_KYT_Splash_Timer);
      elapsedRoundTime =
        elapsedTime -
        (currentRound - 1) * (perRoundTimer + RR_And_KYT_Splash_Timer);
      break;
    default:
      break;
  }

  return { remainingRoundTime, elapsedRoundTime };
};

export const useWindowSize = () => {
  const [size, setSize] = React.useState([0, 0]);

  React.useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return {
    width: size[0],
    height: size[1],
    isMobile: size[0] < MOBILE_BREAKPOINT,
  };
};

export const useSize = (target: React.RefObject<HTMLDivElement>) => {
  const [size, setSize] = React.useState<DOMRect>();

  React.useLayoutEffect(() => {
    setSize(target.current?.getBoundingClientRect());
  }, [target]);

  useResizeObserver(target, (entry) => setSize(entry.contentRect));
  return size;
};

export const useSlackButtonPresence = () => {
  const [showSlackButton, setShowSlackButton] = React.useState(false);
  const slackAppStatus = useAppSelector(selectSlackAppStatus);
  const activeSlackChannelName = useAppSelector(selectSlackChannelName);

  React.useEffect(() => {
    setShowSlackButton(false);

    if (slackAppStatus !== "active" || activeSlackChannelName === "") {
      setShowSlackButton(true);
    }
  }, [slackAppStatus, activeSlackChannelName]);

  return showSlackButton;
};

export const useIsGuestHost = () => {
  const [isGuestHost, setIsGuestHost] = React.useState(false);
  const guestParticipantId = useAppSelector(selectGuestParticipantId);
  const hostParticipantId = useAppSelector(selectHostId);

  React.useEffect(() => {
    setIsGuestHost(!!(guestParticipantId === hostParticipantId));
  }, [hostParticipantId, guestParticipantId]);

  return isGuestHost;
};

export const useIsLoggedInUser = () => {
  const userLoginStatus = useAppSelector(selectUserLoginStatus);
  const [isLoggedInUser, setIsLoggedInUser] = React.useState(false);

  React.useEffect(() => {
    setIsLoggedInUser(false);

    if (userLoginStatus === loginStatus.LOGGED_IN) {
      setIsLoggedInUser(true);
    }
  }, [userLoginStatus]);

  return isLoggedInUser;
};

export const useUpdatePositionOnTourHighlight = (leftMargin: string) => {
  // if lounge tour is open, adjust some css for tour card
  const isLoungeTourOpen = useAppSelector(selectIsLoungeTourOpen);

  React.useEffect(() => {
    const updatePosition = (leftMargin: string) => {
      const reactTourEl = document.getElementsByClassName("lounge-tour") as any;
      reactTourEl[0].style["left"] = `${leftMargin}px`;
    };

    if (isLoungeTourOpen) {
      updatePosition("0");
    }

    return () => {
      updatePosition(leftMargin);
    };
  }, []);
};
