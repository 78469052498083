export const Mindful_Talk_Description =
  "From appreciation to inspiration, choose a topic and put your heads together.";

export const Mindful_Talk_Splash_Timer = 7;

export const Mindful_Talk_Timer_Per_Round = 2 * 60;

export const Mindful_Talk_background_color = "gree";

export const MT_Bg_color = "green";

export const MINDFUL_TALK_CHANNEL = "MINDFUL_TALK_CHANNEL";
