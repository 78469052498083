import { AnyAction } from "@reduxjs/toolkit";

// helper function to match any actions in a provided list
// actions can be `string` types or redux-toolkit action creators
export const isAnyOf =
  (...matchers: Array<string | { type: string }>) =>
  (action: AnyAction) =>
    matchers.some((matcher) =>
      typeof matcher === "string"
        ? matcher === action.type
        : matcher.type === action.type
    );
