import React from "react";
import { useTheme } from "styled-components";

const GreenSearchIcon = () => {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
    >
      <path
        d="M22 38.6356C30.8366 38.6356 38 31.4722 38 22.6356C38 13.7991 30.8366 6.63562 22 6.63562C13.1634 6.63562 6 13.7991 6 22.6356C6 31.4722 13.1634 38.6356 22 38.6356Z"
        fill={theme.color.green500}
        stroke={theme.color.green200}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42 42.6356L34.5 35.1356"
        stroke={theme.color.green200}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GreenSearchIcon;
