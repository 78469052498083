import * as React from "react";
import styled from "styled-components";
import { FundingIdea } from "./FundingCounter";
import { FundAmount, LeaderboardListItem } from "./LeaderBoardListMenu";
import MixerBuddiesList from "./MixerBuddiesList";
import { ZIndexLevel } from "../../utils/zIndex.enum";

const FundAmountAndMixerBuddiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
`;

const MobileMixerBuddiesListContainer = styled.div`
  z-index: ${ZIndexLevel.TOOLBAR_OPTIONS_INDEX};
  display: flex;
  border-radius: 16px;
  align-items: center;
  overflow-x: hidden;
  width: calc(100vw - 120px);
  padding: 0;
  overflow-x: scroll;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const MobileLeaderboarFundAmount = styled(FundAmount)`
  font-size: 16px;
  font-family: "Space Mono";

  & span {
    font-size: 14px;
  }
`;

const MobileLeaderboardListItem = styled(LeaderboardListItem)<{
  height: string;
}>`
  height: ${({ height }) => height};
  margin: 0 1rem;
  justify-content: flex-start;
`;

interface MobileLeaderboardItemPropsInterface {
  height: string;
  mixerBuddies: Array<string>;
  index: number;
  fundAmount?: string | number;
  disabledFundAmount?: boolean;
}

const MobileLeaderboardItem: React.FC<MobileLeaderboardItemPropsInterface> = ({
  height,
  mixerBuddies,
  index,
  fundAmount = "00",
  disabledFundAmount = false,
}) => {
  return (
    <MobileLeaderboardListItem height={height}>
      <FundingIdea>{index}</FundingIdea>
      <FundAmountAndMixerBuddiesContainer>
        <MobileLeaderboarFundAmount isDisabled={disabledFundAmount}>
          <span>$</span>
          {fundAmount}
          mn
        </MobileLeaderboarFundAmount>
        <MobileMixerBuddiesListContainer>
          <MixerBuddiesList mixerBuddies={mixerBuddies} />
        </MobileMixerBuddiesListContainer>
      </FundAmountAndMixerBuddiesContainer>
    </MobileLeaderboardListItem>
  );
};

export default MobileLeaderboardItem;
