import amplitude from "amplitude-js";
import React from "react";
import { toast } from "react-toastify";
import styled, { useTheme } from "styled-components";
import WifiOffSVG from "../assets/WifiOffSVG";
import ErrorMessageToast from "../components/error/ErrorMessageToast";
import { selectMeetingFailed } from "../store/features/meetingSlice";
import { useAppSelector } from "../store/hooks";
import { AmplitudeEvents } from "../types/amplitudeEvents";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const IconContainer = styled.div``;

const ToastMsg = styled.div`
  margin-left: 13px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
`;

const NetworkProvider: React.FC = ({ children }) => {
  const [isDisconnected, setIsDisconnected] = React.useState(false);
  const [isDown, setIsDown] = React.useState(false);
  const theme = useTheme();
  const meetingFailed = useAppSelector(selectMeetingFailed);

  const toastId = React.useRef<string | number | undefined>(undefined);

  const handleConnectionChange = () => {
    const condition = navigator.onLine ? "online" : "offline";
    if (condition === "online") {
      const webPing = setInterval(() => {
        fetch("//google.com", {
          mode: "no-cors",
        })
          .then(() => {
            setIsDisconnected(false);
            clearInterval(webPing);
          })
          .catch(() => setIsDisconnected(true));
      }, 2000);
      return;
    }

    return setIsDisconnected(true);
  };

  React.useEffect(() => {
    if (meetingFailed) {
      toast.dismiss();
      toast(
        <ErrorMessageToast message="Something went wrong while connecting with this social." />,
        {
          position: "top-center",
          autoClose: false,
          closeOnClick: false,
          closeButton: false,
          rtl: false,
          pauseOnFocusLoss: false,
          draggable: false,
          style: {
            padding: "0 24px",
            marginTop: "24px",
            marginLeft: "-100px",
            width: "auto",
            height: "58px",
            background: theme.color.white,
            color: theme.color.black,
            borderRadius: "16px",
            boxShadow: "0px 4px 16px -2px rgba(0, 0, 0, 0.08)",
          },
        }
      );

      amplitude
        .getInstance()
        .logEvent(AmplitudeEvents.CHIME_MEETING_FAILED, {});
    }
  }, [meetingFailed]);

  React.useEffect(() => {
    window.addEventListener("online", handleConnectionChange);
    window.addEventListener("offline", handleConnectionChange);
    return () => {
      window.removeEventListener("online", handleConnectionChange);
      window.removeEventListener("offline", handleConnectionChange);
    };
  }, []);

  const DisconnectMsg = () => (
    <Container>
      <IconContainer>
        <WifiOffSVG />
      </IconContainer>
      <ToastMsg>Your internet connection is unstable.</ToastMsg>
    </Container>
  );

  React.useEffect(() => {
    if (isDisconnected) {
      toast.dismiss(toastId.current);
      toastId.current = toast(<DisconnectMsg />, {
        delay: 500,
        position: "top-center",
        autoClose: false,
        closeOnClick: false,
        closeButton: false,
        rtl: false,
        pauseOnFocusLoss: false,
        draggable: false,
        style: {
          padding: "0 24px",
          marginTop: "24px",
          marginLeft: "-27px",
          width: "374px",
          height: "58px",
          background: theme.color.white,
          color: theme.color.black,
          borderRadius: "16px",
          boxShadow: "0px 4px 16px -2px rgba(0, 0, 0, 0.08)",
        },
      });

      amplitude
        .getInstance()
        .logEvent(AmplitudeEvents.INTERNET_DISCONNECTED, {});
      setIsDown(true);
    }
  }, [isDisconnected, theme.color.black, theme.color.white]);

  React.useEffect(() => {
    if (isDown && !isDisconnected) {
      toast.dismiss();

      toastId.current = toast(
        <ErrorMessageToast message="Internet connection is back to normal" />,
        {
          position: "top-center",
          delay: 500,
          autoClose: 10000,
          closeOnClick: false,
          closeButton: false,
          rtl: false,
          pauseOnFocusLoss: false,
          draggable: false,
          style: {
            padding: "0 24px",
            marginTop: "24px",
            marginLeft: "-100px",
            width: "520px",
            height: "58px",
            background: theme.color.white,
            color: theme.color.black,
            borderRadius: "16px",
            boxShadow: "0px 4px 16px -2px rgba(0, 0, 0, 0.08)",
          },
        }
      );

      amplitude
        .getInstance()
        .logEvent(AmplitudeEvents.INTERNET_RECONNECTED, {});
      setIsDown(false);
    }
  }, [isDisconnected, isDown, theme.color.black, theme.color.white]);
  return <>{children}</>;
};

export default NetworkProvider;
