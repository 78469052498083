import {
  createAction,
  createAsyncThunk,
  createSlice,
  Draft,
  PayloadAction,
} from "@reduxjs/toolkit";
import axios from "axios";
import { TriviaTimerMessage } from "../../types/socketMessage";
import { RootState } from "../store";
import {
  TEAM_BROKEN,
  TEAM_CLOSED,
  TEAM_DISCONNECT,
  TEAM_MESSAGE,
  TEAM_OPEN,
  TEAM_PREFIX,
} from "./socketActionTypes";
import { SOCKET_CONTROL_MESSAGES } from "./ws";
import * as ReduxWebSocket from "@giantmachines/redux-websocket";

export enum MixerStateOptions {
  INITIAL = "INITIAL",
  TRIAL = "TRIAL",
  RESET = "RESET",
  PLAY = "PLAY",
  SKIP = "SKIP",
  REVEALED = "REVEALED",
  OPEN_REVEALED = "OPEN_REVEALED",
  END = "END",
}

export interface Prompts {
  text: string;
  id: string;
}

export interface TeamState {
  connected: boolean;
  prompts: Prompts[];
  userInput: string;
  isSubmitted: boolean;
  selectedPromptId: string;
}

const initialState: TeamState = {
  connected: false,
  prompts: [],
  userInput: "",
  isSubmitted: false,
  selectedPromptId: "",
};

export const sendTeamPromptsData = (request: any) => {
  return ReduxWebSocket.send(request, TEAM_PREFIX);
};

export const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    resetTeamSlice: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder

      .addCase(
        createAction<{ message: string }>(TEAM_MESSAGE),
        (state: Draft<TeamState>, action) => {
          let response;
          try {
            const message = action.payload.message.replaceAll('"', "");
            response = JSON.parse(atob(message));

            if (response.info === SOCKET_CONTROL_MESSAGES.TEAM_PROMPTS) {
              return {
                ...state,
                ...response?.data,
              };
            }

            return state;
          } catch (e) {
            console.error(e);
          }
        }
      )
      .addCase(
        createAction<{ message: string }>(TEAM_CLOSED),
        (state: Draft<TeamState>, action) => {
          return { ...state, connected: false };
        }
      )
      .addCase(
        createAction<{ message: string }>(TEAM_OPEN),
        (state: Draft<TeamState>, action) => {
          return { ...state, connected: true };
        }
      )
      .addCase(
        createAction<{ message: string }>(TEAM_DISCONNECT),
        (state: Draft<TeamState>, action) => {
          return { ...state, connected: false };
        }
      )
      .addCase(
        createAction<{ message: string }>(TEAM_BROKEN),
        (state: Draft<TeamState>, action) => {
          return { ...state, connected: false };
        }
      );
  },
});

export const { resetTeamSlice } = teamSlice.actions;

export const selectTeamSliceState = (state: RootState) => state.team;

export default teamSlice.reducer;
