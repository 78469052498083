const BuildingIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0003 1.8335H4.00033C3.26395 1.8335 2.66699 2.43045 2.66699 3.16683V13.8335C2.66699 14.5699 3.26395 15.1668 4.00033 15.1668H12.0003C12.7367 15.1668 13.3337 14.5699 13.3337 13.8335V3.16683C13.3337 2.43045 12.7367 1.8335 12.0003 1.8335Z"
        stroke="#475766"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 15.1667V12.5H10V15.1667"
        stroke="#475766"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33301 4.5H5.34051"
        stroke="#475766"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.667 4.5H10.6745"
        stroke="#475766"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 4.5H8.0075"
        stroke="#475766"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 7.1665H8.0075"
        stroke="#475766"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 9.8335H8.0075"
        stroke="#475766"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.667 7.1665H10.6745"
        stroke="#475766"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.667 9.8335H10.6745"
        stroke="#475766"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33301 7.1665H5.34051"
        stroke="#475766"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33301 9.8335H5.34051"
        stroke="#475766"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BuildingIcon;
