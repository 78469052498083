const RRIcon: React.FC<{ size?: string }> = ({ size = "24" }) => {
  return (
    <svg
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="m11 7c0 2.76142-2.23858 5-5 5-.23 0-.45638-.0155-.67813-.0456l-.91936 1.1032c-.1932.2319-.23485.5546-.10684.8279.12801.2732.40255.4478.70435.4478h6.11898l-.6685 5.348c-.0429.3426.1453.6724.462.8099.3168.1375.6862.0497.9072-.2155l1.4288-1.7146c-.1613-.4912-.2485-1.0159-.2485-1.5611 0-2.7614 2.2386-5 5-5 .23 0 .4564.0155.6782.0456l.9193-1.1032c.1932-.2319.2349-.5546.1069-.8279-.128-.27326-.4026-.44784-.7044-.44784h-6.1189l.6685-5.34797c.0428-.34261-.1454-.67244-.4621-.80993s-.6862-.04971-.9072.21554l-1.4288 1.71458c.1613.4912.2485 1.01597.2485 1.56112zm6.8814 6.0017c-2.1543.0628-3.8814 1.8288-3.8814 3.9983 0 .2056.0155.4076.0454.6049zm-7.8814-6.0017c0 2.16945-1.7271 3.9355-3.88136 3.9983l3.83593-4.60314c.02992.19725.04543.39923.04543.60484z"
        fill="#475766"
        fillRule="evenodd"
      />
      <g fill="#05aaff">
        <path d="m10 7c0 2.20914-1.79086 4-4 4s-4-1.79086-4-4 1.79086-4 4-4 4 1.79086 4 4z" />
        <path d="m22 17c0 2.2091-1.7909 4-4 4s-4-1.7909-4-4 1.7909-4 4-4 4 1.7909 4 4z" />
      </g>
    </svg>
  );
};

export default RRIcon;
