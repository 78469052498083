import { RosterAttendeeType } from "../hooks/useChime";
import { EndScreenData } from "../store/features/mixerSlice";
export interface TTALAttendee extends RosterAttendeeType {
  answer?: TtalSection;
  next?: boolean;
}

export interface TTALEndScreenParticipants extends TTALAttendee {
  endScreen?: EndScreenData;
}

export interface SelectedAttendee extends RosterAttendeeType {
  promptId: string;
  isCurrent?: boolean;
}

export type TtalSection = 1 | 2 | 3;

export enum TtalToastType {
  WAITING,
  SUBMIT,
  SKIPPED,
  END,
  SWITCH_NOTES_TAKER,
}

export const TTAL_SPLASH_TIMER = 7;

export const TWO_TRUTHS_AND_A_LIE_CHANNEL = "TWO_TRUTHS_AND_A_LIE";

export const TTAL_Description =
  "Three statements, one lie. Do you have the detective instincts?";

export const TTAL_Bg_Color = "green";
