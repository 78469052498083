import { LocalStorageKeys } from "./localstorage";

export const removeShowVideoStartedToastFromLocalStorage = () => {
  localStorage.removeItem(LocalStorageKeys.ShowVideoStartedToasts);
};

export const setShowVideoStartedToastToLocalStorage = (
  showVideoStartedToasts: string
) => {
  localStorage.setItem(
    LocalStorageKeys.ShowVideoStartedToasts,
    showVideoStartedToasts
  );
};

export const getShowVideoStartedToastFromLocalStorage = () => {
  return localStorage.getItem(LocalStorageKeys.ShowVideoStartedToasts);
};
