import * as React from "react";
import styled, { useTheme } from "styled-components";

import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  selectFundDetails,
  setFundDetails,
  setTotalFundAmount,
} from "../../store/features/DrunkStartup/drunkStartupSlice";
import { FundedIdeasItemInterface } from "./FundingModal";

const FundSlider = styled.div`
  border-radius: 2px;
  width: 260px;

  & .rc-slider-disabled {
    background: none;
  }

  & .rc-slider-handle:active {
    border-color: none;
    box-shadow: none;
    cursor: grabbing;
  }

  &:hover .slider-tooltip {
    display: block;
  }
`;

export const FundingAmount = styled.div<{ amount: number }>`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;

  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: -0.01em;

  color: ${({ amount, theme }) =>
    amount > 0 ? theme.color.black : theme.color.lightGray};
`;

const TooltipContainer = styled.div`
  position: absolute;
  bottom: 42px;
  left: -23%;
`;

interface FundingAmountSliderProps {
  segmentGroupId: string;
  tentativeFunds: FundedIdeasItemInterface[];
  setTentativeFunds: React.Dispatch<
    React.SetStateAction<FundedIdeasItemInterface[]>
  >;
}

const FundingAmountSlider: React.FC<FundingAmountSliderProps> = ({
  segmentGroupId = "",
  tentativeFunds = [],
  setTentativeFunds,
}) => {
  const dispatch = useAppDispatch();
  const fundDetails = useAppSelector(selectFundDetails);
  const theme = useTheme();
  const [funds, setFunds] =
    React.useState<FundedIdeasItemInterface[]>(tentativeFunds);

  const changeHandler = React.useCallback((val) => {
    setTentativeFunds((t) => {
      const updatedTentativeFunds = filterSegmentGroups(t, val);

      const updatedTotalAmount = getTotalAmount(updatedTentativeFunds);

      if (updatedTotalAmount <= 100) {
        return filterSegmentGroups(t, val);
      }
      return t;
    });

    setFunds((t) => filterSegmentGroups(t, val));

    let totalAmount = getTotalAmount(tentativeFunds);

    if (totalAmount > 0)
      dispatch(setTotalFundAmount(Math.abs(100 - totalAmount)));
  }, []);

  React.useEffect(() => {
    const updatedFundData = {
      ...fundDetails,
      [segmentGroupId]: {
        segment_group_id: segmentGroupId,
        amount: Math.abs(
          tentativeFunds.find((a) => a.segment_group_id === segmentGroupId)
            ?.amount || 0
        ),
      },
    };
    let newFundAdded = Object.values(updatedFundData).reduce(
      (acc, curr) => acc + curr.amount,
      0
    );

    if (newFundAdded <= 100) {
      dispatch(setFundDetails(updatedFundData));
    }
  }, [funds]);

  const onChangeHandler = (val: number) => {
    changeHandler(val);
  };

  const getTotalAmount = (fundsArr: FundedIdeasItemInterface[]) => {
    if (fundsArr.length > 0) {
      let totalAmount = fundsArr
        .map((fund) => fund.amount)
        .reduce((acc, curr) => {
          return acc + curr;
        });
      return totalAmount;
    }

    return 0;
  };

  React.useEffect(() => {
    let totalAmount = getTotalAmount(tentativeFunds);

    if (totalAmount >= 0)
      dispatch(setTotalFundAmount(Math.abs(100 - totalAmount)));
  }, [tentativeFunds]);

  const filterSegmentGroups = (
    tentativeFundsArr: FundedIdeasItemInterface[],
    value: number
  ) => {
    return [
      ...tentativeFundsArr.filter((a) => a.segment_group_id !== segmentGroupId),
      { amount: value, segment_group_id: segmentGroupId },
    ];
  };

  return (
    <>
      <FundSlider key={segmentGroupId}>
        <Slider
          min={0}
          max={100}
          value={
            tentativeFunds.find((a) => a.segment_group_id === segmentGroupId)
              ?.amount || 0
          }
          step={1}
          onChange={(val) => onChangeHandler(Number(val))}
          railStyle={{
            borderRadius: "2px",
            height: "6px",
            background: theme.color.green100,
            top: "4px",
          }}
          handleStyle={{
            background: "white",
            border: `4px solid ${theme.color.green500}`,
            height: "16px",
            width: "16px",
            boxSizing: "border-box",
            marginTop: "-6px",
            opacity: "1",
          }}
          trackStyle={{
            height: "6px",
            background: theme.color.green500,
            top: "4px",
          }}
        />
      </FundSlider>
      <FundingAmount
        amount={
          tentativeFunds.find((a) => a.segment_group_id === segmentGroupId)
            ?.amount || 0
        }
      >
        {(
          tentativeFunds.find((a) => a.segment_group_id === segmentGroupId)
            ?.amount || 0
        )
          .toString()
          .padStart(2, "0")}{" "}
        mn
      </FundingAmount>
    </>
  );
};

export default FundingAmountSlider;
