import * as ReduxWebSocket from "@giantmachines/redux-websocket";
import {
  TEAM_PREFIX,
  LOUNGE_PREFIX,
  MIXER_PREFIX,
  PARENT_PREFIX,
  JOIN_PREFIX,
} from "./socketActionTypes";

type PrefixType =
  | typeof LOUNGE_PREFIX
  | typeof PARENT_PREFIX
  | typeof MIXER_PREFIX
  | typeof TEAM_PREFIX
  | typeof JOIN_PREFIX; // Add other prefix here and enerate url in connect function

export const connect = (
  prefix: PrefixType,
  id?: string,
  externalAttendeeId?: string
) => {
  const url = getWsUrl(prefix, id, externalAttendeeId);
  return ReduxWebSocket.connect(url, [], prefix);
};

export const getWsUrl = (
  prefix: string,
  id?: string,
  externalAttendeeId?: string
) => {
  const wsUrl = `${process.env.REACT_APP_SOCKET_URL}/${id}?externalAttendeeId=${externalAttendeeId}`;
  let level = "";
  switch (prefix) {
    case LOUNGE_PREFIX:
      level = "SegmentLevel";
      break;
    case PARENT_PREFIX:
      level = "EventInstanceLevel";
      break;
    case MIXER_PREFIX:
      level = "SegmentLevel";
      break;
    case TEAM_PREFIX:
      level = "TeamLevel";
      break;
    case JOIN_PREFIX:
      level = "EventLevel";
      break;
    default:
  }
  return `${wsUrl}&level=${level}`;
};

export const { disconnect } = ReduxWebSocket;

export enum SOCKET_CONTROL_MESSAGES {
  DISCONNECT = "disconnect",
  REFRESH = "refresh",
  ICEBREAKER = "icebreaker",
  RENDEZVOUS = "rendezvous",
  REFRESH_PANEL = "refresh_panel",
  TIMER = "timer",
  TRIVIA_TIMER = "TRIVIA_TIMER",
  WYR_TIMER = "WYR_TIMER",
  CHARADES_TIMER = "CHARADES_TIMER",
  DOODLE_TIMER = "DOODLE_TIMER",
  HOW_TO_PLAY_TIME = "HOW_TO_PLAY_TIME",
  TTAL_TIMER = "TTAL_TIMER",
  TTAL_MIXER_MESSAGE = "TTAL_MIXER_MESSAGE",
  LOUNGE_VIDEO_MESSAGE = "lounge_video",
  MID_TIMER = "mid_time",
  CHARADES_REFRESH = "CHARADES_REFRESH",
  CHARADES_MID_ROUND_TIMER = "CHARADES_MID_ROUND_TIMER",
  TEAM_PROMPTS = "TEAM_PROMPTS",
  CHARADES_ANSWER_MESSAGE = "ANSWER_SUBMITTED",
  CHARADES_STATE_REFRESH = "CHARADES_STATE_REFRESH",
  FUNDING_REFRESH_MESSAGE = "FUNDING_REFRESH_MESSAGE",
  SLACK_REFRESH_MESSAGE = "SLACK_CHANNEL_SETUP_REFRESH_MESSAGE",
  CANVAS_REFRESH_MESSAGE = "CANVAS_REFRESH_MESSAGE",
}
