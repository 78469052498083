import { RosterAttendeeType } from "../hooks/useChime";

export interface WYRAttendee extends RosterAttendeeType {
  answer?: WyrSide;
}

export type WyrSide = "left" | "right";

export const WOULD_YOU_RATHER_CHANNEL = "WOULD_YOU_RATHER";

export const WYR_TIME_PER_QUESTION = 20;

export const WYR_SPLASH_TIMER = 7;

export const WYR_NEXT_ANIMATION_START_TIME = 15;

export const WYR_Description =
  "World’s toughest questions need an answer. Choose your side.";

export const WYR_Bg_Color = "green";
