import React, { useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import {
  loginStatus,
  selectOrgDetails,
  selectUserLoginStatus,
} from "../../store/features/userSlice";
import { RoutesEnum } from "../../routes/routes.enum";

interface ProtectedRouteProps {
  Component: React.FC;
  path: string;
  exact?: boolean;
}

const ProtectedRoute: React.FunctionComponent<ProtectedRouteProps> = ({
  Component,
  ...args
}) => {
  const history = useHistory();
  const userLoginStatus = useAppSelector(selectUserLoginStatus);
  const orgDetails = useAppSelector(selectOrgDetails);

  useEffect(() => {
    const redirectLink = sessionStorage.getItem("redirectAfterLogin");
    const isLoggedIn = userLoginStatus === loginStatus.LOGGED_IN;

    if (userLoginStatus === loginStatus.LOGGED_OUT) {
      history.push(RoutesEnum.SIGN_IN);
    } else if (isLoggedIn && orgDetails.length === 0) {
      history.push(RoutesEnum.CREATE_TEAM);
    }

    // If user had come here from a link redirect user there instead of dashboard
    if (redirectLink && isLoggedIn && orgDetails.length !== 0) {
      history.push(redirectLink);
    }
  }, [history, userLoginStatus]);

  return <Route render={() => <Component />} {...args} />;
};

export default ProtectedRoute;
