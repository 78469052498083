import React from "react";

interface Props {
  primaryColor?: string;
  secondaryColor?: string;
  width?: number;
  height?: number;
}

const TeamsIcon: React.FC<Props> = ({
  primaryColor,
  secondaryColor,
  width = 24,
  height = 24,
}) => {
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill={"none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.4168 21.0001V19.2223C22.4168 18.2793 22.0656 17.3749 21.4405 16.7081C20.8154 16.0414 19.9676 15.6667 19.0835 15.6667H12.4168C11.5328 15.6667 10.6849 16.0414 10.0598 16.7081C9.43469 17.3749 9.0835 18.2793 9.0835 19.2223V21.0001"
        fill={secondaryColor || "#FBE5D4"}
        stroke={secondaryColor || "#FBE5D4"}
        strokeWidth="1.77778"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1942 12.1111C18.1579 12.1111 19.7498 10.5192 19.7498 8.55556C19.7498 6.59188 18.1579 5 16.1942 5C14.2305 5 12.6387 6.59188 12.6387 8.55556C12.6387 10.5192 14.2305 12.1111 16.1942 12.1111Z"
        fill={secondaryColor || "#FBE5D4"}
        stroke={secondaryColor || "#FBE5D4"}
        strokeWidth="1.77778"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5835 21V19C16.5835 17.9391 16.1884 16.9217 15.4851 16.1716C14.7819 15.4214 13.8281 15 12.8335 15H5.3335C4.33893 15 3.38511 15.4214 2.68185 16.1716C1.97858 16.9217 1.5835 17.9391 1.5835 19V21"
        fill={primaryColor || "#ED7B2A"}
        stroke={primaryColor || "#ED7B2A"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5835 11C11.7926 11 13.5835 9.20914 13.5835 7C13.5835 4.79086 11.7926 3 9.5835 3C7.37436 3 5.5835 4.79086 5.5835 7C5.5835 9.20914 7.37436 11 9.5835 11Z"
        fill={primaryColor || "#ED7B2A"}
        stroke={primaryColor || "#ED7B2A"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TeamsIcon;
