export const checkLoginStatus = (): boolean => {
  return JSON.parse(sessionStorage.getItem("loginFailed") || "false");
};

export const clearLoginStatus = () => {
  sessionStorage.removeItem("loginFailed");
};

export const setLoginStatusFailed = () => {
  sessionStorage.setItem("loginFailed", "true");
};
