import * as React from "react";
import styled from "styled-components";
import { ZIndexLevel } from "../../utils/zIndex.enum";

const MixerBuddiesNameText = styled.div<{ color: string }>`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin: 10px 7px;
  max-width: 50px;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${({ color, theme }) => (color ? color : theme.color.darkGray)};
`;

const MixerBuddiesNameSeperator = styled.div`
  background: ${({ color, theme }) =>
    color ? color : theme.lounge.backgroundHover};
  border-radius: 3px;
  height: 5px;
  width: 5px;
`;

const MixerBuddiesListItem = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  max-width: 400px;
  z-index: ${ZIndexLevel.TOOLBAR_OPTIONS_INDEX};

  :last-child :last-child {
    display: none;
  }
`;

interface MixerBuddiesListPropsInterface {
  mixerBuddies: Array<string>;
  textColor?: string;
  seperatorColor?: string;
}

const MixerBuddiesList: React.FC<MixerBuddiesListPropsInterface> = ({
  textColor = "",
  seperatorColor = "",
  mixerBuddies = [],
}) => {
  return (
    <>
      {mixerBuddies.map((mixerBuddy, index) => (
        <MixerBuddiesListItem key={index}>
          <MixerBuddiesNameText color={textColor}>
            {mixerBuddy}
          </MixerBuddiesNameText>
          <MixerBuddiesNameSeperator
            color={seperatorColor}
          ></MixerBuddiesNameSeperator>
        </MixerBuddiesListItem>
      ))}
    </>
  );
};

export default MixerBuddiesList;
