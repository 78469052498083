import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { clearLocalStorage } from "../utils/clearLocalStorage";
import { Store } from "redux";

let axiosRequestInterceptorId: number;
let axiosResponseInterceptorId: number;

export const setUpRequestInterceptor = (
  store: Store,
  axiosInstance: AxiosInstance
) => {
  // axios request interceptors
  axiosRequestInterceptorId = axiosInstance.interceptors.request.use(
    (request: AxiosRequestConfig) => {
      // Set credential option to true for all api calls. -- This is needed for feature branches only as the api & web are on different domains
      request.withCredentials =
        process.env.REACT_APP_ENVIRONMENT !== "production";

      return request;
    }
  );
};

export const setUpResponseInterceptor = (axiosInstance: AxiosInstance) => {
  // axios response interceptors
  const handleError = async (error: AxiosError) => {
    // console.log(" axiosCall AxiosError from interceptor ->", { error });
    if (
      error?.response?.config.url?.includes("/auth/token/validate") &&
      error?.response?.status === 401
    ) {
      clearLocalStorage();
      window.location.href = "/sign-in";
    }

    if (error) return Promise.reject(error);
  };

  axiosResponseInterceptorId = axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      // console.log(" axiosCall AxiosResponse -> ", { response });
      return response;
    },
    handleError
  );
};

export const setUpInterceptor = (
  store: Store,
  axiosInstance: AxiosInstance
) => {
  setUpRequestInterceptor(store, axiosInstance);
  setUpResponseInterceptor(axiosInstance);
};

export const ejectRequestInterceptor = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.eject(axiosRequestInterceptorId);
};

export const ejectResponseInterceptor = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.response.eject(axiosResponseInterceptorId);
};

/* 

if we need to ignoere the axios interceptors then we can create new axios instance 
```
  const instance = axios.create()
  const response = await instance.get(url);
```

If we want to override the default axios headers/add new ones, then we can pass header object to axios
```
  axios.get(
    url, 
    {
      transformRequest: [(data, headers) => {
        headers["Authorization"] = "XXXXXX";
        return data;
      }],
    }
  );
```

If we want to eject any request/response interceptors from axios, we can call to ejectRequestInterceptor before making an ajax call, and add it again
```
  ejectRequestInterceptor()
  ajax.get()
  setUpRequestInterceptor(store, axiosInstance);
```
*/
