export const DrunkStartups_Description =
  "Ideate with your team on some crazy ideas. Let's see who gets funded.";

export const DrunkStartups_Splash_Timer = 7;

export const DrunkStartups_background_color = "yellow";

export const DrunkStartups_step1_Timer = 300;

export const DrunkStartup_Toolbar_Dropdown_Options = {
  ZOOM: "zoom",
  SHAPES: "shapes",
  COLOR: "color",
  DASH: "dash",
};

export const DrunkStartups_Idea_Length = 75;

export const VIDEO_TILE_HEIGHT = 130;
