import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { ThemeColors } from "../../types/activity";

const Container = styled(motion.button)<{
  background: ThemeColors;
  backgroundHover: ThemeColors;
  color: ThemeColors;
  colorHover: ThemeColors;
  borderRadius: number;
  height?: number;
  textSize: string;
  boxShadow?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => (height ? `${height}px` : "48px")};
  background: ${(props) => props.theme.color[props.background as ThemeColors]};
  border-radius: ${(props) => props.borderRadius}px;
  font-weight: 700;
  font-size: ${({ textSize }) => textSize};
  line-height: 160%;
  text-align: center;
  color: ${(props) => props.theme.color[props.color as ThemeColors]};
  gap: 10px;
  cursor: pointer;
  width: 100%;
  border: none;
  user-select: none;
  box-shadow: ${({ boxShadow }) => boxShadow};

  &:hover {
    background: ${(props) =>
      props.theme.color[props.backgroundHover as ThemeColors]};
    color: ${(props) => props.theme.color[props.colorHover as ThemeColors]};
  }
  &:focus {
    outline: none;
  }
`;

interface MotionButtonProps {
  clickHandler: () => void;
  backgroundColor?: ThemeColors;
  backgroundColorHover?: ThemeColors;
  textColor?: ThemeColors;
  textSize?: string;
  textColorHover?: ThemeColors;
  borderRadius?: number;
  height?: number;
  boxShadow?: string;
}

const MotionButton: React.FC<MotionButtonProps> = ({
  children,
  clickHandler,
  backgroundColor = "purple500",
  backgroundColorHover = "purple600",
  textColor = "white",
  textColorHover = "white",
  borderRadius = 16,
  height,
  textSize = "16px",
  boxShadow,
}) => {
  const [isButtonPressed, setIsButtonPressed] = React.useState(false);
  const [isButtonTapped, setIsButtonTapped] = React.useState(false);

  const handleButtonMouseTap = () => {
    setIsButtonTapped(true);
    setTimeout(() => {
      setIsButtonTapped(false);
    }, 100);
  };

  return (
    <Container
      textSize={textSize}
      onClick={clickHandler}
      onTap={handleButtonMouseTap}
      onMouseDown={() => setIsButtonPressed(true)}
      onMouseUp={() => setIsButtonPressed(false)}
      onMouseLeave={() => setIsButtonPressed(false)}
      animate={{
        scale: isButtonPressed || isButtonTapped ? 0.94 : 1,
      }}
      background={backgroundColor}
      backgroundHover={backgroundColorHover}
      color={textColor}
      colorHover={textColorHover}
      borderRadius={borderRadius}
      height={height}
      boxShadow={boxShadow}
    >
      {children}
    </Container>
  );
};

export default MotionButton;
