import { UserInterface } from "../components/Dashboard/TeamView";
import {
  DashboardTemplate,
  TemplateItem,
} from "../store/features/dashboardSlice";
import { EventStatusTypes } from "../store/features/eventSlice";

interface EventMember {
  id: string;
  status: string;
  organizationId: string;
  eventId: string;
  entityType: string;
  entityId: string;
  role: string;
  updatedBy: string;
}

export interface CreateEventResponse {
  eventId: string;
  eventCode: string;
  participantId: string;
}

export interface CreateEventInstanceResponse {
  id: string;
  eventId: string;
  scheduledStartTime: string;
  startTime: string;
  status: string;
  currentSegmentId: string;
  lastSegmentStartTime: string;
  meetingURL: string;
  templateId: string;
  template: DashboardTemplate;
  organizationId: string;
  startedByParticipantId: string;
  startedByParticipant: string;
  nearestChimeServer: string;
}

export enum EventInstanceStatus {
  ONGOING = "ONGOING",
  ENDED = "ENDED",
  UPCOMING = "UPCOMING",
}

export interface GetInstanceResponse {
  eventTemplate: EventTemplate;
  templatesMap: { [key: string]: Template };
  event: Event;
  eventInstanceId?: string;
  eventInstanceStatus: EventInstanceStatus;
  startTime?: string;
  endTime?: string;
  currentSegmentId?: string;
  currentSegmentExtended?: boolean;
  currentThemeId?: string;
  allHostParticipantIds: string[];
  hostParticipantId?: string;
  participantId?: string;
  organizationName: string;
  organizationId: string;
  organizationCode: string;
  authorized: boolean;
  numberOfAttendees: number;
  country?: string;
  eventInstanceHostParticipantId: string;
  participantsMap: {
    [key: string]: {
      name: string;
      id: string;
    };
  };
}
export interface EventTemplate {
  id: string;
  isActive: boolean;
  eventId: string;
  templateId: string;
  lastUpdatedBy: string;
  isRandom: boolean;
}

export interface Template {
  id: string;
  isActive: boolean;
  name: string;
  description: string;
  approximateTime: number;
  code: string;
  startsAt: string;
  endsAt: string;
  maxActivities: number;
  sortOrder: number;
  isHidden: boolean;
  loginNeeded: boolean;
  isRandom: boolean;
  isCustom: boolean;
  lastUpdatedBy: string;
  tags: any[];
}

interface Event {
  id: string;
  name: string;
  isRecurring: boolean;
  organizationId: string;
  sortingTime: string;
  creatorParticipantID: string;
  CreatorParticipant: CreatorParticipant;
  frequency: string;
  status: EventStatusTypes;
  data?: any;
  code: string;
}

interface CreatorParticipant {
  id: string;
  name: string;
  videoOn: boolean;
  muted: boolean;
  userId: string;
}

// TODO - define inteface properly
export interface GetEventDetailsResponse {
  eventTemplate: EventTemplate;
  templatesMap: { [key: string]: Template };
  event: Event;
  eventMembers: Array<EventMember>;
  participantsMap: {
    [key: string]: {
      name: string;
      id: string;
      videoOn: boolean;
      muted: boolean;
      userId: string;
    };
  };
  userMap: {
    [key: string]: {
      email: string;
      id: string;
    };
  };
  userDetailMapByUserId: {
    [key: string]: {
      id: string;
      userId: string;
      user: UserInterface;
      name: string;
      data: null;
      activeOrganizationId: string;
    };
  };
  invitedEmailsMap: {
    [key: string]: {
      id: string;
      isActive: boolean;
      organizationId: string;
      email: string;
    };
  };
  templateItemsByTemplateIdMap: { [key: string]: TemplateItem[] };
}

export interface UpdatedTemplate {
  isCustom?: boolean;
  defaultTemplateDetails?: { templateCode: string };
  customTemplateDetails?: CustomTemplateDetails;
}

export interface CustomTemplateDetails {
  templateName?: string;
  templateItems?: MixerItem[];
}

export interface MixerItem {
  activityId?: string;
  themeId?: string;
}

export interface UpdateScheduleSocialInterface {
  name: string;
  frequency: string;
  alternateHostEmails: Array<string>;
  alternateHostParticipantIds: Array<string>;
  isTemplateEdited?: boolean;
  template?: UpdatedTemplate;
  isRandom?: boolean;
}
