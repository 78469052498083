import { LocalStorageKeys } from "./localstorage";

export const removeGuestParticipantIdFromLocalStorage = () => {
  localStorage.removeItem(LocalStorageKeys.GuestParticipantId);
};

export const setGuestParticipantIdToLocalStorage = (
  guestParticipantId: string
) => {
  localStorage.setItem(LocalStorageKeys.GuestParticipantId, guestParticipantId);
};

export const getGuestParticipantIdFromLocalStorage = () => {
  return localStorage.getItem(LocalStorageKeys.GuestParticipantId);
};
