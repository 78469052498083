import * as React from "react";
import styled from "styled-components";
import amplitude from "amplitude-js";
import { ZIndexLevel } from "../../utils/zIndex.enum";
import LeaderBoardTrophySvg from "../../assets/DrunkStartup/LeaderBoardTrophySvg";
import CaretIcon from "../../assets/CaretIcon";
import { DrunkStartupsIdeaItem } from "./FundingModal";
import MixerBuddiesList from "./MixerBuddiesList";
import { FundingIdea } from "./FundingCounter";
import { sortBy } from "lodash";
import { SegmentGroupIdsMapInterface } from "./DsBackToLoungeCanvas";
import OutsideClickHandler from "react-outside-click-handler";
import { SegmentsWithFundAmountMapInterface } from "./DsViewOnlyCanvas";
import { AmplitudeEvents } from "../../types/amplitudeEvents";

const Container = styled.div`
  display: flex;
`;

const LeaderBoardMenu = styled.div`
  z-index: ${ZIndexLevel.TOOLBAR_OPTIONS_INDEX};
  padding: 5px 5px;
  margin-left: 1.4rem;
  display: flex;
  justify-content: space-around;
  height: 29px;
  background: ${(props) => props.theme.color.white};
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 12%);
  border-radius: 16px;
  align-items: center;
  width: 130px;
  color: ${(props) => props.theme.color.darkGray};
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 160%;
  letter-spacing: -0.01em;
  cursor: pointer;
`;

export const Leaderboard = styled.div`
  z-index: ${ZIndexLevel.TOOLBAR_OPTIONS_INDEX};
  position: absolute;
  bottom: 0;
  left: 1.2rem;
  width: fit-content;
  padding: 16px;
  background: ${(props) => props.theme.color.white};
  box-shadow: 0px 4px 16px -2px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  box-sizing: border-box;
`;

export const LeaderboardAmount = styled(Leaderboard)`
  left: 165px;
  bottom: 0;
  padding: 6px 12px;
  z-index: ${ZIndexLevel.LEADERBOARD_INDEX};
`;

export const LeaderBoardTitle = styled.div`
  width: 150px;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  align-items: center;
  color: ${(props) => props.theme.color.darkGray};
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 160%;
  letter-spacing: -0.01em;
`;

const MixerBuddiesListContainer = styled.div`
  min-width: 300px;
  width: max-content;
  max-width: 500px;
  margin-right: 10px;
  overflow-x: scroll;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const FundAmount = styled.div<{ isDisabled: boolean }>`
  width: fit-content;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;

  text-align: right;
  letter-spacing: -0.01em;
  color: ${({ isDisabled, theme }) =>
    isDisabled ? theme.color.lightGray : theme.color.green500};
`;

export const LeaderboardListItem = styled(DrunkStartupsIdeaItem)`
  padding: 0px;
  display: grid;
  grid-template-columns: 2rem 1fr 4rem;
  justify-content: start;
`;

interface LeaderBoardListMenuPropsInterface {
  segmentGroupIdsMap?: SegmentGroupIdsMapInterface;
  activeSegmentGroup: string;
  segmentsWithFundAmount: SegmentsWithFundAmountMapInterface;
  getCurrentSegmentFundAMount: (activeSegmentId: string) => number | false;
}

const LeaderBoardListMenu: React.FC<LeaderBoardListMenuPropsInterface> = ({
  segmentGroupIdsMap = {},
  activeSegmentGroup = "",
  segmentsWithFundAmount,
  getCurrentSegmentFundAMount,
}) => {
  const [showLeaderBoard, setShowLeaderBoard] = React.useState(false);

  const closeLeaderBoardModal = () => {
    setShowLeaderBoard(false);
  };

  React.useEffect(() => {
    if (showLeaderBoard) {
      amplitude.getInstance().logEvent(AmplitudeEvents.DS_OPENED_LEADERBOARD);
    }
  }, [showLeaderBoard]);

  return (
    <Container>
      <LeaderBoardMenu onClick={() => setShowLeaderBoard(true)}>
        {<LeaderBoardText showLeaderBoard={showLeaderBoard} />}
      </LeaderBoardMenu>

      <OutsideClickHandler onOutsideClick={closeLeaderBoardModal}>
        {showLeaderBoard && (
          <Leaderboard>
            <LeaderBoardTitle onClick={closeLeaderBoardModal}>
              {<LeaderBoardText showLeaderBoard={showLeaderBoard} />}
            </LeaderBoardTitle>
            {sortBy(segmentsWithFundAmount, ["amount"])
              .reverse()
              .map(({ index, participants = [], amount }: any) => (
                <LeaderboardListItem key={index}>
                  <FundingIdea>{index}</FundingIdea>
                  <MixerBuddiesListContainer>
                    <MixerBuddiesList mixerBuddies={participants} />
                  </MixerBuddiesListContainer>
                  <FundAmount isDisabled={amount <= 0}>
                    ${amount}
                    mn
                  </FundAmount>
                </LeaderboardListItem>
              ))}
          </Leaderboard>
        )}
      </OutsideClickHandler>

      {getCurrentSegmentFundAMount(activeSegmentGroup) && (
        <LeaderboardAmount>
          <FundAmount isDisabled={false}>
            ${getCurrentSegmentFundAMount(activeSegmentGroup)}mn
          </FundAmount>
        </LeaderboardAmount>
      )}
    </Container>
  );
};

export const LeaderBoardText: React.FC<{ showLeaderBoard: boolean }> = ({
  showLeaderBoard,
}) => {
  return (
    <>
      <LeaderBoardTrophySvg />
      Leaderboard
      <CaretIcon inverted={showLeaderBoard} />
    </>
  );
};

export default LeaderBoardListMenu;
