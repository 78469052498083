import React, { Suspense } from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  MemoryRouter,
  Switch,
  RouteComponentProps,
} from "react-router-dom";
import { RoutesEnum } from "./routes.enum";
import CreateSocial from "../pages/CreateSocial";
import Bye from "../pages/Bye";
import GoogleRedirect from "../components/auth/GoogleRedirect";
import ProtectedRoute from "../components/auth/ProtectedRoute";
import NetworkProvider from "../Providers/NetworkProvider";
import Login from "../pages/Login";
import OrganizationJoin from "../pages/OrganizationJoin";
import SpinningLoader from "../components/common/SpinningLoader";
import DrunkStartups from "../pages/Share/DrunkStartups";
import SlackCallback from "../pages/SlackCallback";
import CreateTeam from "../pages/CreateTeam";
import MemoizedRedirection from "../components/auth/MemoizeRedriectionRouts";
import SlackCreateChannel from "../pages/SlackCreateChannel";
import { lazyImportWithRetry } from "../utils/lazyRetry";

const DrunkStartup = lazyImportWithRetry(
  () => import("../pages/DrunkStartups")
);
const Charades = lazyImportWithRetry(() => import("../pages/Charades"));
const Dashboard = lazyImportWithRetry(() => import("../pages/Dashboard"));
const Doodle = lazyImportWithRetry(() => import("../pages/Doodle"));
const WouldYouRather = lazyImportWithRetry(
  () => import("../pages/WouldYouRather")
);
const Lounge = lazyImportWithRetry(() => import("../pages/Lounge"));
const TwoTruthsAndALie = lazyImportWithRetry(
  () => import("../pages/TwoTruthsAndALie")
);
const TeamInvite = lazyImportWithRetry(() => import("../pages/TeamInvite"));
const Trivia = lazyImportWithRetry(() => import("../pages/Trivia"));
const JoinSocial = lazyImportWithRetry(() => import("../pages/JoinSocial"));
const RapidRendezvous = lazyImportWithRetry(
  () => import("../pages/RapidRendezvous")
);
const MindfulTalk = lazyImportWithRetry(() => import("../pages/MindfulTalk"));

interface MatchProps {
  id: string;
}

interface SocialRoutesProps extends RouteComponentProps<MatchProps> {}

const Routes = () => {
  function Nested(props: SocialRoutesProps) {
    let id = props.match.params.id;
    return (
      <MemoryRouter>
        <Switch>
          <NetworkProvider>
            <Route
              path="/"
              exact
              render={() => <JoinSocial eventCode={id} />}
            />
            <Route path={RoutesEnum.LOUNGE} exact component={Lounge} />
            <Route
              path={RoutesEnum.WOULD_YOU_RATHER}
              exact
              component={WouldYouRather}
            />
            <Route path={RoutesEnum.TRIVIA} exact component={Trivia} />
            <Route path={RoutesEnum.DOODLE} exact component={Doodle} />
            <Route path={RoutesEnum.CHARADES} exact component={Charades} />
            <Route
              path={RoutesEnum.TWO_TRUTHS_AND_A_LIE}
              exact
              component={TwoTruthsAndALie}
            />
            <Route
              path={RoutesEnum.RAPID_RENDEZVOUS}
              exact
              component={RapidRendezvous}
            />
            <Route
              path={RoutesEnum.MINDFUL_TALK}
              exact
              component={MindfulTalk}
            />
            <Route
              path={RoutesEnum.DRUNK_STARTUPS}
              exact
              component={DrunkStartup}
            />
            <Route path={RoutesEnum.BYE} component={Bye} />
          </NetworkProvider>
          <Redirect to={RoutesEnum.CREATE_SOCIAL} />
        </Switch>
      </MemoryRouter>
    );
  }

  return (
    <Suspense fallback={<SpinningLoader />}>
      <Router>
        <Switch>
          <Route
            path={"/"}
            exact
            render={() => {
              window.location.href = "https://thursday.social";
              return <></>;
            }}
          />

          {/* Removing this temporarily to remove sign-in sign-up flow
         <Route path={"/sign-up"} exact component={Login} />
        <Route path={"/sign-in"} exact component={Login} />
        <ProtectedRoute
          path={RoutesEnum.DASHBOARD}
          exact
          component={Dashboard}
        /> */}
          <Route path={"/sign-in"} exact component={Login} />
          <Route
            path={`${RoutesEnum.TEAM}/:code`}
            exact
            component={TeamInvite}
          />

          <ProtectedRoute
            path={RoutesEnum.CREATE_TEAM}
            exact
            Component={() => <CreateTeam />}
          />
          <Route
            path={"/organization/join/:id"}
            exact
            component={OrganizationJoin}
          />
          <Route
            path={"/auth/google/callback"}
            exact
            component={GoogleRedirect}
          />

          <Route path={RoutesEnum.DASHBOARD} exact component={Dashboard} />

          <Route
            path={RoutesEnum.MY_SOCIAL}
            exact
            component={() => <Dashboard initialView="my-socials" />}
          />

          <Route
            path={RoutesEnum.TEAM}
            exact
            component={() => <Dashboard initialView="team" />}
          />

          <MemoizedRedirection
            path={RoutesEnum.CREATE_SOCIAL}
            Component={CreateSocial}
          />
          <Route path={RoutesEnum.BYE} component={Bye} />
          <Route
            path={`${RoutesEnum.SLACK}/create-channel`}
            component={SlackCreateChannel}
          />
          <Route
            path={`${RoutesEnum.SLACK}/callback`}
            component={SlackCallback}
          />
          {/* TODO: DrunkStartupsV1.0: Add separate route for ds in share routes */}
          <Route
            path={`${RoutesEnum.DS_SHARE}/:id`}
            component={DrunkStartups}
          />
          <Route path={`${RoutesEnum.SOCIAL}/:id`} component={Nested} />
          <Route
            path={"/start-your-free-trial"}
            exact
            render={() => {
              window.location.href = `${process.env.REACT_APP_BASE_URL}/sign-in`;
              return <></>;
            }}
          />
          <Redirect to={"/"} />
        </Switch>
      </Router>
    </Suspense>
  );
};

export default Routes;
