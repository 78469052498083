import React from "react";
import styled, { useTheme } from "styled-components";

const Svg = styled.svg<{ isLeft: boolean }>`
  transform: ${({ isLeft }) => (isLeft ? "rotate(180deg)" : "rotate(0deg)")};
`;

const NextArrow: React.FC<{
  isDisabled?: boolean;
  isLeft?: boolean;
  color?: string;
  height?: number;
  width?: number;
}> = ({ isDisabled, isLeft = false, color, height = 14, width = 8 }) => {
  const theme = useTheme();
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={height}
      height={width}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      isLeft={isLeft}
    >
      <path
        d="M1 13L7 7L1 1"
        stroke={
          isDisabled
            ? theme.color.lightGray
            : color
            ? color
            : theme.color.purple500
        }
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default NextArrow;
