import * as React from "react";
import styled from "styled-components";
import { DrunkStartupsIdeaItem } from "./FundingModal";
import { FundingAmount } from "./FundingAmountSlider";
import { FundingIdea } from "./FundingCounter";

const InviteFriendsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 160%;
  color: ${(props) => props.theme.color.darkGray};
`;

const InviteFriendsBox = styled.div`
  width: 200px;

  & span {
    font-weight: 600;
    color: ${(props) => props.theme.color.purple500};
  }
`;

const FundSlider = styled.div`
  border-radius: 2px;
  width: 260px;
  border-radius: 2px;
  height: 6px;
  background: ${(props) => props.theme.color.green100};
`;

const FundSliderPointer = styled.div`
  background: ${(props) => props.theme.color.white};
  border: 4px solid ${(props) => props.theme.color.green500};
  height: 16px;
  width: 16px;
  box-sizing: border-box;
  margin-top: -6px;
  border-radius: 24px;
  margin-left: -7px;
`;

const InviteFriendsWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 20%;
`;

const DrunkStartupsIdeaSkeleton = styled.div`
  opacity: 0.3;
`;

const InviteTeammatesContainer = styled.div`
  position: relative;
  margin-bottom: 2rem;
`;

const DS_SAMPLE_IDEAS = [2, 3];

const InviteTeammates: React.FC = () => {
  return (
    <InviteTeammatesContainer>
      <DrunkStartupsIdeaSkeleton>
        {DS_SAMPLE_IDEAS.map((item) => (
          <DrunkStartupsIdeaItem key={item}>
            <FundingIdea>{item}</FundingIdea>
            <FundSlider>
              <FundSliderPointer></FundSliderPointer>
            </FundSlider>
            <FundingAmount amount={0}>00 mn</FundingAmount>
          </DrunkStartupsIdeaItem>
        ))}
      </DrunkStartupsIdeaSkeleton>

      <InviteFriendsWrapper>
        <InviteFriendsContainer>
          <InviteFriendsBox>
            <span>Invite your teammates</span> to make
            <div> multiple drunk startup teams</div>
          </InviteFriendsBox>
        </InviteFriendsContainer>
      </InviteFriendsWrapper>
    </InviteTeammatesContainer>
  );
};

export default InviteTeammates;
