const WYRIconDisabled: React.FC<{ isTooltip?: boolean }> = ({ isTooltip }) => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={isTooltip ? "white" : "#475766"}>
        <path
          clipRule="evenodd"
          d="m12 1.20001c-.6628 0-1.2.53726-1.2 1.2v5.00287c.3833-.09977.7854-.15288 1.2-.15288.4145 0 .8167.0531 1.2.15288v-5.00287c0-.66274-.5373-1.2-1.2-1.2zm1.2 7.24609v7.1078c-.3768.1272-.7803.1961-1.2.1961s-.8232-.0689-1.2-.1961v-7.10779c.3768-.12717.7803-.19611 1.2-.19611s.8232.06894 1.2.1961zm-2.4 8.151c.3833.0998.7854.1529 1.2.1529.4145 0 .8167-.0531 1.2-.1529v5.0029c0 .6628-.5373 1.2-1.2 1.2-.6628 0-1.2-.5372-1.2-1.2z"
          fillRule="evenodd"
        />
        <path d="m15.75 12c0 2.0711-1.6789 3.75-3.75 3.75-2.07107 0-3.75-1.6789-3.75-3.75 0-2.07107 1.67893-3.75 3.75-3.75 2.0711 0 3.75 1.67893 3.75 3.75z" />
        <g clipRule="evenodd" fillRule="evenodd">
          <path d="m14.0119 16.3041c-.1372.3898-.2119.8091-.2119 1.2459 0 2.0711 1.6789 3.75 3.75 3.75s3.75-1.6789 3.75-3.75-1.6789-3.75-3.75-3.75c-.4368 0-.8561.0747-1.2459.2119-.4714 1.0067-1.2855 1.8208-2.2922 2.2922z" />
          <path d="m9.98809 7.69588c.13721-.3898.21191-.80912.21191-1.24587 0-2.07107-1.67892-3.75-3.74999-3.75s-3.75 1.67893-3.75 3.75 1.67893 3.74999 3.75 3.74999c.43675 0 .85607-.0747 1.24587-.21191.47137-1.00669 1.28552-1.82084 2.29221-2.29221z" />
        </g>
      </g>
    </svg>
  );
};

export default WYRIconDisabled;
