import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useQuery } from "../../hooks";
import { setLoginStatusFailed } from "../../utils/loginStatus";
import { getUserDetails } from "../../store/features/userSlice";
import { useAppDispatch } from "../../store/hooks";
import React from "react";
import { getContactEmail, getFirstLastName } from "../../utils/freshMarketer";
import { RoutesEnum } from "../../routes/routes.enum";

const GoogleRedirect = () => {
  const query = useQuery();
  const code = String(query.get("code"));
  const state = String(query.get("state"));
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [formEmail, setFormEmail] = React.useState("");
  const handleFormSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (code) {
      let url = `${process.env.REACT_APP_API_IDENTIFIER}/auth/google/callback`;
      axios
        .get<any>(url, {
          params: { state: state, code: code },
          withCredentials: true,
        })
        .then(async (res) => {
          dispatch(getUserDetails());

          //if new user add to fresh marketer
          if (res?.data?.isNewSignup) {
            let contactEmail = res?.data?.userDetails?.user?.email;
            contactEmail = getContactEmail(contactEmail);
            setFormEmail(contactEmail);

            const orgUsers = res?.data?.organizationUsers;
            const isOrgCreator = orgUsers.some(
              (orgUser: { organization: { createdBy: string } }) =>
                orgUser?.organization?.createdBy ===
                res?.data?.userDetails?.userId
            );

            const nameObj = getFirstLastName(res?.data?.userDetails?.name);
            const contactProperties = {
              "First Name": nameObj.firstName,
              "Last Name": nameObj.lastName,
              Email: contactEmail,
              is_org_creator: isOrgCreator,
              on_production: process.env.REACT_APP_ENVIRONMENT === "production",
              on_staging: process.env.REACT_APP_ENVIRONMENT === "staging",
              on_development:
                process.env.REACT_APP_ENVIRONMENT === "development",
              has_signed_up: true,
              signup_date: res?.data?.userCreatedAt,
            };

            // add the contact to freshmarketer
            var identifier = contactEmail;
            window.fwcrm.identify(identifier, contactProperties);

            // simulate form submission to capture timezone details
            await document.getElementById("freshworks-submitBtn")?.click();
          }

          let s = JSON.parse(state);
          if (s?.RedirectRoute) {
            history.push(`/${s?.RedirectRoute}`);
          } else {
            history.push(RoutesEnum.DASHBOARD);
          }
        })
        .catch((err) => {
          setLoginStatusFailed();
          history.goBack();
        });
    }
  }, [code]);

  return (
    <>
      <form name="myform" id="myform" onSubmit={handleFormSubmit}>
        <input
          type="text"
          name="email"
          placeholder="email"
          hidden={true}
          value={formEmail}
        ></input>
        <button
          type="submit"
          id="freshworks-submitBtn"
          name="submitBtn"
          hidden={true}
        >
          Enter
        </button>
      </form>
    </>
  );
};

export default GoogleRedirect;
