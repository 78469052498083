import React from "react";
import { useTheme } from "styled-components";
import { ThemeColors } from "../types/activity";

const GridLayoutIcon: React.FC<{ size?: number; color?: string }> = ({
  size = 48,
  color = "purple",
}) => {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        d="M20 6H6V20H20V6Z"
        fill={theme.color[`${color}500` as ThemeColors]}
        stroke={theme.color[`${color}500` as ThemeColors]}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42 6H28V20H42V6Z"
        fill={theme.color[`${color}200` as ThemeColors]}
        stroke={theme.color[`${color}200` as ThemeColors]}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42 28H28V42H42V28Z"
        fill={theme.color[`${color}200` as ThemeColors]}
        stroke={theme.color[`${color}200` as ThemeColors]}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 28H6V42H20V28Z"
        fill={theme.color[`${color}200` as ThemeColors]}
        stroke={theme.color[`${color}200` as ThemeColors]}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GridLayoutIcon;
