import { FunctionComponent } from "react";
import styled from "styled-components";
import InputField from "../common/InputField";

interface NameInputProps {
  width?: string;
  value?: string;
  errorHandler?: ({
    isError,
    text,
  }: {
    isError: boolean;
    text: string;
  }) => void;
  handleTextChange: Function;
  isError?: boolean;
}

const NameInput: FunctionComponent<NameInputProps> = ({
  width,
  value,
  handleTextChange,
  errorHandler,
  isError = false,
}) => {
  const handleUserInput = (input: string) => {
    const err = {
      isError: false,
      text: "",
    };

    if (input.length > 0 && input.length < 30) {
      handleTextChange(input);
    } else if (input.length === 0) {
      handleTextChange(input);

      err.isError = true;
      err.text = "Team name cannot be empty";
    } else {
      err.isError = true;
      err.text = "Team name should be less than 30 characters";
    }

    errorHandler?.(err);
  };

  return (
    <InputField
      isError={isError}
      onInput={handleUserInput}
      value={value}
      placeholder="Your team name"
      width={width}
    />
  );
};

export default NameInput;
