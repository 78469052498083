import theme from "../theme";
import { ActivityEnum } from "../utils/activity.enum";

export interface Activity {
  id: string;
  name: ActivityEnum | IcebreakerEnum;
  recommendedGroupSize: number;
  recommendedDurationInMins: number;
  data?: any;
  type: ActivityType;
  isActive: boolean;
  isHidden: boolean;
}

export type ActivityType = "MIXER" | "ICEBREAKER";

export enum IcebreakerEnum {
  THIS_OR_THAT = "THIS_OR_THAT",
}

export enum IcebreakerNameEnum {
  THIS_OR_THAT = "This or that",
}

export type ThemeColors = keyof typeof theme.color;

export type MixerColors =
  | "red"
  | "blue"
  | "yellow"
  | "orange"
  | "green"
  | "purple";
