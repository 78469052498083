import axios from "axios";

export interface SlackInstallRedirectUrlInterface {
  organizationId: string;
  eventId?: string; // Optional. send if slack notification is to be sent
  organizationName?: string; // Optional. send if want to create new organization
  externalId?: string; // Optional. send if user wants to join existing organization
}

export interface ChannelsListItem {
  channelName: string;
  id: string;
}

export const getSlackInstallRedirectUrl = async ({
  organizationId,
  eventId,
}: SlackInstallRedirectUrlInterface) => {
  try {
    const response = await axios.get<string>(
      `${process.env.REACT_APP_API_IDENTIFIER}/slack/redirect-url?organizationId=${organizationId}&eventId=${eventId}`
    );
    return response.data;
  } catch (err) {
    console.error("err", err);
  }
};

interface SlackAppCallbackInterface {
  code: string;
  state: string;
}

export interface SlackCallbackResponseInterface {
  channelName: string;
  teamName: string;
}

export const slackAppCallback = async ({
  code,
  state,
}: SlackAppCallbackInterface): Promise<{
  data: SlackCallbackResponseInterface | false;
  status: number;
}> => {
  try {
    const response = await axios.post<SlackCallbackResponseInterface>(
      `${process.env.REACT_APP_API_IDENTIFIER}/slack/callback?code=${code}&state=${state}`,
      {}
    );
    if (response.status === 200) {
      return { data: response.data, status: response.status };
    } else {
      console.error("err", response);
      return { data: false, status: response.status };
    }
  } catch (err) {
    const { response = {} } = err as any;
    return { data: false, status: response?.status || 500 };
  }
};

export const slackItJoinSocial = async (eventId: string) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_IDENTIFIER}/slack/message/join-social?eventId=${eventId}`,
      {}
    );
    if (response.status === 200) {
      return true;
    } else {
      console.error("err", response);
      return false;
    }
  } catch (err) {
    console.error("err", err);
    return false;
  }
};

export const getSlackChannelsList = async ({
  organizationId,
}: SlackInstallRedirectUrlInterface) => {
  try {
    const response = await axios.get<ChannelsListItem[]>(
      `${process.env.REACT_APP_API_IDENTIFIER}/slack/channel?organizationId=${organizationId}`
    );
    return response.data;
  } catch (err) {
    console.error("err", err);
  }
};

export const createSlackChannel = async ({
  organizationId,
  eventId,
  organizationName,
}: SlackInstallRedirectUrlInterface) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_IDENTIFIER}/slack/channel?eventId=${eventId}`,
      {
        name: organizationName,
        organizationId: organizationId,
      }
    );

    if (response.status !== 200) {
      return false;
    }

    return true;
  } catch (err) {
    console.log("error", err);
    return false;
  }
};

export const joinExistingSlackChannel = async ({
  organizationId,
  eventId,
  externalId,
}: SlackInstallRedirectUrlInterface) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_IDENTIFIER}/slack/channel/join?eventId=${eventId}`,
      {
        externalId: externalId,
        organizationId: organizationId,
      }
    );

    if (response.status !== 200) {
      return false;
    }

    return true;
  } catch (err) {
    console.log("error", err);
    return false;
  }
};
