import { RosterAttendeeType } from "../hooks/useChime";

export const DOODLE_DRAW_TIME_PER_QUESTION = 22;

export const DOODLE_TIME_PER_QUESTION = 40;

export const DOODLE_SPLASH_TIMER = 7;

export type DrawData = {
  mouseX: number;
  mouseY: number;
  mouseIsPressed: boolean;
};

export const DOODLE_CHANNEL = "DOODLE";

export const DOODLE_VIDEO_RESOLUTION = 180;

export interface DoodleAttendee extends RosterAttendeeType {
  url?: string;
  score?: number;
  currentScore?: number;
  guessedWord?: string;
}

export const Doodle_Description =
  "You draw, A.I. guesses. Can you out-doodle everyone else?";

export const Doodle_Bg_Color = "yellow";
