import * as React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import amplitude from "amplitude-js";

import {
  addOrUpdateFunds,
  fetchAvailableFundings,
} from "../../API/drunkStartups";
import {
  FundDetailsMapInterface,
  selectFundDetails,
  selectTotalFundAmount,
  setFundDetails,
} from "../../store/features/DrunkStartup/drunkStartupSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import Spinner from "../common/Spinner";
import SpinningLoader from "../common/SpinningLoader";
import FundingAmountSlider from "./FundingAmountSlider";
import InviteTeammates from "./InviteTeammates";
import { ZIndexLevel } from "../../utils/zIndex.enum";
import FundingCounter from "./FundingCounter";
import { SegmentGroupIdsMapInterface } from "./DsBackToLoungeCanvas";
import { AmplitudeEvents } from "../../types/amplitudeEvents";

const Container = styled.div`
  position: absolute;
  color: black;
  top: 4.5rem;
  right: -3rem;
  z-index: ${ZIndexLevel.FUNDING_MODAL_INDEX};
  padding: 16px;
  width: 400px;
  background: ${(props) => props.theme.color.white};
  box-shadow: 0px 4px 16px -2px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  box-sizing: border-box;
`;

const CenterDivDrop = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  left: calc(50% - 32px / 2);
  top: -8px;
  background: ${(props) => props.theme.color.white};
  transform: rotate(45deg);
  border-radius: 5px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 160%;
  letter-spacing: -0.01em;
  color: ${(props) => props.theme.color.lightGray};

  display: flex;
  align-items: center;
  justify-content: flex-start;

  & span {
    margin-left: 5px;
    color: ${(props) => props.theme.color.yellow600};
  }
`;

const ContainerFooter = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const SubmitFundButton = styled(motion.button)`
  height: 56px;
  left: 0px;
  top: 26px;
  background: ${(props) => props.theme.color.purple500};
  border-radius: 12px;
  border: none;
  user-select: none;
  cursor: pointer;

  /* Button Bold */
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.01em;

  color: ${(props) => props.theme.color.white};
`;

const SummaryText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 160%;

  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  justify-content: center;
  margin-bottom: 8px;

  /* Neutrals/Space Grey */

  color: ${(props) => props.theme.color.darkGray};

  & span {
    color: ${(props) => props.theme.color.green500};
    margin: 0px 3px;
  }
`;

export const DrunkStartupsIdeaItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 13px 0px;
  padding: 10px 0px;
  position: relative;
`;

interface FundingModalProps {
  segmentId?: string;
  segmentGroupIdsMap?: SegmentGroupIdsMapInterface;
  setShowFundingModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface FundedIdeasItemInterface {
  amount: number;
  segment_group_id: string;
  participants?: Array<string>;
  index?: number;
}

const FundingModal: React.FC<FundingModalProps> = ({
  segmentId = "",
  segmentGroupIdsMap = {},
  setShowFundingModal,
}) => {
  const dispatch = useAppDispatch();
  const totalFundAmount = useAppSelector(selectTotalFundAmount);
  const [fundingsData, setFundingsData] =
    React.useState<SegmentGroupIdsMapInterface>({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [isButtonPressed, setIsButtonPressed] = React.useState(false);
  const [isButtonTapped, setIsButtonTapped] = React.useState(false);

  const fundDetails = useAppSelector(selectFundDetails);
  const [tentativeFunds, setTentativeFunds] = React.useState<
    FundedIdeasItemInterface[]
  >([]);

  React.useEffect(() => {
    (async () => {
      const resData: any = await fetchAvailableFundings(segmentId);
      const updatedFundingsData = Object.keys(segmentGroupIdsMap).reduce(
        (acc: SegmentGroupIdsMapInterface, curr: string) => {
          const getAmountFromResData =
            resData &&
            resData.find(
              (data: FundedIdeasItemInterface) => data.segment_group_id === curr
            );
          const amount =
            (getAmountFromResData && getAmountFromResData.amount) || 0;
          return {
            ...acc,
            [curr]: {
              ...segmentGroupIdsMap[curr],
              amount,
              segment_group_id: curr,
            },
          };
        },
        {}
      );
      setFundingsData(updatedFundingsData);
    })();
  }, []);

  React.useEffect(() => {
    const tentativeFundsArr = Object.values(
      fundingsData
    ) as FundedIdeasItemInterface[];
    setTentativeFunds(tentativeFundsArr);

    const updatedTentativeFunds = tentativeFundsArr.map(
      ({ amount, segment_group_id }) => {
        return { amount, segment_group_id };
      }
    );

    const updatedFundData = updatedTentativeFunds.reduce(
      (acc: FundDetailsMapInterface, curr: FundedIdeasItemInterface) => {
        return {
          ...acc,
          [curr.segment_group_id]: {
            ...curr,
          },
        };
      },
      {}
    );
    dispatch(setFundDetails(updatedFundData));
  }, [fundingsData]);

  const onSubmitFundClickHandler = async () => {
    if (isLoading) return;
    setIsLoading(true);

    const fundDetailsArray = Object.values(fundDetails);

    await addOrUpdateFunds(segmentId, fundDetailsArray);
    setIsLoading(false);
    setShowFundingModal(false);
    amplitude.getInstance().logEvent(AmplitudeEvents.DS_FUND_SUBMITTED);
  };

  return (
    <Container>
      <CenterDivDrop />
      <Title>
        Fund some <span>Drunk startups</span>
      </Title>

      {Object.entries(fundingsData).length === 0 && (
        <SpinningLoader width={"370px"} height={"188px"} />
      )}

      {Object.entries(fundingsData).map(
        ([segmentGroupId, { participants = [], amount = 0, index }]) => (
          <DrunkStartupsIdeaItem key={segmentGroupId}>
            <FundingCounter mixerBuddies={participants}>{index}</FundingCounter>
            <FundingAmountSlider
              segmentGroupId={segmentGroupId}
              tentativeFunds={tentativeFunds}
              setTentativeFunds={setTentativeFunds}
            />
          </DrunkStartupsIdeaItem>
        )
      )}

      {Object.entries(fundingsData).length === 1 && <InviteTeammates />}

      <ContainerFooter>
        <SummaryText>
          {Object.entries(fundingsData).length === 1 ? (
            <div>You have no competition. It's a total monopoly!</div>
          ) : (
            <div>
              You have a total of <span>{totalFundAmount} million </span>
              left to fund the best ideas &#59;&#41;
            </div>
          )}
        </SummaryText>
        <SubmitFundButton
          animate={{
            scale: isButtonPressed || isButtonTapped ? 0.94 : 1,
          }}
          onTap={() => {
            setIsButtonTapped(true);
            setTimeout(() => {
              setIsButtonTapped(false);
            }, 100);
          }}
          onMouseDown={() => setIsButtonPressed(true)}
          onMouseUp={() => setIsButtonPressed(false)}
          onMouseLeave={() => setIsButtonPressed(false)}
          onClick={() => onSubmitFundClickHandler()}
        >
          {isLoading ? (
            <Spinner size={24} opacity="00" border={4} borderOpacity={255} />
          ) : (
            <>Fund it!</>
          )}
        </SubmitFundButton>
      </ContainerFooter>
    </Container>
  );
};

export default FundingModal;
