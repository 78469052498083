import { captureMessage, Severity } from "@sentry/react";
import React from "react";
import styled from "styled-components";
import { ActionButton } from ".";
import { motion } from "framer-motion";
import { googleRedirectApi } from "../../API/invite";
import GoogleIcon from "../../assets/GoogleIcon";
import ScheduleIcon from "../../assets/ScheduleNew";
import SlackIcon from "../../assets/SlackNew";
import TeamsIcon from "../../assets/TeamsNew";
import { useQuery } from "../../hooks";
import { SentryErrorLogMessages } from "../../types/SentryErrorMessages";
import { checkLoginStatus, clearLoginStatus } from "../../utils/loginStatus";
import { SignInText } from "./LoginComponent";
import { AmplitudeEvents } from "../../types/amplitudeEvents";
import amplitude from "amplitude-js";

const CreateAccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  gap: 8px;
  width: 320px;
  height: 251px;
  background: #ffffff;
  box-shadow: 0px 4px 16px -2px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
`;

const SomethingWentWrongContainer = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  color: ${({ theme }) => theme.color.darkGray};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 36px;
  border-bottom: 2px solid ${({ theme }) => theme.lounge.background};
  margin-bottom: 32px;
`;

const MainHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 16px 0px 8px 16px;
`;
const SubHeading = styled.div`
  height: 32px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 160%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #2f373d;
`;
const InfoContainer = styled.div<{
  isGuest?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 6px;
  gap: 8px;
  height: 22px;
  background: ${(props) => (props.isGuest ? "#FDF2EA" : "#E9F7F3")};
  border-radius: 4px;
  margin-left: 8px;
`;
const InfoText = styled.div<{
  isGuest?: boolean;
}>`
  width: ${(props) => (props.isGuest ? "61px" : "27px")};
  height: 18px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 160%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: ${(props) => (props.isGuest ? "#ED7B2A" : "#1EB482")};
`;

const InformationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-left: 16px;
  height: 26px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: -0.01em;
  color: #475766;
`;

const GuestLoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  gap: 8px;
  width: 320px;
  height: 251px;
  background: #ffffff;
  box-shadow: 0px 4px 16px -2px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
`;

const SignInButton = styled(ActionButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.lounge.background};
`;

const GuestSignInButtonContainer = styled(ActionButton)<{
  pressed: boolean;
  isError?: boolean;
}>`
  background: #6633ff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 88px;
  height: 56px;
  border-radius: 16px;
  margin-top: 0px;
  box-shadow: ${({ pressed }) =>
    pressed
      ? "0px 2px 2px -2px rgba(0, 0, 0, 0.12)"
      : "0px 2px 4px -1px rgba(0, 0, 0, 0.12)"};
  &:hover {
    background: ${(props) => props.theme.color.purple600};
  }
`;

const GuestSigninText = styled.div`
  width: 140px;
  height: 26px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;
  color: #ffffff;
`;

const AdvantageContainer = styled.div<{ isGuest: boolean }>`
  display: flex;
  margin-left: 16px;
  margin-top: 10px;
  margin-bottom: 24px;
  filter: ${(props) => (props.isGuest ? "grayscale(1)" : "grayscale(0)")};
  opacity: ${(props) => (props.isGuest ? "0.24" : "")};
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 24px;
`;

const AdvantageText = styled.div`
  width: 80px;
  height: 21px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 160%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #2f373d;
  margin-top: 7px;
`;

const ButtonContainer = styled(motion.div)<{
  pressed: boolean;
  isError?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  height: 26px;
  background: ${(props) => props.theme.lounge.background};
  box-shadow: ${({ pressed }) =>
    pressed
      ? "0px 2px 2px -2px rgba(0, 0, 0, 0.12)"
      : "0px 2px 4px -1px rgba(0, 0, 0, 0.12)"};
  z-index: 4;
  border-radius: 16px;
  margin: ${({ isError }) => (isError ? "32px 0 0" : "0px 20px 0px 20px")};
  &:hover {
    background: ${(props) => props.theme.lounge.backgroundHover};
    ${SignInButton} {
      background: ${(props) => props.theme.lounge.backgroundHover};
    }
  }
`;

const UserSignInButtonContainer = styled.div``;

interface SignUpContainerProps {
  redirect: string;
  isGuestLogin: boolean;
  organizationCode?: string;
  signupFrom: string;
}

const SignUpContainer: React.FC<SignUpContainerProps> = ({
  organizationCode = "",
  redirect,
  isGuestLogin,
  signupFrom,
}) => {
  const query = useQuery();
  const emailIdentifier = query.get("email_identifier") || "";

  const [isButtonPressed, setIsButtonPressed] = React.useState(false);
  const [isButtonTapped, setIsButtonTapped] = React.useState(false);
  const [isLoginFailed, setIsLoginFailed] = React.useState(false);

  const handleButtonMouseTap = () => {
    setIsButtonTapped(true);
    setTimeout(() => {
      setIsButtonTapped(false);
    }, 100);
  };

  const handleAmplitude = () => {
    if (signupFrom === "guestlogin") {
      amplitude.getInstance().logEvent(AmplitudeEvents.GUEST_LOGIN);
    } else if (signupFrom === "signup") {
      amplitude.getInstance().logEvent(AmplitudeEvents.SIGNUP_GOOGLE);
    } else if (signupFrom === "mysocial") {
      amplitude
        .getInstance()
        .logEvent(AmplitudeEvents.CREATE_AN_ACCOUNT_MY_SOCIALS);
    } else if (signupFrom === "teams") {
      amplitude.getInstance().logEvent(AmplitudeEvents.CREATE_AN_ACCOUNT_TEAMS);
    } else if (signupFrom === "schedulebutton") {
      amplitude
        .getInstance()
        .logEvent(AmplitudeEvents.SIGNUP_FROM_SCHEDULE_BUTTONS);
    }
  };

  React.useEffect(() => {
    const isLoginFailing = checkLoginStatus();

    if (isLoginFailing) {
      clearLoginStatus();

      // Log to sentry
      captureMessage(
        SentryErrorLogMessages.GoogleRedirectFailed,
        Severity.Error
      );
    }
  }, []);

  return (
    <CreateAccountContainer>
      <MainHeading>
        <SubHeading>
          {isGuestLogin ? `Try it out` : `Create an account`}
        </SubHeading>
        <InfoContainer isGuest={isGuestLogin}>
          <InfoText isGuest={isGuestLogin}>
            {isGuestLogin ? `NO SIGNUP` : `FREE`}
          </InfoText>
        </InfoContainer>
      </MainHeading>
      <InformationContainer>
        {isGuestLogin
          ? `Skip the hassle of signing up.`
          : `Get added benefits by signing up.`}
      </InformationContainer>
      <AdvantageContainer isGuest={isGuestLogin}>
        <IconContainer>
          <ScheduleIcon />
          <AdvantageText>Schedule</AdvantageText>
        </IconContainer>
        <IconContainer>
          <SlackIcon />
          <AdvantageText>Slack bot</AdvantageText>
        </IconContainer>
        <IconContainer>
          <TeamsIcon />
          <AdvantageText>Teams</AdvantageText>
        </IconContainer>
      </AdvantageContainer>
      {isGuestLogin ? (
        <GuestSignInButtonContainer
          isError={isLoginFailed}
          pressed={isButtonPressed || isButtonTapped}
          animate={{
            scale: isButtonPressed || isButtonTapped ? 0.9 : 1,
          }}
          onClick={() => {
            handleAmplitude();
            window.location.href = `${process.env.REACT_APP_BASE_URL}/${redirect}`;
          }}
          onTap={handleButtonMouseTap}
          onMouseDown={() => setIsButtonPressed(true)}
          onMouseUp={() => setIsButtonPressed(false)}
          onMouseLeave={() => setIsButtonPressed(false)}
        >
          <GuestSigninText>Continue as guest</GuestSigninText>
        </GuestSignInButtonContainer>
      ) : (
        <UserSignInButtonContainer>
          <ButtonContainer
            isError={isLoginFailed}
            pressed={isButtonPressed || isButtonTapped}
            animate={{
              scale: isButtonPressed || isButtonTapped ? 0.9 : 1,
            }}
          >
            <SignInButton
              width={282}
              onClick={async () => {
                handleAmplitude();
                const success = await googleRedirectApi({
                  organizationCode,
                  redirect,
                  emailIdentifier,
                });
                if (!success) {
                  setIsLoginFailed(true);
                }
              }}
              onTap={handleButtonMouseTap}
              onMouseDown={() => setIsButtonPressed(true)}
              onMouseUp={() => setIsButtonPressed(false)}
              onMouseLeave={() => setIsButtonPressed(false)}
            >
              <GoogleIcon />
              <SignInText>Sign up with Google </SignInText>
            </SignInButton>
          </ButtonContainer>
        </UserSignInButtonContainer>
      )}
    </CreateAccountContainer>
  );
};

export default SignUpContainer;
