import { FunctionComponent } from "react";

interface WarningIconProps {}

const WarningIcon: FunctionComponent<WarningIconProps> = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.9734 24.0003L18.3067 5.33361C18.0741 4.92322 17.7368 4.58186 17.3293 4.34437C16.9217 4.10688 16.4584 3.98175 15.9867 3.98175C15.515 3.98175 15.0517 4.10688 14.6441 4.34437C14.2366 4.58186 13.8993 4.92322 13.6667 5.33361L3.00003 24.0003C2.76494 24.4074 2.64167 24.8695 2.64271 25.3396C2.64375 25.8098 2.76907 26.2713 3.00596 26.6774C3.24285 27.0835 3.58289 27.4197 3.99162 27.6521C4.40035 27.8844 4.86324 28.0045 5.33336 28.0003H26.6667C27.1346 27.9998 27.5941 27.8762 27.9991 27.642C28.4041 27.4077 28.7403 27.0711 28.974 26.6658C29.2078 26.2605 29.3307 25.8008 29.3306 25.3329C29.3305 24.8651 29.2073 24.4055 28.9734 24.0003Z"
        fill="#FFC200"
        stroke="#FFC200"
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 12V17.3333"
        stroke="black"
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 22.6667H16.0133"
        stroke="black"
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WarningIcon;
