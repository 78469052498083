import React from "react";
import { useParams } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import amplitude from "amplitude-js";

import InformationIcon from "../../assets/InformationIcon";
import ThursdayLoginLogo from "../../assets/ThursdayLoginLogo";
import DsViewOnlyCanvas from "../../components/DrunkStartup/DsViewOnlyCanvas";
import BgLineArt from "../../assets/BgLineArt.png";
import bottomArt from "../../assets/bottom.png";
import { useQuery, useWindowSize } from "../../hooks";
import { fetchDsSharePageCanvases } from "../../API/drunkStartups";
import {
  ResDataInterface,
  SegmentGroupIdsMapInterface,
} from "../../components/DrunkStartup/DsBackToLoungeCanvas";
import { AmplitudeEvents } from "../../types/amplitudeEvents";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.color.yellow500};
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

const Header = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: ${(props) => (props.isMobile ? "absolute" : "static")};
  bottom: 0;
  margin-bottom: 18px;
  z-index: 1;
`;

const Logo = styled.div`
  display: flex;
  align-items: baseline;
  font-family: Labil;
  font-weight: 700;
  font-size: 28px;
  line-height: 160%;
  font-feature-settings: "salt" on, "ss02" on;
  color: ${(props) => props.theme.color.black};
  margin-top: 32px;
  cursor: pointer;
`;

const Subtext = styled.div`
  display: flex;
  align-items: flex-end;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  text-align: center;
  color: ${(props) => props.theme.color.black};
  margin-top: 2px;
`;

const SubtextLogoContainer = styled.div`
  display: flex;
  align-items: flex-end;
  cursor: pointer;
`;

const Text = styled.div`
  margin-right: 4px;
`;

const BackgroundArt = styled.div<{ bg: string }>`
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  bottom: 0;
  background: url(${(props) => props.bg});
  background-size: contain;
  pointer-events: none;
  z-index: 0;
`;

const FooterArt = styled.div<{ bg: string }>`
  width: 100vw;
  height: 400px;
  position: absolute;
  bottom: 0;
  background: url(${(props) => props.bg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: bottom;
  pointer-events: none;
`;

const DrunkStartups: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const query = useQuery();
  const theme = useTheme();
  const { isMobile } = useWindowSize();
  const [activeSegmentGroup, setActiveSegmentGroup] = React.useState(
    +(query.get("i") || "0") || 0
  );
  const [initialDocument, setInitialDocument] = React.useState({});
  // TODO: DrunkStartupsV1.0: Add type
  const [roomStorage, setRoomStorage] = React.useState<any[]>([]);
  const [promptText, setPromptText] = React.useState("");
  const [mixerBuddies, setMixerBuddies] = React.useState([]);
  const canvasRef = React.useRef<HTMLDivElement>(null);
  const [segmentGroupIdsMap, setSegmentGroupIdsMap] =
    React.useState<SegmentGroupIdsMapInterface>({});

  React.useEffect(() => {
    amplitude.getInstance().logEvent(AmplitudeEvents.DS_SHARE_PAGE_VISITED);
  }, []);

  React.useEffect(() => {
    (async () => {
      if (id) {
        const data = await fetchDsSharePageCanvases(id);
        if (data) {
          setRoomStorage(data.roomData);
          setPromptText(data.promptText?.[0] || "");
          const segmentGroupIds = data.roomData.reduce(
            (
              acc: SegmentGroupIdsMapInterface,
              { segmentGroupId, participants }: ResDataInterface,
              currentIndex: number
            ) => {
              return {
                ...acc,
                [segmentGroupId]: {
                  participants: participants,
                  index: currentIndex + 1,
                },
              };
            },
            {}
          );
          setSegmentGroupIdsMap(segmentGroupIds);
        }
      }
    })();
  }, [id]);

  React.useEffect(() => {
    if (roomStorage.length) {
      setMixerBuddies(roomStorage[activeSegmentGroup]?.participants);
      setInitialDocument({
        id: "doc",
        name: "New Document",
        version: roomStorage[activeSegmentGroup]?.roomData.data.version,
        pages: {
          page: {
            id: "page",
            name: "Page",
            childIndex: 1,
            shapes:
              roomStorage[activeSegmentGroup]?.roomData.data.shapes.data || {},
            bindings:
              roomStorage[activeSegmentGroup]?.roomData.data.bindings.data ||
              {},
          },
        },
        pageStates: {
          page: {
            id: "page",
            selectedIds: [],
            camera: {
              point: [64, -15],
              zoom: 1,
            },
            editingId: null,
          },
        },
        assets:
          roomStorage[activeSegmentGroup]?.roomData.data.assets.data || {},
      });
    }
  }, [activeSegmentGroup, roomStorage]);

  return (
    <Container>
      <Header isMobile={isMobile}>
        <Logo
          onClick={() => {
            window.location.href = `${process.env.REACT_APP_MARKETING_URL}/drunk-startups`;
          }}
        >
          <Text>Drunk startups</Text>
          <InformationIcon color={theme.color.yellow200} />
        </Logo>
        <Subtext>
          <Text>A team building mixer by</Text>
          <SubtextLogoContainer
            onClick={() => {
              window.location.href = `${process.env.REACT_APP_MARKETING_URL}`;
            }}
          >
            <ThursdayLoginLogo width={85} height={24} />
          </SubtextLogoContainer>
        </Subtext>
      </Header>
      <DsViewOnlyCanvas
        activeSegmentGroup={activeSegmentGroup}
        setActiveSegmentGroup={setActiveSegmentGroup}
        roomStorage={roomStorage}
        mixerBuddies={mixerBuddies}
        canvasRef={canvasRef}
        initialDocument={initialDocument}
        showFundThis={false}
        showTeam
        showPrompt
        isMobile={isMobile}
        width="73%"
        segmentGroupIdsMap={segmentGroupIdsMap}
        promptText={promptText}
        dsSegmentId={id}
      />
      <BackgroundArt bg={BgLineArt} />
      <FooterArt bg={bottomArt} hidden={isMobile} />
    </Container>
  );
};

export default DrunkStartups;
