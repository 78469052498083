import { FunctionComponent, MouseEventHandler } from "react";
import styled from "styled-components";

interface TextBtnProps {
  onClickHandler?: MouseEventHandler;
  color?: string;
  hoverColor?: string;
  fntWeight?: string;
  fntSize?: string;
}

const MainContainer = styled.span<{
  color?: string;
  hoverColor?: string;
  fntWeight: string;
  fntSize: string;
}>`
  font-weight:${({ fntWeight }) => fntWeight};
  font-size: ${({ fntSize }) => fntSize};
  line-height: 160%;
  color: ${({ theme, color }) => color || theme.color.darkGray};
  cursor: pointer;
  text-align: center;

  &:hover {
    text-decoration: underline;
    color: ${({ theme, hoverColor }) => hoverColor || theme.color.black};
  }, 
`;

const TextBtn: FunctionComponent<TextBtnProps> = ({
  children,
  onClickHandler,
  color,
  hoverColor,
  fntWeight = "500",
  fntSize,
}) => {
  return (
    <MainContainer
      fntSize={fntSize || "16px"}
      hoverColor={hoverColor}
      color={color}
      onClick={onClickHandler}
      fntWeight={fntWeight}
    >
      {children}
    </MainContainer>
  );
};

export default TextBtn;
