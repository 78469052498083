const SlashCommandIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.2031 4.5H3.79688C3.35677 4.5 3 4.85677 3 5.29688V18.7031C3 19.1432 3.35677 19.5 3.79688 19.5H20.2031C20.6432 19.5 21 19.1432 21 18.7031V5.29688C21 4.85677 20.6432 4.5 20.2031 4.5Z"
        fill="#ED7B2A"
        stroke="#ED7B2A"
        strokeWidth="2.12125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 8.104L7.5 15.604"
        stroke="#FBE5D4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.75 15H16.5"
        stroke="#FBE5D4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SlashCommandIcon;
