import React, { useEffect } from "react";
import { Route, useLocation } from "react-router-dom";
import {
  loginStatus,
  selectOrgDetails,
  selectUserLoginStatus,
} from "../../store/features/userSlice";
import { useAppSelector } from "../../store/hooks";

interface MemoizedRedirectionProps {
  Component: React.FC;
  path: string;
  exact?: boolean;
}

const MemoizedRedirection: React.FunctionComponent<MemoizedRedirectionProps> =
  ({ Component, ...args }) => {
    const location = useLocation();
    const userLoginStatus = useAppSelector(selectUserLoginStatus);
    const orgDetails = useAppSelector(selectOrgDetails);

    useEffect(() => {
      const redirectLink = sessionStorage.getItem("redirectAfterLogin");
      const skipAddingSession =
        userLoginStatus === loginStatus.LOGGED_IN && orgDetails.length > 0;

      if (userLoginStatus === loginStatus.LOGGED_IN && orgDetails.length == 0) {
        return;
      }

      if (redirectLink === location.pathname + location.search) {
        sessionStorage.removeItem("redirectAfterLogin");
      } else if (skipAddingSession) {
        sessionStorage.removeItem("redirectAfterLogin");
      } else {
        sessionStorage.setItem(
          "redirectAfterLogin",
          location.pathname + location.search
        );
      }
    }, [userLoginStatus, orgDetails]);

    return <Route render={() => <Component />} {...args} />;
  };

export default MemoizedRedirection;
