import React from "react";

const SlackIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.76088 0.059082C8.44678 0.0593737 8.13582 0.121537 7.84575 0.242021C7.55567 0.362506 7.29218 0.538951 7.0703 0.761281C6.84842 0.983611 6.67252 1.24747 6.55263 1.53779C6.43273 1.8281 6.3712 2.1392 6.37155 2.4533C6.37127 2.76737 6.43283 3.07842 6.55275 3.36869C6.67267 3.65895 6.84858 3.92275 7.07045 4.14504C7.29233 4.36732 7.5558 4.54372 7.84585 4.66419C8.1359 4.78464 8.44683 4.84679 8.76088 4.84709H11.1502V2.4533C11.1506 2.1392 11.0891 1.8281 10.9691 1.53779C10.8493 1.24747 10.6734 0.983611 10.4515 0.761281C10.2296 0.538951 9.9661 0.362506 9.67603 0.242021C9.38597 0.121537 9.075 0.0593737 8.76088 0.059082ZM8.76088 6.44352H2.38933C2.07527 6.44382 1.76433 6.50597 1.47429 6.62642C1.18425 6.74689 0.92077 6.92329 0.6989 7.14557C0.47703 7.36785 0.301115 7.63165 0.181197 7.92192C0.0612798 8.21219 -0.000290473 8.52324 1.488e-06 8.8373C-0.000348882 9.1514 0.0611783 9.4625 0.18107 9.75282C0.30096 10.0431 0.476867 10.307 0.698743 10.5293C0.920618 10.7517 1.18412 10.9281 1.47419 11.0486C1.76427 11.1691 2.07523 11.2312 2.38933 11.2315H8.76088C9.075 11.2312 9.38597 11.1691 9.67603 11.0486C9.9661 10.9281 10.2296 10.7517 10.4515 10.5293C10.6734 10.307 10.8493 10.0431 10.9691 9.75282C11.0891 9.4625 11.1506 9.1514 11.1502 8.8373C11.1505 8.52324 11.0889 8.21219 10.969 7.92192C10.8491 7.63165 10.6732 7.36785 10.4513 7.14557C10.2294 6.92329 9.96598 6.74689 9.67593 6.62642C9.38588 6.50597 9.07495 6.44382 8.76088 6.44352Z"
        fill="#36C5F0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.8934 8.8373C23.8937 8.52324 23.8321 8.21219 23.7122 7.92192C23.5922 7.63165 23.4164 7.36785 23.1946 7.14557C22.9726 6.92329 22.7091 6.74689 22.4191 6.62642C22.1291 6.50597 21.8181 6.44382 21.5041 6.44352C21.1901 6.44382 20.8791 6.50597 20.5891 6.62642C20.2989 6.74689 20.0356 6.92329 19.8136 7.14557C19.5917 7.36785 19.4159 7.63165 19.2959 7.92192C19.1761 8.21219 19.1144 8.52324 19.1147 8.8373V11.2315H21.5041C21.8182 11.2312 22.1291 11.1691 22.4192 11.0486C22.7092 10.9281 22.9727 10.7517 23.1947 10.5293C23.4166 10.307 23.5924 10.0431 23.7124 9.75282C23.8322 9.4625 23.8937 9.1514 23.8934 8.8373ZM17.5219 8.8373V2.4533C17.5222 2.1392 17.4607 1.8281 17.3407 1.53779C17.2209 1.24747 17.0449 0.983611 16.8231 0.761281C16.6012 0.538951 16.3377 0.362506 16.0476 0.242021C15.7576 0.121537 15.4466 0.0593737 15.1325 0.059082C14.8184 0.0593737 14.5074 0.121537 14.2174 0.242021C13.9273 0.362506 13.6638 0.538951 13.4419 0.761281C13.22 0.983611 13.0441 1.24747 12.9242 1.53779C12.8043 1.8281 12.7428 2.1392 12.7432 2.4533V8.8373C12.7428 9.1514 12.8043 9.4625 12.9242 9.75282C13.0441 10.0431 13.22 10.307 13.4419 10.5293C13.6638 10.7517 13.9273 10.9281 14.2174 11.0486C14.5074 11.1691 14.8184 11.2312 15.1325 11.2315C15.4466 11.2312 15.7576 11.1691 16.0476 11.0486C16.3377 10.9281 16.6012 10.7517 16.8231 10.5293C17.0449 10.307 17.2209 10.0431 17.3407 9.75282C17.4607 9.4625 17.5222 9.1514 17.5219 8.8373Z"
        fill="#2EB67D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1325 24.0001C15.4466 23.9998 15.7576 23.9376 16.0476 23.8171C16.3377 23.6966 16.6012 23.5203 16.8231 23.2979C17.0449 23.0756 17.2209 22.8118 17.3407 22.5214C17.4607 22.2311 17.5222 21.9199 17.5219 21.6059C17.5221 21.2918 17.4606 20.9808 17.3406 20.6904C17.2207 20.4003 17.0447 20.1364 16.8229 19.9141C16.6011 19.6919 16.3376 19.5154 16.0475 19.3949C15.7575 19.2746 15.4466 19.2124 15.1325 19.2121H12.7432V21.6059C12.7428 21.9199 12.8043 22.2311 12.9242 22.5214C13.0441 22.8118 13.22 23.0756 13.4419 23.2979C13.6638 23.5203 13.9273 23.6966 14.2174 23.8171C14.5074 23.9376 14.8184 23.9998 15.1325 24.0001ZM15.1325 17.6156H21.5041C21.8181 17.6154 22.1291 17.5533 22.4191 17.4328C22.7091 17.3123 22.9726 17.1359 23.1946 16.9136C23.4164 16.6913 23.5922 16.4275 23.7122 16.1372C23.8321 15.847 23.8937 15.5359 23.8934 15.2219C23.8937 14.9078 23.8322 14.5967 23.7124 14.3064C23.5924 14.016 23.4166 13.7522 23.1947 13.5298C22.9727 13.3075 22.7092 13.1311 22.4192 13.0106C22.1291 12.8901 21.8182 12.8279 21.5041 12.8276H15.1325C14.8184 12.8279 14.5074 12.8901 14.2174 13.0106C13.9273 13.1311 13.6638 13.3075 13.4419 13.5298C13.22 13.7522 13.0441 14.016 12.9242 14.3064C12.8043 14.5967 12.7428 14.9078 12.7432 15.2219C12.7429 15.5359 12.8044 15.847 12.9244 16.1372C13.0443 16.4275 13.2202 16.6913 13.4421 16.9136C13.6639 17.1359 13.9274 17.3123 14.2175 17.4328C14.5075 17.5533 14.8184 17.6154 15.1325 17.6156Z"
        fill="#ECB22E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.02511e-06 15.2219C-0.000289937 15.5359 0.0612803 15.847 0.181198 16.1372C0.301115 16.4275 0.47703 16.6913 0.6989 16.9136C0.920772 17.1359 1.18425 17.3123 1.4743 17.4328C1.76433 17.5533 2.07527 17.6154 2.38933 17.6156C2.7034 17.6154 3.01433 17.5533 3.30438 17.4328C3.59442 17.3123 3.8579 17.1359 4.07977 16.9136C4.30163 16.6913 4.47755 16.4275 4.59747 16.1372C4.71738 15.847 4.77897 15.5359 4.77867 15.2219V12.8276H2.38933C2.0752 12.8279 1.7642 12.8901 1.47409 13.0106C1.18399 13.1311 0.920468 13.3076 0.698587 13.53C0.476705 13.7524 0.300807 14.0163 0.180942 14.3067C0.0610773 14.597 -0.000406765 14.9082 2.02511e-06 15.2223V15.2219ZM6.37155 15.2219V21.6059C6.3712 21.9199 6.43273 22.2311 6.55263 22.5214C6.67252 22.8118 6.84842 23.0756 7.0703 23.2979C7.29218 23.5203 7.55567 23.6966 7.84575 23.8171C8.13582 23.9376 8.44678 23.9998 8.76088 24.0001C9.075 23.9998 9.38597 23.9376 9.67603 23.8171C9.9661 23.6966 10.2296 23.5203 10.4515 23.2979C10.6734 23.0756 10.8493 22.8118 10.9692 22.5214C11.0891 22.2311 11.1506 21.9199 11.1502 21.6059V15.2223C11.1506 14.9082 11.0891 14.5971 10.9692 14.3068C10.8493 14.0165 10.6734 13.7526 10.4515 13.5303C10.2296 13.308 9.9661 13.1315 9.67603 13.011C9.38597 12.8905 9.075 12.8284 8.76088 12.8281C8.44678 12.8284 8.13582 12.8905 7.84575 13.011C7.55567 13.1315 7.29218 13.308 7.0703 13.5303C6.84842 13.7526 6.67252 14.0165 6.55263 14.3068C6.43273 14.5971 6.3712 14.9082 6.37155 15.2223"
        fill="#E01E5A"
      />
    </svg>
  );
};

export default SlackIcon;
