import { FunctionComponent } from "react";
import styled, { useTheme } from "styled-components";
import LaptopIcon from "../../assets/LaptopIcon";
import { RoutesEnum } from "../../routes/routes.enum";
import { selectIsCoHost } from "../../store/features/eventSlice";
import { useAppSelector } from "../../store/hooks";
import { handleInAppRedirection } from "../../utils/handleInAppRedirections";
import { LocalStorageKeys } from "../../utils/localstorage";
import TextBtn from "../common/TextBtn";
import { MeetingStatusBox, MeetingStatusIcon } from "./Elements";

interface JoinSocialArchivedViewsProps {}

const ArchivedMeetingMessage = styled.div`
  font-weight: 400;
  font-size: 13px;
  color: ${({ theme }) => theme.color.black};
  margin-top: 8px;
  text-align: center;
  cursor: default;
`;

const JoinSocialArchivedViews: FunctionComponent<JoinSocialArchivedViewsProps> =
  () => {
    const theme = useTheme();
    const isCoHost = useAppSelector(selectIsCoHost);

    const handleUnarchiveClick = () => {
      localStorage.setItem(LocalStorageKeys.MySocialView, "archived");
      handleInAppRedirection(RoutesEnum.MY_SOCIAL);
    };

    return (
      <>
        {" "}
        <MeetingStatusBox>
          <MeetingStatusIcon>
            <LaptopIcon />
          </MeetingStatusIcon>
          This social has been archived
          {isCoHost ? "" : " by the hosts"}
        </MeetingStatusBox>
        <ArchivedMeetingMessage>
          {isCoHost ? (
            <>
              Want to{" "}
              <TextBtn
                color={theme.color.purple500}
                hoverColor={theme.color.purple600}
                fntSize="inherit"
                onClickHandler={handleUnarchiveClick}
              >
                unarchive
              </TextBtn>{" "}
              it?
            </>
          ) : (
            <>
              It was fun while it lasted. Time for a{" "}
              <TextBtn
                color={theme.color.purple500}
                hoverColor={theme.color.purple600}
                fntSize="inherit"
                onClickHandler={() =>
                  handleInAppRedirection(RoutesEnum.DASHBOARD)
                }
              >
                new social
              </TextBtn>
              ?
            </>
          )}
        </ArchivedMeetingMessage>
      </>
    );
  };

export default JoinSocialArchivedViews;
