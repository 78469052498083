export enum ActivityEnum {
  VIDEO_MEETING = "VIDEO_MEETING",
  LOUNGE = "LOUNGE",
  WOULD_YOU_RATHER = "WOULD_YOU_RATHER",
  TRIVIA = "TRIVIA",
  DOODLE_RACE = "DOODLE",
  RAPID_RENDEZVOUS = "RAPID_RENDEZVOUS",
  MINDFUL_TALK = "MINDFUL_TALK",
  KNOW_YOUR_TEAM = "KNOW_YOUR_TEAM",
  CHARADES = "CHARADES",
  TWO_TRUTHS_AND_A_LIE = "TWO_TRUTHS_AND_A_LIE",
  DRUNK_STARTUPS = "IDEAS_CAFE",
  LOUNGE_VIDEO = "LOUNGE_VIDEO",
}

export enum ActivityDisplayNamesEnum {
  VIDEO_MEETING = "VIDEO_MEETING",
  LOUNGE = "Lounge",
  WOULD_YOU_RATHER = "Would you rather",
  TRIVIA = "Trivia",
  DOODLE_RACE = "Doodle race",
  CHARADES = "Charades",
  TWO_TRUTHS_AND_A_LIE = "2 Truths and a lie",
  KNOW_YOUR_TEAM = "Know your team",
  RAPID_RENDEZVOUS = "Speed 1-on-1s",
  MINDFUL_TALK = "Mindful talks",
  DRUNK_STARTUPS = "Drunk startups",
}
