import React from "react";
import { useTheme } from "styled-components";

const GreenFingurePointerIcon = () => {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
    >
      <path
        d="M16.8571 32.1356L11.5 26.6356L15.6429 17.1356L37.5 21.751L16.8571 32.1356Z"
        fill={theme.color.green500}
      />
      <path
        d="M43.5 29.1356C43.5 33.1139 41.9196 36.9292 39.1066 39.7422C36.2936 42.5553 32.4782 44.1356 28.5 44.1356"
        fill={theme.color.green500}
      />
      <path
        d="M43.5 29.1356C43.5 33.1139 41.9196 36.9292 39.1066 39.7422C36.2936 42.5553 32.4782 44.1356 28.5 44.1356"
        stroke={theme.color.green200}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36 23.1356V21.1356C36 20.0748 35.6049 19.0573 34.9016 18.3072C34.1984 17.557 33.2446 17.1356 32.25 17.1356V17.1356C31.2554 17.1356 30.3016 17.557 29.5984 18.3072C28.8951 19.0573 28.5 20.0748 28.5 21.1356"
        fill={theme.color.green500}
      />
      <path
        d="M36 23.1356V21.1356C36 20.0748 35.6049 19.0573 34.9016 18.3072C34.1984 17.557 33.2446 17.1356 32.25 17.1356V17.1356C31.2554 17.1356 30.3016 17.557 29.5984 18.3072C28.8951 19.0573 28.5 20.0748 28.5 21.1356"
        stroke={theme.color.green200}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 20.1356V9.13562C19.5 8.07475 19.1049 7.05734 18.4016 6.30719C17.6984 5.55705 16.7446 5.13562 15.75 5.13562C14.7554 5.13562 13.8016 5.55705 13.0983 6.30719C12.3951 7.05734 12 8.07475 12 9.13562V29.1356"
        fill={theme.color.green500}
      />
      <path
        d="M19.5 20.1356V9.13562C19.5 8.07475 19.1049 7.05734 18.4016 6.30719C17.6984 5.55705 16.7446 5.13562 15.75 5.13562C14.7554 5.13562 13.8016 5.55705 13.0983 6.30719C12.3951 7.05734 12 8.07475 12 9.13562V29.1356"
        stroke={theme.color.green200}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.741 22.5587C35.741 21.5182 36.1497 20.5204 36.8772 19.7847C37.6048 19.0489 38.5916 18.6356 39.6205 18.6356C40.6494 18.6356 41.6362 19.0489 42.3637 19.7847C43.0913 20.5204 43.5 21.5182 43.5 22.5587V28.4433C43.5 32.6052 41.8651 36.5966 38.9549 39.5395C36.0447 42.4823 32.0976 44.1356 27.9819 44.1356H24.1024C18.6711 44.1356 15.3735 42.4487 12.4832 39.5456L5.50009 32.4841C4.83268 31.7366 4.47509 30.7583 4.50135 29.7515C4.52761 28.7448 4.93571 27.7869 5.64115 27.076C6.34659 26.3652 7.29534 25.9559 8.29096 25.9329C9.28658 25.9099 10.2528 26.275 10.9896 26.9525L14.4036 30.4049"
        fill={theme.color.green500}
      />
      <path
        d="M35.741 22.5587C35.741 21.5182 36.1497 20.5204 36.8772 19.7847C37.6048 19.0489 38.5916 18.6356 39.6205 18.6356C40.6494 18.6356 41.6362 19.0489 42.3637 19.7847C43.0913 20.5204 43.5 21.5182 43.5 22.5587V28.4433C43.5 32.6052 41.8651 36.5966 38.9549 39.5395C36.0447 42.4823 32.0976 44.1356 27.9819 44.1356H24.1024C18.6711 44.1356 15.3735 42.4487 12.4832 39.5456L5.50009 32.4841C4.83268 31.7366 4.47509 30.7583 4.50135 29.7515C4.52761 28.7448 4.93571 27.7869 5.64115 27.076C6.34659 26.3652 7.29534 25.9559 8.29096 25.9329C9.28658 25.9099 10.2528 26.275 10.9896 26.9525L14.4036 30.4049"
        stroke={theme.color.green200}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.5 20.1356V18.1356C28.5 17.0748 28.0259 16.0573 27.182 15.3072C26.3381 14.557 25.1935 14.1356 24 14.1356V14.1356C22.8065 14.1356 21.6619 14.557 20.818 15.3072C19.9741 16.0573 19.5 17.0748 19.5 18.1356V20.1356"
        fill={theme.color.green500}
      />
      <path
        d="M28.5 20.1356V18.1356C28.5 17.0748 28.0259 16.0573 27.182 15.3072C26.3381 14.557 25.1935 14.1356 24 14.1356V14.1356C22.8065 14.1356 21.6619 14.557 20.818 15.3072C19.9741 16.0573 19.5 17.0748 19.5 18.1356V20.1356"
        stroke={theme.color.green200}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GreenFingurePointerIcon;
