import axios from "axios";
import {
  CreateEventInstanceResponse,
  CreateEventResponse,
  GetEventDetailsResponse,
  GetInstanceResponse,
  UpdateScheduleSocialInterface,
} from "../types/event";
import { GetMeetingDetailsResponse } from "../types/meeting";
import {
  InstancesOfEventResponse,
  ScheduledSocialsResponse,
} from "../types/ScheduleEvent";
import { generateSocialName } from "../utils";

export const createEvent = async ({
  templateCode,
  frequency,
  organizationId,
  isScheduled,
}: {
  templateCode: string;
  frequency: string;
  organizationId: string;
  isScheduled?: boolean;
}) => {
  try {
    const response = await axios.post<CreateEventResponse>(
      `${process.env.REACT_APP_API_IDENTIFIER}/event/create-from-template?templateCode=${templateCode}`,
      {
        event: {
          name: generateSocialName(),
          frequency,
          isScheduled,
        },
        organizationId,
      }
    );
    return response.data;
  } catch (err) {
    console.error("err", err);
  }
};

export const createEventInstance = async ({
  eventId,
  nearestChimeServer,
}: {
  eventId: string;
  nearestChimeServer: string;
}) => {
  try {
    const response = await axios.post<CreateEventInstanceResponse>(
      `${process.env.REACT_APP_API_IDENTIFIER}/event-instance`,
      {
        eventId,
        nearestChimeServer,
      }
    );
    return response.data;
  } catch (err) {
    console.error("err", err);
  }
};

export const updateEventInstanceStatus = async ({
  eventInstanceId,
  status,
}: {
  eventInstanceId: string;
  status: string;
}) => {
  try {
    await axios.put(
      `${process.env.REACT_APP_API_IDENTIFIER}/event-instance/${eventInstanceId}`,
      {
        status,
      }
    );
  } catch (err) {
    console.error("err", err);
  }
};

export const updateEventName = async ({
  eventId,
  name,
}: {
  eventId: string;
  name: string;
}) => {
  try {
    await axios.put(
      `${process.env.REACT_APP_API_IDENTIFIER}/event/${eventId}`,
      {
        name,
      }
    );
  } catch (err) {
    console.error("err", err);
  }
};

export const getMeetingDetailsApi = async ({
  eventInstanceId,
  segmentGroupId,
}: {
  eventInstanceId: string;
  segmentGroupId: string;
}) => {
  try {
    const response = await axios.post<GetMeetingDetailsResponse>(
      `${process.env.REACT_APP_API_IDENTIFIER}/meeting/get-meeting-details/${eventInstanceId}/${segmentGroupId}`
    );
    return response.data;
  } catch (err) {
    console.error("err", err);
  }
};

export const getEventInstance = async ({
  eventCode,
}: {
  eventCode: string;
}) => {
  try {
    const response = await axios.get<GetInstanceResponse>(
      `${process.env.REACT_APP_API_IDENTIFIER}/event/instance?eventCode=${eventCode}`
    );
    return response.data;
  } catch (err) {
    console.error("err", err);
  }
};

export const getScheduledEventDetails = async ({
  eventId,
}: {
  eventId: string;
}) => {
  try {
    const response = await axios.get<GetEventDetailsResponse>(
      `${process.env.REACT_APP_API_IDENTIFIER}/event/${eventId}/detail`
    );
    return response.data;
  } catch (err) {
    console.error("err", err);
  }
};

export const getScheduledEvents = async ({
  organizationId,
  paginationIdentifier,
  isArchived = false,
}: {
  organizationId: string;
  paginationIdentifier?: string;
  isArchived?: boolean;
}) => {
  try {
    const eventType = isArchived ? "archived" : "active";
    let url = `${process.env.REACT_APP_API_IDENTIFIER}/event/${eventType}?organizationId=${organizationId}`;
    if (paginationIdentifier) {
      url = `${url}&paginationIdentifier=${paginationIdentifier}`;
    }
    const response = await axios.get<ScheduledSocialsResponse>(url);
    return response.data;
  } catch (err) {
    console.error("err", err);
  }
};

export const putEventDetails = async ({
  eventId,
  postData,
}: {
  eventId: string;
  postData: UpdateScheduleSocialInterface;
}) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_IDENTIFIER}/event/${eventId}/detail`,
      postData
    );

    if (response.status === 201) {
      return true;
    }

    return false;
  } catch (err) {
    console.error("err", err);
    return false;
  }
};

export const getInstancesOfEvent = async (eventId: string) => {
  try {
    const response = await axios.get<InstancesOfEventResponse>(
      `${process.env.REACT_APP_API_IDENTIFIER}/event/${eventId}/instances`
    );
    return response.data;
  } catch (err) {
    console.error("err", err);
  }
};

export const updateEventStatus = async ({
  eventId,
  status,
}: {
  eventId: string;
  status: "active" | "archived";
}) => {
  try {
    await axios.put(
      `${process.env.REACT_APP_API_IDENTIFIER}/event/${eventId}`,
      {
        status,
      }
    );
  } catch (err) {
    console.error("err", err);
  }
};
