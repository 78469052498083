import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { fetchCurrentSegmentGroupPrompt } from "../../API/common";
import { Activity } from "../../types/activity";

import { RootState } from "../store";
import { SegmentPromptData } from "./Lounge/loungeSlice";
import { MeetingState, Participants } from "./meetingSlice";
import { Theme } from "./mixerSlice";

export interface SendAnswerPayload {
  segmentGroupId: string;
  segmentId: string;
  timeTaken: number;
  participantId: string;
  promptId: string;
  optionId?: string;
  teamId?: string;
  text?: string;
}

export interface CreateCurrentQuestionPayload {
  segmentGroupId: string;
  participantId: string;
  question: Question;
  preparationTime: number;
}
export interface Question {
  id?: string;
  name?: string;
  text?: string;
  activityId?: string;
  type?: string;
  participantId?: string;
  options?: Option[];
  icons?: string[];
  correctAnswer?: number;
  imageLink?: string;
  didYouKnow?: string;
}

export interface Option {
  id?: string;
  isCorrect?: boolean;
  text?: string;
}
export interface Answer {
  id: string;
  isCorrect: boolean;
  optionId: string;
  participantId: string;
  promptId: string;
  score: number;
  segmentGroupId: string;
  segmentId: string;
  timeTaken: number;
  text?: string;
}
export interface Asset {
  id: string;
  text: string;
  link: string;
  info: string;
  extraData?: {
    thumbnail?: string;
    duration?: number;
    source?: string;
  };
}
export interface PromptData {
  id: string;
  activityId?: string;
  activity: Activity;
  assets: Asset[];
  options: Option[];
  text: string;
  type: string;
  participantId?: string;
}
export interface Word {
  id: string;
  name: string;
}
export interface WordData {
  id: string;
  segment_id: Question;
  word: Word;
}
export interface GroupAnswer {
  participants: Participants[];
  text: string;
}
export interface Summary {
  segmentTheme: Theme;
  groupAnswers: GroupAnswer[];
}

export interface QuestionsState {
  questionsLoading: boolean;
  currentQuestion?: PromptData;
  questionsData?: SegmentPromptData[];
  wordsData?: WordData[];
  wordsLoading: boolean;
  questionSaved: boolean;
  hints: Option[];
  answers: Answer[];
  summary?: Summary;
  summaryLoading: boolean;
}

const initialState: QuestionsState = {
  questionsLoading: true,
  wordsLoading: true,
  questionSaved: false,
  summaryLoading: false,
  hints: [],
  answers: [],
};

export const fetchQuestions = createAsyncThunk(
  "fetchQuestions",
  async (total: number, { getState }) => {
    const { meeting } = getState() as { meeting: MeetingState };
    const response = await axios.get<SegmentPromptData[]>(
      `${process.env.REACT_APP_API_IDENTIFIER}/segment-prompt?segmentId=${meeting.currentSegmentId}&limit=${total}`
    );

    return response.data;
  }
);

export const fetchAllSegmentPrompts = createAsyncThunk(
  "fetchActiveQuestions",
  async (segmentId: string) => {
    const response = await axios.get<SegmentPromptData[]>(
      `${process.env.REACT_APP_API_IDENTIFIER}/segment-prompt?segmentId=${segmentId}&isActive=false`
    );
    return response.data;
  }
);

export const sendAnswer = createAsyncThunk(
  "sendAnswer",
  async (payload: SendAnswerPayload) => {
    const response = await axios.post<string>(
      `${process.env.REACT_APP_API_IDENTIFIER}/answer`,
      payload
    );
    return response.data;
  }
);

export const createAnswer = createAsyncThunk(
  "createAnswer",
  async (payload: SendAnswerPayload) => {
    const api = `${process.env.REACT_APP_API_IDENTIFIER}/answer/create-answer`;
    const response = await axios.post<string>(api, payload);
    return response.data;
  }
);

export const getAllAnswers = createAsyncThunk(
  "getAllAnswers",
  async ({
    segmentGroupId,
    promptId,
  }: {
    segmentGroupId: string;
    promptId: string;
  }) => {
    const response = await axios.get<Answer[]>(
      `${process.env.REACT_APP_API_IDENTIFIER}/answer/group?segmentGroupId=${segmentGroupId}&promptId=${promptId}`
    );
    return response.data;
  }
);

export const createCurrentQuestion = createAsyncThunk(
  "createCurrentQuestion",
  async (payload: CreateCurrentQuestionPayload) => {
    const api = `${process.env.REACT_APP_API_IDENTIFIER}/ttal/current-prompt/${payload.segmentGroupId}?participantId=${payload.participantId}`;
    const response = await axios.post<string>(api, payload.question, {
      headers: { "Preparation-Time": payload.preparationTime },
    });
    return response.data;
  }
);

export const fetchCurrentQuestion = createAsyncThunk(
  "fetchCurrentQuestion",
  async (segmentGroupId: string) => {
    const response = await fetchCurrentSegmentGroupPrompt(segmentGroupId);
    return response;
  }
);

export const fetchHints = createAsyncThunk(
  "fetchHints",
  async ({
    segmentGroupId,
    limit,
  }: {
    segmentGroupId: string;
    limit: number;
  }) => {
    const api = `${process.env.REACT_APP_API_IDENTIFIER}/ttal/hints?limit=${limit}&segmentGroupId=${segmentGroupId}`;
    const response = await axios.get<Option[]>(api);
    return response.data;
  }
);

export const fetchSummary = createAsyncThunk(
  "fetchSummary",
  async (segmentId: string) => {
    const response = await axios.get<Summary>(
      `${process.env.REACT_APP_API_IDENTIFIER}/segment/${segmentId}/summary`
    );
    return response.data;
  }
);

export const questionsSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {
    resetCurrentQuestion: (state) => {
      state.currentQuestion = undefined;
    },
    resetAnswers: (state) => {
      state.answers = [];
    },
    resetQuestions: (state) => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuestions.pending, (state) => {
        state.questionsLoading = true;
      })
      .addCase(fetchQuestions.fulfilled, (state, action) => {
        state.questionsLoading = false;
        state.questionsData = action.payload;
      })
      .addCase(fetchAllSegmentPrompts.pending, (state) => {
        state.questionsLoading = true;
      })
      .addCase(fetchAllSegmentPrompts.fulfilled, (state, action) => {
        state.questionsLoading = false;
        state.questionsData = action.payload;
      })
      .addCase(fetchCurrentQuestion.pending, (state, action) => {
        state.questionsLoading = true;
      })
      .addCase(fetchCurrentQuestion.fulfilled, (state, action) => {
        state.questionsLoading = false;
        state.currentQuestion = action.payload;
      })
      .addCase(createCurrentQuestion.pending, (state, action) => {
        state.questionSaved = false;
      })
      .addCase(createCurrentQuestion.fulfilled, (state, action) => {
        state.questionSaved = true;
      })
      .addCase(fetchHints.fulfilled, (state, action) => {
        state.hints = action.payload;
      })
      .addCase(getAllAnswers.pending, (state, action) => {
        state.answers = [];
      })
      .addCase(getAllAnswers.fulfilled, (state, action) => {
        state.answers = action.payload;
      })
      .addCase(fetchSummary.pending, (state, action) => {
        state.summaryLoading = true;
      })
      .addCase(fetchSummary.fulfilled, (state, action) => {
        state.summaryLoading = false;
        state.summary = action.payload;
      });
  },
});

export const { resetCurrentQuestion, resetAnswers, resetQuestions } =
  questionsSlice.actions;

export const selectQuestionsLoading = (state: RootState) =>
  state.questions.questionsLoading;
export const selectQuestionsData = (state: RootState) =>
  state.questions.questionsData;
export const selectCurrentQuestion = (state: RootState) =>
  state.questions.currentQuestion;

export const selectWordsLoading = (state: RootState) =>
  state.questions.wordsLoading;
export const selectWordsData = (state: RootState) => state.questions.wordsData;

export const selectQuestionSaved = (state: RootState) =>
  state.questions.questionSaved;

export const selectHints = (state: RootState) => state.questions.hints;

export const selectAllAnswers = (state: RootState) => state.questions.answers;

export const selectSummary = (state: RootState) => state.questions.summary;

export const selectSummaryLoading = (state: RootState) =>
  state.questions.summaryLoading;

export default questionsSlice.reducer;
