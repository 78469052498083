const IcebreakerIcon: React.FC<{ size?: string }> = ({ size = "20" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.16667 18L15.8333 18C16.7525 18 17.5 17.2525 17.5 16.3333L17.5 4.66667C17.5 3.7475 16.7525 3 15.8333 3L4.16667 3C3.2475 3 2.5 3.7475 2.5 4.66667L2.5 16.3333C2.5 17.2525 3.2475 18 4.16667 18ZM13.3333 12.5833C13.665 12.5834 13.983 12.7153 14.2174 12.9499C14.4518 13.1844 14.5834 13.5025 14.5833 13.8342C14.5832 14.1658 14.4514 14.4838 14.2168 14.7182C13.9822 14.9526 13.6641 15.0843 13.3325 15.0842C13.1683 15.0841 13.0057 15.0517 12.854 14.9888C12.7023 14.9259 12.5645 14.8338 12.4484 14.7176C12.3324 14.6015 12.2403 14.4636 12.1775 14.3119C12.1147 14.1602 12.0824 13.9975 12.0825 13.8333C12.0826 13.6691 12.115 13.5065 12.1778 13.3549C12.2407 13.2032 12.3329 13.0654 12.449 12.9493C12.5652 12.8332 12.7031 12.7411 12.8548 12.6784C13.0065 12.6156 13.1691 12.5833 13.3333 12.5833ZM10 9.25C10.3316 9.25011 10.6496 9.38196 10.8841 9.61653C11.1185 9.85111 11.2501 10.1692 11.25 10.5008C11.2499 10.8325 11.118 11.1505 10.8835 11.3849C10.6489 11.6193 10.3308 11.7509 9.99917 11.7508C9.66753 11.7507 9.34953 11.6189 9.11511 11.3843C8.88069 11.1497 8.74906 10.8316 8.74917 10.5C8.74928 10.1684 8.88112 9.85036 9.1157 9.61594C9.35028 9.38152 9.66837 9.24989 10 9.25ZM6.66667 5.91667C6.83087 5.91672 6.99346 5.94912 7.14515 6.01201C7.29684 6.0749 7.43465 6.16705 7.55072 6.2832C7.6668 6.39935 7.75885 6.53723 7.82164 6.68896C7.88443 6.84069 7.91672 7.00329 7.91667 7.1675C7.91661 7.33171 7.88421 7.4943 7.82132 7.64598C7.75843 7.79767 7.66628 7.93548 7.55013 8.05156C7.43398 8.16763 7.29611 8.25969 7.14438 8.32248C6.99265 8.38527 6.83004 8.41755 6.66583 8.4175C6.3342 8.41739 6.0162 8.28554 5.78178 8.05097C5.54736 7.81639 5.41572 7.4983 5.41583 7.16667C5.41594 6.83504 5.54779 6.51703 5.78236 6.28261C6.01694 6.04819 6.33504 5.91656 6.66667 5.91667Z"
        fill="#6633FF"
        stroke="#6633FF"
        strokeWidth="1.6"
      />
      <path
        d="M14.4998 13.1131C14.2497 12.8628 13.9105 12.7222 13.5568 12.7221C13.3816 12.722 13.2082 12.7565 13.0464 12.8234C12.8845 12.8904 12.7374 12.9886 12.6136 13.1124C12.4897 13.2362 12.3914 13.3832 12.3243 13.545C12.2572 13.7068 12.2226 13.8803 12.2226 14.0554C12.2225 14.2306 12.257 14.404 12.3239 14.5659C12.3909 14.7277 12.4891 14.8748 12.6129 14.9987C12.7367 15.1226 12.8837 15.2209 13.0455 15.288C13.2073 15.355 13.3808 15.3896 13.5559 15.3896C13.9097 15.3898 14.249 15.2494 14.4992 14.9993C14.7494 14.7493 14.89 14.4101 14.8901 14.0563C14.8903 13.7026 14.7498 13.3633 14.4998 13.1131Z"
        fill="white"
      />
      <path
        d="M10.9442 9.55751C10.6942 9.30729 10.355 9.16666 10.0012 9.16654C9.64751 9.16642 9.30821 9.30683 9.05799 9.55688C8.80778 9.80693 8.66714 10.1461 8.66703 10.4999C8.66691 10.8536 8.80732 11.1929 9.05736 11.4431C9.30741 11.6933 9.64662 11.834 10.0004 11.8341C10.3541 11.8342 10.6934 11.6938 10.9436 11.4438C11.1938 11.1937 11.3345 10.8545 11.3346 10.5008C11.3347 10.147 11.1943 9.80772 10.9442 9.55751Z"
        fill="white"
      />
      <path
        d="M6.95607 5.71268C6.79427 5.6456 6.62085 5.61104 6.44569 5.61098C6.09195 5.61086 5.75265 5.75127 5.50244 6.00132C5.25222 6.25137 5.11159 6.59057 5.11147 6.94431C5.11135 7.29805 5.25176 7.63735 5.50181 7.88757C5.75186 8.13778 6.09106 8.27842 6.4448 8.27854C6.61996 8.27859 6.79341 8.24415 6.95525 8.17718C7.1171 8.1102 7.26416 8.01201 7.38806 7.8882C7.51195 7.76439 7.61024 7.61738 7.67733 7.45558C7.74441 7.29379 7.77897 7.12036 7.77903 6.9452C7.77908 6.77005 7.74464 6.5966 7.67767 6.43476C7.61069 6.27291 7.5125 6.12584 7.38869 6.00195C7.26487 5.87806 7.11787 5.77976 6.95607 5.71268Z"
        fill="white"
      />
    </svg>
  );
};

export default IcebreakerIcon;
