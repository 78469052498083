export const GreenTick: React.FC<{
  width: number;
  height: number;
  tickColor?: string;
  backgroundColor?: string;
}> = ({ width, height, tickColor, backgroundColor }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.0038 91.6595C73.0163 91.6595 91.6705 73.0053 91.6705 49.9928C91.6705 26.9803 73.0163 8.32617 50.0038 8.32617C26.9913 8.32617 8.33716 26.9803 8.33716 49.9928C8.33716 73.0053 26.9913 91.6595 50.0038 91.6595Z"
        fill={backgroundColor || "#1EB482"}
        stroke={backgroundColor || "#1EB482"}
        strokeWidth="12.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.241 50L45.3521 62.5L67.5743 37.5"
        stroke={tickColor || "#E9F7F3"}
        strokeWidth="12"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const WhiteTick = () => {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20.667" cy="20.667" r="20" fill="#1EB482" />
      <path
        d="M30.667 12.667L16.917 28.667L10.667 21.3943"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
