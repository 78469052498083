import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import amplitude from "amplitude-js";

import { slackAppCallback, SlackCallbackResponseInterface } from "../API/slack";
import SpinningLoader from "../components/common/SpinningLoader";
import { useQuery } from "../hooks";
import { useAppSelector } from "../store/hooks";
import background from "../assets/signUpPageBackground.png";
import {
  selectSlackChannelName,
  selectSlackWorkspaceName,
} from "../store/features/userSlice";
import { RoutesEnum } from "../routes/routes.enum";
import { AmplitudeEvents } from "../types/amplitudeEvents";
import SlackAddOrSelectForm, {
  Bold,
} from "../components/Slack/SlackAddOrSelectForm";
import WorkspaceDetails from "../components/Slack/WorkspaceDetails";
import SlackSuccssOrErrorPrompt from "../components/Slack/SlackSuccssOrErrorPrompt";
import { TruncatedName } from "../components/common/Styled";
import { GreenTick } from "../assets/GreenTick";
import AlertTriangleIcon from "../assets/AlertTriangleIcon";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-image: url(${background});
  background-size: cover;
`;

const SubHeading = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  align-items: center;
  text-align: center;
  color: ${(props) => props.theme.color.darkGray};
  white-space: pre-wrap;
  width: 455px;
`;

const SlackCallback: React.FC = () => {
  const query = useQuery();
  const history = useHistory();
  const state = query.get("state");
  const code = query.get("code");
  const callbackError = query.get("error");
  const slackWorkspaceName = useAppSelector(selectSlackWorkspaceName);
  const slackChannelName = useAppSelector(selectSlackChannelName);
  const [appInstalled, setAppInstalled] = React.useState<boolean>();
  const [callbackApiStatus, setCallbackApiStatus] = React.useState<number>();
  const [slackWorkspaceDetails, setSlackWorkspaceDetails] =
    React.useState<SlackCallbackResponseInterface>();

  React.useEffect(() => {
    if (callbackError === "access_denied") {
      // Redirect to dashboard in case the user cancels the flow
      amplitude
        .getInstance()
        .logEvent(AmplitudeEvents.SLACK_APP_CALLBACK_PAGE_OPENED, {
          status: "access_denied",
        });
      history.push(RoutesEnum.DASHBOARD);
    }
  }, [callbackError]);

  React.useEffect(() => {
    (async () => {
      if (code && state) {
        const { data: slackAppInstalled, status } = await slackAppCallback({
          code,
          state,
        });
        setAppInstalled(!!slackAppInstalled);
        setCallbackApiStatus(status);
        amplitude
          .getInstance()
          .logEvent(AmplitudeEvents.SLACK_APP_CALLBACK_PAGE_OPENED, {
            status: !!slackAppInstalled ? "installed" : "not_installed",
          });
        if (slackAppInstalled) {
          setSlackWorkspaceDetails(slackAppInstalled);
        }
      }
    })();
  }, [state, code]);

  return (
    <>
      {typeof appInstalled === "boolean" ? (
        <Container>
          {appInstalled ? (
            <SlackAddOrSelectForm />
          ) : (
            <>
              <SlackSuccssOrErrorPrompt
                icon={
                  appInstalled ? (
                    <GreenTick width={80} height={80} />
                  ) : (
                    <AlertTriangleIcon size={80} />
                  )
                }
                headingText={
                  appInstalled
                    ? "Slack app installed"
                    : callbackApiStatus === 423
                    ? "The Slack app is already installed in your workspace by another team."
                    : "Slack app is already installed in your workspace"
                }
                subHeading={
                  callbackApiStatus !== 423 ? (
                    <SubHeading>
                      Check for{" "}
                      <TruncatedName maxWidth={130}>
                        {" "}
                        <Bold>#{slackChannelName}</Bold>
                      </TruncatedName>{" "}
                      channel in your workspace
                    </SubHeading>
                  ) : (
                    <></>
                  )
                }
              />
              {callbackApiStatus !== 423 && (
                <WorkspaceDetails
                  workspaceName={
                    slackWorkspaceName || slackWorkspaceDetails?.teamName
                  }
                />
              )}
            </>
          )}
        </Container>
      ) : (
        <SpinningLoader />
      )}
    </>
  );
};

export default SlackCallback;
