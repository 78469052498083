import * as React from "react";
import styled from "styled-components";
import AlertTriangleIcon from "../../assets/AlertTriangleIcon";
import { GreenTick } from "../../assets/GreenTick";
import { TruncatedName } from "../common/Styled";
import { Bold, Heading } from "./SlackAddOrSelectForm";

const Box = styled.div`
  display: flex;
  width: 503px;
  flex-direction: column;
  align-items: center;
  background: ${(props) => props.theme.color.white};
  box-shadow: 0px 4px 16px -2px rgba(0, 0, 0, 0.08);
  border-radius: 28px;
  box-sizing: border-box;
  padding: 64px 24px;
`;

interface SlackSuccssOrErrorPromptPropsInterface {
  icon: JSX.Element;
  headingText: string;
  subHeading?: JSX.Element;
}

const SlackSuccssOrErrorPrompt: React.FC<SlackSuccssOrErrorPromptPropsInterface> =
  ({ icon, headingText = "", subHeading }) => {
    return (
      <Box>
        {icon}
        <Heading>{headingText}</Heading>
        {subHeading}
      </Box>
    );
  };

export default SlackSuccssOrErrorPrompt;
