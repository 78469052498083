import React from "react";
import styled from "styled-components";
import { ThursdayFullTextLogo } from "../assets/BrandLogos";
import {
  BigThankYou,
  BlueSquare,
  GreenStar,
  OrangeTriangle,
} from "../assets/ByePage";
import { RoutesEnum } from "../routes/routes.enum";
import {
  loginStatus,
  selectUserLoginStatus,
} from "../store/features/userSlice";
import { useAppSelector } from "../store/hooks";
// import { productHuntHandler } from "../utils/productHuntHandler";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  font-size: 36px;
`;

const Header = styled.div`
  width: 100vw;
  box-sizing: border-box;
  max-height: 50px;
`;

const ThursdayLogoContainer = styled.div`
  display: flex;
  margin-left: 12px;
  padding-top: 12px;
  width: max-content;
  cursor: pointer;
`;

const HeroSection = styled.div`
  width: 100vw;
  height: calc(100vh - 50px);
`;

const SectionOne = styled.div`
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const TextHeader = styled.p`
  padding: 0;
  margin: 0;
  font-family: Labil;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 58px;
  color: ${(props) => props.theme.color.black};

  @media (max-width: 780px) {
    font-size: 32px;
    line-height: 42px;
  }
`;

const HeaderOne = styled(TextHeader)`
  color: ${(props) => props.theme.color.purple500};
`;

const HeaderTwo = styled(TextHeader)`
  margin-top: 1px;
`;

const LogosContainer = styled.div`
  margin-top: 28px;
`;

const LogoInlineMargin = styled.span`
  margin: 0 24.6px;
`;

const BottomSection = styled.div`
  left: 50%;
  position: absolute;
  transform: translate(-50%, 0);
  text-align: center;
  bottom: 51px;
`;

const Bye: React.FC = () => {
  const userLoginStatus = useAppSelector(selectUserLoginStatus);

  // React.useEffect(() => {
  //   // Show producthunt early access popup
  //   productHuntHandler();
  // }, []);

  React.useEffect(() => {
    let redirectTimer: NodeJS.Timeout;
    if (userLoginStatus === loginStatus.LOGGED_IN) {
      redirectTimer = setTimeout(() => {
        window.location.href = `${process.env.REACT_APP_BASE_URL}${RoutesEnum.DASHBOARD}?show-modal=true`;
      }, 5000);
    }
    return () => {
      clearTimeout(redirectTimer);
    };
  });

  return (
    <Container>
      <Header>
        <ThursdayLogoContainer
          onClick={() => (window.location.href = "https://thursday.social")}
        >
          <ThursdayFullTextLogo />
        </ThursdayLogoContainer>
      </Header>

      <HeroSection>
        <SectionOne>
          <HeaderOne>see you sooooon</HeaderOne>

          <HeaderTwo>for great vibes & mad fun!</HeaderTwo>

          <LogosContainer>
            <GreenStar />

            <LogoInlineMargin>
              <BlueSquare />
            </LogoInlineMargin>

            <OrangeTriangle />
          </LogosContainer>
        </SectionOne>

        <BottomSection>
          <BigThankYou />
        </BottomSection>
      </HeroSection>
    </Container>
  );
};

export default Bye;
