const TriviaIcon: React.FC<{ size?: string }> = ({ size = "24" }) => {
  return (
    <svg
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#475766">
        <rect height="3" rx="1.5" width="9" x="2.25" y="18.75" />
        <rect height="3" rx="1.5" width="9" x="12.75" y="18.75" />
        <rect height="3" rx="1.5" width="9" x="2.25" y="14.25" />
        <rect height="3" rx="1.5" width="9" x="12.75" y="14.25" />
      </g>
      <path
        d="m15.75 6c0 2.07107-1.6789 3.75-3.75 3.75-2.07107 0-3.75-1.67893-3.75-3.75s1.67893-3.75 3.75-3.75c2.0711 0 3.75 1.67893 3.75 3.75z"
        fill="#ed7b2a"
      />
      <path
        clipRule="evenodd"
        d="m15.2129 9.49859c.9449-.8682 1.5371-2.11424 1.5371-3.49859 0-.2975-.0274-.58862-.0797-.87097.5952-.39737 1.3104-.62903 2.0797-.62903 2.0711 0 3.75 1.67893 3.75 3.75 0 2.0711-1.6789 3.75-3.75 3.75-1.6333 0-3.0227-1.0442-3.5371-2.50141zm-.2129-1.24822c.4709-.62681.75-1.406.75-2.25037 0-.00012 0-.00024 0-.00037-.4709.62681-.75 1.406-.75 2.25037z"
        fill="#ed7b2a"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="m7.32967 5.12904c-.59514-.39738-1.31035-.62904-2.07967-.62904-2.07107 0-3.75 1.67893-3.75 3.75 0 2.0711 1.67893 3.75 3.75 3.75 1.6333 0 3.02272-1.0442 3.53712-2.50141-.94491-.8682-1.53712-2.11423-1.53712-3.49859 0-.2975.02735-.58862.07967-.87096zm.92033.87059v.00037c0 .84437.27907 1.62356.75 2.25037 0-.00013 0-.00025 0-.00037 0-.84437-.27907-1.62356-.75-2.25037z"
        fill="#ed7b2a"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default TriviaIcon;
