import React from "react";
import { motion } from "framer-motion";
import amplitude from "amplitude-js";
import styled from "styled-components";
import { slackItJoinSocial } from "../../API/slack";
import SlackIcon from "../../assets/SlackIcon";
import ModalProvider from "../../Providers/ModalProvider";
import { selectEventDetails } from "../../store/features/eventSlice";
import {
  selectSlackAppStatus,
  selectTriggerSlackButtonClick,
} from "../../store/features/userSlice";
import { useAppSelector } from "../../store/hooks";
import { AmplitudeEvents } from "../../types/amplitudeEvents";
import Spinner from "../common/Spinner";
import InstallSlackAppModal from "./InstallSlackAppModal";
import { useSlackButtonPresence } from "../../hooks";

const Tooltip = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: max-content;
  background: ${(props) => props.theme.color.black};
  border-radius: 24px;
  box-sizing: border-box;
  padding: 12px 16px;
  font-weight: 500;
  font-size: 13px;
  line-height: 160%;
  text-align: center;
  color: ${(props) => props.theme.color.white};
`;

const TooltipContainer = styled.div<{ fromBottom: boolean }>`
  display: none;
  flex-direction: ${(props) =>
    props.fromBottom ? "column-reverse" : "column"};
  align-items: center;
  justify-content: center;
  position: absolute;
  top: ${(props) => !props.fromBottom && "-64px"};
  bottom: ${(props) => props.fromBottom && "-64px"};
`;

const ToolTipDownArrow = styled.div<{ fromBottom: boolean }>`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid ${(props) => props.theme.color.black};
  transform: rotate(${(props) => (props.fromBottom ? "180deg" : "0deg")});
`;

const Container = styled(motion.div)<{
  compactView: boolean;
  width: number;
  height: number;
  borderRadius: number;
  showShadow: boolean;
  showBackground: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: ${(props) => props.borderRadius}px;
  width: ${(props) => (props.compactView ? `${props.width}px` : "auto")};
  height: ${(props) => `${props.height}px`};
  background: ${(props) => props.showBackground && props.theme.color.slack};
  flex-grow: ${(props) => (props.compactView ? "0" : "1")};
  cursor: pointer;
  box-shadow: ${(props) =>
    props.showShadow && "0px 2px 4px -1px rgba(0, 0, 0, 0.24)"};

  &:hover ${TooltipContainer} {
    display: flex;
  }
`;

const Message = styled.div<{
  textColor: string;
}>`
  font-weight: 700;
  font-size: 13px;
  line-height: 160%;
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.textColor ? props.textColor : props.theme.color.white};
  margin-left: 6px;
`;

const SlackButton: React.FC<{
  tooltipText?: string;
  tooltipFromBottom?: boolean;
  sendNotification: boolean;
  compactView?: boolean;
  width?: number;
  height?: number;
  borderRadius?: number;
  showShadow?: boolean;
  showBackground?: boolean;
  textColor?: string;
  showIcon?: boolean;
  showToolTip?: boolean;
}> = ({
  tooltipText,
  tooltipFromBottom = false,
  sendNotification,
  compactView = false,
  showIcon = true,
  width = 64,
  height = 64,
  borderRadius = 24,
  showBackground = true,
  textColor,
  showShadow = false,
  showToolTip = true,
}) => {
  const [shared, setShared] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isButtonPressed, setIsButtonPressed] = React.useState(false);
  const [isButtonTapped, setIsButtonTapped] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const slackAppStatus = useAppSelector(selectSlackAppStatus);
  const eventDetails = useAppSelector(selectEventDetails);
  const triggerSlackButtonClick = useAppSelector(selectTriggerSlackButtonClick);
  const isActiveSlackChannelNameEmpty = useSlackButtonPresence();

  const handleButtonMouseTap = () => {
    setIsButtonTapped(true);
    setTimeout(() => {
      setIsButtonTapped(false);
    }, 100);
  };

  const handleClick = () => {
    if (slackAppStatus !== "active") {
      setShowModal(true);
      return;
    }

    if (isActiveSlackChannelNameEmpty) {
      amplitude
        .getInstance()
        .logEvent(AmplitudeEvents.SLACK_APP_CREATE_CHANNEL_PAGE_OPENED);
      const url = `${process.env.REACT_APP_BASE_URL}/slack/create-channel`;
      setTimeout(() => {
        window.open(url, "_blank");
      }, 0);
      return;
    }

    slackItSocial();
  };

  const slackItSocial = async () => {
    amplitude.getInstance().logEvent(AmplitudeEvents.SLACK_IT_BUTTON_CLICKED);
    if (eventDetails?.eventId) {
      setLoading(true);
      await slackItJoinSocial(eventDetails?.eventId);
      setLoading(false);
      setShared(true);
      setTimeout(() => {
        setShared(false);
      }, 2000);
    }
  };

  React.useEffect(() => {
    triggerSlackButtonClick && handleClick();
  }, [triggerSlackButtonClick]);

  return (
    <>
      {showModal && (
        <ModalProvider setShowModal={setShowModal}>
          <InstallSlackAppModal
            sendNotification={sendNotification}
            heading="Continue the conversation on Slack"
          />
        </ModalProvider>
      )}
      <Container
        compactView={compactView}
        width={width}
        height={height}
        showBackground={showBackground}
        borderRadius={borderRadius}
        showShadow={showShadow}
        onTap={handleButtonMouseTap}
        onMouseDown={() => setIsButtonPressed(true)}
        onMouseUp={() => setIsButtonPressed(false)}
        onMouseLeave={() => setIsButtonPressed(false)}
        animate={{
          scale: isButtonPressed || isButtonTapped ? 0.94 : 1,
        }}
        onClick={handleClick}
      >
        {showToolTip && (
          <TooltipContainer fromBottom={tooltipFromBottom}>
            <Tooltip>{tooltipText}</Tooltip>
            <ToolTipDownArrow fromBottom={tooltipFromBottom} />
          </TooltipContainer>
        )}
        {loading ? (
          <Spinner color="#ffffff" size={32} border={4} />
        ) : (
          <>
            {(!shared || compactView) && showIcon && <SlackIcon />}
            {!compactView && (
              <Message textColor={textColor ? textColor : ""}>
                {shared
                  ? "Shared on slack"
                  : slackAppStatus === "active"
                  ? "slack it"
                  : "add to slack"}
              </Message>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default SlackButton;
