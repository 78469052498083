import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export interface VideoOnLoungeState {
  isVideoOnLoungeInputActive: boolean;
  isVideoOnLoungeMinimized: boolean;
  showVideoCategoryPlaylist: boolean;
  showReplaceCurrentVideoModal: boolean;
  showStartMixerWarning: boolean;
  replaceCurrentVideoLink: string;
}

const initialState: VideoOnLoungeState = {
  isVideoOnLoungeInputActive: false,
  isVideoOnLoungeMinimized: false,
  showVideoCategoryPlaylist: false,
  showReplaceCurrentVideoModal: false,
  showStartMixerWarning: false,
  replaceCurrentVideoLink: "",
};

export const videoOnLoungeSlice = createSlice({
  name: "videoOnLounge",
  initialState,
  reducers: {
    setIsVideoOnLoungeMinimized: (state, action: PayloadAction<boolean>) => {
      state.isVideoOnLoungeMinimized = action.payload;
    },
    setShowVideoCategoryPlaylist: (state, action: PayloadAction<boolean>) => {
      state.showVideoCategoryPlaylist = action.payload;
    },
    setShowReplaceCurrentVideoModal: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.showReplaceCurrentVideoModal = action.payload;
    },
    setReplaceCurrentVideoLink: (state, action: PayloadAction<string>) => {
      state.replaceCurrentVideoLink = action.payload;
    },
    setShowStartMixerWarning: (state, action: PayloadAction<boolean>) => {
      state.showStartMixerWarning = action.payload;
    },
    setIsVideoOnLoungeInputActive: (state, action: PayloadAction<boolean>) => {
      state.isVideoOnLoungeInputActive = action.payload;
    },
  },
});

export const {
  setIsVideoOnLoungeMinimized,
  setShowVideoCategoryPlaylist,
  setShowReplaceCurrentVideoModal,
  setReplaceCurrentVideoLink,
  setShowStartMixerWarning,
  setIsVideoOnLoungeInputActive,
} = videoOnLoungeSlice.actions;

export const selectIsVideoOnLoungeMinimized = (state: RootState) =>
  state.videoOnLounge.isVideoOnLoungeMinimized;

export const selectShowVideoCategoryPlaylist = (state: RootState) =>
  state.videoOnLounge.showVideoCategoryPlaylist;

export const selectShowReplaceCurrentVideoModal = (state: RootState) =>
  state.videoOnLounge.showReplaceCurrentVideoModal;

export const selectReplaceCurrentVideoLink = (state: RootState) =>
  state.videoOnLounge.replaceCurrentVideoLink;

export const selectShowStartMixerWarning = (state: RootState) =>
  state.videoOnLounge.showStartMixerWarning;

export const selectIsVideoOnLoungeInputActive = (state: RootState) =>
  state.videoOnLounge.isVideoOnLoungeInputActive;

export default videoOnLoungeSlice.reducer;
