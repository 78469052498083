import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import amplitude from "amplitude-js";
import {
  getSlackInstallRedirectUrl,
  SlackInstallRedirectUrlInterface,
} from "../../API/slack";
import IcebreakerIcon from "../../assets/IcebreakerIcon";
import LoopbackIcon from "../../assets/LoopbackIcon";
import SlackIcon from "../../assets/SlackIcon";
import SlashCommandIcon from "../../assets/SlashCommandIcon";
import ThursdayIcon from "../../assets/ThursdayIcon";
import UserListIcon from "../../assets/UserListIcon";
import { selectEventDetails } from "../../store/features/eventSlice";
import { selectActiveOrganization } from "../../store/features/userSlice";
import { useAppSelector } from "../../store/hooks";
import { AmplitudeEvents } from "../../types/amplitudeEvents";
import Spinner from "../common/Spinner";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  gap: 4px;
  width: 560px;
  height: 100%;
  left: calc(50% - 560px / 2);
  top: calc(50% - 736px / 2);
  background: ${(props) => props.theme.color.white};
  box-shadow: 0px 4px 16px -2px rgba(0, 0, 0, 0.08);
  border-radius: 32px;
`;

export const LogosContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.color.lightGray};
  line-height: 160%;
  margin-top: 42px;
  margin-bottom: 13px;
`;

export const Logos = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 140px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
`;

export const LogoSubheading = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 160%;
  color: ${(props) => props.theme.color.purple500};
  letter-spacing: -0.01em;
`;

const SlackIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  width: 48px;
  height: 48px;
  background: ${(props) => props.theme.color.slack};
  cursor: pointer;
`;

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
  padding: 0px 16px;
`;

const Heading = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.01em;
  color: ${(props) => props.theme.color.black};
  margin-bottom: 5px;
`;

const SubHeading = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  text-align: center;
  letter-spacing: -0.01em;
  color: ${(props) => props.theme.color.darkGray};
`;

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`;

const SummaryOverlay = styled.div`
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  height: 230px;
  width: 92%;
  position: absolute;
  bottom: 5rem;
  right: 0;
`;

const SummaryBulletText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 160%;
  letter-spacing: -0.01em;
  color: ${(props) => props.theme.color.lightGray};
  margin-bottom: 10px;
`;

const SummaryBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 16px;
`;

const SummaryTextsBlock = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
`;

const SummaryHeading = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 160%;
  color: ${(props) => props.theme.color.black};
`;

const SummaryIcon = styled.div`
  padding: 5px;
`;

const SummaryDescription = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: ${(props) => props.theme.color.darkGray};
`;

const Button = styled(motion.button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  padding: 15px 24px;
  margin-top: 16px;
  height: 56px;
  background: ${(props) => props.theme.color.slack};
  border-radius: 16px;
  cursor: pointer;
  font-family: "Inter";
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  color: ${(props) => props.theme.color.white};
  border: none;
  user-select: none;
`;

const COMING_SOON_FEATURES = [
  {
    icon: <SlashCommandIcon />,
    title: "/Slash Command",
    description: "Quickly start socials right inside from slack.",
  },
  {
    icon: <LoopbackIcon />,
    title: "Lookback",
    description:
      "Get reported on your past socials and analyse what works with your team.",
  },
  {
    icon: <IcebreakerIcon />,
    title: "Ice-breaker prompts",
    description:
      "Send conversation starters and ice-breakers on your slack channel of choice.",
  },
];

interface InstallSlackAppModalPropsInterface {
  sendNotification: boolean;
  heading: string;
}

const InstallSlackAppModal: React.FC<InstallSlackAppModalPropsInterface> = ({
  sendNotification,
  heading = "",
}) => {
  const organization = useAppSelector(selectActiveOrganization);
  const eventDetails = useAppSelector(selectEventDetails);

  const [isButtonPressed, setIsButtonPressed] = React.useState(false);
  const [isButtonTapped, setIsButtonTapped] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    amplitude
      .getInstance()
      .logEvent(AmplitudeEvents.SLACK_APP_INSTALL_MODAL_OPENED);
  }, []);

  const redirectToSlack = async () => {
    amplitude
      .getInstance()
      .logEvent(AmplitudeEvents.SLACK_APP_INSTALL_BUTTON_CLICKED);
    if (isLoading) return;
    setIsLoading(true);
    let payload: SlackInstallRedirectUrlInterface = {
      organizationId: organization.organization.id,
    };
    if (sendNotification && eventDetails?.eventId) {
      payload = { ...payload, eventId: eventDetails.eventId };
    }
    const url = await getSlackInstallRedirectUrl(payload);
    if (url) {
      /* 
        Another approach instead of window.open()  
          - http://surl.li/cevml
        why setTimeout 
          - Safari blocks any call to window.open() which is made inside an async function.
          - setTimeout code runs on the main thread, instead of the asynchronous one
      */
      setTimeout(() => {
        window.open(url, "_blank");
      }, 0);
    }
    setIsLoading(false);
  };

  return (
    <Container>
      <LogosContainer>
        <Logos>
          <ThursdayIcon />+
          <SlackIconContainer>
            <SlackIcon />
          </SlackIconContainer>
        </Logos>
        <LogoSubheading>THURSDAY FOR SLACK</LogoSubheading>
      </LogosContainer>

      <HeadingContainer>
        <Heading>{heading}</Heading>
        <SubHeading>
          Your favourite culture building tool, right at home.
        </SubHeading>
      </HeadingContainer>

      <SummaryContainer>
        <SummaryBulletText>Available now:</SummaryBulletText>
        <SummaryBlock>
          <SummaryIcon>
            <UserListIcon />
          </SummaryIcon>
          <SummaryTextsBlock>
            <SummaryHeading>Mixer summaries</SummaryHeading>
            <SummaryDescription>
              Don't end the fun times after the mixer, carry them forward async
              on slack with our seamless slack bot
            </SummaryDescription>
          </SummaryTextsBlock>
        </SummaryBlock>
      </SummaryContainer>

      <SummaryOverlay></SummaryOverlay>
      <SummaryContainer>
        <SummaryBulletText>Coming soon:</SummaryBulletText>
        {COMING_SOON_FEATURES.map(({ icon, title, description }, index) => (
          <SummaryBlock key={index}>
            <SummaryIcon>{icon}</SummaryIcon>
            <SummaryTextsBlock>
              <SummaryHeading>{title}</SummaryHeading>
              <SummaryDescription>{description}</SummaryDescription>
            </SummaryTextsBlock>
          </SummaryBlock>
        ))}
      </SummaryContainer>

      <Button
        animate={{
          scale: isButtonPressed || isButtonTapped ? 0.94 : 1,
        }}
        onTap={() => {
          setIsButtonTapped(true);
          setTimeout(() => {
            setIsButtonTapped(false);
          }, 100);
        }}
        onMouseDown={() => setIsButtonPressed(true)}
        onMouseUp={() => setIsButtonPressed(false)}
        onMouseLeave={() => setIsButtonPressed(false)}
        onClick={redirectToSlack}
      >
        {isLoading ? (
          <Spinner size={24} opacity="00" border={4} borderOpacity={255} />
        ) : (
          <>Add to slack</>
        )}
      </Button>
    </Container>
  );
};

export default InstallSlackAppModal;
