import React from "react";

const StarIcon: React.FC<{
  size?: number;
  mainColor?: string;
  secondaryColor?: string;
}> = ({ size = 48, mainColor = "#FFC200", secondaryColor = "#FFF3CC" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.1033 5.81663C23.4701 5.07346 24.5299 5.07346 24.8967 5.81663L29.9476 16.0491C30.0931 16.3439 30.3743 16.5484 30.6996 16.596L41.9962 18.2471C42.8161 18.367 43.1429 19.3748 42.5493 19.953L34.3768 27.913C34.1409 28.1427 34.0333 28.4739 34.0889 28.7984L36.0174 40.0426C36.1575 40.8596 35.3 41.4826 34.5664 41.0967L24.4655 35.7848C24.1741 35.6315 23.8259 35.6315 23.5345 35.7848L13.4336 41.0967C12.7 41.4826 11.8425 40.8596 11.9826 40.0426L13.9111 28.7984C13.9667 28.4739 13.8591 28.1427 13.6232 27.913L5.45068 19.953C4.85708 19.3748 5.18387 18.367 6.00378 18.2471L17.3004 16.596C17.6257 16.5484 17.9069 16.3439 18.0524 16.0491L23.1033 5.81663Z"
      fill={mainColor}
      stroke={mainColor}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 12L10 8"
      stroke={secondaryColor}
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M34 12L38 8"
      stroke={secondaryColor}
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M8.72973 30.2678L3.26562 31.7319"
      stroke={secondaryColor}
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M44.4641 31.7322L39 30.2681"
      stroke={secondaryColor}
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);

export default StarIcon;
