import React from "react";
import styled, { useTheme } from "styled-components";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import amplitude from "amplitude-js";

import CopyLinkIcon from "../../assets/CopyLinkIcon";
import elon from "../../assets/DrunkStartup/elon.png";
import { MOBILE_BREAKPOINT } from "../../types/breakpoints";
import { ActionButton } from "../common";
import BrainstormIcon from "../../assets/BrainstormIcon";
import FundingModal from "./FundingModal";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  selectDsCanvasMinimized,
  setDsCanvasMinimized,
} from "../../store/features/DrunkStartup/drunkStartupSlice";
import LeaderBoardListMenu from "./LeaderBoardListMenu";
import MixerBuddiesList from "./MixerBuddiesList";
import { ZIndexLevel } from "../../utils/zIndex.enum";
import { TDDocument, Tldraw, TldrawApp } from "@tldraw/tldraw";
import ZoomOptionsControlSkin from "./ZoomOptionsControlSkin";
import { SegmentGroupIdsMapInterface } from "./DsBackToLoungeCanvas";
import { isEmpty, mergeWith } from "lodash";
import OutsideClickHandler from "react-outside-click-handler";
import MobileLeaderboardPanel from "./MobileLeaderBoardPanel";
import MinusIcon from "../../assets/MinusIcon";
import { selectTotalOnStage } from "../../store/features/Lounge/loungeSlice";
import { useSize } from "../../hooks";
import { getDrunkStartupsShareUrl } from "../../utils/DrunkStartups";
import { ArrowContainer } from "./ChooseOrAddIdea";
import NextArrow from "../../assets/NextArrow";
import { copyToClipboard } from "../../utils";
import ToastMessage from "../common/ToastMessage";
import { GreenTick } from "../../assets/GreenTick";
import { fetchFundingLeaderBoard } from "../../API/drunkStartups";
import {
  selectFundUpdated,
  setFundUpdated,
} from "../../store/features/parentSlice";
import { AmplitudeEvents } from "../../types/amplitudeEvents";

const MinimizeContainer = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
  background: ${(props) => props.theme.color.white};
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  z-index: 1;
  cursor: pointer;
`;

export const DsLoungeCanvasContainer = styled.div<{
  width?: string;
  isMobile?: boolean;
  isMinimized?: boolean;
  totalOnStage?: number;
}>`
  position: absolute;
  left: ${(props) => (props.isMinimized ? "28px" : "50%")};
  transform: translateX(${(props) => (props.isMinimized ? "0" : "-50%")})
    scale(${(props) => (props.isMinimized ? 0 : 1)});
  top: ${(props) =>
    props.isMinimized
      ? "28px"
      : (props.totalOnStage || 0) > 0
      ? "316px"
      : "180px"};
  height: calc(
    100vh - ${(props) => ((props.totalOnStage || 0) > 0 ? 316 : 180)}px - 108px
  );
  display: flex;
  transition: all 0.4s ease-in-out;
  transform-origin: top left;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.width || "764px"};
  background: ${(props) => props.theme.color.white};
  box-shadow: 0px 4px 16px -2px rgba(0, 0, 0, 0.08);
  border-radius: 32px;
  box-sizing: border-box;
  padding: ${(props) => (props.isMobile ? "6px" : "20px 12px 12px")};
  z-index: 4;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    justify-content: flex-start;
    top: 0;
    bottom: 100px;
    width: 100%;
    height: calc(100vh - 100px);
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.12);
    border-radius: 0px 0px 24px 24px;
  }

  &:hover {
    ${MinimizeContainer} {
      display: flex;
    }
  }
`;

const Header = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: ${(props) => (props.isMobile ? "6px 12px" : "0")};
  z-index: 2;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  font-family: Labil;
  font-weight: 700;
  font-size: 20px;
  line-height: 160%;
  color: ${(props) => props.theme.color.darkGray};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CanvasNumber = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Space Mono";
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  width: 32px;
  height: 32px;
  background: ${(props) =>
    props.isActive ? props.theme.color.purple500 : props.theme.color.purple100};
  color: ${(props) =>
    props.isActive ? props.theme.color.white : props.theme.color.purple500};
  border-radius: 50%;
  margin: 0 4px;
  cursor: pointer;

  &:hover {
    background: ${(props) =>
      props.isActive
        ? props.theme.color.purple600
        : props.theme.color.purple200};
    color: ${(props) =>
      props.isActive ? props.theme.color.white : props.theme.color.purple600};
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 16px;
  background: ${(props) => props.theme.lounge.backgroundHover};
  border-radius: 4px;
  margin: 0 13px;
  box-sizing: border-box;
`;

const FundThisButton = styled(motion.button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 114px;
  box-sizing: border-box;
  height: 32px;
  background: ${(props) => props.theme.color.green500};
  border-radius: 24px;
  cursor: pointer;
  font-weight: 600;
  font-size: 13px;
  line-height: 160%;
  color: ${(props) => props.theme.color.white};
  border: none;
  user-select: none;
  margin: 0 4px;

  &:hover {
    background: ${(props) => props.theme.color.green600};
  }
  &:disabled {
    background: ${(props) => props.theme.color.purple200};
  }
`;

const ElonIcon = styled.a<{ bg: string }>`
  width: 32px;
  height: 32px;
  background: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-position-x: center;
  margin: 0 4px;
  cursor: pointer;
  &:hover {
    transform: rotate(-15deg);
  }
  transition: transform 0.2s ease-in-out;
`;

const ShareLink = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: ${(props) => props.theme.color.orange100};
  border-radius: 50%;
  margin: 0 0 0 4px;
  cursor: pointer;
  &:hover {
    background: ${(props) => props.theme.color.orange200};
  }
`;

const CanvasContainer = styled.div<{ isMobile: boolean }>`
  width: ${(props) => (props.isMobile ? "fill-available" : "100%")};
  /* height: 429px; */
  flex-grow: 1;
  box-sizing: border-box;
  border-radius: 24px;
  margin-top: 8px;
  position: ${(props) => (props.isMobile ? "absolute" : "relative")};
  height: ${(props) => props.isMobile && "100%"};
  margin: ${(props) => (props.isMobile ? "0 6px" : "8px 0 0 0")};
  z-index: 1;

  & #tl {
    border-radius: 24px;
  }

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    border: ${(props) =>
      props.isMobile
        ? "none"
        : `1px solid ${props.theme.lounge.backgroundHover}`};
  }
`;

const MixerBuddiesListContainer = styled.div`
  z-index: ${ZIndexLevel.TOOLBAR_OPTIONS_INDEX};
  padding: 5px 0px;
  display: flex;
  background: ${(props) => props.theme.color.white};
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 12%);
  border-radius: 16px;
  align-items: center;
  max-width: 375px;
  overflow-x: hidden;
  margin-right: 0.5rem;
`;

const TeamDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

const TeamText = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: ${(props) => props.theme.color.darkGray};
`;

const TeamName = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 160%;
  color: ${(props) => props.theme.color.black};
  text-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
`;

const Prompt = styled.div<{ isMobile: boolean }>`
  position: absolute;
  top: ${(props) => (props.isMobile ? "58px" : "12px")};
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: fit-content;
  padding: 8px 10px;
  box-sizing: border-box;
  background: ${(props) => props.theme.color.white};
  box-shadow: 0px 4px 16px -2px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  z-index: 1;
`;

const IdeaText = styled.div`
  font-weight: 600;
  font-size: 11px;
  line-height: 160%;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${(props) => props.theme.color.lightGray};
`;

const PromptText = styled.div`
  max-width: 324px;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${(props) => props.theme.color.black};
`;

const PromptButton = styled(ActionButton)`
  background: ${(props) => props.theme.color.white};
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.12);
  border-radius: 12px;

  &:hover {
    background: ${(props) => props.theme.color.white};
  }
`;

const CanvasBottomToolBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  bottom: 1.3rem;
  z-index: ${ZIndexLevel.CANVAS_TOOLBAR_OPTIONS_INDEX};

  /* hiding mixer buddies list on mobile devices */
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

const CanvasBottomToolBarLeftMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;
`;

const EditorContainer = styled.div<{ height: number; width: number }>`
  position: fixed;
  width: ${({ width }) => (width ? `${width}px` : `500px`)};
  height: ${({ height }) => (height ? `${height}px` : `500px`)};

  /* reduce some height on mobile device*/
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    height: ${({ height }) => (height ? `${height - 64}px` : `500px`)};
  }
`;

const NavigationHandlers = styled.div`
  display: flex;
  width: 100%;
  position: absolute;
  top: 50%;
  z-index: ${ZIndexLevel.TOOLBAR_OPTIONS_INDEX};
  justify-content: space-between;

  /* hiding  NavigationHandlers on desktop device*/
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

const MobileArrowContainer = styled(ArrowContainer)<{ disabled?: boolean }>`
  height: 36px;
  width: 36px;
  border-radius: 24px;
  margin: 0 1rem;
  background: ${({ disabled, theme }) =>
    disabled ? `none` : theme.color.white};
  box-shadow: ${({ disabled }) =>
    disabled ? `none` : `0px 2px 4px - 1px rgba(0, 0, 0, 0.12)`};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "all")};
`;

export interface SegmentsWithFundAmountItem {
  amount?: number;
  participants: Array<string>;
  index: number;
}

export interface SegmentsWithFundAmountMapInterface {
  [key: string]: SegmentsWithFundAmountItem;
}

interface DsViewOnlyCanvasProps {
  activeSegmentGroup: number;
  setActiveSegmentGroup: React.Dispatch<React.SetStateAction<number>>;
  roomStorage: any[];
  mixerBuddies?: Array<string>;
  canvasRef: React.RefObject<HTMLDivElement>;
  initialDocument: {};
  showFundThis?: boolean;
  showTeam?: boolean;
  showPrompt?: boolean;
  isMobile?: boolean; // Use this flag only for share page
  width?: string;
  segmentGroupIdsMap?: SegmentGroupIdsMapInterface;
  allowMinimize?: boolean;
  promptText?: string;
  dsSegmentId: string;
}

const AppContext = React.createContext({} as TldrawApp);

const DsViewOnlyCanvas: React.FC<DsViewOnlyCanvasProps> = ({
  activeSegmentGroup,
  setActiveSegmentGroup,
  roomStorage,
  mixerBuddies = [],
  canvasRef,
  initialDocument,
  showFundThis = true,
  showTeam = false,
  showPrompt = false,
  isMobile = false,
  width = "764px",
  segmentGroupIdsMap = {},
  allowMinimize = false,
  promptText,
  dsSegmentId,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const canvasRefSize = useSize(canvasRef);

  const [collapsePrompt, setCollapsePrompt] = React.useState(false);
  const [showFundingModal, setShowFundingModal] = React.useState(false);
  const [copied, setCopied] = React.useState(false);
  const [isCopyButtonTapped, setIsCopyButtonTapped] = React.useState(false);
  const [segmentsWithFundAmount, setSegmentsWithFundAmount] =
    React.useState<SegmentsWithFundAmountMapInterface>({});

  const dsCanvasMinimized = useAppSelector(selectDsCanvasMinimized);
  const totalOnStage = useAppSelector(selectTotalOnStage);
  const [app, setApp] = React.useState<TldrawApp>();
  const isFundUpdated = useAppSelector(selectFundUpdated);

  React.useEffect(() => {
    setTimeout(() => {
      setCollapsePrompt(true);
    }, 5000);
  }, []);

  React.useEffect(() => {
    (async () => {
      if (dsSegmentId) {
        const resData = await fetchFundingLeaderBoard(dsSegmentId);
        if (resData) {
          let customizer = (
            objValue: number,
            srcValue: SegmentsWithFundAmountItem
          ) => {
            return { amount: objValue || 0, ...srcValue };
          };
          let mergedSegmentsWithFundAmount = mergeWith(
            resData,
            segmentGroupIdsMap,
            customizer
          );
          setSegmentsWithFundAmount(mergedSegmentsWithFundAmount);
          dispatch(setFundUpdated(false));
        }
      }
    })();
  }, [isFundUpdated, segmentGroupIdsMap]);

  const getCurrentSegmentFundAMount = (activeSegmentId: string) => {
    return segmentsWithFundAmount?.[activeSegmentId]?.amount || false;
  };

  const getMixerBuddiesList = (
    activeSegmentGroupIndex: number
  ): Array<string> => {
    const segmentGroupId =
      roomStorage?.[activeSegmentGroupIndex]?.segmentGroupId;
    if (isEmpty(segmentGroupIdsMap)) return [];

    return segmentGroupIdsMap
      ? segmentGroupIdsMap[segmentGroupId].participants
      : [];
  };

  const onMount = React.useCallback(
    (tl_app: TldrawApp) => {
      tl_app?.toggleGrid();
      tl_app?.pause();
      setApp(tl_app);

      setTimeout(() => {
        tl_app && tl_app?.updateDocument(initialDocument as TDDocument);
        tl_app && tl_app?.zoomToFit();
      }, 100);
    },
    [initialDocument, activeSegmentGroup]
  );

  React.useEffect(() => {
    app && app?.updateDocument(initialDocument as TDDocument);
    app && app?.zoomToFit();
  }, [app, activeSegmentGroup, initialDocument]);

  const tweetToElon = () => {
    amplitude.getInstance().logEvent(AmplitudeEvents.DS_TWEETED_TO_ELON);
    const fund = getCurrentSegmentFundAMount(
      roomStorage?.[activeSegmentGroup]?.segmentGroupId
    );
    const raiseFundText = fund
      ? `and have raised $${fund}mn in the pre-seed round`
      : `and are now looking to raise the pre-seed round`;
    const tweet = `@elonmusk we were brainstorming on "${promptText}" @ThursdayByFolly, ${raiseFundText}. 🎉 %0A%0AYou can lead the round if you put in $100M more of $DOGE. What say? ${getDrunkStartupsShareUrl(
      dsSegmentId,
      activeSegmentGroup
    )}`;
    const url = `https://twitter.com/intent/tweet?text=${tweet}`;
    return url;
  };

  const handleCopy = () => {
    copyToClipboard(getDrunkStartupsShareUrl(dsSegmentId, activeSegmentGroup));
    toast.dismiss();
    toast(
      <ToastMessage
        message="Link copied to clipboard"
        icon={<GreenTick width={24} height={24} />}
      />,
      {
        position: "top-center",
        delay: 500,
        autoClose: 2000,
        closeOnClick: false,
        closeButton: false,
        rtl: false,
        pauseOnFocusLoss: false,
        draggable: false,
        className: "ds-copy-toast",
        style: {
          padding: "16px 24px",
          height: "58px",
          background: theme.color.white,
          color: theme.color.black,
          borderRadius: "16px",
          boxShadow: "0px 4px 16px -2px rgba(0, 0, 0, 0.08)",
          width: "max-content",
          margin: "0",
        },
      }
    );
    amplitude.getInstance().logEvent(AmplitudeEvents.DS_LINK_COPIED);
  };

  const handleCopyButtonMouseTap = () => {
    setIsCopyButtonTapped(true);
    setTimeout(() => {
      setIsCopyButtonTapped(false);
    }, 100);
  };

  return (
    <DsLoungeCanvasContainer
      width={width}
      isMobile={isMobile}
      isMinimized={dsCanvasMinimized}
      totalOnStage={totalOnStage}
    >
      {allowMinimize && (
        <MinimizeContainer
          onClick={() => {
            dispatch(setDsCanvasMinimized(true));
          }}
        >
          <MinusIcon />
        </MinimizeContainer>
      )}
      <Header isMobile={isMobile}>
        {showTeam ? (
          <TeamDetails>
            <TeamText>Team</TeamText>
            <TeamName>Quirky parrots</TeamName>
          </TeamDetails>
        ) : (
          <Title>Drunk startups</Title>
        )}
        <Row>
          {!isMobile &&
            Object.values(segmentGroupIdsMap).map(({ index }) => (
              <CanvasNumber
                key={index}
                isActive={index === activeSegmentGroup + 1}
                onClick={() => setActiveSegmentGroup(index - 1)}
              >
                {index}
              </CanvasNumber>
            ))}
          {showFundThis && (
            <>
              <Divider />
              <FundThisButton
                onClick={() => setShowFundingModal(!showFundingModal)}
              >
                $ Fund this
              </FundThisButton>
              <ElonIcon bg={elon} href={tweetToElon()} target="_blank" />
            </>
          )}
          {showTeam && isMobile ? (
            <PromptButton
              width={40}
              height={40}
              onClick={() => setCollapsePrompt((val) => !val)}
            >
              {collapsePrompt ? (
                <BrainstormIcon
                  fill="white"
                  stroke={theme.color.darkGray}
                  highlight={theme.color.lightGray}
                  bottom={theme.color.darkGray}
                />
              ) : (
                <BrainstormIcon />
              )}
            </PromptButton>
          ) : (
            <ShareLink
              onClick={handleCopy}
              onTap={handleCopyButtonMouseTap}
              onMouseDown={() => setCopied(true)}
              onMouseUp={() => setCopied(false)}
              onMouseLeave={() => setCopied(false)}
              animate={{
                scale: copied || isCopyButtonTapped ? 0.8 : 1,
              }}
            >
              <CopyLinkIcon color={theme.color.orange500} />
            </ShareLink>
          )}

          {showFundingModal && (
            <OutsideClickHandler
              onOutsideClick={() => setShowFundingModal(false)}
            >
              <FundingModal
                setShowFundingModal={setShowFundingModal}
                segmentId={dsSegmentId}
                segmentGroupIdsMap={segmentGroupIdsMap}
              />
            </OutsideClickHandler>
          )}
        </Row>
      </Header>
      <NavigationHandlers>
        <MobileArrowContainer
          isLeft
          onClick={() => setActiveSegmentGroup(activeSegmentGroup - 1)}
          hide={Object.keys(segmentGroupIdsMap).length === 1}
          disabled={activeSegmentGroup === 0}
        >
          <NextArrow
            isLeft
            color={theme.color.black}
            isDisabled={activeSegmentGroup === 0}
          />
        </MobileArrowContainer>
        <MobileArrowContainer
          onClick={() => setActiveSegmentGroup(activeSegmentGroup + 1)}
          hide={Object.keys(segmentGroupIdsMap).length === 1}
          disabled={
            activeSegmentGroup === Object.keys(segmentGroupIdsMap).length - 1
          }
        >
          <NextArrow
            color={theme.color.black}
            isDisabled={
              activeSegmentGroup === Object.keys(segmentGroupIdsMap).length - 1
            }
          />
        </MobileArrowContainer>
      </NavigationHandlers>
      <CanvasContainer ref={canvasRef} isMobile={isMobile}>
        {showPrompt &&
          ((!collapsePrompt && isMobile) || !isMobile) &&
          promptText && (
            <Prompt isMobile={isMobile}>
              <IdeaText>the idea</IdeaText>
              <PromptText>{promptText}</PromptText>
            </Prompt>
          )}
        <EditorContainer
          width={canvasRefSize?.width || 737}
          height={canvasRefSize?.height || 500}
        >
          {roomStorage?.[activeSegmentGroup]?.segmentGroupId && (
            <Tldraw
              showPages={false}
              showMenu={false}
              showStyles={false}
              showUI={false}
              disableAssets={false}
              readOnly={true}
              onMount={onMount}
            />
          )}
        </EditorContainer>
      </CanvasContainer>

      <CanvasBottomToolBar>
        <LeaderBoardListMenu
          key={activeSegmentGroup}
          segmentGroupIdsMap={segmentGroupIdsMap}
          activeSegmentGroup={roomStorage?.[activeSegmentGroup]?.segmentGroupId}
          segmentsWithFundAmount={segmentsWithFundAmount}
          getCurrentSegmentFundAMount={getCurrentSegmentFundAMount}
        />

        <CanvasBottomToolBarLeftMenu>
          {app && (
            <AppContext.Provider value={app}>
              <ZoomOptionsControlSkin tlApp={app} />
            </AppContext.Provider>
          )}

          <MixerBuddiesListContainer>
            <MixerBuddiesList
              mixerBuddies={getMixerBuddiesList(activeSegmentGroup)}
            />
          </MixerBuddiesListContainer>
        </CanvasBottomToolBarLeftMenu>
      </CanvasBottomToolBar>

      {/* {isMobile && ( */}
      <MobileLeaderboardPanel
        segmentGroupIdsMap={segmentGroupIdsMap}
        activeSegmentGroup={roomStorage?.[activeSegmentGroup]?.segmentGroupId}
      />
      {/* )} */}
    </DsLoungeCanvasContainer>
  );
};

export default DsViewOnlyCanvas;
