import React from "react";
import styled from "styled-components";
import BlueShareIcon from "../../assets/BlueShareIcon";
import { joinBanner } from "../../assets/CdnUrls";
import MonitorIcon from "../../assets/MonitorIcon";

import {
  selectEventDateTime,
  selectEventInstanceName,
} from "../../store/features/eventSlice";
import { useAppSelector } from "../../store/hooks";
import { handleShare } from "../../utils";

const Container = styled.div<{ background: string }>`
  display: flex;
  flex-direction: column;
  background: ${({ background }) => `url(${background})`};
  background-size: cover;
  padding: 40px 24px;
  box-sizing: border-box;
  justify-content: center;
  min-width: 100vw;
  min-height: 100vh;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 16px 16px;
  height: 100%;

  height: 322px;
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0px 4px 16px -2px rgba(0, 0, 0, 0.08);
  border-radius: 32px;
`;

const MonitorIconConatiner = styled.div`
  align-self: flex-start;
`;

const Msg = styled.div`
  align-self: flex-start;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 160%;
  letter-spacing: -0.01em;
  color: ${({ theme }) => theme.color.black};
  margin-top: 22px;
`;
const ShareButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const ShareMsg = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 160%;
  letter-spacing: -0.01em;
  color: ${({ theme }) => theme.color.darkGray};
  margin-bottom: 8px;
`;

const ShareButton = styled.button`
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 15px 24px;
  gap: 10px;
  border: none;
  background: ${({ theme }) => theme.color.blue100};
  border-radius: 16px;
  width: 100%;

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: -0.01em;
  color: ${({ theme }) => theme.color.blue500};
`;

const JoinedFromMobile: React.FC = () => {
  const eventInstanceName = useAppSelector(selectEventInstanceName);
  const eventDateTime = useAppSelector(selectEventDateTime);

  return (
    <Container background={joinBanner}>
      <Content>
        <MonitorIconConatiner>
          <MonitorIcon />
        </MonitorIconConatiner>
        <Msg>Thursday is only available on desktop for now</Msg>
        <ShareButtonContainer>
          <ShareMsg>Open this link on your desktop to access</ShareMsg>
          <ShareButton
            type="button"
            onClick={() => handleShare(eventInstanceName, eventDateTime)}
          >
            <BlueShareIcon />
            Share
          </ShareButton>
        </ShareButtonContainer>
      </Content>
    </Container>
  );
};

export default JoinedFromMobile;
