export const FlashIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 2L3 14H12L11 22L21 10H12L13 2Z"
        fill="#FFC200"
        stroke="#FFC200"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const TrophyIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 21H15"
        stroke="#05AAFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 17.25V21"
        stroke="#05AAFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5811 12H19.4998C20.2955 12 21.0585 11.6839 21.6211 11.1213C22.1837 10.5587 22.4998 9.79565 22.4998 9V7.5C22.4998 7.30109 22.4208 7.11032 22.2801 6.96967C22.1395 6.82902 21.9487 6.75 21.7498 6.75H18.7498"
        stroke="#05AAFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.4376 12H4.49072C3.69507 12 2.93201 11.6839 2.3694 11.1213C1.80679 10.5587 1.49072 9.79565 1.49072 9V7.5C1.49072 7.30109 1.56974 7.11032 1.71039 6.96967C1.85104 6.82902 2.04181 6.75 2.24072 6.75H5.24072"
        stroke="#05AAFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 5.25V10.4156C5.25 14.1375 8.23125 17.2219 11.9531 17.25C12.8435 17.2562 13.7263 17.0862 14.5506 16.7497C15.375 16.4133 16.1247 15.917 16.7564 15.2896C17.3882 14.6622 17.8896 13.916 18.2317 13.094C18.5739 12.272 18.75 11.3904 18.75 10.5V5.25C18.75 5.05109 18.671 4.86032 18.5303 4.71967C18.3897 4.57902 18.1989 4.5 18 4.5H6C5.80109 4.5 5.61032 4.57902 5.46967 4.71967C5.32902 4.86032 5.25 5.05109 5.25 5.25Z"
        fill="#69CCFF"
        stroke="#69CCFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const MagnifyingGlassIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 18L21 21"
        stroke="#FFDA66"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.875 18.75C15.2242 18.75 18.75 15.2242 18.75 10.875C18.75 6.52576 15.2242 3 10.875 3C6.52576 3 3 6.52576 3 10.875C3 15.2242 6.52576 18.75 10.875 18.75Z"
        fill="#FFF9E6"
        stroke="#FFC200"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ClownIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="2" y="2" width="20" height="20" rx="10" fill="#FBDADA" />
      <path
        d="M9.29992 6.60369L9.30032 6.60367L9.29873 6.59218C9.24407 6.19702 8.96791 5.83333 8.57135 5.83333C8.3505 5.83333 8.16233 5.95126 8.0348 6.11863C7.90736 6.28588 7.83333 6.50961 7.83333 6.74987V8.25012H7.83295L7.83372 8.26145L7.8429 8.39631L7.8425 8.39633L7.84409 8.40784C7.8988 8.80294 8.17493 9.16667 8.57132 9.16667C8.79224 9.16667 8.98045 9.04877 9.10802 8.88138C9.23548 8.71412 9.30949 8.49039 9.30949 8.25013V6.74988H9.30987L9.3091 6.73855L9.29992 6.60369ZM16.1571 6.60369L16.1575 6.60367L16.1559 6.59218C16.1012 6.19702 15.8251 5.83333 15.4285 5.83333C15.2077 5.83333 15.0195 5.95126 14.892 6.11863C14.7645 6.28588 14.6905 6.50961 14.6905 6.74987V8.25012H14.6901L14.6909 8.26145L14.7001 8.39631L14.6997 8.39633L14.7013 8.40784C14.756 8.80294 15.0321 9.16667 15.4285 9.16667C15.6494 9.16667 15.8376 9.04877 15.9652 8.88138C16.0927 8.71412 16.1667 8.49039 16.1667 8.25013V6.74988H16.1671L16.1663 6.73855L16.1571 6.60369Z"
        fill="#EC4747"
        stroke="#EC4747"
        strokeWidth="0.333333"
      />
      <path
        d="M14.1434 17.0596L14.1434 17.0597C14.3464 17.4495 14.2118 17.9519 13.8411 18.188C12.6387 18.9537 11.3232 19.1518 9.94381 18.7822C8.56463 18.4127 7.52441 17.5834 6.86596 16.319L6.86596 16.319C6.66296 15.9292 6.79754 15.4268 7.1683 15.1907C7.54858 14.9486 8.03223 15.0782 8.24047 15.478L8.24049 15.478C8.69556 16.3522 9.39266 16.9091 10.3752 17.1724C11.3576 17.4356 12.2398 17.3019 13.0712 16.7724L13.0712 16.7724C13.4516 16.5302 13.935 16.6598 14.1434 17.0596Z"
        fill="#EC4747"
        stroke="#EC4747"
        strokeWidth="0.333333"
      />
      <path
        d="M18.0989 11.334L18.5833 11.3335C19.3657 11.3335 20 11.9301 20 12.6662C20 13.3589 19.4381 13.9282 18.7198 13.9929L18.5833 13.999L17.7969 13.9999L12.4721 13.9988C12.2114 13.9988 12 13.7998 12 13.5545V11.7774C12 11.5594 12.167 11.3781 12.3872 11.3404L12.4721 11.3333L18.0989 11.334Z"
        fill="#EC4747"
      />
    </svg>
  );
};

export const ArrowsOutHorizontalIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 4V20"
        stroke="#78D2B4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 9H7C8.10457 9 9 9.89543 9 11V12"
        stroke="#1EB482"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 12.5L1.5 9L5 5.5"
        stroke="#1EB482"
        strokeWidth="2.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5 15H17C15.8954 15 15 15.8954 15 17V18"
        stroke="#1EB482"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 11.5L22.5 15L19 18.5"
        stroke="#1EB482"
        strokeWidth="2.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const FlagIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.75 15.7501C9.75 11.2501 14.25 20.2501 20.25 15.7501V4.50009C14.25 9.00009 9.75 8.7887e-05 3.75 4.50009"
        fill="#F4B07F"
      />
      <path
        d="M3.75 15.7501C9.75 11.2501 14.25 20.2501 20.25 15.7501V4.50009C14.25 9.00009 9.75 8.7887e-05 3.75 4.50009"
        stroke="#F4B07F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.75 20.25V4.5"
        stroke="#ED7B2A"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PlusCircleIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
        fill="#78D2B4"
        stroke="#78D2B4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 8V16"
        stroke="#12996C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 12H16"
        stroke="#12996C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const FastForwardCircleIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        fill="#F4B07F"
        stroke="#F4B07F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 15.5713L11.8571 11.9999L6.5 8.42847V15.5713Z"
        fill="#ED7B2A"
        stroke="#ED7B2A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6426 15.5713L18.9997 11.9999L13.6426 8.42847V15.5713Z"
        fill="#ED7B2A"
        stroke="#ED7B2A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const MegaphoneIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.25 7.5V19.0969C14.2489 19.2215 14.2792 19.3444 14.3382 19.4542C14.3972 19.564 14.483 19.6572 14.5875 19.725L15.6188 20.4094C15.7187 20.4761 15.8331 20.5178 15.9525 20.531C16.0719 20.5443 16.1928 20.5287 16.3049 20.4855C16.4169 20.4423 16.517 20.3728 16.5967 20.2829C16.6763 20.193 16.7332 20.0852 16.7625 19.9688L18 15"
        fill="#EC4747"
      />
      <path
        d="M14.25 7.5V19.0969C14.2489 19.2215 14.2792 19.3444 14.3382 19.4542C14.3972 19.564 14.483 19.6572 14.5875 19.725L15.6188 20.4094C15.7187 20.4761 15.8331 20.5178 15.9525 20.531C16.0719 20.5443 16.1928 20.5287 16.3049 20.4855C16.4169 20.4423 16.517 20.3728 16.5967 20.2829C16.6763 20.193 16.7332 20.0852 16.7625 19.9688L18 15"
        stroke="#EC4747"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 15C18.9946 15 19.9484 14.6049 20.6516 13.9017C21.3549 13.1984 21.75 12.2446 21.75 11.25C21.75 10.2555 21.3549 9.30164 20.6516 8.59838C19.9484 7.89511 18.9946 7.50003 18 7.50003H14.25C14.25 7.50003 9.14062 7.50003 4.22812 3.3844C4.11886 3.29398 3.9862 3.2364 3.84553 3.21833C3.70486 3.20026 3.56195 3.22245 3.43338 3.28232C3.30481 3.34219 3.19585 3.43728 3.11914 3.55657C3.04244 3.67586 3.00112 3.81446 3 3.95628V18.5438C3.00112 18.6856 3.04244 18.8242 3.11914 18.9435C3.19585 19.0628 3.30481 19.1579 3.43338 19.2177C3.56195 19.2776 3.70486 19.2998 3.84553 19.2817C3.9862 19.2637 4.11886 19.2061 4.22812 19.1157C9.14062 15 14.25 15 14.25 15H18Z"
        fill="#F49191"
        stroke="#F49191"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 7.5V19.0969C14.2489 19.2215 14.2792 19.3444 14.3382 19.4542C14.3972 19.564 14.483 19.6572 14.5875 19.725L15.6188 20.4094C15.7187 20.4761 15.8331 20.5178 15.9525 20.531C16.0719 20.5443 16.1928 20.5287 16.3049 20.4855C16.4169 20.4423 16.517 20.3728 16.5967 20.2829C16.6763 20.193 16.7332 20.0852 16.7625 19.9688"
        stroke="#EC4747"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
