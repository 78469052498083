import { LocalStorageKeys } from "./localstorage";

export const removeActiveOrganizationIdFromLocalStorage = () => {
  localStorage.removeItem(LocalStorageKeys.ActiveOrganizationId);
};

export const setActiveOrganizationIdToLocalStorage = (activeOrgId: string) => {
  localStorage.setItem(LocalStorageKeys.ActiveOrganizationId, activeOrgId);
};

export const getActiveOrganizationIdFromLocalStorage = () => {
  return localStorage.getItem(LocalStorageKeys.ActiveOrganizationId);
};
