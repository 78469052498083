import { FunctionComponent } from "react";
import styled from "styled-components";

interface InputFieldProps {
  maxLength?: number;
  placeholder?: string;
  onInput?: Function;
  width?: string;
  value?: string;
  isError?: boolean;
  textColor?: string;
  height?: string;
  customPadding?: string;
  onFocusHandler?: Function;
  onBlurHandler?: Function;
}

const Input = styled.input<{
  width?: string;
  isError?: boolean;
  textColor?: string;
  customPadding?: string;
}>`
  padding: ${({ customPadding }) => customPadding || "15px 16px"};
  background: ${({ theme, isError }) =>
    isError ? theme.color.red100 : theme.color.backgroundGray};
  border-radius: 16px;
  border: none;
  font-size: 16px;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  color: ${({ theme, textColor }) => textColor || theme.color.darkGray};

  &:hover {
    background: ${({ theme, isError }) =>
      isError ? theme.color.red200 : theme.lounge.backgroundHover};
  }

  &:focus {
    border: none;
    outline: none;
  }

  &::placeholder {
    font-weight: 500;
    font-size: 16px;
    font-style: italic;
    color: ${({ theme }) => theme.color.darkGray};

    opacity: 0.35;
  }
`;

const InputField: FunctionComponent<InputFieldProps> = ({
  maxLength = 30,
  placeholder = "",
  onInput = () => {},
  width,
  height,
  value,
  isError = false,
  textColor = "",
  customPadding,
  onFocusHandler = () => {},
  onBlurHandler = () => {},
}) => {
  return (
    <Input
      isError={isError}
      onChange={(ev) => {
        onInput(ev.target?.value);
      }}
      height={height}
      customPadding={customPadding}
      textColor={textColor}
      value={value}
      width={width}
      maxLength={maxLength}
      placeholder={placeholder}
      onFocus={() => onFocusHandler()}
      onBlur={() => onBlurHandler()}
    />
  );
};

export default InputField;
