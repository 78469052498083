import { FunctionComponent } from "react";

interface TeamIconProps {}

const TeamIcon: FunctionComponent<TeamIconProps> = () => {
  return (
    <svg
      width="72"
      height="41"
      viewBox="0 0 72 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 12.5C18.8284 12.5 19.5 11.8284 19.5 11C19.5 10.1716 18.8284 9.5 18 9.5C17.1716 9.5 16.5 10.1716 16.5 11C16.5 11.8284 17.1716 12.5 18 12.5Z"
        fill="#A385FF"
        stroke="#A385FF"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 33.5L18 24.5L22.5 33.5"
        stroke="#A385FF"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 15.5L18 18.5L27 15.5"
        stroke="#A385FF"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 18.5V24.5"
        stroke="#A385FF"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54 12.5C54.8284 12.5 55.5 11.8284 55.5 11C55.5 10.1716 54.8284 9.5 54 9.5C53.1716 9.5 52.5 10.1716 52.5 11C52.5 11.8284 53.1716 12.5 54 12.5Z"
        fill="#A385FF"
        stroke="#A385FF"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.5 33.5L54 24.5L58.5 33.5"
        stroke="#A385FF"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45 15.5L54 18.5L63 15.5"
        stroke="#A385FF"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54 18.5V24.5"
        stroke="#A385FF"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.9999 10.5C36.9204 10.5 37.6666 9.75383 37.6666 8.83335C37.6666 7.91288 36.9204 7.16669 35.9999 7.16669C35.0794 7.16669 34.3333 7.91288 34.3333 8.83335C34.3333 9.75383 35.0794 10.5 35.9999 10.5Z"
        fill="#6633FF"
        stroke="#6633FF"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31 33.8333L36 23.8333L41 33.8333"
        stroke="#6633FF"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26 13.8333L36 17.1666L46 13.8333"
        stroke="#6633FF"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36 17.1667V23.8334"
        stroke="#6633FF"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TeamIcon;
