import { FunctionComponent } from "react";
import styled from "styled-components";
import GlobeToastIcon from "../../assets/GlobeToastIcon";
import SadFaceEmoji from "../../assets/SadFaceEmoji";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const IconContainer = styled.div<{ iconSize: number }>`
  font-size: ${({ iconSize }) => iconSize}px;
`;

const ToastMsg = styled.div`
  margin-left: 13px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  max-width: 347px;
`;

const RefreshButton = styled.div`
  width: 115px;
  height: 26px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 160%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: ${(props) => props.theme.color.purple500};
  flex: none;
  order: 3;
  flex-grow: 0;
`;

const Rectangle = styled.div`
  width: 2px;
  height: 26px;
  background: ${(props) => props.theme.lounge.background};
  border-radius: 4px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 0px 12px;
`;

export enum ErrorMessageIconNames {
  GLOBE = "GLOBE",
  SADFACE = "SADFACE",
  WARNING = "WARNING",
  UPSET = "UPSET",
}

interface ErrorMessageToastProps {
  message?: string;
  showActionBtn?: boolean;
  iconName?: ErrorMessageIconNames;
  iconSize?: number;
}

const ErrorMessageToast: FunctionComponent<ErrorMessageToastProps> = ({
  message = "Something went wrong!",
  showActionBtn = true,
  iconName = ErrorMessageIconNames.GLOBE,
  iconSize = 16,
}) => {
  const icons = {
    [ErrorMessageIconNames.GLOBE]: <GlobeToastIcon />,
    [ErrorMessageIconNames.SADFACE]: <>😔</>,
    [ErrorMessageIconNames.WARNING]: <>⚠️</>,
    [ErrorMessageIconNames.UPSET]: <SadFaceEmoji />,
  };

  return (
    <Container>
      <IconContainer iconSize={iconSize}>{icons[iconName]}</IconContainer>
      <ToastMsg>{message}</ToastMsg>
      {showActionBtn && (
        <>
          <Rectangle></Rectangle>
          <RefreshButton onClick={() => window.location.reload()}>
            REFRESH NOW
          </RefreshButton>
        </>
      )}
    </Container>
  );
};

export default ErrorMessageToast;
