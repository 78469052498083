export const Charades_Description =
  "Compete in teams. Act out smart, so your team can guess the word fast!";

export const CHARADES_ACTOR_VIDEO_RESOLUTION = 480;

export const Charades_Bg_Color = "blue";

export const TIME_TO_GIVE_WORD = 23;

export const TIME_TO_GIVE_WORD_MANUALLY = 20;

export const TIME_FOR_SENT = 22;

export const TOTAL_TIME_FOR_WORD = 75;

export const END_SCREEN_DURATION = 20;
