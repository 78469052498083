interface RRBuddyProps {
  color: string;
}
const RRBuddy: React.FC<RRBuddyProps> = ({ color }) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="3" y="3" width="42" height="42" rx="12" fill={color} />
      <path
        d="M29.8337 34.5V32.1667C29.8337 30.929 29.342 29.742 28.4668 28.8668C27.5917 27.9917 26.4047 27.5 25.167 27.5H15.8337C14.596 27.5 13.409 27.9917 12.5338 28.8668C11.6587 29.742 11.167 30.929 11.167 32.1667V34.5"
        fill="white"
      />
      <path
        d="M29.8337 34.5V32.1667C29.8337 30.929 29.342 29.742 28.4668 28.8668C27.5917 27.9917 26.4047 27.5 25.167 27.5H15.8337C14.596 27.5 13.409 27.9917 12.5338 28.8668C11.6587 29.742 11.167 30.929 11.167 32.1667V34.5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M11 34.5H29.5" stroke="white" strokeWidth="2" />
      <path
        d="M20.5002 22.8333C23.0775 22.8333 25.1668 20.744 25.1668 18.1667C25.1668 15.5893 23.0775 13.5 20.5002 13.5C17.9228 13.5 15.8335 15.5893 15.8335 18.1667C15.8335 20.744 17.9228 22.8333 20.5002 22.8333Z"
        fill="white"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.8335 34.4999V32.1666C36.8327 31.1326 36.4886 30.1282 35.8551 29.311C35.2216 28.4938 34.3346 27.9101 33.3335 27.6516"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.667 13.6516C29.6708 13.9086 30.5605 14.4924 31.1959 15.311C31.8313 16.1295 32.1761 17.1362 32.1761 18.1724C32.1761 19.2086 31.8313 20.2154 31.1959 21.0339C30.5605 21.8525 29.6708 22.4363 28.667 22.6933"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RRBuddy;
