import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { getOrganizationMembers } from "../../API/organization";
import { Activity } from "../../types/activity";
import { RootState } from "../store";

export enum MemberRoles {
  "MEMBER" = "MEMBER",
  "ADMIN" = "ADMIN",
}

export interface Members {
  name: string;
  email: string;
  role: MemberRoles;
  icon: string;
  id: string;
  participantId: string;
}

export interface DashboardTemplate {
  color: string;
  name: string;
  description: string;
  code: string;
  tags: string;
  approximateTime: number;
  maxActivities: number;
}

export interface Theme {
  id: string;
  name: string;
}

export interface TemplateItem {
  id: string;
  loungeActivityType: string;
  loungeActivityId: string;
  description: string;
  activity: Activity;
  theme: Theme;
}

export interface TemplateDetail {
  id: string;
  template: DashboardTemplate;
  templateItems: TemplateItem[];
}

export interface DashboardState {
  templateDetails: TemplateDetail[];
  templateDetailsLoading: boolean;
  organizationMembers: Members[];
  isTeamCountHighlighted: boolean;
  scheduledSocialsCount: number;
}

const initialState: DashboardState = {
  templateDetails: [],
  templateDetailsLoading: false,
  organizationMembers: [],
  isTeamCountHighlighted: false,
  scheduledSocialsCount: 0,
};

export const getTemplateDetails = createAsyncThunk("template", async () => {
  const response = await axios.get<TemplateDetail[]>(
    `${process.env.REACT_APP_API_IDENTIFIER}/template/paid`
  );
  return response.data;
});

export const fetchOrganizationMembers = createAsyncThunk(
  "fetchOrganizationMembers",
  async (organizationId: string) => {
    return await getOrganizationMembers(organizationId);
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    resetDashboard: (state) => initialState,
    setOrganizationMembers: (state, action: PayloadAction<Members[]>) => {
      state.organizationMembers = action.payload;
    },
    setTeamCountHighlighted: (state, action: PayloadAction<boolean>) => {
      state.isTeamCountHighlighted = action.payload;
    },
    setScheduledSocialsCount: (state, action: PayloadAction<number>) => {
      state.scheduledSocialsCount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTemplateDetails.pending, (state) => {
        state.templateDetailsLoading = true;
      })
      .addCase(getTemplateDetails.fulfilled, (state, action) => {
        state.templateDetailsLoading = false;
        state.templateDetails = action.payload;
      })
      .addCase(fetchOrganizationMembers.fulfilled, (state, action) => {
        state.organizationMembers = action.payload || [];
      });
  },
});

export const {
  resetDashboard,
  setOrganizationMembers,
  setTeamCountHighlighted,
  setScheduledSocialsCount,
} = dashboardSlice.actions;

export const selectTemplateDetails = (state: RootState) =>
  state.dashboard.templateDetails;

export const selectOrganizationMembers = (state: RootState) => {
  return state.dashboard.organizationMembers;
};

export const selectIsTeamCountHighlighted = (state: RootState) => {
  return state.dashboard.isTeamCountHighlighted;
};

export const selectScheduledSocialsCount = (state: RootState) => {
  return state.dashboard.scheduledSocialsCount;
};

export default dashboardSlice.reducer;
