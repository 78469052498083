import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import AlertIcon from "../../assets/alertIcon";

import {
  selectGuestName,
  selectTemplateName,
  selectUserEmail,
  setGuestName,
} from "../../store/features/authSlice";
import {
  EventStatusTypes,
  selectEventDetails,
  selectEventInstanceHost,
  selectEventStatus,
  selectIsCoHost,
} from "../../store/features/eventSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getJoinSocialBtnContent,
  editSocialName,
  isEmailValid,
} from "../../utils";
import CopyLinkButton from "../common/CopyLinkButton";
import Tooltip from "../common/Tooltip";
import TickIcon from "../../assets/TickIcon";
import EditIcon from "../../assets/EditIcon";
import { getContactEmail } from "../../utils/freshMarketer";
import SlackButton from "../Slack/SlackButton";
import { useSlackButtonPresence } from "../../hooks";
import TextBtn from "../common/TextBtn";
import MotionButton from "../common/MotionButton";
import LockIcon from "../../assets/LockIcon";
import { getTimeDiffInMinutes } from "../../utils/getTimeDiffInMinute";
import { selectActiveOrganization } from "../../store/features/userSlice";
import { RoutesEnum } from "../../routes/routes.enum";
import { JoinPageViews } from "../../types/views";
import JoinPageHostList from "./HostList";
import { ColouredBox } from "./Elements";
import { handleInAppRedirection } from "../../utils/handleInAppRedirections";
import JoinSocialArchivedViews from "./ArchivedViews";
import JoinSocialWaitingViews from "./WaitingViews";
import { validateStr } from "../../utils";
import { validate } from "uuid";

const NameContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0;
  outline: none;
  cursor: pointer;
  width: 100%;
`;

export const SocialName = styled.input<{
  state?: string;
  socialNameError?: boolean;
}>`
  color: ${(props) =>
    props.state === "disabled"
      ? props.theme.color.lightGray
      : props.theme.color.black};
  letter-spacing: -0.01em;
  font-weight: 600;
  font-size: 32px;
  outline: none;
  border-bottom: 1px solid white;
  padding-bottom: 10px;
  margin-top: 8px;
  cursor: ${(props) => (props.state === "disabled" ? "auto" : "pointer")};
  border: 0;
  display: flex;
  background: white;
  width: calc(100% - 26px);
  text-overflow: ellipsis;
  border-bottom: ${({ socialNameError, theme }) =>
    socialNameError ? `2px solid ${theme.color.red500}` : "none"};

  &:focus-visible {
    outline: 0;
    width: calc(100% - 26px);
    border-bottom: 1px solid
      ${({ socialNameError, theme }) =>
        socialNameError ? theme.color.red500 : theme.color.lightGray};
  }

  &::placeholder {
    content: attr(placeholder);
    font-style: italic;
    opacity: 1;
  }
`;

const NameIconContainer = styled.div``;

const IconContainer = styled.div`
  width: 17px;
`;

const TooltipContainer = styled.div`
  position: absolute;
  bottom: 85px;
  left: -120px;
  color: #fff;
  text-align: center;
`;

export const InputName = styled.input<{ showError: boolean }>`
  width: 100%;
  height: 64px;
  border-radius: 24px;
  background: ${(props) =>
    props.showError ? props.theme.color.red100 : props.theme.lounge.background};
  color: ${(props) => props.theme.color.darkGray};
  font-size: 16px;
  border: ${(props) => (props.showError ? `1px solid #F49191` : "none")};
  box-sizing: border-box;
  padding: 22px;

  &::placeholder {
    color: ${(props) =>
      props.showError ? "#F49191" : props.theme.color.lightGray};
    font-style: italic;
    opacity: 1;
  }
  &:focus {
    outline: 0;
    outline-width: 0;
  }
`;

export const InputContainer = styled.div<{
  margin: string;
}>`
  display: flex;
  margin: ${({ margin }) => margin};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  margin-right: 20px;
  width: 100%;
`;

const StartSocial = styled.button`
  border: none;
  background: ${(props) => props.theme.color.purple500};
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.12);
  border-radius: 24px;
  font-weight: 700;
  font-size: 20px;
  color: #ffffff;
  cursor: pointer;
  padding: 16px 28px;
  flex-grow: 1;

  @media (max-width: 950px) {
    font-size: 16px;
    padding: 16px 24px;
  }

  @media (max-width: 790px) {
    font-size: 14px;
  }
`;

const HostMessage = styled.div`
  margin: 0 0 48px 19px;
`;

const RedText = styled.span`
  color: ${({ theme }) => theme.color.red500};
`;

const CopyLinkButtonToolTip = styled.div`
  position: absolute;
  height: 42px;
  width: 226px;
  text-align: center;
  padding: 12px 16px;
  font-size: 13px;
  line-height: 20px;
  background: ${(props) => props.theme.color.black};
  border-radius: 20px;
  color: ${(props) => props.theme.color.white};
  transform: translateY(-83px);
  display: none;
`;

const ToolTipDownArrow = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid ${(props) => props.theme.color.black};
  transform: translateY(-18px);
  display: none;
`;

const CopyLinkButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  &:hover .tooltip {
    display: block;
  }
`;

const TeamName = styled.div<{ color?: string }>`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  cursor: auto;
  color: ${(props) => props.color || props.theme.color.darkGray};
`;

const AlreadyStarted = styled(ColouredBox)`
  background-color: ${({ theme }) => theme.color.green100};
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  padding: 11px 10px;
  margin-bottom: 48px;
  color: ${({ theme }) => theme.color.black};
`;

const OrTxt = styled.div`
  margin: 24px 0;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 160%;
  color: ${({ theme }) => theme.color.lightGray};
  text-align: center;
`;

const ErrMsgContainer = styled.div`
  margin: 6px 0 28px;
`;

const ErrMsg = styled.div`
  font-weight: 600;
  font-size: 11px;
  color: ${({ theme }) => theme.color.red500};
  margin: 0px 12px;
`;

interface JoinDetailsProps {
  startSocialHandler: () => Promise<void>;
  nameRef: React.RefObject<HTMLInputElement>;
  showNameError: boolean;
  setShowNameError: React.Dispatch<React.SetStateAction<boolean>>;
  isGuestHost: boolean;
}

const JoinDetails: React.FC<JoinDetailsProps> = ({
  startSocialHandler,
  nameRef,
  showNameError,
  setShowNameError,
  isGuestHost,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const guestName = useAppSelector(selectGuestName);
  const userEmail = useAppSelector(selectUserEmail);
  const eventDetails = useAppSelector(selectEventDetails);

  const isCoHost = useAppSelector(selectIsCoHost);
  const templateName = useAppSelector(selectTemplateName);
  const evHost = useAppSelector(selectEventInstanceHost);

  const [nameEditMode, setNameEditMode] = React.useState(false);
  const [view, setView] = React.useState<JoinPageViews>(JoinPageViews.NORMAL);
  const [socialName, setSocialName] = React.useState("");
  const socialNameEditRef = React.useRef<HTMLInputElement>(null);
  const [contactEmail, setContactEmail] = React.useState("");
  const showSlackButton = useSlackButtonPresence();
  const eventStatus = useAppSelector(selectEventStatus);
  const activeOrganization = useAppSelector(selectActiveOrganization);

  React.useEffect(() => {
    if (userEmail) setContactEmail(getContactEmail(userEmail));
  }, [userEmail]);

  React.useEffect(() => {
    let viewType = JoinPageViews.NORMAL;

    if (eventStatus === EventStatusTypes.ARCHIVED) {
      viewType = JoinPageViews.ARCHIVE;
    } else if (eventDetails?.eventInstanceStatus !== "ONGOING" && !isCoHost) {
      let isWaiting = true;

      if (eventDetails?.endTime) {
        const timeImMin = getTimeDiffInMinutes(eventDetails?.endTime);

        isWaiting = timeImMin > 15 ? true : false;
      }

      viewType = isWaiting
        ? JoinPageViews.WAITING
        : JoinPageViews.MISSED_SOCIAL;
    }

    setView(viewType);
  }, [eventDetails, isCoHost, eventStatus]);

  const [socialNameError, setSocialNameError] = useState(false);
  const [inputErrorMsg, setInputErrorMsg] = useState("");

  React.useEffect(() => {
    if (eventDetails?.eventInstanceName) {
      setSocialName(eventDetails?.eventInstanceName);
    }
  }, [eventDetails?.eventInstanceName]);

  const onChangeHandler = (ev: any) => {
    setSocialName(ev.target.value);
  };

  const onBlurHandler = () => {
    if (socialNameError) {
      socialNameEditRef?.current?.focus();
    } else {
      setNameEditMode(!nameEditMode);
      editSocialName(eventDetails?.eventId!, socialName);
      socialNameEditRef?.current?.blur();
    }
  };

  React.useEffect(() => {
    setSocialNameError(true);

    if (socialName) {
      setSocialNameError(validateStr(socialName));
    }
  }, [socialName]);

  const onEditIconClickHandler = () => {
    if (!nameEditMode) {
      socialNameEditRef?.current?.focus();
    }
    setNameEditMode(!nameEditMode);
  };

  const validateParticipantName = async (teamName: string) => {
    if (validateStr(teamName)) {
      setShowNameError(true);
      setInputErrorMsg("Your name cannot be empty");
    } else {
      setShowNameError(false);
    }
  };

  const startSocialClickHandler = () => {
    if (guestName === "") {
      setShowNameError(true);
    }

    if (socialNameError) {
      return;
    }

    startSocialHandler();
  };

  return (
    <>
      {evHost?.name &&
        eventDetails?.eventInstanceStatus === "ONGOING" &&
        isCoHost && (
          <AlreadyStarted>
            {evHost?.name} has already started and is running this social.
          </AlreadyStarted>
        )}

      <TeamName
        color={
          view === JoinPageViews.ARCHIVE ||
          view === JoinPageViews.ARCHIVE_RESTRICTED
            ? theme.color.lightGray
            : undefined
        }
      >
        {eventDetails?.organizationName}
      </TeamName>

      <NameContainer>
        <SocialName
          state={
            view === JoinPageViews.ARCHIVE ||
            view === JoinPageViews.ARCHIVE_RESTRICTED
              ? "disabled"
              : undefined
          }
          ref={socialNameEditRef}
          type="text"
          disabled={
            !isCoHost ||
            view === JoinPageViews.ARCHIVE ||
            view === JoinPageViews.ARCHIVE_RESTRICTED
          }
          socialNameError={socialNameError}
          value={socialName}
          size={
            socialName.length > 0 ? socialName.length + 1 : socialName.length
          }
          placeholder="Name your Social"
          onChange={(e) => onChangeHandler(e)}
          onClick={onEditIconClickHandler}
          onBlur={onBlurHandler}
          onKeyPress={(ev) => {
            if (ev.key === "Enter") {
              onBlurHandler();
            }
          }}
          maxLength={30}
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          suppressContentEditableWarning
        />
        {isCoHost &&
          !(
            view === JoinPageViews.ARCHIVE ||
            view === JoinPageViews.ARCHIVE_RESTRICTED
          ) && (
            <NameIconContainer onClick={onEditIconClickHandler}>
              {nameEditMode ? (
                <TickIcon
                  stroke={socialNameError ? theme.color.lightGray : undefined}
                  width={"32"}
                  height={"32"}
                />
              ) : (
                <EditIcon width={"22"} height={"22"} />
              )}
            </NameIconContainer>
          )}
      </NameContainer>

      <JoinPageHostList view={view} />

      {view !== JoinPageViews.MISSED_SOCIAL &&
        view !== JoinPageViews.WAITING &&
        view !== JoinPageViews.ARCHIVE &&
        view !== JoinPageViews.ARCHIVE_RESTRICTED && (
          <>
            <InputContainer
              margin={
                (eventDetails?.organizationName === "" ||
                  !eventDetails?.organizationName) &&
                (isCoHost || isGuestHost)
                  ? "24px 0"
                  : "48px 0"
              }
            >
              <InputName
                disabled={false}
                ref={nameRef}
                type="text"
                placeholder="What do your friends call you?"
                value={guestName}
                onChange={(ev) => {
                  setShowNameError(false);
                  dispatch(setGuestName(ev.target.value));
                }}
                onKeyUp={() => {
                  validateParticipantName(guestName);
                }}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    ev.preventDefault();
                    validateParticipantName(guestName);
                    startSocialClickHandler();
                  }
                }}
                showError={showNameError}
              />
              <input
                type="text"
                name="email"
                placeholder="email"
                hidden={true}
                value={contactEmail}
              />
            </InputContainer>

            <ButtonContainer>
              <StartSocial type="button" onClick={startSocialClickHandler}>
                {getJoinSocialBtnContent(
                  templateName,
                  eventDetails?.eventInstanceStatus !== "ONGOING" && isCoHost
                )}
              </StartSocial>

              {!!eventDetails?.organizationId &&
                eventDetails.organizationId !==
                  "00000000-0000-0000-0000-000000000000" &&
                showSlackButton && (
                  <SlackButton
                    compactView
                    sendNotification={false}
                    tooltipText="Send link on Slack"
                  />
                )}

              <CopyLinkButtonContainer>
                {eventDetails?.organizationName !== "" && (
                  <>
                    <ToolTipDownArrow className={"tooltip"} />
                    <CopyLinkButtonToolTip className={"tooltip"}>
                      Your team will be able to sign up and join the social with
                      this single link
                    </CopyLinkButtonToolTip>
                  </>
                )}
                <CopyLinkButton
                  width={64}
                  height={64}
                  compactView
                  iconSize="24"
                />
              </CopyLinkButtonContainer>
            </ButtonContainer>
          </>
        )}

      {(view === JoinPageViews.ARCHIVE ||
        view === JoinPageViews.ARCHIVE_RESTRICTED) && (
        <JoinSocialArchivedViews />
      )}

      {(view === JoinPageViews.MISSED_SOCIAL ||
        view === JoinPageViews.WAITING) && (
        <JoinSocialWaitingViews view={view} />
      )}
    </>
  );
};

export default JoinDetails;
