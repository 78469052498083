import React from "react";

interface Props {
  fill?: string;
  width?: number;
  height?: number;
}

const SlackIcon: React.FC<Props> = ({ fill, width = 25, height = 24 }) => {
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill={fill || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.30074 2.04932C9.03899 2.04956 8.77985 2.10136 8.53813 2.20177C8.29639 2.30217 8.07682 2.44921 7.89192 2.63448C7.70701 2.81976 7.56043 3.03964 7.46053 3.28157C7.36061 3.5235 7.30933 3.78275 7.30963 4.0445C7.30939 4.30622 7.36069 4.56543 7.46063 4.80732C7.56056 5.04921 7.70715 5.26904 7.89204 5.45428C8.07694 5.63952 8.2965 5.78652 8.53821 5.8869C8.77992 5.98728 9.03903 6.03907 9.30074 6.03932H11.2918V4.0445C11.2921 3.78275 11.2409 3.5235 11.141 3.28157C11.0411 3.03964 10.8945 2.81976 10.7096 2.63448C10.5247 2.44921 10.3051 2.30217 10.0634 2.20177C9.82164 2.10136 9.5625 2.04956 9.30074 2.04932ZM9.30074 7.36968H3.99111C3.72939 7.36993 3.47028 7.42172 3.22858 7.5221C2.98688 7.62249 2.76731 7.76949 2.58242 7.95472C2.39753 8.13996 2.25093 8.35979 2.151 8.60168C2.05107 8.84357 1.99976 9.10278 2 9.3645C1.99971 9.62625 2.05098 9.8855 2.15089 10.1274C2.2508 10.3694 2.39739 10.5893 2.58229 10.7745C2.76718 10.9598 2.98677 11.1068 3.22849 11.2072C3.47022 11.3076 3.72936 11.3594 3.99111 11.3597H9.30074C9.5625 11.3594 9.82164 11.3076 10.0634 11.2072C10.3051 11.1068 10.5247 10.9598 10.7096 10.7745C10.8945 10.5893 11.0411 10.3694 11.141 10.1274C11.2409 9.8855 11.2921 9.62625 11.2918 9.3645C11.2921 9.10278 11.2408 8.84357 11.1409 8.60168C11.0409 8.35979 10.8943 8.13996 10.7094 7.95472C10.5245 7.76949 10.305 7.62249 10.0633 7.5221C9.82157 7.42172 9.56246 7.36993 9.30074 7.36968Z"
        fill="#4A154B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.911 9.3645C21.9113 9.10278 21.8599 8.84357 21.76 8.60168C21.66 8.35979 21.5135 8.13996 21.3286 7.95472C21.1436 7.76949 20.9241 7.62249 20.6824 7.5221C20.4407 7.42172 20.1816 7.36993 19.9199 7.36968C19.6582 7.36993 19.3991 7.42172 19.1574 7.5221C18.9156 7.62249 18.6961 7.76949 18.5111 7.95472C18.3263 8.13996 18.1798 8.35979 18.0798 8.60168C17.9799 8.84357 17.9285 9.10278 17.9288 9.3645V11.3597H19.9199C20.1817 11.3594 20.4407 11.3076 20.6825 11.2072C20.9242 11.1068 21.1438 10.9598 21.3288 10.7745C21.5136 10.5893 21.6602 10.3694 21.7602 10.1274C21.86 9.8855 21.9113 9.62625 21.911 9.3645ZM16.6014 9.3645V4.0445C16.6017 3.78275 16.5504 3.5235 16.4504 3.28157C16.3506 3.03964 16.2039 2.81976 16.0191 2.63448C15.8342 2.44921 15.6146 2.30217 15.3729 2.20177C15.1311 2.10136 14.872 2.04956 14.6103 2.04932C14.3485 2.04956 14.0894 2.10136 13.8476 2.20177C13.6059 2.30217 13.3863 2.44921 13.2014 2.63448C13.0165 2.81976 12.8699 3.03964 12.77 3.28157C12.6701 3.5235 12.6189 3.78275 12.6191 4.0445V9.3645C12.6189 9.62625 12.6701 9.8855 12.77 10.1274C12.8699 10.3694 13.0165 10.5893 13.2014 10.7745C13.3863 10.9598 13.6059 11.1068 13.8476 11.2072C14.0894 11.3076 14.3485 11.3594 14.6103 11.3597C14.872 11.3594 15.1311 11.3076 15.3729 11.2072C15.6146 11.1068 15.8342 10.9598 16.0191 10.7745C16.2039 10.5893 16.3506 10.3694 16.4504 10.1274C16.5504 9.8855 16.6017 9.62625 16.6014 9.3645Z"
        fill="#4A154B"
      />
      <path
        opacity="0.4"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.6103 22.0001C14.872 21.9998 15.1311 21.948 15.3729 21.8476C15.6146 21.7472 15.8342 21.6002 16.0191 21.4149C16.2039 21.2297 16.3506 21.0098 16.4504 20.7679C16.5504 20.5259 16.6017 20.2666 16.6014 20.0049C16.6016 19.7431 16.5503 19.484 16.4503 19.242C16.3504 19.0002 16.2038 18.7803 16.0189 18.5951C15.8341 18.4099 15.6145 18.2628 15.3728 18.1624C15.1311 18.0622 14.872 18.0103 14.6103 18.0101H12.6191V20.0049C12.6189 20.2666 12.6701 20.5259 12.77 20.7679C12.8699 21.0098 13.0165 21.2297 13.2014 21.4149C13.3863 21.6002 13.6059 21.7472 13.8476 21.8476C14.0894 21.948 14.3485 21.9998 14.6103 22.0001ZM14.6103 16.6797H19.9199C20.1816 16.6795 20.4407 16.6277 20.6824 16.5273C20.9241 16.4269 21.1436 16.2799 21.3286 16.0947C21.5135 15.9094 21.66 15.6896 21.76 15.4477C21.8599 15.2058 21.9113 14.9466 21.911 14.6849C21.9113 14.4231 21.86 14.1639 21.7602 13.922C21.6602 13.68 21.5136 13.4601 21.3288 13.2749C21.1438 13.0896 20.9242 12.9426 20.6825 12.8422C20.4407 12.7418 20.1817 12.6899 19.9199 12.6897H14.6103C14.3485 12.6899 14.0894 12.7418 13.8476 12.8422C13.6059 12.9426 13.3863 13.0896 13.2014 13.2749C13.0165 13.4601 12.8699 13.68 12.77 13.922C12.6701 14.1639 12.6189 14.4231 12.6191 14.6849C12.6189 14.9466 12.6702 15.2058 12.7701 15.4477C12.8701 15.6896 13.0167 15.9094 13.2016 16.0947C13.3864 16.2799 13.606 16.4269 13.8477 16.5273C14.0894 16.6277 14.3485 16.6795 14.6103 16.6797Z"
        fill="#4A154B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 14.6849C1.99976 14.9466 2.05107 15.2058 2.151 15.4477C2.25093 15.6896 2.39753 15.9094 2.58242 16.0947C2.76731 16.2799 2.98688 16.4269 3.22858 16.5273C3.47028 16.6277 3.72939 16.6795 3.99111 16.6797C4.25283 16.6795 4.51194 16.6277 4.75365 16.5273C4.99535 16.4269 5.21492 16.2799 5.39981 16.0947C5.58469 15.9094 5.73129 15.6896 5.83122 15.4477C5.93115 15.2058 5.98247 14.9466 5.98222 14.6849V12.6897H3.99111C3.72933 12.6899 3.47017 12.7418 3.22841 12.8422C2.98666 12.9426 2.76706 13.0897 2.58216 13.275C2.39725 13.4603 2.25067 13.6802 2.15078 13.9222C2.0509 14.1642 1.99966 14.4235 2 14.6853V14.6849ZM7.30963 14.6849V20.0049C7.30933 20.2666 7.36061 20.5259 7.46053 20.7679C7.56043 21.0098 7.70701 21.2297 7.89192 21.4149C8.07682 21.6002 8.29639 21.7472 8.53813 21.8476C8.77985 21.948 9.03899 21.9998 9.30074 22.0001C9.5625 21.9998 9.82164 21.948 10.0634 21.8476C10.3051 21.7472 10.5247 21.6002 10.7096 21.4149C10.8945 21.2297 11.0411 21.0098 11.141 20.7679C11.2409 20.5259 11.2921 20.2666 11.2918 20.0049V14.6853C11.2921 14.4235 11.2409 14.1643 11.141 13.9223C11.0411 13.6804 10.8945 13.4605 10.7096 13.2752C10.5247 13.09 10.3051 12.9429 10.0634 12.8425C9.82164 12.7421 9.5625 12.6903 9.30074 12.6901C9.03899 12.6903 8.77985 12.7421 8.53813 12.8425C8.29639 12.9429 8.07682 13.09 7.89192 13.2752C7.70701 13.4605 7.56043 13.6804 7.46053 13.9223C7.36061 14.1643 7.30933 14.4235 7.30963 14.6853"
        fill="#4A154B"
      />
    </svg>
  );
};

export default SlackIcon;
