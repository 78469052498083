import React from "react";

const MTIcon: React.FC<{ size?: string }> = ({ size = "24" }) => {
  return (
    <svg
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="m5 11c-.55228 0-1 .4477-1 1 0 .0334.00164.0665.00484.099.32161-.0649.6544-.099.99516-.099 1.0008 0 1.93293.294 2.71471.8004 1.08898-1.111 2.60659-1.8004 4.28529-1.8004s3.1963.6894 4.2853 1.8004c.7818-.5064 1.7139-.8004 2.7147-.8004.3408 0 .6736.0341.9952.099.0032-.0325.0048-.0656.0048-.099 0-.5523-.4477-1-1-1h-7zm10.0005 2c-.8358-.6279-1.8747-1-3.0005-1s-2.16474.3721-3.00049 1z"
        fill="#475766"
        fillRule="evenodd"
      />
      <g fill="#1eb482">
        <path d="m17 17c0 2.7614-2.2386 5-5 5-2.76142 0-5-2.2386-5-5s2.23858-5 5-5c2.7614 0 5 2.2386 5 5z" />
        <path
          clipRule="evenodd"
          d="m16.9287 20.4226c.6754-.9707 1.0713-2.1504 1.0713-3.4226s-.3959-2.4519-1.0713-3.4226c.6042-.3664 1.3131-.5774 2.0713-.5774 2.2091 0 4 1.7909 4 4s-1.7909 4-4 4c-.7582 0-1.4671-.211-2.0713-.5774zm-.7855-.6228c.5409-.7989.8568-1.7624.8568-2.7998s-.3159-2.0009-.8568-2.7998c-.7072.7215-1.1432 1.7097-1.1432 2.7998s.436 2.0783 1.1432 2.7998z"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="m7.07131 13.5774c-.60417-.3664-1.31311-.5774-2.07131-.5774-2.20914 0-4 1.7909-4 4s1.79086 4 4 4c.7582 0 1.46714-.211 2.07131-.5774-.67537-.9707-1.07131-2.1504-1.07131-3.4226s.39594-2.4519 1.07131-3.4226zm.78548.6228c-.54087.7989-.85679 1.7624-.85679 2.7998s.31591 2.0009.85679 2.7998c.70717-.7215 1.14321-1.7097 1.14321-2.7998s-.43604-2.0783-1.14321-2.7998z"
          fillRule="evenodd"
        />
      </g>
      <rect fill="#475766" height="2" rx="1" width="16" x="4" y="3" />
      <rect fill="#475766" height="2" rx="1" width="16" x="4" y="7" />
    </svg>
  );
};

export default MTIcon;
