export enum TemplateCode {
  INSTANT_SOCIAL = "bb50faba-ddc9-4db9-bd54-bdc4526b80b7",
  CREATE_YOUR_OWN = "aba81eeb-6275-4b2f-be80-3fee8082acf5",
  CHAI_TIME = "f2e905cf-c744-4dd5-b57e-6244ebc0c8a8",
  WELCOMING_NEW_HIRES = "5431a018-263a-4330-9216-3c0a8b52d9e8",
  FUN_TIMES = "175fe40a-c194-455e-995a-322d353bc315",
  CASUAL_CONVERSATIONS = "6d786ace-df49-4bf2-9eb2-edf6d746f38e",
  WHAT_INSPIRES_US = "01b2964c-600b-4dfd-8f42-ccc61d54e49f",
  APPRECIATION_DAY = "82769091-39a6-44b8-aa80-93a313d980c1",
  CULTURE_TALK = "18cac2ab-b6d4-48e3-9978-d979a831aea0",
  CREATIVE_COLLAB = "e9002e26-2791-44d3-93ff-af2694798acd",
}

export enum TemplateName {
  charades = "charades",
  ttal = "ttal",
  CHAI_TIME = "Chai Time",
  WELCOMING_NEW_HIRES = "Welcoming new hires",
  FUN_TIMES = "Fun Times",
  CASUAL_CONVERSATIONS = "Casual Conversations",
  WHAT_INSPIRES_US = "What inspires us?",
  APPRECIATION_DAY = "Appreciation day",
  CULTURE_TALK = "Culture talk",
  CUSTOM_TEMPLATE = "Custom Template",
}

export enum TemplateItemTheme {
  APPRECIATION = "Appreciation",
  CULTURE = "Culture",
  INSPIRATION = "Inspiration",
  REMOTE_WORK = "Remote Work",
  FASHION = "Fashion",
  PRODUCTIVITY = "Productivity",
  DEFAULT = "default",
}

export enum TemplateIconColors {
  Orange500 = "orange500",
  Blue500 = "blue500",
  Red500 = "red500",
  Green500 = "green500",
  Yellow500 = "yellow500",
  BackgroundGray = "backgroundGray",
  LightGray = "lightGray",
  Purple500 = "purple500",
  White = "white",
}

export enum TemplateHoverColors {
  Orange600 = "orange600",
  Blue600 = "blue600",
  Red600 = "red600",
  Green600 = "green600",
  Yellow600 = "yellow600",
  BackgroundGrayHover = "backgroundGrayHover",
  Purple600 = "purple600",
  White = "white",
}

export enum TemplateBGColors {
  Orange100 = "orange100",
  Blue100 = "blue100",
  Red100 = "red100",
  Green100 = "green100",
  Yellow100 = "yellow100",
  BackgroundGray = "backgroundGray",
  Purple100 = "purple100",
}
