const LoopbackIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="4" fill="#FFDA66" />
      <path
        d="M3 3V8H8"
        stroke="#FFC200"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.05 13.0001C3.27151 15.0059 4.1607 16.8791 5.57478 18.3188C6.98886 19.7585 8.84577 20.6812 10.8473 20.9387C12.8488 21.1961 14.8788 20.7735 16.6112 19.7386C18.3436 18.7036 19.678 17.1165 20.3999 15.2321C21.1219 13.3476 21.1896 11.2752 20.5921 9.34766C19.9947 7.42012 18.7668 5.7493 17.1056 4.60353C15.4444 3.45776 13.4463 2.90354 11.4322 3.02987C9.41817 3.15619 7.50501 3.95574 6 5.30009L3 8.00009"
        stroke="#FFC200"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 7V12L16 14"
        stroke="#FFC200"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LoopbackIcon;
