import React from "react";

const QuestionMarkIcon: React.FC<{ size?: number; color?: string }> = ({
  size = 36,
  color = "white",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 37 36"
      fill="none"
    >
      <path
        d="M18.5371 33C26.8214 33 33.5371 26.2843 33.5371 18C33.5371 9.71573 26.8214 3 18.5371 3C10.2528 3 3.53711 9.71573 3.53711 18C3.53711 26.2843 10.2528 33 18.5371 33Z"
        stroke={color}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1724 13.5009C14.525 12.4984 15.2211 11.653 16.1373 11.1146C17.0535 10.5761 18.1307 10.3793 19.1781 10.5589C20.2255 10.7386 21.1756 11.2831 21.86 12.0961C22.5444 12.9092 22.9189 13.9381 22.9174 15.0009C22.9174 18.0009 18.4174 19.5009 18.4174 19.5009"
        stroke={color}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5371 25.5H18.553"
        stroke={color}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default QuestionMarkIcon;
