import React, { ComponentType } from "react";
import amplitude from "amplitude-js";
import { AmplitudeEvents } from "../types/amplitudeEvents";
import { LocalStorageKeys } from "./localstorage";

type ComponentPromise<T = any> = Promise<{ default: ComponentType<T> }>;

export function lazyImportWithRetry(
  componentImport: () => ComponentPromise
): React.LazyExoticComponent<React.ComponentType<any>> {
  return React.lazy(
    () =>
      new Promise((resolve, reject) => {
        const hasRefreshed = JSON.parse(
          window.sessionStorage.getItem(LocalStorageKeys.LazyImportRefresh) ||
            "false"
        );

        componentImport()
          .then((component) => {
            window.sessionStorage.setItem(
              LocalStorageKeys.LazyImportRefresh,
              "false"
            );
            resolve(component);
          })
          .catch((error) => {
            if (!hasRefreshed) {
              // not been refreshed yet
              window.sessionStorage.setItem(
                LocalStorageKeys.LazyImportRefresh,
                "true"
              );
              amplitude
                .getInstance()
                .logEvent(AmplitudeEvents.CHUNK_LOAD_ERROR_REFRESHED);
              return window.location.reload(); // refresh the page
            }
            reject(error);
          });
      })
  );
}
