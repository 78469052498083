import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import websocket from "@giantmachines/redux-websocket";

import meetingReducer from "./features/meetingSlice";
import eventReducer from "./features/eventSlice";
import authReducer from "./features/authSlice";
import loungeReducer from "./features/Lounge/loungeSlice";
import parentReducer from "./features/parentSlice";
import questionsReducer from "./features/questionSlice";
import mixerReducer from "./features/mixerSlice";
import dashboardReducer from "./features/dashboardSlice";
import userReducer from "./features/userSlice";
import teamReducer from "./features/teamSlice";
import {
  JOIN_PREFIX,
  LOUNGE_PREFIX,
  MIXER_PREFIX,
  PARENT_PREFIX,
  TEAM_PREFIX,
} from "./features/socketActionTypes";
import {
  webSocketCustomMiddleware,
  webSocketErrorLoggerMiddleware,
} from "./middleware";
import drunkStartupReducer from "./features/DrunkStartup/drunkStartupSlice";
import videoOnLoungeReducer from "./features/Lounge/videoOnLoungeSlice";
import mixerThemesSliceReducer from "./features/mixerThemesSlice";

const websocketMiddleware = websocket({
  prefix: LOUNGE_PREFIX,
  dateSerializer: (date) => date.toISOString(),
  reconnectOnClose: true,
});

const websocketParentMiddleware = websocket({
  prefix: PARENT_PREFIX,
  dateSerializer: (date) => date.toISOString(),
  reconnectOnClose: true,
});

const websocketMixerMiddleware = websocket({
  prefix: MIXER_PREFIX,
  dateSerializer: (date) => date.toISOString(),
  reconnectOnClose: true,
});

const websocketTeamMiddleware = websocket({
  prefix: TEAM_PREFIX,
  dateSerializer: (date) => date.toISOString(),
  reconnectOnClose: true,
});

const websocketJoinMiddleware = websocket({
  prefix: JOIN_PREFIX,
  dateSerializer: (date) => date.toISOString(),
  reconnectOnClose: true,
});

export const store = configureStore({
  reducer: {
    meeting: meetingReducer,
    auth: authReducer,
    event: eventReducer,
    lounge: loungeReducer,
    parent: parentReducer,
    questions: questionsReducer,
    mixer: mixerReducer,
    dashboard: dashboardReducer,
    user: userReducer,
    team: teamReducer,
    drunkStartup: drunkStartupReducer,
    videoOnLounge: videoOnLoungeReducer,
    mixerThemes: mixerThemesSliceReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ["meta.timestamp", "payload"], // TODO: Remove payload once fixed
      },
    }).concat(
      // loggerMiddleware,
      websocketMiddleware,
      websocketParentMiddleware,
      websocketTeamMiddleware,
      websocketMixerMiddleware,
      webSocketCustomMiddleware,
      webSocketErrorLoggerMiddleware,
      websocketJoinMiddleware
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
