export const getContactEmail = (email: string): string => {
  let contactEmail = email;
  // add env prefix in email
  if (process.env.REACT_APP_ENVIRONMENT !== "production") {
    const split = contactEmail.split("@");
    contactEmail =
      split[0] + "+" + process.env.REACT_APP_ENVIRONMENT + "@" + split[1];
  }
  return contactEmail;
};

export interface NameDetails {
  firstName: string;
  lastName: string;
}

export const getFirstLastName = (fullName: string): NameDetails => {
  fullName = fullName.trim();
  let firstName = fullName;
  let lastName = "";
  const indexOfFirstSpaceCharacter = fullName.indexOf(" ");
  if (indexOfFirstSpaceCharacter !== -1) {
    firstName = fullName.slice(0, indexOfFirstSpaceCharacter);
    lastName = fullName.slice(indexOfFirstSpaceCharacter + 1);
  }
  return { firstName, lastName };
};
