export enum RoutesEnum {
  DASHBOARD = "/dashboard",
  TEAM = "/team",
  CREATE_SOCIAL = "/create-social",
  SOCIAL = "/join",
  MEETING = "/meeting",
  DS_SHARE = "/drunk",
  LOUNGE = "/lounge",
  WOULD_YOU_RATHER = "/would-you-rather",
  TRIVIA = "/trivia",
  BYE = "/bye",
  DOODLE = "/doodle",
  RAPID_RENDEZVOUS = "/rr",
  CHARADES = "/charades",
  TWO_TRUTHS_AND_A_LIE = "/two-truths-and-a-lie",
  MINDFUL_TALK = "/mindful-talk",
  DRUNK_STARTUPS = "/drunk-startups",
  SLACK = "/slack",
  CREATE_TEAM = "/create-team",
  NO_ROUTE = "/",
  SIGN_IN = "/sign-in",
  MY_SOCIAL = "/my-socials",
}
