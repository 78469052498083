import { ActivityEnum } from "../utils/activity.enum";

export const joinBanner = `${process.env.REACT_APP_CDN_BASE_URL}/join-social-background.png`;
export const onScoreSound = `${process.env.REACT_APP_CDN_BASE_URL}/tinker.mp3`;
export const onStartSound = `${process.env.REACT_APP_CDN_BASE_URL}/start.mp3`;
export const onStopSound = `${process.env.REACT_APP_CDN_BASE_URL}/stop.mp3`;
export const mixerVideos: { [key in string]?: string } = {
  [ActivityEnum.KNOW_YOUR_TEAM]: `${process.env.REACT_APP_CDN_BASE_URL}/mixer-assets/kyt.mp4`,
  [ActivityEnum.RAPID_RENDEZVOUS]: `${process.env.REACT_APP_CDN_BASE_URL}/mixer-assets/speedoneonone.mp4`,
  [ActivityEnum.WOULD_YOU_RATHER]: `${process.env.REACT_APP_CDN_BASE_URL}/mixer-assets/WYR720p.mp4`,
  [ActivityEnum.TRIVIA]: `${process.env.REACT_APP_CDN_BASE_URL}/mixer-assets/trivia720p.mp4`,
  [ActivityEnum.DOODLE_RACE]: `${process.env.REACT_APP_CDN_BASE_URL}/mixer-assets/Doodle720p.mp4`,
  [ActivityEnum.TWO_TRUTHS_AND_A_LIE]: `${process.env.REACT_APP_CDN_BASE_URL}/mixer-assets/TTAL720p.mp4`,
  [ActivityEnum.LOUNGE]: `${process.env.REACT_APP_CDN_BASE_URL}/mixer-assets/lounge360p.mp4`,
  [ActivityEnum.CHARADES]: `${process.env.REACT_APP_CDN_BASE_URL}/charades-preview.mp4`,
  [ActivityEnum.MINDFUL_TALK]: `${process.env.REACT_APP_CDN_BASE_URL}/mt_preview.mp4`,
  [ActivityEnum.DRUNK_STARTUPS]: `${process.env.REACT_APP_CDN_BASE_URL}/drunk720.mp4`,
  [ActivityEnum.LOUNGE_VIDEO +
  "_host"]: `${process.env.REACT_APP_CDN_BASE_URL}/lounge-video-host720.mp4`,
  [ActivityEnum.LOUNGE_VIDEO +
  "_participant"]: `${process.env.REACT_APP_CDN_BASE_URL}/lounge-video-participant720.mp4`,
};

export const singleUserTourVideo = `${process.env.REACT_APP_CDN_BASE_URL}/charades-tour.mp4`;
export const singleUserTourMugshot = `${process.env.REACT_APP_CDN_BASE_URL}/deepa-image.jpg`;

export const onDoodleTickSound = `${process.env.REACT_APP_CDN_BASE_URL}/tick.mp3`;
export const combinedMixerVideo = `${process.env.REACT_APP_CDN_BASE_URL}/mixer-assets/mixers360p.mp4`;

export const doodleCorrectSound = `${process.env.REACT_APP_CDN_BASE_URL}/doodle-correct.mp3`;
export const drawTimeEndSound = `${process.env.REACT_APP_CDN_BASE_URL}/draw-time-end.mp3`;
export const newRoundSound = `${process.env.REACT_APP_CDN_BASE_URL}/new-round.mp3`;
export const mixerInstructionSound = `${process.env.REACT_APP_CDN_BASE_URL}/mixer-instruction.mp3`;
export const movementSound = `${process.env.REACT_APP_CDN_BASE_URL}/movement.mp3`;
export const skipSound = `${process.env.REACT_APP_CDN_BASE_URL}/skip.mp3`;
