import {
  WEBSOCKET_BROKEN,
  WEBSOCKET_CLOSED,
  WEBSOCKET_CONNECT,
  WEBSOCKET_DISCONNECT,
  WEBSOCKET_ERROR,
  WEBSOCKET_MESSAGE,
  WEBSOCKET_OPEN,
  WEBSOCKET_SEND,
} from "@giantmachines/redux-websocket";

export const LOUNGE_PREFIX = "LOUNGE";
export const LOUNGE_BREAKOUT = "LOUNGE_BREAKOUT";
export const LOUNGE_BROKEN = `${LOUNGE_PREFIX}::${WEBSOCKET_BROKEN}`;
export const LOUNGE_CLOSED = `${LOUNGE_PREFIX}::${WEBSOCKET_CLOSED}`;
export const LOUNGE_MESSAGE = `${LOUNGE_PREFIX}::${WEBSOCKET_MESSAGE}`;
export const LOUNGE_OPEN = `${LOUNGE_PREFIX}::${WEBSOCKET_OPEN}`;
export const LOUNGE_DISCONNECT = `${LOUNGE_PREFIX}::${WEBSOCKET_DISCONNECT}`;
export const LOUNGE_CONNECT = `${LOUNGE_PREFIX}::${WEBSOCKET_CONNECT}`;
export const LOUNGE_SEND = `${LOUNGE_PREFIX}::${WEBSOCKET_SEND}`;
export const LOUNGE_ERROR = `${LOUNGE_PREFIX}::${WEBSOCKET_ERROR}`;

export const PARENT_PREFIX = "PARENT";
export const PARENT_BREAKOUT = "PARENT_BREAKOUT";
export const PARENT_BROKEN = `${PARENT_PREFIX}::${WEBSOCKET_BROKEN}`;
export const PARENT_CLOSED = `${PARENT_PREFIX}::${WEBSOCKET_CLOSED}`;
export const PARENT_MESSAGE = `${PARENT_PREFIX}::${WEBSOCKET_MESSAGE}`;
export const PARENT_OPEN = `${PARENT_PREFIX}::${WEBSOCKET_OPEN}`;
export const PARENT_DISCONNECT = `${PARENT_PREFIX}::${WEBSOCKET_DISCONNECT}`;
export const PARENT_CONNECT = `${PARENT_PREFIX}::${WEBSOCKET_CONNECT}`;
export const PARENT_SEND = `${PARENT_PREFIX}::${WEBSOCKET_SEND}`;
export const PARENT_ERROR = `${PARENT_PREFIX}::${WEBSOCKET_ERROR}`;

export const MIXER_PREFIX = "MIXER";
export const MIXER_BREAKOUT = "MIXER_BREAKOUT";
export const MIXER_BROKEN = `${MIXER_PREFIX}::${WEBSOCKET_BROKEN}`;
export const MIXER_CLOSED = `${MIXER_PREFIX}::${WEBSOCKET_CLOSED}`;
export const MIXER_MESSAGE = `${MIXER_PREFIX}::${WEBSOCKET_MESSAGE}`;
export const MIXER_OPEN = `${MIXER_PREFIX}::${WEBSOCKET_OPEN}`;
export const MIXER_DISCONNECT = `${MIXER_PREFIX}::${WEBSOCKET_DISCONNECT}`;
export const MIXER_CONNECT = `${MIXER_PREFIX}::${WEBSOCKET_CONNECT}`;
export const MIXER_SEND = `${MIXER_PREFIX}::${WEBSOCKET_SEND}`;
export const MIXER_ERROR = `${MIXER_PREFIX}::${WEBSOCKET_ERROR}`;

export const TEAM_PREFIX = "TEAM";
export const TEAM_BREAKOUT = "TEAM_BREAKOUT";
export const TEAM_BROKEN = `${TEAM_PREFIX}::${WEBSOCKET_BROKEN}`;
export const TEAM_CLOSED = `${TEAM_PREFIX}::${WEBSOCKET_CLOSED}`;
export const TEAM_MESSAGE = `${TEAM_PREFIX}::${WEBSOCKET_MESSAGE}`;
export const TEAM_OPEN = `${TEAM_PREFIX}::${WEBSOCKET_OPEN}`;
export const TEAM_DISCONNECT = `${TEAM_PREFIX}::${WEBSOCKET_DISCONNECT}`;
export const TEAM_CONNECT = `${TEAM_PREFIX}::${WEBSOCKET_CONNECT}`;
export const TEAM_SEND = `${TEAM_PREFIX}::${WEBSOCKET_SEND}`;
export const TEAM_ERROR = `${TEAM_PREFIX}::${WEBSOCKET_ERROR}`;

export const JOIN_PREFIX = "JOIN";
export const JOIN_BREAKOUT = "JOIN_BREAKOUT";
export const JOIN_BROKEN = `${JOIN_PREFIX}::${WEBSOCKET_BROKEN}`;
export const JOIN_CLOSED = `${JOIN_PREFIX}::${WEBSOCKET_CLOSED}`;
export const JOIN_MESSAGE = `${JOIN_PREFIX}::${WEBSOCKET_MESSAGE}`;
export const JOIN_OPEN = `${JOIN_PREFIX}::${WEBSOCKET_OPEN}`;
export const JOIN_DISCONNECT = `${JOIN_PREFIX}::${WEBSOCKET_DISCONNECT}`;
export const JOIN_CONNECT = `${JOIN_PREFIX}::${WEBSOCKET_CONNECT}`;
export const JOIN_SEND = `${JOIN_PREFIX}::${WEBSOCKET_SEND}`;
export const JOIN_ERROR = `${JOIN_PREFIX}::${WEBSOCKET_ERROR}`;
