import theme from "../theme";
import { TemplateName } from "../types/template";
import amplitude from "amplitude-js";
import { AmplitudeEvents } from "../types/amplitudeEvents";
import { ActivityEnum } from "./activity.enum";
import { RoutesEnum } from "../routes/routes.enum";
import { updateEventName } from "../API/event";

export const isOptionActive = (
  meetingManagerDeviceId: string | null,
  currentDeviceId: string
): boolean => {
  if (currentDeviceId === "none" && meetingManagerDeviceId === null) {
    return true;
  }
  return currentDeviceId === meetingManagerDeviceId;
};

const MUGSHOT_COUNT = 20;

export const getMugshot = (
  externalAttendeeId = "0",
  eventInstanceId = "0",
  size: "small" | "large"
) => {
  const key = generateHash(`${externalAttendeeId}${eventInstanceId}`);
  let mugIndex = key % MUGSHOT_COUNT;
  return size === "small"
    ? `https://static.thursday.social/80x80/small+mugshot+-+${mugIndex + 1}.png`
    : `https://static.thursday.social/270x270/large+mugshot+-+${
        mugIndex + 1
      }.png`;
};

const generateHash = (s: string) => {
  let hash = 0;
  if (s.length === 0) return hash;
  for (let i = 0; i < s.length; i++) {
    const chr = s.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return Math.abs(hash);
};

export const formattedSocialDateTime = (socialTime: string): string => {
  const d = new Date(socialTime);
  const month = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
  const date = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
  const day = new Intl.DateTimeFormat("en", { weekday: "long" }).format(d);
  const time = d.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  return `${month} ${date}, ${day} at ${time}`;
};

export const getUserColorIndex = (
  externalAttendeeId = "0",
  eventInstanceId = "0"
) => {
  const key = generateHash(`${externalAttendeeId}${eventInstanceId}`);
  return key % theme.userColors.length;
};

export const stripHTML = (htmlString: string | undefined) => {
  const tmp = document.createElement("div");
  tmp.innerHTML = htmlString || "";
  return tmp.textContent || "";
};

export const copyToClipboard = (content: string) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(content).catch(function () {
      console.warn("Copy to clipboard failed.");
    });
  }
};

const socialNameList = [
  "Super Sunday",
  "Moody Monday",
  "Terrific Tuesday",
  "Wacky Wednesday",
  "Thunderous Thursday",
  "Freaky Friday",
  "Sunny Saturday",
];

/**
 *
 * @param param0 {min: Minimum for the range,max: Max for the range  }
 * @returns Random number between min-max
 */
export const getRandom = ({
  min = 0,
  max = 1,
}: {
  min?: number;
  max?: number;
}) => {
  const r = Math.random() * (max - min) + min;
  return Math.floor(r);
};

export const generateSocialName = () => {
  return socialNameList[new Date().getDay()];
};

export const returnLongestString = (stringsArray: string[]) => {
  let longestString = "";

  stringsArray.forEach((currentStr) => {
    if (currentStr.length > longestString.length) {
      longestString = currentStr;
    }
  });

  return longestString;
};

export const setCaret = (editRef: React.RefObject<HTMLDivElement>) => {
  const range = document.createRange();
  const sel = window.getSelection();

  range.setStart(
    editRef.current!.childNodes[0],
    editRef.current!.childNodes[0].textContent!.length
  );
  range.collapse(true);
  sel?.removeAllRanges();
  sel?.addRange(range);
  editRef.current!.scrollLeft = editRef.current!.offsetWidth;
};

export const animateElement = (
  element: HTMLDivElement,
  animationToAdd: string,
  animationToRemove?: string
) =>
  new Promise((resolve, reject) => {
    animationToRemove && element.classList.remove(animationToRemove);
    element.classList.add(animationToAdd);
    const handleAnimationEnd = (event: AnimationEvent) => {
      event.stopPropagation();
      element.classList.remove(animationToAdd);
      resolve("Animation ended");
    };
    element.addEventListener("animationend", handleAnimationEnd, {
      once: true,
    });
  });

export enum MixerNames {
  charades = "Charades",
  ttal = "2 truths & a lie",
}

export const getMixerName = (templateName: string) => {
  switch (templateName) {
    case TemplateName.charades:
      return MixerNames.charades;
    case TemplateName.ttal:
      return MixerNames.ttal;
    default:
      return "";
  }
};

export const getStartSocialBtnContent = (
  templateName: string,
  activityName: ActivityEnum,
  selectedTriviaThemeName?: string
) => {
  if (activityName === ActivityEnum.TRIVIA) {
    return selectedTriviaThemeName
      ? `Start Trivia: ${selectedTriviaThemeName}`
      : "Trivia: Choose topic";
  }
  const start = "Start";
  if (templateName) {
    return `${start}: ${getMixerName(templateName)}`;
  }
  return `${start} Mixer`;
};

export const editSocialName = (
  eventId: string,
  socialName: string,
  socialNameEditRef?: React.RefObject<HTMLInputElement | HTMLDivElement>,
  setNameEditMode?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  amplitude.getInstance().logEvent(AmplitudeEvents.SOCIAL_NAME_EDITED);
  if (socialNameEditRef) {
    socialNameEditRef.current!.scrollLeft = 0;
  }
  setNameEditMode && setNameEditMode(false);
  updateEventName({ eventId, name: socialName });
};

export const getJoinSocialBtnContent = (
  templateName: string,
  hasStartAccess?: boolean
) => {
  if (templateName) {
    return `Play ${getMixerName(templateName)}`;
  }

  if (hasStartAccess) {
    return `Start social`;
  }
  return `Join social`;
};

export const getButtonWidth = (templateName: string) => {
  switch (templateName) {
    case TemplateName.charades:
      return 140;
    case TemplateName.ttal:
      return 194;
    default:
      return 124;
  }
};

export const getLeftPanelBgColor = (templateName: string) => {
  switch (templateName) {
    case TemplateName.charades:
      return theme.color.blue100;
    case TemplateName.ttal:
      return theme.color.green100;
    default:
      return theme.color.blue100;
  }
};

export const getLeftPanelMixerColor = (templateName: string) => {
  switch (templateName) {
    case TemplateName.charades:
      return theme.color.blue500;
    case TemplateName.ttal:
      return theme.color.green500;
    default:
      return theme.color.blue100;
  }
};

export const handleShare = async (
  eventInstanceName: string | undefined,
  eventDateTime: string | undefined
) => {
  copyToClipboard(window.location.href);
  if (navigator.share) {
    try {
      await navigator
        .share({
          url: window.location.href,
          title: eventInstanceName,
          text: `Join ${eventInstanceName} on ${eventDateTime}`,
        })
        .then(() =>
          console.log("Hooray! Your content was shared to tha world")
        );
    } catch (error) {
      console.log(`Oops! I couldn't share to the world because: ${error}`);
    }
  } else {
    // fallback code
    console.log(
      "Web share is currently not supported on this browser. Please provide a callback"
    );
  }
};

export const getSocialLink = (id: string) => {
  return `${RoutesEnum.SOCIAL}/${id}`;
};

export const isEmailValid = (item: string) => {
  return /\S+@\S+\.\S+/.test(item);
};

export const validateStr = (inputString: string) => {
  if (!inputString) return false;

  return !inputString.replace(/\s/g, "").length || !inputString.length;
};

export const isValidYoutubeUrl = (item: string) => {
  if (!item) return false;

  return /^(https?\:\/\/)?(www\.youtube\.com|youtu\.be)\/.+$/.test(item);
};

export const formatSecondsToTime = (seconds: number) => {
  const h = Math.floor(seconds / 3600)
      .toString()
      .padStart(2, "0"),
    m = Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, "0"),
    s = Math.floor(seconds % 60)
      .toString()
      .padStart(2, "0");

  let formattedTime = "";
  if (h !== "00") {
    formattedTime += `${h}:`;
  }
  formattedTime += `${m}:${s}`;
  return formattedTime;
};

export const redirectToCompanyUrl = () => {
  const companyUrl = "https://truesparrow.com/?ref=thursday";
  window.open(companyUrl, "_blank");
};
