const WifiOffSVG = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.72 11.06C17.5391 11.4597 18.305 11.9603 19 12.55"
        stroke="#2F373D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 12.55C6.48208 11.3108 8.26587 10.4862 10.17 10.16"
        stroke="#2F373D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.71 5.05C12.8524 4.87737 15.0076 5.13816 17.047 5.81682C19.0864 6.49547 20.9682 7.57807 22.58 9"
        stroke="#2F373D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.42004 9C2.80721 7.77385 4.39774 6.79923 6.12004 6.12"
        stroke="#2F373D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.53003 16.11C9.54523 15.3887 10.7597 15.0013 12.005 15.0013C13.2504 15.0013 14.4648 15.3887 15.48 16.11"
        stroke="#2F373D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 20H12.01"
        stroke="#2F373D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 1L23 23"
        stroke="#EC4747"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WifiOffSVG;
