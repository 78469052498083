import styled from "styled-components";

export const PrimaryButton = styled.button<{ disabled?: boolean }>`
  display: flex;
  width: 220px;
  align-items: center;
  justify-content: center;
  background: #6088ff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  padding: 10px;
  margin: 10px;
`;

export const SecondaryButton = styled.button`
  display: flex;
  width: 220px;
  align-items: center;
  justify-content: center;
  background: #ff6076;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  padding: 10px;
  margin: 10px;
`;

export const Select = styled.select`
  width: 200px;
  padding: 5px;
`;

export const TruncatedName = styled.span<{ maxWidth?: number }>`
  max-width: ${({ maxWidth }) => maxWidth || 80}px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
