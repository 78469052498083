import {
  createAction,
  createSlice,
  Draft,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SocketMessage } from "../../types/socketMessage";
import { RootState } from "../store";
import {
  JOIN_MESSAGE,
  PARENT_BROKEN,
  PARENT_CLOSED,
  PARENT_DISCONNECT,
  PARENT_MESSAGE,
  PARENT_OPEN,
} from "./socketActionTypes";
import { SOCKET_CONTROL_MESSAGES } from "./ws";
import { isAnyOf } from "../util";
import { setVideoState, VideoStates } from "./Lounge/loungeSlice";
import { getEventDetails } from "./eventSlice";

export interface ParentState {
  connected: boolean;
  refetchStatus: boolean;
  enterNextSegment: boolean;
  remainingTime: number;
  elapsedTime: number;
  refetchSegments: boolean;
  refetchIcebreakers: boolean;
  refetchRRData: boolean;
  forceEnd: boolean;
  timeExtended: boolean;
  showTimeExtendedNotification: boolean;
  howToPlayTime: number;
  fundsUpdated: boolean;
  slackUpdated: boolean;
  refreshCanvasData: boolean;
  byJoinPage: boolean;
  isSocialNameUpdated: boolean;
}

const initialState: ParentState = {
  connected: false,
  refetchStatus: false,
  enterNextSegment: false,
  remainingTime: -1,
  elapsedTime: -1,
  refetchSegments: false,
  refetchIcebreakers: false,
  forceEnd: false,
  timeExtended: false,
  showTimeExtendedNotification: false,
  refetchRRData: false,
  howToPlayTime: 0,
  fundsUpdated: false,
  slackUpdated: false,
  refreshCanvasData: false,
  byJoinPage: false,
  isSocialNameUpdated: false,
};

export const parentSlice = createSlice({
  name: "parent",
  initialState,
  reducers: {
    setEnterNextSegment: (state, action: PayloadAction<boolean>) => {
      state.enterNextSegment = action.payload;
    },
    setRefetchStatus: (state, action: PayloadAction<boolean>) => {
      state.refetchStatus = action.payload;
    },
    setFundUpdated: (state, action: PayloadAction<boolean>) => {
      state.fundsUpdated = action.payload;
    },
    setSlackUpdated: (state, action: PayloadAction<boolean>) => {
      state.slackUpdated = action.payload;
    },
    setIsSocialNameUpdated: (state, action: PayloadAction<boolean>) => {
      state.isSocialNameUpdated = action.payload;
    },
    setByJoinPage: (state, action: PayloadAction<boolean>) => {
      state.byJoinPage = action.payload;
    },
    resetShowTimeExtendedNotification: (state) => {
      state.showTimeExtendedNotification = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEventDetails.fulfilled, (state, action) => {
        console.log("parentSlice for instance");
        state.timeExtended = action.payload?.currentSegmentExtended || false;
      })
      .addCase(
        createAction<{ message: string }>(PARENT_CLOSED),
        (state: Draft<ParentState>, action) => {
          return { ...state, connected: false };
        }
      )
      .addCase(
        createAction<{ message: string }>(PARENT_OPEN),
        (state: Draft<ParentState>, action) => {
          return { ...state, connected: true };
        }
      )
      .addCase(
        createAction<{ message: string }>(PARENT_DISCONNECT),
        (state: Draft<ParentState>, action) => {
          return { ...state, connected: false };
        }
      )
      .addCase(
        createAction<{ message: string }>(PARENT_BROKEN),
        (state: Draft<ParentState>, action) => {
          return { ...state, connected: false };
        }
      )
      .addMatcher(
        isAnyOf(PARENT_MESSAGE, JOIN_MESSAGE),
        (state: Draft<ParentState>, action) => {
          let response: SocketMessage;
          try {
            const message = action.payload.message.replaceAll('"', "");
            response = JSON.parse(atob(message));

            if (response.info === SOCKET_CONTROL_MESSAGES.REFRESH)
              return {
                ...state,
                enterNextSegment: true,
                refetchStatus: true,
                elapsedTime: -1,
                remainingTime: -1,
              };
            if (response.info === SOCKET_CONTROL_MESSAGES.TIMER) {
              let timeExtendedVar = state.timeExtended;
              let showTimeExtendedNotificationVar =
                state.showTimeExtendedNotification;
              if (response?.timeExtended) {
                timeExtendedVar = response.timeExtended;
                showTimeExtendedNotificationVar = response.timeExtended;
              }
              return {
                ...state,
                remainingTime: response?.remainingTime || -1,
                elapsedTime: response?.elapsedTime || -1,
                forceEnd: response?.forceEnd || false,
                timeExtended: timeExtendedVar,
                showTimeExtendedNotification: showTimeExtendedNotificationVar,
              };
            }
            if (response.info === SOCKET_CONTROL_MESSAGES.HOW_TO_PLAY_TIME) {
              return {
                ...state,
                howToPlayTime: response?.timer || 0,
              };
            }

            if (response.info === SOCKET_CONTROL_MESSAGES.REFRESH_PANEL) {
              return { ...state, refetchSegments: !state.refetchSegments };
            }
            if (response.info === SOCKET_CONTROL_MESSAGES.ICEBREAKER) {
              return {
                ...state,
                refetchIcebreakers: !state.refetchIcebreakers,
              };
            }
            if (response.info === SOCKET_CONTROL_MESSAGES.RENDEZVOUS) {
              console.log("RENDEZVOUS message received");
              return {
                ...state,
                refetchRRData: !state.refetchRRData,
              };
            }
            if (
              response.info === SOCKET_CONTROL_MESSAGES.FUNDING_REFRESH_MESSAGE
            ) {
              return {
                ...state,
                fundsUpdated: true,
              };
            }
            if (
              response.info === SOCKET_CONTROL_MESSAGES.CANVAS_REFRESH_MESSAGE
            ) {
              return {
                ...state,
                refreshCanvasData: true,
              };
            }
            if (
              response.info === SOCKET_CONTROL_MESSAGES.SLACK_REFRESH_MESSAGE
            ) {
              return {
                ...state,
                slackUpdated: true,
              };
            }

            return state;
          } catch (e) {
            console.error(e);
          }
        }
      );
  },
});

export const {
  setEnterNextSegment,
  setRefetchStatus,
  setFundUpdated,
  setSlackUpdated,
  setByJoinPage,
  setIsSocialNameUpdated,
  resetShowTimeExtendedNotification,
} = parentSlice.actions;

export const selectIsParentConnected = (state: RootState) =>
  state.parent.connected;

export const selectEnterNextSegment = (state: RootState) =>
  state.parent.enterNextSegment;
export const selectRefetchStatus = (state: RootState) =>
  state.parent.refetchStatus;

export const selectRemainingTimer = (state: RootState) =>
  state.parent.remainingTime;

export const selectHowToPlayTime = (state: RootState) =>
  state.parent.howToPlayTime;

export const selectElapsedTimer = (state: RootState) =>
  state.parent.elapsedTime;

export const selectForceEnd = (state: RootState) => state.parent.forceEnd;

export const selectTimeExtended = (state: RootState) =>
  state.parent.timeExtended;

export const selectShowTimeExtendedNotification = (state: RootState) =>
  state.parent.showTimeExtendedNotification;

export const selectRefetchSegments = (state: RootState) =>
  state.parent.refetchSegments;

export const selectRefetchIcebreakers = (state: RootState) =>
  state.parent.refetchIcebreakers;

export const selectRefetchRRData = (state: RootState) =>
  state.parent.refetchRRData;

export const selectFundUpdated = (state: RootState) =>
  state.parent.fundsUpdated;

export const selectSlackUpdated = (state: RootState) =>
  state.parent.slackUpdated;

export const selectRefreshCanvasData = (state: RootState) =>
  state.parent.refreshCanvasData;

export const selectByJoinSocial = (state: RootState) => state.parent.byJoinPage;

export const selectIsSocialNameUpdated = (state: RootState) =>
  state.parent.isSocialNameUpdated;

export default parentSlice.reducer;
