import React from "react";

interface TickIconProps {
  stroke?: string;
  width?: string;
  height?: string;
}

const TickIcon: React.FC<TickIconProps> = ({ stroke, width, height }) => {
  return (
    <svg
      width={width || 24}
      height={width || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6667 8L10.3333 15.3333L7 12"
        stroke={stroke || "#6558F5"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TickIcon;
