import React from "react";

const DrunkStartupsIcon: React.FC = () => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m7 2c2.20914 0 4 1.79086 4 4s-1.79086 4-4 4-4-1.79086-4-4 1.79086-4 4-4z"
        fill="#ffc200"
      />
      <path
        d="m15.6125 5c.581.48992 5.3909 5.4966 3.8875 7s-2.8119-1.1884-4.5002.5c-1.6882 1.6884 2.2847 4.6374 2.9422 5.2949.4503.4503.9226.8869 1.2768 1.4182"
        stroke="#475766"
        strokeLinecap="round"
        strokeWidth="3"
      />
      <g clipRule="evenodd" fill="#ffc200" fillRule="evenodd">
        <path d="m3.69248 9.74979c-.437.64111-.69248 1.41581-.69248 2.25021 0 2.2091 1.79086 4 4 4s4-1.7909 4-4c0-.8344-.2555-1.6091-.6925-2.25021-.88143.77811-2.03933 1.25021-3.3075 1.25021s-2.42607-.4721-3.30752-1.25021zm.6617-.74979c.70513-.62236 1.63137-1 2.64582-1s1.94069.37764 2.64582 1c-.70513.62236-1.63137 1-2.64582 1s-1.94069-.37764-2.64582-1z" />
        <path d="m3.69248 15.7498c-.437.6411-.69248 1.4158-.69248 2.2502 0 2.2091 1.79086 4 4 4s4-1.7909 4-4c0-.8344-.2555-1.6091-.6925-2.2502-.88143.7781-2.03933 1.2502-3.3075 1.2502s-2.42607-.4721-3.30752-1.2502zm.6617-.7498c.70513-.6224 1.63137-1 2.64582-1s1.94069.3776 2.64582 1c-.70513.6224-1.63137 1-2.64582 1s-1.94069-.3776-2.64582-1z" />
      </g>
    </svg>
  );
};

export default DrunkStartupsIcon;
