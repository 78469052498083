import styled from "styled-components";
import { motion } from "framer-motion";
import { MixerColors, ThemeColors } from "../../types/activity";

export const ActionButton = styled(motion.button)<{
  width?: number;
  backgroundColor?: MixerColors;
  disabled?: boolean;
  disabledActive?: boolean;
  showActive?: boolean;
  borderRadius?: string;
  height?: number;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => (width ? `${width}px` : "auto")};
  height: ${({ height }) => (height ? `${height}px` : "48px")};
  background: ${(props) =>
    props.backgroundColor
      ? props.theme.color[`${props.backgroundColor}100` as ThemeColors]
      : props.theme.lounge.background};
  color: ${(props) => props.theme.color.black};
  font-size: 16px;
  border-radius: ${({ borderRadius }) => borderRadius || "16px"};
  margin: 4px;
  font-weight: bold;
  user-select: none;
  cursor: ${({ disabled, disabledActive }) =>
    disabled || disabledActive ? "default" : "pointer"};
  border: none;
  position: relative;

  &:hover {
    background: ${(props) =>
      props.showActive
        ? props.theme.color[`${props.backgroundColor}100` as ThemeColors]
        : props.disabled
        ? props.theme.lounge.background
        : props.backgroundColor
        ? props.theme.color[`${props.backgroundColor}200` as ThemeColors]
        : props.theme.lounge.backgroundHover};
  }
  &:focus {
    outline: none;
  }
`;

export const Video = styled.video`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const Image = styled.img<{ width?: string; height?: string }>`
  width: ${({ height }) => height && height};
  height: ${({ height }) => height && height};
`;

export const Tooltip = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  background: ${(props) => props.theme.color.black};
  border-radius: 24px;
  font-weight: 500;
  font-size: 13px;
  line-height: 160%;
  color: ${(props) => props.theme.color.white};
  width: max-content;
  max-width: 326px;
  text-align: center;
`;

export const Arrow = styled.div<{ left?: string }>`
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: -16px;
  left: ${({ left }) => (left ? left : "50%")};
  background-color: ${(props) => props.theme.color.black};
  transform: rotate(45deg) translateX(-10px);
  border-radius: 4px;
`;
