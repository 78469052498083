import React from "react";

const TtalIconDisabled: React.FC<{ isTooltip?: boolean }> = ({ isTooltip }) => {
  const fillColor = isTooltip ? "white" : "#475766";
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={fillColor}>
        <rect height="4" rx="2" width="6" x="2" y="3" />
        <rect height="4" rx="2" width="6" x="9" y="3" />
        <rect height="4" rx="2" width="6" x="16" y="3" />
        <path d="m17 16c0 2.7614-2.2386 5-5 5-2.76142 0-5-2.2386-5-5s2.23858-5 5-5c2.7614 0 5 2.2386 5 5z" />
        <g clipRule="evenodd" fillRule="evenodd">
          <path d="m16.8253 19.5666c.7382-.997 1.1747-2.2308 1.1747-3.5666 0-.4384-.047-.8657-.1363-1.2773.3505-.1436.7342-.2227 1.1363-.2227 1.6569 0 3 1.3431 3 3s-1.3431 3-3 3c-.8559 0-1.6281-.3584-2.1747-.9334zm-.592-.9047c.4857-.7708.7667-1.6835.7667-2.6619 0-.235-.0162-.4662-.0476-.6926-.586.5475-.9524 1.3273-.9524 2.1926 0 .412.083.8045.2333 1.1619z" />
          <path d="m6.13626 14.7227c-.35046-.1436-.73412-.2227-1.13626-.2227-1.65685 0-3 1.3431-3 3s1.34315 3 3 3c.85591 0 1.6281-.3584 2.17466-.9334-.73815-.997-1.17466-2.2308-1.17466-3.5666 0-.4383.04701-.8657.13626-1.2773zm.91132.5847c-.03137.2264-.04758.4576-.04758.6926 0 .9784.281 1.8911.7667 2.6619.15026-.3574.2333-.7499.2333-1.1619 0-.8653-.36636-1.6451-.95242-2.1926z" />
        </g>
      </g>
    </svg>
  );
};

export default TtalIconDisabled;
