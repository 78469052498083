import axios from "axios";

export interface DomainInterface {
  isPublicDomain: boolean;
  orgCount: number;
  prefilledOrgName: string;
}

export const getDomainDetails = async () => {
  const response: {
    data: DomainInterface | null;
    status: number | null;
    error: Error | null;
  } = {
    data: null,
    status: null,
    error: null,
  };

  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_API_IDENTIFIER}/auth/organization/domain-details`
    );

    response.status = resp.status;
    response.data = resp.data;
  } catch (err: any) {
    console.error("err", err);

    response.error = err;
  }

  return response;
};

export const createNewTeam = async ({ orgName }: { orgName: string }) => {
  const response: {
    data: any;
    status: number | null;
    error: Error | null;
  } = {
    data: null,
    status: null,
    error: null,
  };

  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_API_IDENTIFIER}/auth/organization/create`,
      { orgName }
    );
    response.status = resp.status;

    response.data = resp.data;
  } catch (err: any) {
    console.error("err", err);

    response.status = err?.response?.status || err;
    response.error = err;
  }

  return response;
};
