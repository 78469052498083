import React from "react";

interface Props {
  stroke?: string;
  width?: number;
  height?: number;
}

const ScheduleIcon: React.FC<Props> = ({ stroke, width = 25, height = 24 }) => {
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.00977 8.19629H20.5098V18.1963C20.5098 18.7267 20.4307 19.2354 20.2901 19.6105C20.1494 19.9856 19.9587 20.1963 19.7598 20.1963H4.75977C4.56085 20.1963 4.37009 19.9856 4.22944 19.6105C4.08878 19.2354 4.00977 18.7267 4.00977 18.1963V8.19629Z"
        fill="#CDEEFF"
      />
      <path
        d="M3.75 8.25H20.25V4.5C20.25 4.30109 20.171 4.11032 20.0303 3.96967C19.8897 3.82902 19.6989 3.75 19.5 3.75H4.5C4.30109 3.75 4.11032 3.82902 3.96967 3.96967C3.82902 4.11032 3.75 4.30109 3.75 4.5V8.25Z"
        fill="#05AAFF"
      />
      <path
        d="M19.5 3.75H4.5C4.08579 3.75 3.75 4.08579 3.75 4.5V19.5C3.75 19.9142 4.08579 20.25 4.5 20.25H19.5C19.9142 20.25 20.25 19.9142 20.25 19.5V4.5C20.25 4.08579 19.9142 3.75 19.5 3.75Z"
        stroke="#05AAFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 2.25V5.25"
        stroke={stroke || "#05AAFF"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 2.25V5.25"
        stroke={stroke || "#05AAFF"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.75 8.25H20.25"
        stroke={stroke || "#05AAFF"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.625 14.25H9.375"
        stroke={stroke || "#05AAFF"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 11.625V16.875"
        stroke={stroke || "#05AAFF"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ScheduleIcon;
