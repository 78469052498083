import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import CopyLinkIcon from "../../assets/CopyLinkIcon";
import TickIcon from "../../assets/TickIcon";
import { copyToClipboard } from "../../utils";

const CopyButton = styled(motion.div)<{
  width?: number;
  height?: number;
  borderRadius?: number;
  compactView: boolean;
  noPadding: boolean;
  flexGrow: boolean;
}>`
  width: ${({ width }) => width && `${width}px`};
  height: ${({ height }) => height && `${height}px`};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${(props) => props.theme.color.purple100};
  color: ${(props) => props.theme.color.purple500};
  border-radius: ${({ borderRadius }) => borderRadius && `${borderRadius}px`};
  padding: ${({ compactView, noPadding }) =>
    !compactView && !noPadding && "16px 28px"};
  user-select: none;
  flex-grow: ${({ flexGrow }) => flexGrow && 1};

  @media (max-width: 950px) {
    padding: 16px 24px;
  }

  &:hover {
    background: ${(props) => props.theme.color.purple200};
    color: ${(props) => props.theme.color.purple600};
  }
`;

const ButtonText = styled.div<{
  weight: number;
  fontSize: number;
  lineHeight: number;
}>`
  font-weight: ${({ weight }) => `${weight}`};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  line-height: ${({ lineHeight }) => `${lineHeight}px`};
  margin-left: 6px;

  @media (max-width: 950px) {
    font-size: 16px;
  }

  @media (max-width: 790px) {
    font-size: 14px;
  }
`;

interface CopyLinkButtonProps {
  width?: number;
  height?: number;
  weight?: number;
  fontSize?: number;
  lineHeight?: number;
  borderRadius?: number;
  noPadding?: boolean;
  flexGrow?: boolean;
  compactView?: boolean;
  iconSize?: string;
  message?: string;
}

const CopyLinkButton: React.FC<CopyLinkButtonProps> = ({
  width,
  height,
  weight = 600,
  fontSize = 16,
  lineHeight = 26,
  borderRadius = 24,
  compactView = false,
  iconSize,
  message = "Copy invite link",
  noPadding = false,
  flexGrow = false,
}) => {
  const [copied, setCopied] = React.useState(false);
  const [isCopyButtonPressed, setIsCopyButtonPressed] = React.useState(false);
  const [isCopyButtonTapped, setIsCopyButtonTapped] = React.useState(false);

  const handleCopy = () => {
    copyToClipboard(window.location.href);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const handleCopyButtonMouseTap = () => {
    setIsCopyButtonTapped(true);
    setTimeout(() => {
      setIsCopyButtonTapped(false);
    }, 100);
  };

  return (
    <CopyButton
      onClick={handleCopy}
      onTap={handleCopyButtonMouseTap}
      onMouseDown={() => setIsCopyButtonPressed(true)}
      onMouseUp={() => setIsCopyButtonPressed(false)}
      onMouseLeave={() => setIsCopyButtonPressed(false)}
      animate={{
        scale: isCopyButtonPressed || isCopyButtonTapped ? 0.94 : 1,
      }}
      width={width}
      height={height}
      borderRadius={borderRadius}
      compactView={compactView}
      noPadding={noPadding}
      flexGrow={flexGrow}
    >
      {copied ? <TickIcon /> : <CopyLinkIcon size={iconSize} />}
      {!compactView && (
        <ButtonText weight={weight} fontSize={fontSize} lineHeight={lineHeight}>
          {copied ? "Link copied" : message}
        </ButtonText>
      )}
    </CopyButton>
  );
};

export default CopyLinkButton;
