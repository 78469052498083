import { RosterAttendeeType } from "../hooks/useChime";

export interface TriviaAttendee extends RosterAttendeeType {
  answer?: number;
}

export const TRIVIA_CHANNEL = "TRIVIA";

export const TRIVIA_TIME_PER_QUESTION = 25;

export const TRIVIA_TIMER_DURATION = 20;

export const TRIVIA_SHOW_ANSWER_START = 20;

export const TRIVIA_SHOW_ANSWER_END = 25;

export const DID_YOU_KNOW_TIMER_START = 35;

export const TOTAL_TIME = 40;

export const TRIVIA_SPLASH_TIMER = 7;

export const Trivia_Description =
  "A game of quick wit, played in small groups. But we keep a global score.";

export const Trivia_Bg_Color = "orange";
