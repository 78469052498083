export enum ZIndexLevel {
  VIDEO_TILE_INDEX = 5,
  FUNDING_MODAL_INDEX = 5,
  BACKDROP_INDEX = 12,
  TOOLBAR_OPTIONS_INDEX = 5,
  CANVAS_TOOLBAR_OPTIONS_INDEX = 1,
  THIS_OR_THAT_CONTAINER_INDEX = 3,
  LEADERBOARD_INDEX = 3,
  BOTTOM_TOAST_NOTIFICATION = 12,
  SOCIAL_PANEL = 11,
  PROMT_ARROW_INDEX = 5,
  BONUS_LABEL = 25,
  TRIVIA_THEME_LIST = 7,
  LOUNGE_VIDEO = 10,
  INSTRUCTION_MODAL = 5,
  LOUNGE_VIDEO_TIPS = 99999,
  LOUNGE_VIDEO_OVERLAY = 11,
  LOUNGE_VIDEO_OVERLAY_TIPS = 12,
  LOUNGE_VIDEO_RECOMMENDED_MODAL = 15,
  MUTE_MENU = 6,
  LOUNGE_GROUND_INDEX = 2,
  AUDIO_PERMISSIONS_MODAL_INDEX = 100,
  EMAIL_MODAL_OVERLAY_INDEX = 100,
}
