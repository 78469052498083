import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { getEventInstance } from "../../API/event";
import {
  EventInstanceStatus,
  EventTemplate,
  Template,
} from "../../types/event";
import { formattedSocialDateTime } from "../../utils";
import { RootState } from "../store";

export enum EventStatusTypes {
  ACTIVE = "active",
  ARCHIVED = "archived",
}

export interface EventDetails {
  eventInstanceName: string;
  eventInstanceId?: string;
  eventCode?: string;
  eventInstanceStatus: EventInstanceStatus;
  eventId: string;
  startTime?: string;
  endTime?: string;
  isHost: boolean;
  isCoHost: boolean;
  currentSegmentId?: string;
  eventTemplate?: EventTemplate;
  template?: Template;
  currentThemeId?: string;
  organizationId: string;
  organizationName: string;
  authorized: boolean;
  numberOfAttendees: number;
  country?: string;
  hostParticipantId?: string;
  participantId?: string;
  allHostParticipantIds?: string[];
  eventInstanceHost?: {
    name: string;
    id: string;
  };
  allOtherHosts: {
    name: string;
    id: string;
  }[];
  eventStatus: EventStatusTypes | "";
}

export interface EventState {
  eventDetailsloading: boolean;
  eventDetails: EventDetails;
  eventDateTime?: string;
  leftSocial: boolean;
  showSocialCreated: boolean;
  socialJoined: boolean;
}

const initialState: EventState = {
  eventDetailsloading: false,
  eventDetails: {
    eventInstanceName: "",
    eventInstanceStatus: EventInstanceStatus.UPCOMING,
    eventId: "",
    isHost: false,
    isCoHost: false,
    organizationId: "",
    organizationName: "",
    authorized: false,
    numberOfAttendees: 0,
    eventStatus: "",
    allOtherHosts: [],
  },
  leftSocial: false,
  showSocialCreated: false,
  socialJoined: false,
};

export const getEventDetails = createAsyncThunk(
  "events/getCurrentEvent",
  async (eventCode: string) => {
    const data = getEventInstance({ eventCode });

    return data;
  }
);

export const startMixer = createAsyncThunk(
  "startMixer",
  async (eventInstanceId: string) => {
    const response = await axios.put<string>(
      `${process.env.REACT_APP_API_IDENTIFIER}/event-instance/${eventInstanceId}/next`
    );
    return response.data;
  }
);

export const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    setLeftSocial: (state, action: PayloadAction<boolean>) => {
      state.leftSocial = action.payload;
    },
    setShowSocialCreated: (state, action: PayloadAction<boolean>) => {
      state.showSocialCreated = action.payload;
    },
    setSocialJoined: (state, action: PayloadAction<boolean>) => {
      state.socialJoined = action.payload;
    },
    setEventCode: (state, action: PayloadAction<string>) => {
      state.eventDetails.eventCode = action.payload;
    },
    resetEvent: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEventDetails.pending, (state) => {
        state.eventDetailsloading = true;
      })
      .addCase(getEventDetails.fulfilled, (state, action) => {
        if (action.payload) {
          state.eventDetails.eventTemplate = action.payload?.eventTemplate;

          const templateId = action.payload?.eventTemplate?.templateId;
          state.eventDetails.template =
            action.payload?.templatesMap[templateId];
          state.eventDetails.participantId = action?.payload?.participantId;
          state.eventDetailsloading = false;
          state.eventDetails.eventInstanceName = action.payload.event.name;
          state.eventDetails.eventInstanceId = action.payload.eventInstanceId;

          state.eventDetails.eventInstanceStatus =
            action.payload.eventInstanceStatus;

          state.eventDetails.eventId = action.payload.event.id;
          state.eventDetails.startTime = action.payload.startTime;
          state.eventDetails.endTime = action.payload.endTime;
          state.eventDetails.eventInstanceHost =
            action?.payload?.participantsMap[
              action?.payload?.eventInstanceHostParticipantId
            ];

          state.eventDetails.isCoHost =
            action?.payload?.allHostParticipantIds &&
            action?.payload?.participantId
              ? action?.payload?.allHostParticipantIds?.includes(
                  action?.payload?.participantId
                )
              : false;

          state.eventDetails.isHost =
            action?.payload?.eventInstanceHostParticipantId ===
            action?.payload?.participantId;

          let allOtherHostsList = action?.payload?.allHostParticipantIds
            .filter((val) => {
              return action?.payload?.participantId !== val;
            })
            .reduce((final: { name: string; id: string }[], val) => {
              if (action?.payload?.participantsMap[val]) {
                final.push(action?.payload?.participantsMap[val]);
              }
              return final;
            }, []);

          state.eventDetails.allOtherHosts = allOtherHostsList;
          state.eventDetails.eventStatus = action?.payload?.event?.status;

          state.eventDetails.allHostParticipantIds =
            action?.payload?.allHostParticipantIds;
          state.eventDetails.currentSegmentId = action.payload.currentSegmentId;
          state.eventDetails.currentThemeId = action.payload.currentThemeId;
          state.eventDetails.organizationId = action.payload.organizationId;
          state.eventDetails.organizationName =
            action.payload.organizationName || "";
          state.eventDetails.authorized = action.payload.authorized;
          state.eventDetails.numberOfAttendees =
            action.payload.numberOfAttendees;
          state.eventDetails.country = action.payload.country;
          state.eventDetails.hostParticipantId =
            action.payload.eventInstanceHostParticipantId;
          if (action.payload?.startTime) {
            state.eventDateTime = formattedSocialDateTime(
              action.payload.startTime
            );
          }
        }
      });
  },
});

export const {
  setLeftSocial,
  setShowSocialCreated,
  resetEvent,
  setSocialJoined,
  setEventCode,
} = eventSlice.actions;

export const selectEventDetails = (state: RootState) =>
  state.event.eventDetails;
export const selectEventDetailsLoading = (state: RootState) =>
  state.event.eventDetailsloading;
export const selectSocialId = (state: RootState) =>
  state.event.eventDetails?.eventId;
export const selectIsHost = (state: RootState) =>
  state.event.eventDetails?.isHost;
export const selectIsCoHost = (state: RootState) =>
  state.event.eventDetails?.isCoHost;
export const selectEventInstanceName = (state: RootState) =>
  state.event.eventDetails?.eventInstanceName;
export const selectThemeId = (state: RootState) =>
  state.event.eventDetails?.currentThemeId;
export const selectEventDateTime = (state: RootState) =>
  state.event.eventDateTime;
export const selectLeftSocial = (state: RootState) => state.event.leftSocial;
export const selectShowSocialCreated = (state: RootState) =>
  state.event.showSocialCreated;
export const selectNumberOfAttendees = (state: RootState) =>
  state.event.eventDetails?.numberOfAttendees;
export const selectSocialJoined = (state: RootState) =>
  state.event.socialJoined;
export const selectHostId = (state: RootState) =>
  state.event.eventDetails?.hostParticipantId;
export const selectLoggedOutParticipantId = (state: RootState) =>
  state.event.eventDetails?.participantId;
export const selectOrganizationId = (state: RootState) =>
  state.event.eventDetails?.organizationId;

export const selectEventInstanceHost = (state: RootState) =>
  state.event.eventDetails?.eventInstanceHost;

export const selectEventStatus = (state: RootState) =>
  state.event.eventDetails?.eventStatus;

export const selectOtherHosts = (state: RootState) =>
  state.event.eventDetails?.allOtherHosts;

export default eventSlice.reducer;
