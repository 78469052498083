import {
  createAction,
  createAsyncThunk,
  createSlice,
  Draft,
  PayloadAction,
} from "@reduxjs/toolkit";
import * as ReduxWebSocket from "@giantmachines/redux-websocket";

import { RootState } from "../../store";
import {
  LOUNGE_PREFIX,
  LOUNGE_MESSAGE,
  LOUNGE_DISCONNECT,
  LOUNGE_CLOSED,
  LOUNGE_BROKEN,
  LOUNGE_OPEN,
} from "../socketActionTypes";
import { SOCKET_CONTROL_MESSAGES } from "../ws";
import axios from "axios";
import { Segment } from "../../../types/segment";
import { PromptData } from "../questionSlice";
import { IcebreakerEnum } from "../../../types/activity";
import { ActivityEnum } from "../../../utils/activity.enum";
import { setShowVideoStartedToastToLocalStorage } from "../../../utils/videoOnLoungeLocalStorage";

export interface ReactionData {
  id: string;
  index: number;
  x?: number;
  y?: number;
}

export interface LoungeParticipantData {
  eid: string;
  name?: string;
  chimeAttendeeId?: string;
  isOnStage?: boolean;
  x?: number;
  y?: number;
  chat?: string;
  chatNextLine?: number;
  chatPreviousLine?: string;
  info?: string;
  doodleUrl?: string;
  reaction?: ReactionData;
  reactionToRemove?: string;
  reactionIndex?: number;
  ibDropped?: boolean;
  showMTInstructModal?: boolean;
  showDsInstructions?: boolean;
  videoLink?: string;
  videoState?: VideoStates;
  videoDuration?: number;
  suggestedVideopromptId?: string;
  participantSuggestedVideopromptId?: string;
  showVideoStartedToasts?: boolean;
  showVideoEndedToasts?: boolean;
}

export enum VideoStates {
  PLAY = "PLAY",
  PAUSE = "PAUSE",
  "NO-VIDEO" = "NO-VIDEO",
  END = "END",
  INITIAL = "INITIAL",
}
export interface LoungeState {
  connected: boolean;
  data: LoungeParticipantData[];
  isOnStage: boolean;
  firstTimeOnLounge: boolean;
  showPlus: boolean;
  reactions: ReactionData[];
  segments: Segment[];
  newUserDetected: number;
  icebreakerEditMode: boolean;
  icebreakerPrompt?: PromptData;
  icebreakerDropperId?: string;
  icebreakerDropped?: boolean;
  showMTInstructModal?: boolean;
  showDsInstructions?: boolean;
  totalOnStage: number;
  segmentsLoading: boolean;
  videoLink?: string;
  videoState?: VideoStates;
  videoDuration?: number;
  suggestedVideopromptId?: string;
  participantSuggestedVideopromptId?: string;
  videoPromptData?: PromptData | null;
  videoOnLoungeMode: boolean;
  currentActiveSegmentPromptId?: string;
  showVideoStartedToasts: boolean;
  showVideoEndedToasts: boolean;
  hostPausedAction: boolean;
  showNextMixerModal: boolean;
  isShowAboutLoungeOpen: boolean;
  isLoungeTourOpen: boolean;
}

export interface ToastData {
  icon: JSX.Element;
  msg: string;
}

export interface VideoMetadata {
  state: VideoStates;
  maxSeekTime: number;
  videoDuration: number;
  lastSeekTime: number;
  lastSeekTimeUpdateAt: string;
}
export interface SegmentPromptData {
  id: string;
  segmentId: string;
  segmentGroupId: string;
  promptId: string;
  prompt: PromptData;
  startedBy: string;
  organisationId: string;
  videoMetadata?: VideoMetadata;
}

const initialState: LoungeState = {
  connected: false,
  data: [],
  isOnStage: false,
  firstTimeOnLounge: sessionStorage.getItem("firstTimeOnLounge") === "true",
  showPlus: true,
  reactions: [],
  segments: [],
  newUserDetected: 0,
  icebreakerEditMode: false,
  totalOnStage: 0,
  segmentsLoading: false,
  videoLink: "",
  videoDuration: 0,
  videoState: VideoStates["NO-VIDEO"],
  suggestedVideopromptId: "",
  participantSuggestedVideopromptId: "",
  videoPromptData: null,
  videoOnLoungeMode: false,
  currentActiveSegmentPromptId: "",
  showVideoStartedToasts: false,
  showVideoEndedToasts: false,
  hostPausedAction: false,
  showNextMixerModal: false,
  isLoungeTourOpen: false,
  isShowAboutLoungeOpen: false,
};

export const getAllSegments = createAsyncThunk(
  "getAllSegments",
  async (eventInstanceId: string) => {
    const response = await axios.get<Segment[]>(
      `${process.env.REACT_APP_API_IDENTIFIER}/segment?eventInstanceId=${eventInstanceId}`
    );
    return response.data;
  }
);

export const fetchSegmentPrompts = createAsyncThunk(
  "fetchSegmentPrompts",
  async (segmentId: string) => {
    const response = await axios.get<SegmentPromptData[]>(
      `${process.env.REACT_APP_API_IDENTIFIER}/segment-prompt?segmentId=${segmentId}&isActive=true`
    );
    return response.data[0];
  }
);

export const getPromptById = createAsyncThunk(
  "getPromptById",
  async (promptId: string) => {
    const response: { data: PromptData } = await axios.get(
      `${process.env.REACT_APP_API_IDENTIFIER}/prompt/${promptId}`
    );

    return response.data;
  }
);

export const loungeSlice = createSlice({
  name: "lounge",
  initialState,
  reducers: {
    setIsOnStage: (state, action: PayloadAction<boolean>) => {
      state.isOnStage = action.payload;
    },
    setShowPlus: (state, action: PayloadAction<boolean>) => {
      state.showPlus = action.payload;
    },
    setFirstTimeOnLounge: (state, action: PayloadAction<boolean>) => {
      sessionStorage.setItem("firstTimeOnLounge", `${action.payload}`);
      state.firstTimeOnLounge = action.payload;
    },
    resetLounge: (state) => initialState,
    updateSegments: (state, action: PayloadAction<Segment[]>) => {
      state.segments = action.payload;
    },
    setIcebreakerEditMode: (state, action: PayloadAction<boolean>) => {
      state.icebreakerEditMode = action.payload;
    },
    setTotalOnStage: (state, action: PayloadAction<number>) => {
      state.totalOnStage = action.payload;
    },
    setSegmentsLoading: (state, action: PayloadAction<boolean>) => {
      state.segmentsLoading = action.payload;
    },
    setVideoLink: (state, action: PayloadAction<string>) => {
      state.videoLink = action.payload;
    },
    setVideoState: (state, action: PayloadAction<VideoStates>) => {
      state.videoState = action.payload;
    },
    setVideoDuration: (state, action: PayloadAction<number>) => {
      state.videoDuration = action.payload;
    },
    resetVideoPromptData: (state, action: PayloadAction<null>) => {
      state.videoPromptData = action.payload;
    },
    setVideoOnLoungeMode: (state, action: PayloadAction<boolean>) => {
      state.videoOnLoungeMode = action.payload;
    },
    resetSuggestedVideopromptId: (state, action: PayloadAction<string>) => {
      state.suggestedVideopromptId = action.payload;
    },
    resetParticipantSuggestedVideopromptId: (
      state,
      action: PayloadAction<string>
    ) => {
      state.participantSuggestedVideopromptId = action.payload;
    },
    resetCurrentActiveSegmentPromptId: (
      state,
      action: PayloadAction<string>
    ) => {
      state.currentActiveSegmentPromptId = action.payload;
    },
    resetShowVideoStartedToasts: (state, action: PayloadAction<boolean>) => {
      state.showVideoStartedToasts = action.payload;
    },
    resetShowVideoEndedToasts: (state, action: PayloadAction<boolean>) => {
      state.showVideoEndedToasts = action.payload;
    },
    resetHostPausedAction: (state) => {
      state.hostPausedAction = false;
    },
    setShowNextMixerModal: (state, action: PayloadAction<boolean>) => {
      state.showNextMixerModal = action.payload;
    },
    setIsLoungeTourOpen: (state, action: PayloadAction<boolean>) => {
      state.isLoungeTourOpen = action.payload;
    },
    setIsShowAboutLoungeOpen: (state, action: PayloadAction<boolean>) => {
      state.isShowAboutLoungeOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        createAction<{ message: string }>(LOUNGE_MESSAGE),
        (state: Draft<LoungeState>, action) => {
          // TODO: Create proper structure with types, etc
          let newUserDetected = state.newUserDetected;
          let response: LoungeParticipantData;
          try {
            const message = action.payload.message.replaceAll('"', "");
            // Decoding base64 as rabbitmq encodes message before transmitting
            response = JSON.parse(
              decodeURIComponent(escape(atob(message)))
            ) as LoungeParticipantData;
            // Control messages handling block
            if (response.info) {
              // Remove disconnected participant from the array
              if (response.info === SOCKET_CONTROL_MESSAGES.DISCONNECT) {
                const updatedData = [
                  ...state.data.filter((el) => el.eid !== response.eid),
                ];

                return {
                  ...state,
                  data: updatedData,
                };
              }

              if (
                response.info === SOCKET_CONTROL_MESSAGES.LOUNGE_VIDEO_MESSAGE
              ) {
                return {
                  ...state,
                  videoState: VideoStates.PAUSE,
                  hostPausedAction: true,
                };
              }
            }

            if (response.reaction?.id) {
              if (
                state.reactions.some((el) => el.id === response.reaction?.id)
              ) {
                return { ...state };
              }
              const reactionWithCordinates: ReactionData = {
                ...response.reaction,
                x: state.data.find((user) => user.eid === response.eid)?.x,
                y: state.data.find((user) => user.eid === response.eid)?.y,
              };
              const updatedReactions = [
                ...state.reactions,
                reactionWithCordinates,
              ];
              return { ...state, reactions: updatedReactions };
            }
            if (response.reactionToRemove) {
              const updatedReactions = [
                ...state.reactions.filter(
                  (el) => el.id !== response.reactionToRemove
                ),
              ];
              return { ...state, reactions: updatedReactions };
            }
            if (typeof response.ibDropped === "boolean") {
              if (response.ibDropped) {
                return {
                  ...state,
                  icebreakerDropped: true,
                };
              } else {
                return { ...state, icebreakerDropped: false };
              }
            }
            if (typeof response.showMTInstructModal === "boolean") {
              if (response.showMTInstructModal) {
                return {
                  ...state,
                  showMTInstructModal: response.showMTInstructModal,
                };
              } else {
                return { ...state, showMTInstructModal: undefined };
              }
            }

            if (
              response.videoLink ||
              response.videoState ||
              response.videoDuration
            ) {
              const showVideoEndedToastsFlag =
                response.videoState === VideoStates.END;
              const hostPause = response.videoState === VideoStates.PAUSE;
              return {
                ...state,
                videoLink: response.videoLink,
                videoState: response.videoState,
                videoDuration: response.videoDuration,
                showVideoEndedToasts: showVideoEndedToastsFlag,
                hostPausedAction: hostPause,
              };
            }

            if (response.showVideoStartedToasts) {
              setShowVideoStartedToastToLocalStorage(
                response.showVideoStartedToasts.toString()
              );
              return {
                ...state,
                showVideoStartedToasts: response.showVideoStartedToasts,
              };
            }

            if (response.showVideoEndedToasts) {
              return {
                ...state,
                showVideoEndedToasts: response.showVideoEndedToasts,
              };
            }

            if (response.suggestedVideopromptId) {
              return {
                ...state,
                suggestedVideopromptId: response.suggestedVideopromptId,
              };
            }

            if (response.participantSuggestedVideopromptId) {
              return {
                ...state,
                participantSuggestedVideopromptId:
                  response.participantSuggestedVideopromptId,
              };
            }

            if (typeof response.showDsInstructions === "boolean") {
              return {
                ...state,
                showDsInstructions: !!response.showDsInstructions,
              };
            }
            if (response.eid) {
              if (!state.data.some((el) => el.eid === response.eid)) {
                newUserDetected++;
              }
              // Transform cordinates according to screen resolution
              // And prevent user cards overflowing out of window
              if (response.x) {
                const absoluteX = (response.x * window.innerWidth) / 100;
                response.x =
                  absoluteX >= window.innerWidth - 100
                    ? window.innerWidth - 100
                    : absoluteX;
              }
              if (response.y) {
                const absoluteY = (response.y * window.innerHeight) / 100;
                response.y =
                  absoluteY >= window.innerHeight - 120
                    ? window.innerHeight - 120
                    : absoluteY;
              }
              // Update the data
              const updateIndex = state.data.findIndex(
                (el) => el.eid === response.eid
              );

              const updatedData = [...state.data];

              //update the value present in array if present else push the value
              //in this array, in this way array transversal needs to be done only once
              if (updateIndex >= 0) {
                updatedData[updateIndex] = {
                  ...updatedData[updateIndex],
                  ...response,
                };
              } else {
                updatedData.push(response);
              }

              return {
                ...state,
                data: updatedData,
                newUserDetected,
              };
            }

            return { ...state, newUserDetected };
          } catch (e) {
            console.error(e);
          }
        }
      )
      .addCase(
        createAction<{ message: string }>(LOUNGE_OPEN),
        (state: Draft<LoungeState>, action) => {
          return { ...state, connected: true };
        }
      )
      .addCase(
        createAction<{ message: string }>(LOUNGE_CLOSED),
        (state: Draft<LoungeState>, action) => {
          return { ...state, connected: false };
        }
      )
      .addCase(
        createAction<{ message: string }>(LOUNGE_BROKEN),
        (state: Draft<LoungeState>, action) => {
          return { ...state, connected: false };
        }
      )
      .addCase(
        createAction<{ message: string }>(LOUNGE_DISCONNECT),
        (state: Draft<LoungeState>, action) => {
          return { ...state, connected: false };
        }
      )
      .addCase(getAllSegments.fulfilled, (state, action) => {
        state.segments = action.payload;
        state.segmentsLoading = false;
      })
      .addCase(fetchSegmentPrompts.fulfilled, (state, action) => {
        state.currentActiveSegmentPromptId = action.payload?.id;
        state.icebreakerPrompt = action.payload?.prompt;
        if (action.payload?.prompt.activity.type === "ICEBREAKER") {
          state.icebreakerDropperId = action.payload?.startedBy;
        }
        if (action.payload?.videoMetadata) {
          console.log(
            "fetchSegmentPrompts",
            action.payload?.videoMetadata.lastSeekTime
          );
          state.videoState = action.payload?.videoMetadata?.state;
          state.videoDuration = action.payload?.videoMetadata.lastSeekTime;
        }
      })
      .addCase(getPromptById.fulfilled, (state, action) => {
        state.videoPromptData = action.payload;
      });
  },
});

export const sendCursorData = (request: LoungeParticipantData) =>
  ReduxWebSocket.send(request, LOUNGE_PREFIX);

export const {
  setIsOnStage,
  setShowPlus,
  resetLounge,
  updateSegments,
  setFirstTimeOnLounge,
  setIcebreakerEditMode,
  setTotalOnStage,
  setSegmentsLoading,
  setVideoDuration,
  resetVideoPromptData,
  setVideoLink,
  setVideoState,
  setVideoOnLoungeMode,
  resetSuggestedVideopromptId,
  resetParticipantSuggestedVideopromptId,
  resetCurrentActiveSegmentPromptId,
  resetShowVideoStartedToasts,
  resetShowVideoEndedToasts,
  resetHostPausedAction,
  setShowNextMixerModal,
  setIsLoungeTourOpen,
  setIsShowAboutLoungeOpen,
} = loungeSlice.actions;

export const selectloungeParticipants = (state: RootState) => state.lounge.data;

export const selectNumberOfParticipants = (state: RootState) =>
  state.lounge.data.length;

export const selectIsOnStage = (state: RootState) => state.lounge.isOnStage;

export const selectReactions = (state: RootState) => state.lounge.reactions;

export const selectNewUserDetected = (state: RootState) =>
  state.lounge.newUserDetected;

export const selectFirstTimeOnLounge = (state: RootState) =>
  state.lounge.firstTimeOnLounge;

export const selectIsLoungeConnected = (state: RootState) =>
  state.lounge.connected;

export const selectShowPlus = (state: RootState) => state.lounge.showPlus;

export const selectSegments = (state: RootState) => state.lounge.segments;

export const selectSegmentsLoading = (state: RootState) =>
  state.lounge.segmentsLoading;

export const selectIcebreakerMode = (state: RootState) =>
  (state.lounge.icebreakerPrompt?.activity.name as IcebreakerEnum) ===
  IcebreakerEnum.THIS_OR_THAT;

export const selectVideoOnLoungePrompt = (state: RootState) =>
  (state.lounge.icebreakerPrompt?.activity.name as ActivityEnum) ===
  ActivityEnum.LOUNGE_VIDEO;

export const selectVideoOnLoungeMode = (state: RootState) =>
  state.lounge.videoOnLoungeMode;

export const selectIcebreakerEditMode = (state: RootState) =>
  state.lounge.icebreakerEditMode;

export const selectIcebreakerPrompt = (state: RootState) =>
  state.lounge.icebreakerPrompt;

export const selectIcebreakerDropped = (state: RootState) =>
  state.lounge.icebreakerDropped;

export const selectIcebreakerDropperId = (state: RootState) =>
  state.lounge.icebreakerDropperId;

export const selectshowMTInstructModal = (state: RootState) =>
  state.lounge.showMTInstructModal;

export const selectShowDsInstructions = (state: RootState) =>
  state.lounge.showDsInstructions;

export const selectTotalOnStage = (state: RootState) =>
  state.lounge.totalOnStage;

export const selectVideoLink = (state: RootState) => state.lounge.videoLink;

export const selectVideoState = (state: RootState) => state.lounge.videoState;

export const selectVideoDuration = (state: RootState) =>
  state.lounge.videoDuration;

export const selectSuggestedVideopromptId = (state: RootState) =>
  state.lounge.suggestedVideopromptId;

export const selectParticipantSuggestedVideopromptId = (state: RootState) =>
  state.lounge.participantSuggestedVideopromptId;

export const selectVideoPromptData = (state: RootState) =>
  state.lounge.videoPromptData;

export const selectCurrentActiveSegmentPromptId = (state: RootState) =>
  state.lounge.currentActiveSegmentPromptId;

export const selectShowVideoStartedToasts = (state: RootState) =>
  state.lounge.showVideoStartedToasts;

export const selectHostPausedAction = (state: RootState) =>
  state.lounge.hostPausedAction;

export const selectShowVideoEndedToasts = (state: RootState) =>
  state.lounge.showVideoEndedToasts;

export const selectShowNextMixerModal = (state: RootState) =>
  state.lounge.showNextMixerModal;

export const selectIsLoungeTourOpen = (state: RootState) =>
  state.lounge.isLoungeTourOpen;

export const selectIsShowAboutLoungeOpen = (state: RootState) =>
  state.lounge.isShowAboutLoungeOpen;

export default loungeSlice.reducer;
