import { FunctionComponent } from "react";
import { useTheme } from "styled-components";
import ClockTime3 from "../../assets/ClockTime3";
import FrowningFace from "../../assets/FrowningFace";
import { JoinPageViews } from "../../types/views";
import { MeetingStatusBox, MeetingStatusIcon } from "./Elements";

interface JoinSocialWaitingViewsProps {
  view: JoinPageViews;
}

const JoinSocialWaitingViews: FunctionComponent<JoinSocialWaitingViewsProps> =
  ({ view }) => {
    const theme = useTheme();

    return (
      <MeetingStatusBox
        bgC={
          view === JoinPageViews.WAITING
            ? theme.color.yellow100
            : theme.color.orange100
        }
      >
        <MeetingStatusIcon>
          {view === JoinPageViews.WAITING ? <ClockTime3 /> : <FrowningFace />}
        </MeetingStatusIcon>
        {view === JoinPageViews.WAITING
          ? "Waiting for host to start."
          : "Social is over. You just missed it! "}
      </MeetingStatusBox>
    );
  };

export default JoinSocialWaitingViews;
