import React from "react";
import styled from "styled-components";
import Cross from "../assets/Cross";
import Tags, { TagsType } from "../components/common/Tags";

const Backdrop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 12;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const CrossButton = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  cursor: pointer;
`;

const Container = styled.div<{
  borderRadius: number;
  isBackgroundVisible: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background: ${({ theme, isBackgroundVisible }) =>
    isBackgroundVisible ? theme.color.white : "none"};
  border-radius: ${(props) => props.borderRadius}px;

  &:hover {
    ${CrossButton} {
      display: flex;
    }
  }
`;

const LogoContainer = styled.div`
  margin-bottom: 26px;
`;

interface ModalProps {
  children: React.ReactNode;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  isRigid?: boolean;
  logo?: JSX.Element;
  tags?: TagsType[];
  selectedTheme?: string;
  setSelectedTheme?: React.Dispatch<React.SetStateAction<string>>;
  closeHandler?: () => void;
  borderRadius?: number;
  showCross?: boolean;
  isBackgroundVisible?: boolean;
}

const ModalProvider: React.FC<ModalProps> = ({
  children,
  setShowModal,
  isRigid = false,
  logo,
  tags = [],
  selectedTheme,
  setSelectedTheme,
  closeHandler,
  borderRadius = 40,
  showCross = true,
  isBackgroundVisible = true,
}) => {
  return (
    <Backdrop
      onClick={() => {
        if (!isRigid) {
          closeHandler && closeHandler();
          setShowModal(false);
        }
      }}
    >
      <LogoContainer>{logo}</LogoContainer>
      {tags.length > 0 && (
        <Tags
          tags={tags}
          selectedTheme={selectedTheme}
          setSelectedTheme={setSelectedTheme}
        />
      )}

      <Container
        isBackgroundVisible={isBackgroundVisible}
        onClick={(ev) => ev.stopPropagation()}
        borderRadius={borderRadius}
      >
        {children}
        {!isRigid && showCross && (
          <CrossButton
            onClick={() => {
              closeHandler && closeHandler();
              setShowModal(false);
            }}
          >
            <Cross />
          </CrossButton>
        )}
      </Container>
    </Backdrop>
  );
};

export default ModalProvider;
