import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { CaptureConsole, Offline } from "@sentry/integrations";

import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store/store";

Sentry.init({
  dsn: "https://3d3e4176337b4bb7ab81509c83d4bf89@o419822.ingest.sentry.io/5937691",
  integrations: [
    new Integrations.BrowserTracing(),
    new CaptureConsole({
      levels: ["error"],
    }),
    new Offline({
      // limit how many events will be localled saved. Defaults to 30.
      maxStoredEvents: 100,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_ENVIRONMENT,
});

Sentry.setUser({
  id: localStorage.getItem("identifier") || undefined,
  username: localStorage.getItem("guestName") || undefined,
});

declare global {
  interface Window {
    fwcrm: any;
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
