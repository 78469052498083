import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export interface FundDetailItemInterface {
  amount: number;
  segment_group_id: string;
}

export interface FundDetailsMapInterface {
  [key: string]: FundDetailItemInterface;
}
export interface DrunkStartupState {
  previousDSMixerSegmentId?: string;
  currentZoomLevel: string;
  dropDownParentName: string;
  totalFundAmount: number;
  fundDetails: FundDetailsMapInterface;
  dsCanvasMinimized: boolean;
}

const initialState: DrunkStartupState = {
  previousDSMixerSegmentId: localStorage.getItem("ds-segment-id") || "",
  currentZoomLevel: "100%",
  dropDownParentName: "",
  totalFundAmount: 100,
  fundDetails: {},
  dsCanvasMinimized: false,
};

export const drunkStartupSlice = createSlice({
  name: "drunkStartup",
  initialState,
  reducers: {
    setPreviousDSMixerSegmentId: (state, action: PayloadAction<string>) => {
      localStorage.setItem("ds-segment-id", action.payload);
      state.previousDSMixerSegmentId = action.payload;
    },
    setCurrentZoomLevel: (state, action: PayloadAction<string>) => {
      state.currentZoomLevel = action.payload;
    },
    setDropDownParent: (state, action: PayloadAction<string>) => {
      state.dropDownParentName = action.payload;
    },
    setTotalFundAmount: (state, action: PayloadAction<number>) => {
      state.totalFundAmount = action.payload;
    },
    setFundDetails: (state, action: PayloadAction<FundDetailsMapInterface>) => {
      state.fundDetails = action.payload;
    },
    setDsCanvasMinimized: (state, action: PayloadAction<boolean>) => {
      state.dsCanvasMinimized = action.payload;
    },
  },
});

export const {
  setPreviousDSMixerSegmentId,
  setDropDownParent,
  setCurrentZoomLevel,
  setTotalFundAmount,
  setFundDetails,
  setDsCanvasMinimized,
} = drunkStartupSlice.actions;

export const previousDSMixerSegment = (state: RootState) =>
  state.drunkStartup.previousDSMixerSegmentId;

export const selectCurrentZoomLevel = (state: RootState) =>
  state.drunkStartup.currentZoomLevel;

export const selectDropDownParent = (state: RootState) =>
  state.drunkStartup.dropDownParentName;

export const selectTotalFundAmount = (state: RootState) =>
  state.drunkStartup.totalFundAmount;

export const selectFundDetails = (state: RootState) =>
  state.drunkStartup.fundDetails;

export const selectDsCanvasMinimized = (state: RootState) =>
  state.drunkStartup.dsCanvasMinimized;

export default drunkStartupSlice.reducer;
