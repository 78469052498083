export const AmplitudeEvents = {
  VIDEO_ON: "VIDEO_ON",
  VIDEO_OFF: "VIDEO_OFF",
  MUTED: "MUTED",
  UNMUTED: "UNMUTED",
  WYR_SELECTION: "WYR_SELECTION",
  START_MIXER: "START_MIXER",
  END_FOR_ALL: "END_FOR_ALL",
  LEAVE_SOCIAL: "LEAVE_SOCIAL",
  VIDEO_PERMISSION: "VIDEO_PERMISSION",
  AUDIO_PERMISSION: "AUDIO_PERMISSION",
  JOINED_STAGE: "JOINED_STAGE",
  EXIT_STAGE: "EXIT_STAGE",
  SOCIAL_FEEDBACK: "SOCIAL_FEEDBACK",
  WYR_FEEDBACK: "WYR_FEEDBACK",
  JOINED_MIXER: "JOINED_MIXER",
  STAYED_TILL_END_OF_MIXER: "STAYED_TILL_END_OF_MIXER",
  TRIVIA_SELECTION: "TRIVIA_SELECTION",
  TRIVIA_FEEDBACK: "TRIVIA_FEEDBACK",
  CHAT_OPENED: "CHAT_OPENED",
  CHAT_CLOSED: "CHAT_CLOSED",
  SOCIAL_PANEL_OPENED: "SOCIAL_PANEL_OPENED",
  SOCIAL_PANEL_CLOSED: "SOCIAL_PANEL_CLOSED",
  MIXER_DELETED: "MIXER_DELETED",
  MIXER_ADDED: "MIXER_ADDED",
  MIXER_REARRANGED: "MIXER_REARRANGED",
  SOCIAL_NAME_EDITED: "SOCIAL_NAME_EDITED",
  SOCIAL_CREATED: "SOCIAL_CREATED",
  JOINED_SOCIAL: "JOINED_SOCIAL",
  DOODLE_START_DRAWING: "DOODLE_START_DRAWING",
  DOODLE_CORRECT_GUESS: "DOODLE_CORRECT_GUESS",
  DOODLE_WRONG_GUESS: "DOODLE_WRONG_GUESS",
  EMAIL_SUBMITTED: "EMAIL_SUBMITTED",
  VOICE_FOCUS_TOGGLED: "VOICE_FOCUS_TOGGLED",
  INTERNET_DISCONNECTED: "INTERNET_DISCONNECTED",
  INTERNET_RECONNECTED: "INTERNET_RECONNECTED",
  CAMERA_SWITCHED: "CAMERA_SWITCHED",
  SPEAKER_SWITCHED: "SPEAKER_SWITCHED",
  MIC_SWITCHED: "MIC_SWITCHED",
  CAMERA_TESTED: "CAMERA_TESTED",
  SPEAKER_TESTED: "SPEAKER_TESTED",
  MIC_TESTED: "MIC_TESTED",
  REACTION_BOX_OPENED: "REACTION_BOX_OPENED",
  REACTION_SELECTED: "REACTION_SELECTED",
  REACTION_SENT: "REACTION_SENT",
  END_MIXER_MODAL_CLOSED: "END_MIXER_MODAL_CLOSED",
  END_MIXER_MODAL_OPENED: "END_MIXER_MODAL_OPENED",
  EXTEND_MIXER_MODAL_CLOSED: "EXTEND_MIXER_MODAL_CLOSED",
  EXTEND_MIXER_MODAL_OPENED: "EXTEND_MIXER_MODAL_OPENED",
  ENDED_MIXER: "ENDED_MIXER",
  CHAT_ON_ENTER: "CHAT_ON_ENTER",
  ICEBREAKER_STARTED: "ICEBREAKER_STARTED",
  ICEBREAKER_CLEARED: "ICEBREAKER_CLEARED",
  ICEBREAKER_PROMPT_UPDATED: "ICEBREAKER_PROMPT_UPDATED",
  PAY_NOW_CTA_CLICKED: "PAY_NOW_CTA_CLICKED",
  TTAL_SELECT_STATEMENT: "TTAL_SELECT_STATEMENT",
  TTAL_SUBMIT_STATEMENTS: "TTAL_SUBMIT_STATEMENTS",
  TTAL_REVEALED: "TTAL_REVEALED",
  TTAL_REFRESH_HINT: "TTAL_REFRESH_HINT",
  TTAL_SKIPPED: "TTAL_SKIPPED",
  TTAL_EDIT_STATEMENT: "TTAL_EDIT_STATEMENT",
  SPEED_1ON1_NEW_ROUND: "SPEED_1ON1_NEW_ROUND",
  RR_PROMPT_OPENED: "RR_PROMPT_OPENED",
  CHIME_MEETING_FAILED: "CHIME_MEETING_FAILED",
  CHIME_MEETING_FATAL_ERROR: "CHIME_MEETING_FATAL_ERROR",
  NOTES_TAKEN: "NOTES_TAKEN",
  NOTES_TAKER_SWITCHED: "NOTES_TAKER_SWITCHED",
  SELECT_THEME: "SELECT_THEME",
  PDF_DOWNLOADED: "PDF_DOWNLOADED",
  HOW_IT_WORKS_OPENED: "HOW_IT_WORKS_OPENED",
  DS_ADDED_OWN_IDEA: "DS_ADDED_OWN_IDEA",
  DS_FUND_SUBMITTED: "DS_FUND_SUBMITTED",
  DS_OPENED_LEADERBOARD: "DS_OPENED_LEADERBOARD",
  DS_TWEETED_TO_ELON: "DS_TWEETED_TO_ELON",
  DS_LINK_COPIED: "DS_LINK_COPIED",
  DS_CANVAS_MINIMIZED: "DS_CANVAS_MINIMIZED",
  DS_CANVAS_MAXIMIZED: "DS_CANVAS_MAXIMIZED",
  DS_SHARE_PAGE_VISITED: "DS_SHARE_PAGE_VISITED",
  SLACK_APP_INSTALL_MODAL_OPENED: "SLACK_APP_INSTALL_MODAL_OPENED",
  SLACK_APP_INSTALL_BUTTON_CLICKED: "SLACK_APP_INSTALL_BUTTON_CLICKED",
  SLACK_IT_BUTTON_CLICKED: "SLACK_IT_BUTTON_CLICKED",
  SLACK_APP_CALLBACK_PAGE_OPENED: "SLACK_APP_CALLBACK_PAGE_OPENED",
  SLACK_APP_CREATE_CHANNEL_PAGE_OPENED: "SLACK_APP_CREATE_CHANNEL_PAGE_OPENED",
  MY_SOCIALS_TAB_OPENED: "MY_SOCIALS_TAB_OPENED",
  SCHEDULE_SOCIAL_LIST_VIEW: "SCHEDULE_SOCIAL_LIST_VIEW",
  ARCHIVED_SOCIAL_LIST_VIEW: "ARCHIVED_SOCIAL_LIST_VIEW",
  SCHEDULED_SOCIAL_EDITED: "SCHEDULED_SOCIAL_EDITED",
  SOCIAL_ARCHIVED: "SOCIAL_ARCHIVED",
  SOCIAL_UNARCHIVED: "SOCIAL_UNARCHIVED",
  SCHEDULED_SOCIAL_STARTED: "SCHEDULED_SOCIAL_STARTED",
  SCHEDULED_SOCIAL_HISTORY_EXPANDED: "SCHEDULED_SOCIAL_HISTORY_EXPANDED",
  SCHEDULED_SOCIAL_ADDED_TO_GCAL: "SCHEDULED_SOCIAL_ADDED_TO_GCAL",
  SCHEDULED_SOCIAL_ADDED_TO_OUTLOOK: "SCHEDULED_SOCIAL_ADDED_TO_OUTLOOK",
  SOCIAL_SCHEDULED: "SOCIAL_SCHEDULED",
  CHUNK_LOAD_ERROR_REFRESHED: "CHUNK_LOAD_ERROR_REFRESHED",
  GUEST_LOGIN: "GUEST_LOGIN",
  SIGNUP_GOOGLE: "SIGNUP_GOOGLE",
  LOG_IN_GOOGLE: "LOG_IN_GOOGLE",
  CREATE_AN_ACCOUNT_MY_SOCIALS: "CREATE_AN_ACCOUNT_MY_SOCIALS",
  CREATE_AN_ACCOUNT_TEAMS: "CREATE_AN_ACCOUNT_TEAMS",
  SIGNUP_FROM_DASHBOARD_MENU: "SIGNUP_FROM_DASHBOARD_MENU",
  SIGNUP_FROM_SCHEDULE_BUTTONS: "SIGNUP_FROM_SCHEDULE_BUTTONS",
  SCHEDULE_DASHBOARD_HEADER: "SCHEDULE_DASHBOARD_HEADER",
  INSTANT_SOCIAL: "INSTANT_SOCIAL",
  LOUNGE_TOUR_TIP_HOVER: "LOUNGE_TOUR_TIP_HOVER",
  LOUNGE_TOUR_TIP_START: "LOUNGE_TOUR_TIP_START",
  LOUNGE_TOUR_TIP_CLOSE: "LOUNGE_TOUR_TIP_CLOSE",
};
