import React from "react";
import SlackAddOrSelectForm from "../components/Slack/SlackAddOrSelectForm";
import WorkspaceDetails from "../components/Slack/WorkspaceDetails";
import { selectSlackWorkspaceName } from "../store/features/userSlice";
import { useAppSelector } from "../store/hooks";
import { Container } from "./SlackCallback";

const SlackCreateChannel: React.FC = () => {
  const slackWorkspaceName = useAppSelector(selectSlackWorkspaceName);

  return (
    <Container>
      <SlackAddOrSelectForm />
      <WorkspaceDetails workspaceName={slackWorkspaceName} />
    </Container>
  );
};

export default SlackCreateChannel;
