import React, { useEffect, useState } from "react";
import styled from "styled-components";
import signUpPage from "../../assets/signUpPageBackground.png";
import ThursdayLoginLogo from "../../assets/ThursdayLoginLogo";
import { ActionButton } from ".";
import { useQuery } from "../../hooks";
import { googleRedirectApi } from "../../API/invite";
import SignUpContainer from "./SignUpContainer";
import { AmplitudeEvents } from "../../types/amplitudeEvents";
import amplitude from "amplitude-js";

const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: url(${signUpPage});
  background-size: cover;
`;

export const SignInButton = styled(ActionButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.lounge.background};
`;

export const SignInText = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: #4285f4;
  margin-left: 16px;
`;

const TnC = styled.div`
  line-height: 18px;
  color: ${(props) => props.theme.color.darkGray};
  font-size: 11px;
  font-weight: 600;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 8px;
  display: flex;
`;

const UnderlinedText = styled.a`
  text-decoration: underline;
  color: ${(props) => props.theme.color.darkGray} !important;
  margin: 0px 3px;
`;

const LogoContainer = styled.div`
  position: fixed;
  top: 24px;
  left: 24px;
`;

export const BottomDiv = styled.div`
  margin-top: 9px;
  display: flex;
  justify-content: center;
  line-height: 21px;
  font-size: 13px;
`;

export const BottomDivGrayText = styled.div`
  font-weight: 400;
  color: ${(props) => props.theme.color.darkGray};
`;

export const BottomDivLoginText = styled.div`
  font-weight: 600;
  text-decoration: underline;
  margin-left: 4px;
  color: ${(props) => props.theme.color.purple500};
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.color.purple600};
  }
`;

const LoginAndBottomTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 16px;
  height: 100vh;
  width: 100vw;
`;

const MainLogincContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const OptionText = styled.div`
  width: 19px;
  height: 21px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 160%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #b2bcc6;
  margin: 123px 24px;
`;

interface LoginComponentProps {
  redirect?: string;
  organizationCode?: string;
  title?: string;
  subtitle?: string;
}

const LoginComponent: React.FunctionComponent<LoginComponentProps> = ({
  redirect = "dashboard",
  title = "Sign up on Thursday",
  subtitle = "",
  organizationCode = "",
}) => {
  const query = useQuery();
  const emailIdentifier = query.get("email_identifier") || "";

  return (
    <Container>
      <LogoContainer>
        <ThursdayLoginLogo />
      </LogoContainer>

      <LoginAndBottomTextContainer>
        <MainLogincContainer>
          <SignUpContainer
            organizationCode={organizationCode}
            redirect={redirect}
            isGuestLogin={true}
            signupFrom={"guestlogin"}
          />

          <OptionText> OR</OptionText>

          <SignUpContainer
            organizationCode={organizationCode}
            redirect={redirect}
            isGuestLogin={false}
            signupFrom={"signup"}
          />
        </MainLogincContainer>
        <BottomDiv>
          <BottomDivGrayText>Already have an account?</BottomDivGrayText>{" "}
          <BottomDivLoginText
            onClick={() => {
              amplitude.getInstance().logEvent(AmplitudeEvents.LOG_IN_GOOGLE);
              googleRedirectApi({
                organizationCode,
                redirect,
                emailIdentifier,
              });
            }}
          >
            Log in
          </BottomDivLoginText>
        </BottomDiv>
      </LoginAndBottomTextContainer>
      <TnC>
        By continuing you agree to our{" "}
        <UnderlinedText
          target="_blank"
          href={process.env.REACT_APP_MARKETING_URL + "/terms"}
        >
          Terms {"&"} Conditions
        </UnderlinedText>{" "}
        and our{" "}
        <UnderlinedText
          target="_blank"
          href={process.env.REACT_APP_MARKETING_URL + "/privacy"}
        >
          Privacy Policy
        </UnderlinedText>
      </TnC>
    </Container>
  );
};

export default LoginComponent;
