import React from "react";
import { Helmet } from "react-helmet-async";
import {
  selectEventDateTime,
  selectEventInstanceName,
} from "../store/features/eventSlice";
import { useAppSelector } from "../store/hooks";

const SocialMetaProvider: React.FC = ({ children }) => {
  const eventInstanceName = useAppSelector(selectEventInstanceName);
  const eventDateTime = useAppSelector(selectEventDateTime);

  return (
    <>
      {eventDateTime ? (
        <Helmet>
          <title>{`Join ${eventInstanceName}`}</title>
          <meta
            name="description"
            content={`Starts ${eventDateTime}`}
            data-rh="true"
          />
        </Helmet>
      ) : null}
      {children}
    </>
  );
};

export default SocialMetaProvider;
