import axios from "axios";
import { FundedIdeasItemInterface } from "../components/DrunkStartup/FundingModal";
import { FundDetailItemInterface } from "../store/features/DrunkStartup/drunkStartupSlice";

interface FundingLeaderBoardDataInterface {
  [key: string]: number;
}

export const controller = new AbortController();

export const fetchDsCanvases = async (dsSegmentId: string) => {
  try {
    // TODO: DrunkStartupsV1.0: Add type
    const response = await axios.get(
      `${process.env.REACT_APP_API_IDENTIFIER}/drunk-startups/get-room-data/${dsSegmentId}`
    );
    return response.data;
  } catch (err) {
    console.error("err", err);
  }
};

export const fetchDsSharePageCanvases = async (dsSegmentId: string) => {
  try {
    // TODO: DrunkStartupsV1.0: Add type
    const response = await axios.get(
      `${process.env.REACT_APP_API_IDENTIFIER}/drunk-startups/get-share-room-data/${dsSegmentId}`
    );
    return response.data;
  } catch (err) {
    console.error("err", err);
  }
};

export const liveblocksAuthEndpoint = async (room: string) => {
  if (room) {
    const response = await fetch(
      `${process.env.REACT_APP_API_IDENTIFIER}/drunk-startups/auth/${room}`,
      {
        method: "POST",
        signal: controller.signal,
        credentials: "include",
      }
    );
    return await response.json();
  } else {
    controller.abort();
  }
};

export const fetchFundingLeaderBoard = async (dsSegmentId: string) => {
  try {
    const response = await axios.get<FundingLeaderBoardDataInterface>(
      `${process.env.REACT_APP_API_IDENTIFIER}/segment/${dsSegmentId}/fund-leaderboard`
    );
    return response.data;
  } catch (err) {
    console.error("err", err);
  }
};

export const fetchAvailableFundings = async (dsSegmentId: string) => {
  try {
    const response = await axios.get<Array<FundedIdeasItemInterface>>(
      `${process.env.REACT_APP_API_IDENTIFIER}/segment/${dsSegmentId}/fund`
    );
    return response.data;
  } catch (err) {
    console.error("err", err);
  }
};

export const addOrUpdateFunds = async (
  dsSegmentId: string,
  fund_details: Array<FundDetailItemInterface>
) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_IDENTIFIER}/segment/${dsSegmentId}/fund`,
      { fund_details }
    );
    if (response.status === 202) {
      return true;
    } else {
      console.error("err", response);
      return false;
    }
  } catch (err) {
    console.error("err", err);
  }
};
