import * as React from "react";
import styled from "styled-components";

const CaretIconContainer = styled.div<{ inverted: boolean }>`
  transform: ${({ inverted }) => (inverted ? `rotateX(180deg)` : `none`)};
  margin-top: ${({ inverted }) => (inverted ? "-3px" : "3px")};
`;

const CaretIcon: React.FC<{ inverted?: boolean; strokeColor?: string }> = ({
  inverted = false,
  strokeColor,
}) => {
  return (
    <CaretIconContainer inverted={inverted}>
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.0022 10.0001L8.00221 6.00007L4.00221 10.0001"
          stroke={strokeColor || "#475766"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </CaretIconContainer>
  );
};

export default CaretIcon;
