export const ThursdayFullTextLogo = () => {
  return (
    <svg
      width="152"
      height="38"
      viewBox="0 0 152 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="152" height="38" fill="white" />
      <path
        d="M8.92204 14.978H12.774V10.658H8.92204V6.33796H3.48604V10.658H0.462036V14.978H3.48604V18.722V22.214C3.48604 26.858 6.29404 29.09 9.96604 29.09C10.866 29.09 11.73 28.982 12.63 28.802L12.594 24.194C12.234 24.266 11.802 24.302 11.226 24.302C9.82204 24.302 8.92204 23.438 8.92204 21.818V18.722V14.978Z"
        fill="#6633FF"
      />
      <path
        d="M26.15 10.298C23.738 10.298 21.866 11.594 20.966 13.718V3.70996H15.458V28.91H20.966V18.83C20.966 16.238 22.19 14.726 24.242 14.726C26.294 14.726 27.482 16.13 27.482 18.542V28.91H32.99V17.714C32.99 13.25 30.254 10.298 26.15 10.298Z"
        fill="#6633FF"
      />
      <path
        d="M47.6139 10.658V20.738C47.6139 23.366 46.4259 24.842 44.4459 24.842C42.5739 24.842 41.4579 23.438 41.4579 21.062V10.658H35.9499V21.854C35.9499 26.318 38.6499 29.306 42.6099 29.306C44.9139 29.306 46.6419 28.406 47.6859 26.786L47.6139 28.91H53.1219V10.658H47.6139Z"
        fill="#6633FF"
      />
      <path
        d="M62.6782 29.522L67.5742 27.434L65.1982 21.854C63.3622 17.75 64.4782 15.338 67.6102 15.338C68.3302 15.338 68.7262 15.374 69.4102 15.446V10.478C68.8702 10.37 68.2582 10.298 67.6102 10.298C64.4782 10.298 62.5702 12.566 62.7142 16.094H62.6422L60.2302 10.514L55.3342 12.566L62.6782 29.522Z"
        fill="#6633FF"
      />
      <path
        d="M79.6505 29.594C84.2945 29.594 87.6065 27.074 87.6065 23.438C87.6065 20.342 85.3025 18.506 81.0545 17.606L79.2905 17.21C78.1745 16.958 77.6345 16.418 77.6345 15.626C77.6345 14.582 78.6425 13.826 80.0105 13.826C81.6665 13.826 83.0705 14.906 83.1425 16.49L88.1105 16.382C88.0025 12.602 84.6185 9.97396 80.0105 9.97396C75.5465 9.97396 72.3425 12.566 72.3425 16.274C72.3425 19.406 74.1785 21.17 78.1025 21.962L79.8665 22.358C81.4145 22.718 82.2425 23.078 82.2425 24.086C82.2425 25.094 81.1985 25.742 79.6505 25.742C77.7065 25.742 76.4465 24.698 76.4105 23.078L71.4065 23.222C71.4425 26.966 74.8265 29.594 79.6505 29.594Z"
        fill="#6633FF"
      />
      <path
        d="M104.125 3.70996V12.71C102.829 11.162 101.029 10.298 98.7974 10.298C93.6134 10.298 90.0134 14.186 90.0134 19.802C90.0134 25.418 93.6134 29.306 98.7974 29.306C101.029 29.306 102.829 28.478 104.125 26.93V28.91H109.633V24.734V3.70996H104.125ZM100.021 24.77C97.3934 24.77 95.5934 22.754 95.5934 19.802C95.5934 16.85 97.4294 14.798 100.021 14.798C102.613 14.798 104.413 16.85 104.413 19.802C104.413 22.754 102.613 24.77 100.021 24.77Z"
        fill="#6633FF"
      />
      <path
        d="M118.346 29.126C121.19 29.126 123.098 27.758 123.818 25.598H123.926C123.926 27.182 124.034 28.298 124.214 28.91H129.542C129.29 27.506 129.182 25.274 129.182 23.33V17.714C129.182 13.25 125.726 10.118 120.758 10.118C116.042 10.118 112.694 13.034 112.514 17.246L117.734 17.354C117.806 15.698 119.03 14.582 120.758 14.582C122.522 14.582 123.71 15.734 123.71 17.426V18.11L120.65 18.398C115.682 18.83 112.694 20.99 112.694 24.266C112.694 27.29 114.854 29.126 118.346 29.126ZM120.47 25.67C119.066 25.67 118.166 24.95 118.166 23.798C118.166 22.358 119.354 21.458 121.406 21.278L123.782 21.026V21.746C123.782 23.942 122.63 25.67 120.47 25.67Z"
        fill="#6633FF"
      />
      <path
        d="M135.014 36.29C139.046 36.29 142.142 33.806 144.05 29.018L151.538 10.658H145.85L141.53 22.394L136.814 10.658H130.982L138.542 28.586L138.254 29.234C137.318 31.178 135.878 31.502 134.078 31.502C133.43 31.502 132.818 31.43 132.494 31.322V36.002C133.214 36.218 134.078 36.29 135.014 36.29Z"
        fill="#6633FF"
      />
    </svg>
  );
};
