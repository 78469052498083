import React from "react";
import { useTheme } from "styled-components";

const GreenDiceIcon = () => {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
    >
      <path
        d="M38 6.63562H10C7.794 6.63562 6 8.42962 6 10.6356V38.6356C6 40.8416 7.794 42.6356 10 42.6356H38C40.206 42.6356 42 40.8416 42 38.6356V10.6356C42 8.42962 40.206 6.63562 38 6.63562ZM16 19.6356C15.2041 19.6354 14.4409 19.3189 13.8783 18.7559C13.3157 18.193 12.9997 17.4295 13 16.6336C13.0003 15.8377 13.3167 15.0745 13.8797 14.5119C14.4427 13.9493 15.2061 13.6334 16.002 13.6336C16.3961 13.6338 16.7863 13.7115 17.1504 13.8624C17.5144 14.0134 17.8452 14.2345 18.1237 14.5133C18.4023 14.7921 18.6233 15.123 18.7739 15.4871C18.9246 15.8513 19.0021 16.2415 19.002 16.6356C19.0019 17.0297 18.9241 17.4199 18.7732 17.784C18.6222 18.148 18.4011 18.4788 18.1223 18.7574C17.8436 19.0359 17.5127 19.2569 17.1485 19.4076C16.7844 19.5583 16.3941 19.6358 16 19.6356ZM24 27.6356C23.2041 27.6354 22.4409 27.3189 21.8783 26.7559C21.3157 26.193 20.9997 25.4295 21 24.6336C21.0003 23.8377 21.3167 23.0745 21.8797 22.5119C22.4427 21.9493 23.2061 21.6334 24.002 21.6336C24.7979 21.6339 25.5611 21.9503 26.1237 22.5133C26.6863 23.0763 27.0023 23.8397 27.002 24.6356C27.0017 25.4315 26.6853 26.1947 26.1223 26.7574C25.5593 27.32 24.7959 27.6359 24 27.6356ZM32 35.6356C31.6059 35.6355 31.2157 35.5577 30.8516 35.4068C30.4876 35.2559 30.1568 35.0347 29.8783 34.7559C29.5997 34.4772 29.3787 34.1463 29.2281 33.7821C29.0774 33.418 28.9999 33.0277 29 32.6336C29.0001 32.2395 29.0779 31.8493 29.2288 31.4853C29.3798 31.1212 29.6009 30.7905 29.8797 30.5119C30.1584 30.2333 30.4893 30.0124 30.8535 29.8617C31.2176 29.711 31.6079 29.6335 32.002 29.6336C32.7979 29.6339 33.5611 29.9503 34.1237 30.5133C34.6863 31.0763 35.0023 31.8397 35.002 32.6356C35.0017 33.4315 34.6853 34.1947 34.1223 34.7574C33.5593 35.32 32.7959 35.6359 32 35.6356Z"
        fill={theme.color.green500}
      />
      <path
        d="M13.6481 18.6239C14.2235 19.1996 15.004 19.5232 15.8179 19.5235C16.2209 19.5236 16.62 19.4444 16.9924 19.2903C17.3648 19.1362 17.7032 18.9102 17.9883 18.6253C18.2733 18.3404 18.4995 18.0022 18.6539 17.6299C18.8082 17.2576 18.8877 16.8586 18.8879 16.4556C18.888 16.0526 18.8087 15.6535 18.6546 15.2811C18.5005 14.9087 18.2746 14.5703 17.9897 14.2852C17.7048 14.0001 17.3666 13.774 16.9943 13.6196C16.622 13.4653 16.223 13.3858 15.8199 13.3856C15.006 13.3853 14.2253 13.7084 13.6496 14.2838C13.0739 14.8591 12.7503 15.6396 12.75 16.4535C12.7497 17.2675 13.0728 18.0482 13.6481 18.6239Z"
        fill={theme.color.green200}
      />
      <path
        d="M21.8292 26.805C22.4046 27.3807 23.185 27.7043 23.999 27.7045C24.8129 27.7048 25.5936 27.3817 26.1693 26.8064C26.7451 26.2311 27.0687 25.4506 27.0689 24.6366C27.0692 23.8227 26.7461 23.042 26.1708 22.4663C25.5954 21.8906 24.815 21.567 24.001 21.5667C23.1871 21.5664 22.4064 21.8895 21.8307 22.4648C21.2549 23.0402 20.9313 23.8207 20.9311 24.6346C20.9308 25.4485 21.2539 26.2292 21.8292 26.805Z"
        fill={theme.color.green200}
      />
      <path
        d="M31.0057 35.6516C31.378 35.806 31.777 35.8855 32.1801 35.8856C32.994 35.8859 33.7747 35.5628 34.3504 34.9875C34.9261 34.4121 35.2497 33.6316 35.25 32.8177C35.2503 32.0038 34.9272 31.2231 34.3519 30.6474C33.7765 30.0716 32.996 29.748 32.1821 29.7478C31.7791 29.7476 31.38 29.8269 31.0076 29.981C30.6352 30.1351 30.2968 30.361 30.0117 30.6459C29.7267 30.9308 29.5005 31.269 29.3461 31.6413C29.1918 32.0136 29.1123 32.4127 29.1121 32.8157C29.112 33.2187 29.1913 33.6178 29.3454 33.9902C29.4995 34.3626 29.7254 34.701 30.0103 34.986C30.2952 35.2711 30.6334 35.4973 31.0057 35.6516Z"
        fill={theme.color.green200}
      />
      <rect
        x="6"
        y="6.63562"
        width="36"
        height="36"
        rx="4"
        stroke={theme.color.green200}
        strokeWidth="3"
      />
    </svg>
  );
};

export default GreenDiceIcon;
