const GoogleIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.8128 16.0787C31.8128 15.0102 31.7261 13.9359 31.5412 12.8848H16.7153V18.9376H25.2054C24.8531 20.8898 23.7212 22.6167 22.0635 23.714V27.6414H27.1288C30.1032 24.9037 31.8128 20.8609 31.8128 16.0787Z"
        fill="#4285F4"
      />
      <path
        d="M16.7152 31.4368C20.9545 31.4368 24.5297 30.0449 27.1343 27.6421L22.0692 23.7147C20.66 24.6736 18.8406 25.2165 16.721 25.2165C12.6203 25.2165 9.14341 22.4499 7.89588 18.7305H2.66895V22.7791C5.33729 28.0871 10.7721 31.4368 16.7152 31.4368Z"
        fill="#34A853"
      />
      <path
        d="M7.89025 18.73C7.23183 16.7778 7.23183 14.6639 7.89025 12.7118V8.66309H2.66911C0.439724 13.1045 0.439724 18.3372 2.66911 22.7786L7.89025 18.73Z"
        fill="#FBBC04"
      />
      <path
        d="M16.7152 6.22041C18.9562 6.18576 21.122 7.02898 22.7449 8.57687L27.2326 4.0892C24.391 1.42088 20.6195 -0.0461221 16.7152 8.26721e-05C10.7721 8.26721e-05 5.33729 3.34994 2.66895 8.66349L7.89009 12.7122C9.13184 8.98692 12.6145 6.22041 16.7152 6.22041Z"
        fill="#EA4335"
      />
    </svg>
  );
};

export default GoogleIcon;
