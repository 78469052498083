const CharadesIcon: React.FC<{ size?: string }> = ({ size = "24" }) => {
  return (
    <svg
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="m12 1.19995c-.6628 0-1.2.53726-1.2 1.2v3.60398c.0663-.00261.133-.00393.2-.00393h2c.067 0 .1336.00132.2.00393v-3.60398c0-.66274-.5373-1.2-1.2-1.2zm1.2 5.80496v9.99019c-.0663.0033-.1329.0049-.2.0049h-2c-.0671 0-.1338-.0017-.2-.0049v-9.99019c.0662-.00326.1329-.00491.2-.00491h2c.0671 0 .1337.00165.2.00491zm-2.4 10.99119c.0663.0026.133.0039.2.0039h2c.067 0 .1336-.0013.2-.0039v3.6038c0 .6628-.5373 1.2001-1.2 1.2001-.6628 0-1.2-.5373-1.2-1.2z"
        fill="#475766"
        fillRule="evenodd"
      />
      <g fill="#05aaff">
        <path d="m7 11c0-2.20914 1.79086-4 4-4h2c2.2091 0 4 1.79086 4 4v2c0 2.2091-1.7909 4-4 4h-2c-2.20914 0-4-1.7909-4-4z" />
        <circle cx="5.5" cy="5.5" r="2.5" />
        <circle cx="5.5" cy="18.5" r="2.5" />
        <circle cx="2.5" cy="12.5" r="2.5" />
        <circle r="2.5" transform="matrix(-1 0 0 1 18.5 5.5)" />
        <circle r="2.5" transform="matrix(-1 0 0 1 18.5 18.5)" />
        <circle r="2.5" transform="matrix(-1 0 0 1 21.5 12.5)" />
      </g>
    </svg>
  );
};

export default CharadesIcon;
