import * as React from "react";
import { HelmetProvider } from "react-helmet-async";
import { ThemeProvider } from "styled-components";
import amplitude from "amplitude-js";

import Routes from "./routes";
import theme from "./theme";
import SocialMetaProvider from "./Providers/SocialMetaProvider";
import AuthAndMonitorProvider from "./Providers/AuthAndMonitorProvider";
import { setUpInterceptor } from "./Services/interceptors";
import { useStore } from "react-redux";
import axios from "axios";
import { setIsInterceptorSet } from "./store/features/authSlice";
import { useAppDispatch } from "./store/hooks";

const App: React.FunctionComponent = () => {
  const store = useStore();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    setUpInterceptor(store, axios);
    dispatch(setIsInterceptorSet(true));
  }, []);

  React.useEffect(() => {
    let monitoringFilePath = "localMonitoring.js";
    const externalScript = document.createElement("script");

    if (process.env.REACT_APP_ENVIRONMENT === "production") {
      monitoringFilePath = "prodMonitoring.js";
    }

    externalScript.src = monitoringFilePath;
    externalScript.type = "text/javascript";

    document.head.appendChild(externalScript);
  }, []);

  amplitude.getInstance().init("9fc53026dda3397923221ce4209e5d68");

  return (
    <HelmetProvider>
      <SocialMetaProvider>
        <ThemeProvider theme={theme}>
          <AuthAndMonitorProvider>
            <Routes />
          </AuthAndMonitorProvider>
        </ThemeProvider>
      </SocialMetaProvider>
    </HelmetProvider>
  );
};

export default App;
