import React from "react";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  selectIdentifier,
  selectIsInterceptorSet,
  setIdentifier,
} from "../store/features/authSlice";
import {
  getEventDetails,
  selectEventDetails,
  selectLoggedOutParticipantId,
} from "../../src/store/features/eventSlice";
import {
  selectIsParentConnected,
  selectSlackUpdated,
  setSlackUpdated,
} from "../store/features/parentSlice";
import {
  getUserDetails,
  loginStatus,
  selectActiveOrganization,
  selectGuestParticipantId,
  selectIsAdmin,
  selectIsValidated,
  selectParticipantId,
  selectUserLoginStatus,
} from "../store/features/userSlice";
import { ToastContainer } from "react-toastify";
import { utmData } from "../API/common";
import { utmCookieRequest } from "../API/common";
import { useIsGuestHost, useIsLoggedInUser, useWindowQuery } from "../hooks";
import { removeGuestParticipantIdFromLocalStorage } from "../utils/guestParticipantStore";
import SpinningLoader from "../components/common/SpinningLoader";

// TODO: Separate into two providers: Auth & Monitoring
const AuthAndMonitorProvider: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const eventDetails = useAppSelector(selectEventDetails);
  const identifier = useAppSelector(selectIdentifier);
  const connected = useAppSelector(selectIsParentConnected);
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const isAdmin = useAppSelector(selectIsAdmin);
  const slackUpdated = useAppSelector(selectSlackUpdated);
  const params = useWindowQuery();
  const ref = params.get("ref");
  const utm_source = params.get("utm_source");
  const utm_medium = params.get("utm_medium");
  const utm_campaign = params.get("utm_campaign");
  const utm_term = params.get("utm_term");
  const utm_content = params.get("utm_content");
  const participantId = useAppSelector(selectParticipantId);
  const isInterceptorSet = useAppSelector(selectIsInterceptorSet);
  const guestParticipantId = useAppSelector(selectGuestParticipantId);
  const loggedOutParticipantId = useAppSelector(selectLoggedOutParticipantId);
  const isGuestHost = useIsGuestHost();
  const isValidated = useAppSelector(selectIsValidated);
  const isUserLoggedIn = useIsLoggedInUser();

  React.useEffect(() => {
    let identifier = "";
    if (participantId && participantId !== identifier) {
      removeGuestParticipantIdFromLocalStorage();
      identifier = participantId;
    }
    if (isGuestHost && guestParticipantId) {
      identifier = guestParticipantId;
    }
    if (loggedOutParticipantId && !identifier) {
      identifier = loggedOutParticipantId;
    }
    dispatch(setIdentifier(identifier));
  }, [
    dispatch,
    identifier,
    participantId,
    isGuestHost,
    guestParticipantId,
    loggedOutParticipantId,
  ]);

  React.useEffect(() => {
    if (connected && eventDetails?.eventCode) {
      // Get instance on new parent socket connection
      dispatch(getEventDetails(eventDetails.eventCode));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected, dispatch, eventDetails?.eventCode]);

  React.useEffect(() => {
    //utm tracking cookie request
    let utmParams: utmData = {};
    if (utm_source) utmParams["utm_source"] = utm_source;
    if (ref) utmParams["ref"] = ref;
    if (utm_medium) utmParams["utm_medium"] = utm_medium;
    if (utm_campaign) utmParams["utm_campaign"] = utm_campaign;
    if (utm_term) utmParams["utm_term"] = utm_term;
    if (utm_content) utmParams["utm_content"] = utm_content;
    utmCookieRequest(utmParams);
  }, []);

  React.useEffect(() => {
    if (isInterceptorSet) {
      dispatch(getUserDetails());
    }
  }, [dispatch, isInterceptorSet]);

  React.useEffect(() => {
    if (slackUpdated) {
      dispatch(getUserDetails());
      dispatch(setSlackUpdated(false));
    }
  }, [dispatch, slackUpdated]);

  return (
    <>
      {isUserLoggedIn && !isValidated ? <SpinningLoader /> : children}
      {/* This toast container is responsible for all the toast messages throughout the app */}
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
    </>
  );
};

export default AuthAndMonitorProvider;
