import styled from "styled-components";
import { TldrawApp } from "@tldraw/tldraw";
import { ReactComponent as PlusCircleIcon } from "../../assets/DrunkStartup/PlusCircleIcon.svg";
import { ReactComponent as MinusCircleIcon } from "../../assets/DrunkStartup/MinusCircleIcon.svg";
import { MOBILE_BREAKPOINT } from "../../types/breakpoints";

const ToolbarOptionsContainer = styled.div`
  display: flex;
  bottom: 0rem;
  justify-content: center;
  align-items: center;
  height: 29px;
  gap: 1rem;

  /* hiding zoom controls on mobile devices */
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

const ActionTools = styled.div`
  padding: 0 4px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  height: 29px;
  z-index: 4;
  user-select: none;
`;

const ActionToolButton = styled.div`
  cursor: pointer;
`;

const ActionToolButtonIcon = styled.div`
  height: 29px;
`;

const ZoomOptionsControlSkin = ({ tlApp: app }: { tlApp: TldrawApp }) => {
  return (
    <ToolbarOptionsContainer>
      <ActionTools>
        <ActionToolButton onClick={() => app.zoomIn()}>
          <ActionToolButtonIcon>
            <PlusCircleIcon />
          </ActionToolButtonIcon>
        </ActionToolButton>
        <ActionToolButton onClick={() => app.zoomOut()}>
          <ActionToolButtonIcon>
            <MinusCircleIcon />
          </ActionToolButtonIcon>
        </ActionToolButton>
      </ActionTools>
    </ToolbarOptionsContainer>
  );
};

export default ZoomOptionsControlSkin;
