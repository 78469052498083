import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
export interface AuthState {
  isAuthorized: boolean | undefined;
  isInterceptorSet: boolean;
  guestName: string;
  identifier: string;
  userEmail?: string;
  templateName: string;
}

const initialState: AuthState = {
  isAuthorized: undefined,
  isInterceptorSet: false,
  guestName: localStorage.getItem("guestName") || "",
  identifier: localStorage.getItem("identifier") || "",
  userEmail: localStorage.getItem("userEmail") || "",
  templateName: sessionStorage.getItem("templateName") || "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsAuthorized: (state, action: PayloadAction<boolean>) => {
      state.isAuthorized = action.payload;
    },
    setIsInterceptorSet: (state, action: PayloadAction<boolean>) => {
      state.isInterceptorSet = action.payload;
    },
    setGuestName: (state, action: PayloadAction<string>) => {
      localStorage.setItem("guestName", action.payload);
      state.guestName = action.payload;
    },
    setIdentifier: (state, action: PayloadAction<string>) => {
      localStorage.setItem("identifier", action.payload);
      state.identifier = action.payload;
    },
    setUserEmail: (state, action: PayloadAction<string>) => {
      localStorage.setItem("userEmail", action.payload);
      state.userEmail = action.payload;
    },
    setTemplateName: (state, action: PayloadAction<string>) => {
      sessionStorage.setItem("templateName", `${action.payload}`);
      state.templateName = action.payload;
    },
  },
});

export const {
  setGuestName,
  setIdentifier,
  setIsAuthorized,
  setIsInterceptorSet,
  setUserEmail,
  setTemplateName,
} = authSlice.actions;

export const selectGuestName = (state: RootState) => state.auth.guestName;

export const selectIdentifier = (state: RootState) => state.auth.identifier;

export const selectIsAuthorized = (state: RootState) => state.auth.isAuthorized;

export const selectIsInterceptorSet = (state: RootState) =>
  state.auth.isInterceptorSet;

export const selectUserEmail = (state: RootState) => state.auth.userEmail;

export const selectTemplateName = (state: RootState) => state.auth.templateName;

export default authSlice.reducer;
