interface Breakpoints extends Array<string> {
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
}

export var darkTheme: {
  breakpoints: Breakpoints;
  mediaQueries: {
    min: {
      xs: string;
      sm: string;
      md: string;
      lg: string;
      xl: string;
    };
    max: {
      xs: string;
      sm: string;
      md: string;
      lg: string;
      xl: string;
    };
  };
  fonts: {
    body: string;
    monospace: string;
  };
  fontSizes: {
    baseFontSize: string;
    fontWeight: string;
    h1: {
      fontSize: string;
      fontWeight: string;
      lineHeight: string;
      mobile: {
        fontSize: string;
        fontWeight: string;
        lineHeight: string;
      };
    };
    h2: {
      fontSize: string;
      fontWeight: string;
      lineHeight: string;
      mobile: {
        fontSize: string;
        fontWeight: string;
        lineHeight: string;
      };
    };
    h3: {
      fontSize: string;
      fontWeight: string;
      lineHeight: string;
      mobile: {
        fontSize: string;
        fontWeight: string;
        lineHeight: string;
      };
    };
    h4: {
      fontSize: string;
      fontWeight: string;
      lineHeight: string;
      mobile: {
        fontSize: string;
        fontWeight: string;
        lineHeight: string;
      };
    };
    h5: {
      fontSize: string;
      fontWeight: string;
      lineHeight: string;
      mobile: {
        fontSize: string;
        fontWeight: string;
        lineHeight: string;
      };
    };
    h6: {
      fontSize: string;
      fontWeight: string;
      lineHeight: string;
      mobile: {
        fontSize: string;
        fontWeight: string;
        lineHeight: string;
      };
    };
    text: {
      fontSize: string;
      lineHeight: string;
    };
    label: {
      fontSize: string;
      lineHeight: string;
    };
    small: {
      fontSize: string;
      lineHeight: string;
    };
    footnote: {
      fontSize: string;
      lineHeight: string;
    };
  };
  radii: {
    default: string;
    circle: string;
  };
  zIndex: {
    navigation: number;
    controlBar: number;
    modal: number;
    popOver: number;
    notificationGroup: number;
  };
  modalSizes: {
    md: {
      width: string;
      height: string;
    };
    lg: {
      width: string;
      height: string;
    };
    fullscreen: {
      width: string;
      height: string;
    };
  };
  iconButtonSizes: {
    sm: string;
    md: string;
    lg: string;
  };
  name: string;
  buttons: {
    primary: {
      shadow: string;
      static: {
        bgd: string;
        border: string;
        text: string;
        shadow: string;
      };
      hover: {
        bgd: string;
        border: string;
        text: string;
        shadow: string;
      };
      focus: {
        bgd: string;
        border: string;
        text: string;
        shadow: string;
      };
      active: {
        bgd: string;
        border: string;
        text: string;
        shadow: string;
      };
      selected: {
        bgd: string;
        border: string;
        text: string;
        shadow: string;
      };
      disabled: {
        bgd: string;
        border: string;
        text: string;
        shadow: string;
      };
    };
    secondary: {
      shadow: string;
      static: {
        bgd: string;
        border: string;
        text: string;
        shadow: string;
      };
      hover: {
        bgd: string;
        border: string;
        text: string;
        shadow: string;
      };
      focus: {
        bgd: string;
        border: string;
        text: string;
        shadow: string;
      };
      active: {
        bgd: string;
        border: string;
        text: string;
        shadow: string;
      };
      selected: {
        bgd: string;
        border: string;
        text: string;
        shadow: string;
      };
      disabled: {
        bgd: string;
        border: string;
        text: string;
        shadow: string;
      };
    };
    icon: {
      shadow: string;
      static: {
        bgd: string;
        border: string;
        text: string;
        shadow: string;
      };
      hover: {
        bgd: string;
        border: string;
        text: string;
        shadow: string;
      };
      focus: {
        bgd: string;
        border: string;
        text: string;
        shadow: string;
      };
      active: {
        bgd: string;
        border: string;
        text: string;
        shadow: string;
      };
      selected: {
        bgd: string;
        border: string;
        text: string;
        shadow: string;
      };
      disabled: {
        bgd: string;
        border: string;
        text: string;
        shadow: string;
      };
    };
  };
  colors: {
    primary: {
      lightest: string;
      lighter: string;
      light: string;
      main: string;
      dark: string;
      darker: string;
      darkest: string;
    };
    secondary: {
      light: string;
      main: string;
      dark: string;
    };
    error: {
      lightest: string;
      lighter: string;
      light: string;
      primary: string;
      dark: string;
      darker: string;
      darkest: string;
    };
    success: {
      lightest: string;
      lighter: string;
      light: string;
      primary: string;
      dark: string;
      darker: string;
      darkest: string;
    };
    info: {
      lightest: string;
      lighter: string;
      light: string;
      primary: string;
      dark: string;
      darker: string;
      darkest: string;
    };
    warning: {
      lightest: string;
      lighter: string;
      light: string;
      primary: string;
      dark: string;
      darker: string;
      darkest: string;
    };
    greys: {
      black: string;
      grey100: string;
      grey90: string;
      grey80: string;
      grey70: string;
      grey60: string;
      grey50: string;
      grey40: string;
      grey30: string;
      grey20: string;
      grey10: string;
      white: string;
    };
  };
  global: {
    bgd: string;
    text: string;
    fontSize: string;
  };
  links: {
    fontColor: string;
    fontColorHover: string;
    fontColorActive: string;
    fontColorVisited: string;
  };
  shadows: {
    none: string;
    small: string;
    medium: string;
    large: string;
  };
  inputs: {
    bgd: string;
    border: string;
    borderRadius: string;
    fontColor: string;
    placeholder: string;
    shadow: string;
    clearBg: string;
    focus: {
      bgd: string;
      border: string;
      shadow: string;
    };
    error: {
      border: string;
      fontColor: string;
      shadow: string;
    };
    checked: {
      bgd: string;
      border: string;
      fontColor: string;
      shadow: string;
    };
  };
  modal: {
    bgd: string;
    text: string;
    wrapperBgd: string;
    titleSize: string;
    titleWeight: string;
    shadow: string;
    border: string;
  };
  popOver: {
    menuBgd: string;
    menuBorder: string;
    shadow: string;
    itemBgd: string;
    itemText: string;
    titleText: string;
    active: {
      itemBgd: string;
      itemText: string;
    };
    disabled: string;
    separator: string;
  };
  notification: {
    shadow: string;
    error: {
      text: string;
      closeButton: {
        text: string;
        hover: {
          bgd: string;
          text: string;
        };
        active: {
          bgd: string;
          text: string;
        };
      };
    };
    success: {
      text: string;
      closeButton: {
        text: string;
        hover: {
          bgd: string;
          text: string;
        };
        active: {
          bgd: string;
          text: string;
        };
      };
    };
    info: {
      text: string;
      closeButton: {
        text: string;
        hover: {
          bgd: string;
          text: string;
        };
        active: {
          bgd: string;
          text: string;
        };
      };
    };
    warning: {
      text: string;
      closeButton: {
        text: string;
        hover: {
          bgd: string;
          text: string;
        };
        active: {
          bgd: string;
          text: string;
        };
      };
    };
  };
  controlBar: {
    text: string;
    shadow: string;
    bgd: string;
    border: string;
    opacity: number;
    selected: {
      text: string;
      bgd: string;
    };
  };
  roster: {
    title: string;
    primaryText: string;
    secondaryText: string;
    headerBorder: string;
    containerBorder: string;
    bgd: string;
    fgd: string;
    shadow: string;
    maxWidth: string;
  };
  navbar: {
    text: string;
    bgd: string;
    headerBorder: string;
    wrapperBgd: string;
  };
  videoGrid: {
    bgd: string;
  };
  chatBubble: {
    incoming: {
      bgd: string;
      fontColor: string;
      linkColor: string;
      linkColorHover: string;
      linkColorActive: string;
      linkColorVisited: string;
    };
    outgoing: {
      bgd: string;
      fontColor: string;
      linkColor: string;
      linkColorHover: string;
      linkColorActive: string;
      linkColorVisited: string;
    };
    container: {
      fontColor: string;
      bgd: string;
    };
  };
  channelList: {
    bgd: string;
    fontColor: string;
    border: string;
    active: {
      bgd: string;
      fontColor: string;
    };
    hover: {
      bgd: string;
    };
    focus: {
      border: string;
      selectedBorder: string;
    };
    selected: {
      bgd: string;
      fontColor: string;
    };
    iconButton: {
      activeBgd: string;
    };
  };
  chatDateHeader: {
    bgd: string;
    fontColor: string;
  };
  messageAttachment: {
    size: {
      fontColor: string;
      bgd: string;
      letterSpacing: string;
      lineHight: string;
      fontSize: string;
    };
    icon: {
      bgd: string;
      color: string;
    };
    name: {
      fontColor: string;
    };
    content: {
      letterSpacing: string;
      bgd: string;
      fontColor: string;
    };
  };
};
