import React from "react";

const BrainstormIcon: React.FC<{
  size?: number;
  fill?: string;
  stroke?: string;
  highlight?: string;
  bottom?: string;
}> = ({
  size = 24,
  fill = "#FFC200",
  stroke = "#FFC200",
  highlight = "#FFF3CC",
  bottom = "#FFDA66",
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.3782 15.6562C6.48532 14.9619 5.76216 14.0735 5.26351 13.0583C4.76485 12.0432 4.50378 10.9278 4.50008 9.79678C4.48133 5.72803 7.7532 2.34366 11.822 2.24991C13.3966 2.21258 14.9431 2.67198 16.242 3.56294C17.541 4.45391 18.5264 5.73121 19.0586 7.21371C19.5908 8.6962 19.6428 10.3086 19.2071 11.8223C18.7714 13.336 17.8702 14.6741 16.6313 15.6468C16.3577 15.8583 16.136 16.1296 15.9832 16.44C15.8305 16.7503 15.7507 17.0915 15.7501 17.4374V17.9999C15.7501 18.1988 15.6711 18.3896 15.5304 18.5302C15.3898 18.6709 15.199 18.7499 15.0001 18.7499H9.00008C8.80117 18.7499 8.6104 18.6709 8.46975 18.5302C8.3291 18.3896 8.25008 18.1988 8.25008 17.9999V17.4374C8.24773 17.0941 8.16814 16.7557 8.01721 16.4474C7.86628 16.139 7.64788 15.8686 7.3782 15.6562Z"
        fill={fill}
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 21.75H15"
        stroke={bottom}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7617 5.3252C13.6784 5.48059 14.5242 5.91667 15.1825 6.57325C15.8407 7.22984 16.279 8.07456 16.4367 8.99082"
        stroke={highlight}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BrainstormIcon;
